import React, { useState, useEffect } from 'react';
import util from "../../util/util";
import { useParams,Link } from 'react-router-dom';
import { sdx } from "../../sdx";
import { If, Paging, Perpagedd ,AmtFormat} from "../../util/controls";
import fbase from "../../services/firebase";
import lang from '../../lang';
let client_id = 0, ref = {};

let $ = window.$;

export default function SellingItems(props) {

    client_id = props.client_id;
    const fn = {};
    const { id } = useParams();
    let pfn = props.computedMatch;
    const [d, setData] = useState({
        list: { result: [], page: {} },
        sdata: { p: 1, ps: 25 },
        data: {},
        branches: [],
        roles: [],
        m_users: [],
        m_users_branch:[],
        all_data: [],
        name: 0,
        is_arabic: 0,
        curInfo: {},
        download_url : '' ,
        downloadPdf :'',
        print : '',
        branches: [],
        branchesids: [],
        branch_name : '',
        todayDate : '',
        start_date : '',
        end_date : '',
        sub_role :[],
        super_role:[],
        user_type:'',
        branchesidscopy: [],
        date_type: '',
    });
    fn.render = () => { setData({ ...d }); }

       d.todayDate = new Date();
       d.todayDate = d.todayDate.getFullYear() + "-" + (d.todayDate.getMonth() + 1) + "-" + d.todayDate.getDate();

    fn.sdata = (p, ps) => {
        
        const  start_date = $('#from_date').val();
        const  end_date = $('#to_date').val();
        d.sdata.p = p ? p : 1;
        d.sdata.ps = ps ? ps : d.sdata.ps;
        d.sdata.start_date =  start_date ? start_date : window.sessionStorage['start_date'];
        d.sdata.end_date =  end_date ? end_date : window.sessionStorage['end_date'];
        d.sdata.authtoken = window.localStorage['token'];
        d.sdata.branch_ids = d.branchesids;
        
        return $("#sbfrm").serialize() + '&p=' + d.sdata.p + '&ps=' + d.sdata.ps + '&client_id=' + props.client_id + '&start_date=' +d.sdata.start_date + '&end_date=' +d.sdata.end_date +'&authtoken=' +d.sdata.authtoken;
    }

    fn.lists = (e, p, ps) => {
        if (e) e.preventDefault();
        util.ajx("reports/expensesDetails", fn.sdata(p, ps)).then(res => {
            if (res.code === 200) {
                d.list = res.result;
                d.curInfo = res.curInfo;
                d.m_users = res.m_users;
                d.all_data = res.all_data;
                d.date_type = res.date_type;

                // d.download_url = res.download_url;
                // d.downloadPdf = res.downloadPdf;
                // d.print = res.print;
                d.branches = res.branches;
                d.branchesids = res.branch_ids;
                d.branchesidscopy = res.branch_ids_copy;
                d.branch_name = res.branch_name;
                var dd = new Date(res.start_date);
                var strDate = dd.getDate()+ "-" + (dd.getMonth() + 1) + "-" +dd.getFullYear();
                d.start_date = strDate;


                var d1 = new Date(res.end_date);
                var end_date = d1.getDate()+ "-" + (d1.getMonth() + 1) + "-" +d1.getFullYear();
                d.end_date = end_date;
                d.sub_role = sdx.d.sub_role;
                d.super_role = sdx.d.super_role;
                d.user_type = sdx.d.user_type;
                if(d.branchesids.length ===  d.branchesidscopy.length){
                    d.branch_name = 'All';
                }
                fn.render();
            }
        })
    }
   

    fn.printSellingItems =(e, p, ps) =>{
        if (e) e.preventDefault();
        util.ajx("reports/printSellingItems", fn.sdata(p, ps)).then(res => {
            $("#iframe_print").append(res.result);
            $("#iframe_print").print();
            $("#iframe_print").html('');
        })

    }

    fn.handleChange = (e) => {
        d.data[e.target.name] = e.target.value;
        fn.render();
      }
    
      fn.handleStaffChange = (e) =>{
        d.data[e.target.name] = e.target.value;
        var id = e.target.value;
        if (id != '') {
            //  alert("ok");
            util.ajx("reports/staffList", { id: id }).then(res => {
                //console.log(res.result);
                    d.m_users_branch = res.result;
                fn.render();
            })
        }else{
            d.m_users_branch = []; 
            
        }
        fn.render();
 
    }

      fn.openForm = (dtl) => {
    
        d.data = dtl ? { ...dtl } : { branches: [] };
        var branch_ids =d.branchesids;
        // if (d.branch_name == 'All') {
        //      $("#branchall").attr('checked', true);
        // }
        if(d.branchesids.length ===  d.branchesidscopy.length){
            d.branch_name = 'All';
        }
    
        fn.render();
        console.log(d.branch_name);
        window.showModal($("#branchModal"), true);
      }
      fn.setAllchecked = (e) => {
        let bid = e.target.value;
        
        if (!e.target.checked) {
          d.branchesids = [];
          d.branch_name = '';
        }else{
            d.branchesids =  d.branchesidscopy.slice() ;
            d.branch_name = 'All';
        }
        console.log(d.branchesids);
        fn.render();
      }

      fn.openDateForm = (dtl) => {
    
        d.data = dtl ? { ...dtl } : { branches: [] };
    
        $('#from_date').val('');
        $('#to_date').val('');

        fn.render();
        window.showModal($("#dateModal"), true);
      }
    
    
      fn.setBranchCheck = (e) => {
        let bid = e.target.value;
        if (e.target.checked) {
          d.branchesids.push(bid);
        } else {
          d.branchesids.splice(d.branchesids.indexOf(bid), 1);
        }

        
        // d.branchesids = d.data.branches;
        d.branch_name = '';
        if(d.branchesids.length ===  d.branchesidscopy.length){
            d.branch_name = 'All';
        }

        console.log(d.branchesids.length  +'  ' + d.branchesidscopy.length);

        fn.render();
      }
    
      fn.save =(e, p, ps) => {
        if (e) e.preventDefault();
        // let fd = new FormData($("#bfrm")[0]);
        util.ajx("reports/expensesDetails", fn.sdata(p, ps)).then(res => {
          $("#branchModal").modal('hide');
            d.list = res.result;
            d.curInfo = res.curInfo;
            d.download_url = res.download_url;
            d.downloadPdf = res.downloadPdf;
            d.print = res.print;
            d.all_data = res.all_data;
            d.date_type = res.date_type;
            d.branches = res.branches;
            d.branchesids = res.branch_ids
            d.branchesidscopy = res.branch_ids_copy;
            d.branch_name = res.branch_name;
            var dd = new Date(res.start_date);
            var strDate = dd.getDate()+ "-" + (dd.getMonth() + 1) + "-" +dd.getFullYear();
            d.start_date = strDate;


            var d1 = new Date(res.end_date);
            var end_date = d1.getDate()+ "-" + (d1.getMonth() + 1) + "-" +d1.getFullYear();
            d.end_date = end_date;
            d.sub_role = sdx.d.sub_role;
            d.super_role = sdx.d.super_role;
            d.user_type = sdx.d.user_type;
            if(d.branchesids.length ===  d.branchesidscopy.length){
                d.branch_name = 'All';
            }
            fn.render();
        })
      }
    
    
      fn.applydate = (e, p, ps) => {
        if (e) e.preventDefault();
        // let fd = new FormData($("#datefrm")[0]);
        util.ajx("reports/expensesDetails", fn.sdata(p, ps)).then(res => {
            d.list = res.result;
            d.curInfo = res.curInfo;
            d.all_data = res.all_data;

            d.download_url = res.download_url;
            d.downloadPdf = res.downloadPdf;
            d.print = res.print;
            d.branches = res.branches;
            $('#dateModal').modal('hide');
            d.branchesids = res.branch_ids
            d.branchesidscopy = res.branch_ids_copy;
            d.branch_name = res.branch_name;
            var dd = new Date(res.start_date);
            var strDate = dd.getDate()+ "-" + (dd.getMonth() + 1) + "-" +dd.getFullYear();
            d.start_date = strDate;


            var d1 = new Date(res.end_date);
            var end_date = d1.getDate()+ "-" + (d1.getMonth() + 1) + "-" +d1.getFullYear();
            d.end_date = end_date;
            d.date_type = res.date_type;

            // d.end_date = res.end_date;
            d.sub_role = sdx.d.sub_role;
            d.super_role = sdx.d.super_role;
            d.user_type = sdx.d.user_type;
            if(d.branchesids.length ===  d.branchesidscopy.length){
                d.branch_name = 'All';
            }
          fn.render();
        })
      }

      $(".datepicker").datepicker({
            dateFormat: 'dd-mm-yy'
       });

    $("#Today").click(function() {
        var d = new Date();
        var strDate = d.getDate()+ "-" + (d.getMonth() + 1) + "-" +d.getFullYear();

        $('#from_date').val(strDate);
        $('#to_date').val(strDate);
        $('#filter_date_type').val('');

        $('#datefilter').html(strDate + ' - ' + strDate);
        $('#filter_date_type').val('Today');
        $('li').removeClass('active');
        $('#Today').addClass('active');
        $('.datepicker').attr("disabled",true);

    });

    $("#Yesterday").click(function() {

        var d = new Date(Date.now() - 864e5);
        var strDate = d.getDate()+ "-" + (d.getMonth() + 1) + "-" +d.getFullYear();

        $('#datefilter').html(strDate + ' - ' + strDate);

        $('#from_date').val(strDate);
        $('#to_date').val(strDate);
        $('#filter_date_type').val('');
        $('#filter_date_type').val('Yesterday');
        $('li').removeClass('active');
        $('#Yesterday').addClass('active');
        $('.datepicker').attr("disabled",true);

    });
    $("#week").click(function() {
        var now = new Date();
        var startDay = 1; //0=sunday, 1=monday etc.
        var d = now.getDay(); //get the current day
        var strDate = new Date(now.valueOf() - (d<=0 ? 7-startDay:d-startDay)*86400000); //rewind to start day
        var endDate = new Date(strDate.valueOf() + 6*86400000); //add 6 days to get last day

        strDate =  strDate.getDate()  + "-" + (strDate.getMonth() + 1) + "-" + strDate.getFullYear();
        endDate = endDate.getDate() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getFullYear() ;

        $('#datefilter').html(strDate + ' - ' + endDate);

        $('#from_date').val(strDate);
        $('#to_date').val(endDate);

        $('#filter_date_type').val('');
        $('#filter_date_type').val('week');
        $('li').removeClass('active');
        $('#week').addClass('active');
        $('.datepicker').attr("disabled",true);


    });

    $("#month").click(function() {

        var date = new Date();
        var strDate = new Date(date.getFullYear(), date.getMonth(), 1);
        
        var endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        strDate =  strDate.getDate()  + "-" + (strDate.getMonth() + 1) + "-" + strDate.getFullYear();
        endDate = endDate.getDate() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getFullYear() ;

        $('#datefilter').html(strDate + ' - ' + endDate);

        $('#from_date').val(strDate);
        $('#to_date').val(endDate);
        $('#filter_date_type').val('');
        $('#filter_date_type').val('month');
        $('li').removeClass('active');
        $('#month').addClass('active');
        $('.datepicker').attr("disabled",true);
    });

    $("#last_month").click(function() {

        // var date = new Date();
        // var strDate = new Date(date.getFullYear(), date.getMonth(), 1);
        // var endDate = new Date(date.getFullYear(), date.getMonth() , 0);
        var now = new Date();
        var endDate = new Date(now.getFullYear(), now.getMonth(), 0);
        var strDate = new Date(now.getFullYear() - (now.getMonth() > 0 ? 0 : 1), (now.getMonth() - 1 + 12) % 12, 1);
        strDate =  strDate.getDate()  + "-" + (strDate.getMonth() + 1) + "-" + strDate.getFullYear();
        endDate = endDate.getDate() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getFullYear() ;

        


        $('#datefilter').html(strDate + ' - ' + endDate);

        $('#from_date').val(strDate);
        $('#to_date').val(endDate);
        $('#filter_date_type').val('');
        $('#filter_date_type').val('last_month');
        $('li').removeClass('active');
        $('#last_month').addClass('active');
        $('.datepicker').attr("disabled",true);
    });

    $("#custom").click(function() {

        var d = new Date();
        var strDate = d.getDate()+ "-" + (d.getMonth() + 1) + "-" +d.getFullYear();

        $('#from_date').val(strDate);
        $('#to_date').val(strDate);
        $('#from_date').addClass('datepicker');
        $('#to_date').addClass('datepicker');
        $('#filter_date_type').val('');
        $('#filter_date_type').val('custom');
        $('li').removeClass('active');
        $('#custom').addClass('active');
        $('.datepicker').attr("disabled",false);

    });


    fn.openExpensesForm = (dtl) => {
        d.data = dtl ? { ...dtl } : {branches: []};
        d.name = d.data.name || 0;
        d.is_arabic = sdx.d.is_arabic;
        d.mobile_digit_length = sdx.d.mobile_digit_length;
        d.languageset = sdx.d.language;
        fn.render();
        window.showModal($("#formModal"), true);
    }

    fn.setRole = (e) => {
        d.data[e.target.name] = e.target.value;
        setData({ ...d, name: e.target.value });
    }

    fn.saveForm = () => {
        let fd = new FormData($("#frm")[0]);
        util.ajx("reports/save_expenses", fd).then(res => {
            if (res.code === 200) {
                window.hideModal($("#formModal"));
                fn.lists(0, d.data.id ? d.sdata.p : 1);
            }
        })
    }

    useEffect(() => {
        fn.lists();
        $('#masterActive').removeClass('active');
        // eslint-disable-next-line
    }, []);

    return View(d, fn);

}

function View(d, fn) {
    return (
         <>
            <div className="">
            <div className="dashboard-top-filter">
            <Link to="/dashboard"><i className="fas fa-arrow-alt-circle-left mr-3"></i></Link>
                <i className="fas fa-filter mr-3"></i>
                {/* <If cond={d.branches.length > 1}>
                <button type="button" id="branch_name" className="btn btn-secondary mr-2" onClick={() => fn.openForm()}>
                { d.branch_name}</button>
                </If>

                <If cond={d.branches.length == 1}>
                <button type="button" id="branch_name" className="btn btn-secondary mr-2" >
                { d.branch_name}</button>
                </If> */}
                
                <button type="button" className="btn btn-secondary" id="datefilter" onClick={() => fn.openDateForm()}>
                {d.start_date} - {d.end_date}</button> 
            </div>


            {/* <div className="modal fade" id="branchModal" role="dialog" aria-labelledby="branchModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="branchModalLabel">Branch </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div className="modal-body">
                    <form id="bfrm" autoComplete="off" spellCheck="false">
                        <div className="form-control mb5" >
                        <label className="m-0 noselect">
                            <input type="checkbox" className="selectall"  value='' id='branchall' checked={d.branch_name == 'All' ? true : false } onClick={fn.setAllchecked} />
                        &nbsp;&nbsp;ALL
                    </label>
                        </div>

                        {d.branches.map((r, i) => (
                        <div className="form-control mb5" key={i}>
                            <label className="m-0 noselect">
                            <input type="checkbox" className="selectall" name="branches[]" id={'branch_' + r.id} checked={d.branchesids?.includes(r.id) ? true : false}   value={r.id} onChange={fn.setBranchCheck} />
                            &nbsp;&nbsp;{r.title}
                            </label>
                        </div>
                        ))}

                        <input type="hidden" name="start_date" value={window.sessionStorage['start_date']} />
                        <input type="hidden" name="end_date" value={window.sessionStorage['end_date']} />
                    </form>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-danger btn-red" onClick={fn.save} >Apply</button>
                    </div>
                </div>
                </div>
            </div> */}

             <div className="modal fade" id="dateModal" role="dialog" aria-labelledby="dateModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="dateModalLabel">{lang('Choose Date')}  </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div className="modal-body">
                    
                        <ul className="nav nav-pills mb-3 order-tab mT12 horizontal-scroll">
                            <li className="nav-item" id='Today' ><a className="nav-link text-dark-grey active" data-toggle="tab">{lang('Today')}</a></li>
                            <li className="nav-item" id='Yesterday' ><a className="nav-link text-dark-grey" data-toggle="tab"  >{lang('Yesterday')}</a></li>
                            <li className="nav-item" id="week" ><a className="nav-link text-dark-grey" data-toggle="tab">{lang('This Week')}</a></li>
                            <li className="nav-item" id="month"><a className="nav-link text-dark-grey"data-toggle="tab"  >{lang('This Month')}</a></li>
                            <li className="nav-item" id="last_month"><a className="nav-link text-dark-grey" data-toggle="tab" >{lang('Last Month')}</a></li>
                            <li className="nav-item" id='custom' ><a className="nav-link text-dark-grey" data-toggle="tab" >{lang('Custom Date')}</a></li>
                           
                        </ul>
                        <form id="datefrm" autoComplete="off" spellCheck="false">
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <div><label className="req">{lang('From')}</label></div>
                                    <input className="datepicker form-control" disabled="true" id="from_date" name="start_date" value={d.start_date} data-date-format="dd-mm-yy" />
                                </div>

                                <div className="form-group col-md-6">
                                    <div><label className="req">{lang('To')}</label></div>
                                    <input className="datepicker form-control" id="to_date" disabled="true" name="end_date" value={d.end_date} data-date-format="dd-mm-yy" />
                                </div>
                            </div>
                        </form>


                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-danger w120 text-uppercase fw600 btn-height36 fs14 btn-red" onClick={fn.applydate} >{lang('Apply')}</button>
                    </div>
                </div>
                </div>
            </div>

                
                <div className="col-xl-12 col-lg-12">
                    <div className="mt-5">
                        <If cond={!client_id}>
                            <div className="d-flex flex-row align-items-center justify-content-between pl-4 pr-4 pt-4">
                                <h6 className="m-0 h5 text-dark">{lang('Expenses Details')}</h6>
                                <If cond={d.date_type == 'today'}>
                                    <div className="ml-auto mt-auto mb-auto">
                                        <button className="btn btn-danger btn-red" onClick={() => fn.openExpensesForm()}><i className="fa fa-plus"></i> {lang('Add')}</button>
                                    </div>

                                </If>
                                
                            </div>
                        </If>
                        <div className={client_id ? "" : "main-content"}>
                            <div className={client_id ? "" : "container-fluid"}>
                                <If cond={d.list.result.length && !client_id}>
                                    {/* <div className="d-flex">
                                            <div className="text-secondary bold600 mb5">
                                                Showing {d.list.page.start + 1} - {d.list.page.start + d.list.page.total} of {d.list.page.total_records} records.
                                </div>
                                        </div> */}
                                </If>

                                <div className="d-flex justify-content-between">
                                    <div className="form-inline">
                                     <form id="sbfrm" onSubmit={fn.lists} autoComplete="on" spellCheck="false">
                                            {/* <div className="input-group search-control">
                                                <input type="search" className="form-control mr-3" name="k" placeholder="Search" />
                                                <button className="btn btn-red-outline btn-danger" type="submit"><i className="fa fa-search"></i></button>
                                            </div> */}
                                            <select className="form-control w130 single-line-dotted mr-3" name="b_id" onChange={e => { d.sdata.b_id = e.target.value; fn.lists(); }}>
                                                <option value="">{lang('Branch (All)')}</option>
                                                {d.branches.map(r => <option key={r.id} value={r.id}>{r.title}</option>)}
                                            </select>
                                            
                                            <select className="form-control w130 single-line-dotted mr-3" name="staff_name"
                                         onChange={e => { d.sdata.staff_name = e.target.value; fn.lists(); }}>
                                            <option value="">{lang('Staff (All)')}</option>
                                            {d.m_users.map(y => <option key={y.id} value={y.id}>{y.name}</option>)}
                                        </select>


                                            
                                    </form> 
                                       
                                    </div>
                                    {/* <div className="d-flex">
                                    <If cond={d.sub_role && d.sub_role[31] == 1 || d.user_type !='STAFF'}>
                                        <div className="form-inline">
                                            <div className="input-group search-control">
                                                <a className="btn btn-md btn-outline-success noround" href={d.download_url} target="_self">
                                                        <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </div>

                                        <div className="form-inline ml-2">
                                            <div className="input-group search-control">
                                                <button className="btn btn-md btn-outline-primary noround" onClick={fn.printSellingItems}><i className="fa fa-print"></i> </button>
                                            </div>
                                        </div>

                                    </If>
                                    </div> */}


                                </div>

                                <div className="dashboard-overview mT10 mB20">
                                            <div className="dashboard-overview-data w-25">
                                                <div className="fs22 fw600 text-black"><AmtFormat curInfo={d.curInfo} amt={d.all_data.total_p } /></div>
                                                <div className="fs14 fw400 text-dark-grey2 pr-4">{lang('Total Sales')}</div>
                                            </div>
                                            <div className="dashboard-overview-data w-25">
                                                <div className="fs22 fw600 text-black"><AmtFormat curInfo={d.curInfo} amt={d.all_data.r_cash } /></div> 
                                                <div className="fs14 fw400 text-dark-grey2 pr-4">{lang('Cash Amount')}</div>
                                            </div>
                                            <div className="dashboard-overview-data w-25">
                                                <div className="fs22 fw600 text-black"><AmtFormat curInfo={d.curInfo} amt={d.all_data.r_bank } /></div>
                                                <div className="fs14 fw400 text-dark-grey2 pr-4">{lang('Bank amount')}</div>
                                            </div>
                                            <div className="dashboard-overview-data w-25">
                                                <div className="fs22 fw600 text-black"><AmtFormat curInfo={d.curInfo} amt={d.all_data.totalExpenses } /></div>
                                                {/* d.getAllOrdersData.total_amt - d.getAllOrdersData.totalUnpaidOrdersSales - d.getAllOrdersData.totalCancelledOrdersSales + d.getAllOrdersData.totalRefundOrdersSales */}
                                                <div className="fs14 fw400 text-dark-grey2 pr-4">{lang('Total Expenses')}</div>
                                            </div>
                                            <div className="dashboard-overview-data w-25">
                                                <div className="fs22 fw600 text-black"><AmtFormat curInfo={d.curInfo} amt={ d.all_data.r_cash  - d.all_data.totalExpenses } /></div>
                                                <div className="fs14 fw400 text-dark-grey2 pr-4">{lang('Remaining Sales(Cash -Total Expenses )')}</div>
                                            </div>
                                            
                                </div>

                                <div className="mt-4">
                                    <If cond={d.list.result.length}>
                                        <div className="table-responsive">
                                            <table className="table border m-0">
                                                <thead className="bg-light text-uppercase font-sm">
                                                    <tr>
                                                        <th className="w20"> {lang('SN')}</th>
                                                        <th className="w80"> {lang('Branch')}</th>
                                                        <th className="w80"> {lang('Name')}</th>
                                                        <th className="w80">{lang('Amount Given')}</th>
                                                        <th className="w80">{lang('Reason')}</th>
                                                        <th className="w80">{lang('Date')}</th>
                                                        <th className="w80"></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-text-vmid">
                                                    {d.list.result.map((v, i) => (
                                                        <tr key={i}>
                                                            <td>{d.list.page.start + i + 1}.</td>
                                                            <td>{v.branch_name?.map((v, j) => <span key={j}>
                                                                    <If cond={j > 0}>, </If>
                                                                    {v.title}
                                                                </span>)}</td>
                                                            
                                                            <td className="text-black fw600">{v.staffname}</td>
                                                            <td> <AmtFormat curInfo={d.curInfo} amt={v.amount} /></td>
                                                            <td className="text-black fw600">{v.reason}</td>
                                                            <td className="text-black fw600">{v.created}</td>
                                                            <td className="text-center">
                                                                <div className="btn-group">
                                                                    <If cond={d.sub_role && d.sub_role[46] == 1 || d.user_type !='STAFF'}>
                                                                        <button type="button" className="btn btn-sm fs16" onClick={() => fn.openExpensesForm(v)}><i className="far fa-edit"></i></button>
                                                                    </If>
                                                                    {/* <button type="button" className="btn btn-sm fs16" onClick={() => fn.deleterec(v.id)}><i className="fa fa-trash-o"></i></button> */}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <If cond={!client_id}>
                                            <div className="d-flex justify-content-between mt-4 pb-3">
                                                <div className="mr5">
                                                    <Paging fn={fn.lists} pageob={d.list.page} />
                                                </div>
                                                <div>
                                                    <Perpagedd fn={fn.lists} />
                                                </div>
                                            </div>
                                        </If>
                                    </If>
                                    <If cond={!d.list.result.length}>
                                        <div className="no-rec">{lang('No record found')}</div>
                                    </If>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/** Form */}
            <div className="modal modal-scroll" id="formModal" data-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title uc">{d.data.id ? 'Edit' : 'Add'} Expenses</div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <form id="frm" autoComplete="off" spellCheck="false">
                                <input type="hidden" name="id" defaultValue={d.data.id} />
                                <div className="row mingap">
                                    <div className="form-group col-md-12">
                                        <label className="req">{lang('Branch')}</label>
                                        <select className="form-control" name="branch_id" value={d.data.branch_id || ''} onChange={fn.handleStaffChange}>
                                            <option value="">{lang('Select')}</option>
                                            {d.branches.map(r => <option key={r.id} value={r.id}>{r.title}</option>)}
                                        </select>
                                    </div>

                                    <If cond={d.sub_role && d.sub_role[46] == 1 || d.user_type !='STAFF'}>
                                        <div className="form-group col-md-6">
                                            <label className="req">{lang('Applicant Name')}</label>
                                            <select className="form-control" name="name" value={d.data.name || ''} onChange={fn.setRole}>
                                                <option value="">{lang('Select')}</option>
                                                <If cond={d.m_users_branch.length}>
                                                {d.m_users_branch.map(y => <option key={y.id} value={y.id}>{y.name}</option>)}
                                                </If>
                                                
                                            </select>
                                        </div>
                                    </If>
                                    <div className="form-group col-md-6">
                                        <label className="req">{lang('Amount')}</label>
                                        <input type="text" className="form-control" name="amount" value={d.data.amount || ''} onChange={fn.handleChange} />
                                    </div>
                                    
                                    <div className="form-group col-md-12">
                                        <label className="">{lang('Reason')}</label>
                                        <textarea rows="2" className="form-control" name="reason" value={d.data.reason || ''} onChange={fn.handleChange}></textarea>
                                    </div>

                                   
                                

                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger btn-red" onClick={fn.saveForm}>{lang('Save')}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="iframe_print" ></div>
        </>
    )
}
