import React, { useState, useEffect } from 'react';
import util from "../../util/util";
import { lang_preferance } from "../../services/data";
import { useParams, Link } from 'react-router-dom';
import { sdx } from "../../sdx";
import { If, Paging, Perpagedd ,AmtFormat} from "../../util/controls";
import lang from '../../lang';
// import fbase from "../../services/firebase";
import RecordPayment from "./record-unpaid-payment";
let client_id = 0, ref = {};

let $ = window.$;

export default function CrmDetails(props) {

    client_id = props.client_id;
    const fn = {};
    const { id } = useParams();
    let pfn = props.computedMatch;
    const [d, setData] = useState({
        list: { result: [], page: {} },
        sdata: { p: 1, ps: 25 },
        data: {},
        roles: [],
        role_id: 0,
        is_arabic: 0,
        curInfo: {},
        branches: [],
        branchesids: [],
        branch_name : '',
        todayDate : '',
        start_date : '',
        end_date : '',
        sub_role :[],
        super_role:[],
        user_type:'',
        branchesidscopy: [],
        getAllOrdersData : [],
        lastOrder:[],
        lang_preferance: lang_preferance,
        unpaidOrders:[],
        unpaidOrderPayAmt : 0,
        card_details: [],
        online_payment_details: [],
        online_payment_setup:0,
        noteList:[],
        noteData:[],
    });
    fn.render = () => { setData({ ...d }); }

       d.todayDate = new Date();
       d.todayDate = d.todayDate.getFullYear() + "-" + (d.todayDate.getMonth() + 1) + "-" + d.todayDate.getDate();

    fn.sdata = (p, ps) => {
         let mob =  pfn.params;
        d.sdata.p = p ? p : 1;
        d.sdata.ps = ps ? ps : d.sdata.ps;
        
        return $("#sbfrm").serialize() + '&p=' + d.sdata.p + '&ps=' + d.sdata.ps + '&client_id=' + props.client_id +'&mob='+ pfn.params.arg;
    }

    fn.lists = (e, p, ps) => {
        
        if (e) e.preventDefault();
        util.ajx("crm/crmDetails", fn.sdata(p, ps)).then(res => {
            if (res.code === 200) {
                
                d.list = res.result;
                d.curInfo = res.curInfo;
                d.getAllOrdersData = res.getAllOrdersData;
                d.lastOrder=res.lastOrder;
                d.card_details = res.card_details;
                d.branches = res.branches;
                d.unpaidOrders = [];
                d.unpaidOrderPayAmt  = 0;
                d.noteList = [];
                // if(d.unpaidOrders.length > 0){
                //     $('#payDiv').show();
                // }else{
                //     $('#payDiv').hide();
                // }
                // d.branchesids = res.branch_ids;
                // d.branch_name = res.branch_name;
                // d.start_date = res.start_date;
                // d.end_date = res.end_date;
                d.sub_role = sdx.d.sub_role;
                d.super_role = sdx.d.super_role;
                d.user_type = sdx.d.user_type;
                // d.branchesidscopy = res.branch_ids_copy;
                // if(d.branchesids.length ===  d.branchesidscopy.length){
                //     d.branch_name = 'All';
                // }

                fn.render();
            }
        })
    }
  

    fn.handleChange = (e) => {
        d.noteData[e.target.name] = e.target.value;
        fn.render();
      }
    
      
    
    
      fn.setUnpaidOrderCount = (e) => {
        let bid = e.target.value;
        var amt = e.target.getAttribute('data-value') 
        if (e.target.checked) {
          d.unpaidOrders.push(bid);
          d.unpaidOrderPayAmt = d.unpaidOrderPayAmt + parseFloat(amt);
        } else {
          d.unpaidOrders.splice(d.unpaidOrders.indexOf(bid), 1);
          d.unpaidOrderPayAmt = d.unpaidOrderPayAmt - parseFloat(amt);
        }

        fn.render();
      }
    fn.openNoteForm = (dtl) => {
        util.ajx("crm/userNotes", {mob : dtl}).then(res => {
          d.noteList = res.result;
          
            // d.curInfo = res.curInfo;
          fn.render();
        })
        window.showModal($("#formModal"), true);
    }
    
    fn.addNotes= (dtl) => {
        d.noteData = dtl ? { ...dtl } : {branches: []};
        fn.render();
        window.showModal($("#formNoteModal"), true);
    }

    fn.saveNotes = (mob) => {
        let fd = new FormData($("#frm")[0]);
        util.ajx("crm/save_notes", fd).then(res => {
            if (res.code === 200) {
                $("#formNoteModal").modal('hide');
                fn.openNoteForm(d.lastOrder.mob);
            }
        })
    }

    fn.deleterec = (id) => {
        let callback = () => {
            util.ajx("crm/delete_notes", { id: id }).then(res => {
                if (res.code === 200) {
                    fn.openNoteForm(d.lastOrder.mob);
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.openOrderDetails = (dtl) => {
        // d.data = dtl ? { ...dtl } : { client_id: props.client_id };
        // d.items = d.data.items;
        // fn.render();
        //window.draggable('#viewOrderModal');
        // window.showModal($("#viewOrderModal"), true);
         

        util.ajx("menu/showOrderDetails" ,{ id: dtl.id } ).then(res => {
            // if (res.code === 200) {
                $('#orderDetails').modal('show');
                $('#orderDetailsView').html('');
                $('#orderDetailsView').append(res.result);
            // }
        })

    }

    useEffect(() => {
        fn.lists();
        $('#masterActive').removeClass('active');
        setData({ ...d, lang_preferance: lang_preferance });
        // eslint-disable-next-line
    }, [lang_preferance]);

    return View(d, fn);

}

function View(d, fn) {
    return (
         <>
            <div className="">
            
                <Link to="/crm"><i className="fas fa-arrow-alt-circle-left mr-3"></i></Link>
                
                <div className="col-xl-12 col-lg-12">
                    <div className="mt-5">
                        <If cond={!client_id}>
                            <div className="d-flex flex-row align-items-center justify-content-between pl-4 pr-4 pt-4">
                                <h6 className="m-0 h5 text-dark"></h6>
                            </div>
                        </If>
                        <div className={client_id ? "" : "main-content"}>
                            <div className={client_id ? "" : "container-fluid"}>
                                    <If cond={d.list.result.length}>
                                        <div className="dashboard-overview mT10 mB20">
                                            <div className="dashboard-overview-data w-50">
                                                <div className="fs22 fw600 text-black"><label className="fs14 text-grey fw500">{lang('Phone NO')} : {d.lastOrder.mob}</label></div>
                                                <div className="fs22 fw600 text-black"><label className="fs14 text-grey fw500">{lang('Name')} : {d.lastOrder.name || ''} </label></div>
                                                <div className="fs22 fw600 text-black"><label className="fs14 text-grey fw500">{lang('Address')} : {d.lastOrder.address || ''}</label></div>
                                                
                                            </div>
                                            
                                            <div className="dashboard-overview-data w-50 ">
                                                <div className="fs22 fw600 text-black"><label className="fs14 text-grey fw500">{lang('Last Order')} : {d.lastOrder.created}</label></div>

                                                <div className="fs22 fw600 text-black"><label className="fs14 text-grey fw500">{lang('Items')}</label>
                                                {d.lastOrder.items && d.lastOrder.items.map((r, i) => (
                                                    <div key={i}><span  className="fs14 text-grey fw500">
                                                        {r.item_name}
                                                        </span>
                                                    </div>
                                                    //   <If cond={r.size_name}>
                                                    //     <span className="text-black fw500"> ({r.size_name})</span>
                                                    // </If>
                                                 ))}
                                                </div>
                                                {/* <div className="fs22 fw600 text-black"><label className="fs14 text-grey fw500">Address : {d.lastOrder.address || ''}</label></div> */}
                                                 <button type="button" className="btn btn-danger btn-red" onClick={() => fn.openNoteForm(d.lastOrder.mob)} >{lang('View Note')}</button>
                                            </div>
                                        </div>
                                     </If>
                                        <div className="dashboard-overview mT10 mB20">
                                            <div className="dashboard-overview-data w-25">
                                                <div className="fs22 fw600 text-black">{d.getAllOrdersData.totalorder}</div>
                                                <div className="fs14 fw400 text-dark-grey2 pr-4">{lang('Total Orders')}</div>
                                            </div>
                                            <div className="dashboard-overview-data w-25">
                                                <div className="fs22 fw600 text-black"><AmtFormat curInfo={d.curInfo} amt={d.getAllOrdersData.grandTotal + d.getAllOrdersData.totalRefundOrdersSales } /></div>
                                                {/* d.getAllOrdersData.total_amt - d.getAllOrdersData.totalUnpaidOrdersSales - d.getAllOrdersData.totalCancelledOrdersSales + d.getAllOrdersData.totalRefundOrdersSales */}
                                                <div className="fs14 fw400 text-dark-grey2 pr-4">{lang('Total Sales')}</div>
                                            </div>
                                            <div className="dashboard-overview-data w-25">
                                                <div className="fs22 fw600 text-black">{d.getAllOrdersData.totalUnpaidOrders}</div>
                                                <div className="fs14 fw400 text-dark-grey2 pr-4">{lang('Pending orders')}</div>
                                            </div>
                                            <div className="dashboard-overview-data w-25 ">
                                                <div className="fs22 fw600 text-black"><AmtFormat curInfo={d.curInfo} amt={d.getAllOrdersData.totalUnpaidOrdersSales} /></div>
                                                <div className="fs14 fw400 pr-4 text-dark-grey2 ">{lang('Pending Sales')}</div>
                                            </div>
                                            <div className="dashboard-overview-data w-25">
                                                <div className="fs22 fw600 text-black">{d.getAllOrdersData.totalCancelledOrders}</div>
                                                <div className="fs14 fw400 text-dark-grey2 pr-4">{lang('Cancelled Orders')}</div>
                                            </div>
                                            {/* <div className="dashboard-overview-data w-25 ">
                                                <div className="fs22 fw600 text-black"><AmtFormat curInfo={d.curInfo} amt={d.getAllOrdersData.totalCancelledOrdersSales} /></div>
                                                <div className="fs14 fw400 pr-4 text-dark-grey2 ">Cancelled Order Amount</div>
                                            </div> */}
                                        </div>
                                    

                                        


                                <div className="form-inline">
                                    <form id="sbfrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                                        <input type="search" className="form-control mr-3" name="k" placeholder="Search By Order ID" />
                                        <button className="btn btn-red-outline btn-danger" type="submit"> <i className="fa fa-search"></i></button>

                                        <select className="form-control w180 single-line-dotted mr-3" name="orders_type" value={d.sdata.orders_type}
                                                onChange={e => { d.sdata.orders_type = e.target.value; fn.lists(); }}>
                                            <option value="">{lang('Order Type (All)')}</option>
                                            <option value="Dining">{lang('Dine-In')} </option>
                                            <option value="Deliver">{lang('Deliver')}</option>
                                            <option value="Pickup">{lang('Pickup')}</option>

                                        </select>
                                         <select className="form-control w180 single-line-dotted mr-3" name="orders_status"
                                                    onChange={e => { d.sdata.orders_status = e.target.value; fn.lists(); }}>
                                            <option value="">{lang('Order Status (All)')}</option>
                                            {/* <option value="New">New </option>
                                            <option value="In Progress">In Progress </option> */}
                                            <option value="Delivered">{lang('Delivered')}</option>
                                            <option value="Cancelled">{lang('Cancelled')}</option>

                                        </select>

                                        <select className="form-control w180 single-line-dotted mr-3" name="pay_types"
                                                    onChange={e => { d.sdata.pay_types = e.target.value; fn.lists(); }}>
                                            <option value="">{lang('Payment Type (All)')}</option>
                                            <option value="cash">{lang('Cash')} </option>
                                            <option value="bank">{lang('Bank')} </option>
                                            <option value="cash_bank">{lang('Cash_Bank')} </option>
                                             <option value="unpaid">{lang('Unpaid')} </option>

                                        </select>

                                        <select className="form-control mr-3" name="branch_id" value={d.sdata.branch_id}
                                            onChange={e => { d.sdata.branch_id = e.target.value; fn.lists(); }}>
                                            <option value="">{lang('Branch (All)')}</option>
                                            {d.branches.map(r => <option key={r.id} value={r.id}>{r.title}</option>)}
                                        </select>

                                        
                                    </form>
                                </div>

                                <div className="dashboard-overview mT10 mB20" id="payDiv" style={{display:(d.unpaidOrders.length > 0)?'block':'none' }}>
                                    <div className="dashboard-overview-data w-25">
                                        <div className="fs22 fw600 text-black"><AmtFormat curInfo={d.curInfo} amt={d.unpaidOrderPayAmt} /></div>
                                        <div className="fs14 fw400 text-dark-grey2 pr-4">{lang('your selected order paying amount')} </div>
                                    </div>
                                    <button type="button" className="btn btn-danger btn-red" onClick={e => { $('#recordPaymentModal').modal();  $('#manualPayment').css('display', 'block');$('#activate_link').css('display', 'none');$(".card_chk").prop( "checked", false ); }} >{lang('Pay now')}</button>
                                </div>


                                <div className="mt-4">
                                    <If cond={d.list.result.length}>
                                        <div className="table-responsive">
                                            <table className="table table-sm border m-0">
                                                <thead className="bg-light text-uppercase font-sm">
                                                    <tr>
                                                        <th className="w20"> {lang('Order ID')}</th>
                                                        <th className="w20"> {lang('Branch')}</th>
                                                        <th className="w20"> {lang('Order Date Time')}</th>
                                                        <th className="w20">{lang('Amount')}</th>
                                                        <th className="w20">{lang('Status')}</th>
                                                        <th className="w20">{lang('Order Type')} </th>
                                                        <th className="w20">{lang('Payment')} </th>
                                                        <th className="w20">{lang('Pay Date Time')} </th>
                                                        <th className="w20"> </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-text-vmid">
                                                    {d.list.result.map((v, i) => (
                                                        <tr key={i}>
                                                            <td onClick={() => {fn.openOrderDetails(v);}}>{'#'+v.odr_no}</td>
                                                             <td >{v.branchname}</td>
                                                            <td >{v.created}</td>
                                                            <td><AmtFormat curInfo={d.curInfo} amt={v.total_amt} /></td>
                                                            <td >{v.status}</td>
                                                            <td >{v.order_type}</td>
                                                             <If cond={v.payment_status == 0}>
                                                                <td>Unpaid</td>
                                                            </If>
                                                             <If cond={v.payment_status == 1}>
                                                                <td>{v.pay_mode}</td>
                                                            </If>
                                                            <td >{(v.pay_date) || '-'}</td>
                                                            <If cond={v.payment_status == 0 && v.status != 'Cancelled'}>
                                                                <td>
                                                                     <If cond={d.sub_role && d.sub_role[44] == 1 || d.user_type !='STAFF'}>
                                                                        <input type="checkbox" name="unpaid_orders[]" value={v.id} data-value={v.total_amt} onChange={fn.setUnpaidOrderCount} /></If>
                                                                 </td>
                                                            </If>
                                                            <If cond={v.payment_status == 1}>
                                                                <td></td>
                                                            </If>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <If cond={!client_id}>
                                            <div className="d-flex justify-content-between mt-4 pb-3">
                                                <div className="mr5">
                                                    <Paging fn={fn.lists} pageob={d.list.page} />
                                                </div>
                                                <div>
                                                    <Perpagedd fn={fn.lists} />
                                                </div>
                                            </div>
                                        </If>
                                    </If>
                                    <If cond={!d.list.result.length}>
                                        <div className="no-rec">{lang('No record found')}</div>
                                    </If>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RecordPayment order={d.unpaidOrderPayAmt} orderList={d.unpaidOrders} funCall={fn.lists} curInfo={d.curInfo} card_details={d.card_details}    online_payment_details={d.online_payment_details}  online_payment_setup ={d.online_payment_setup} />
            <div id="iframe_print" ></div>
            <div className="modal modal-scroll" id="formModal" data-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title uc">{lang('View Notes')}</div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                        <If cond={d.noteList.result && d.noteList.result.length}>
                            <div className="table-responsive">
                                            <table className="table table-sm border m-0">
                                                <thead className="bg-light text-uppercase font-sm">
                                                    <tr>
                                                        <th className="w20"> {lang('Note')}</th>
                                                        <th className="w20"> {lang('Date')}</th>
                                                        <th className="w20"> </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-text-vmid">
                                                    {d.noteList.result && d.noteList.result.map((v, i) => (
                                                        <tr key={i}>
                                                            <td >{v.note}</td>
                                                            <td >{v.created}</td>
                                                            <td>  
                                                                <div className="btn-group">
                                                                    <button type="button" className="btn btn-sm fs16" onClick={() => fn.addNotes(v)}><i className="far fa-edit"></i></button>
                                                                    <button type="button" className="btn btn-sm fs16" onClick={() => fn.deleterec(v.id)}><i className="fa fa-trash-o"></i></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                        </If>
                        <If cond={d.noteList.result && !d.noteList.result.length}>
                            <div className="no-rec">{lang('No record found')}</div>
                        </If>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger btn-red" onClick={fn.addNotes}>{lang('Add Notes')}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal modal-scroll" id="formNoteModal" data-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title uc">{d.noteData.id ? 'Edit' : 'Add'}  {lang('Note')}</div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <form id="frm" autoComplete="off" spellCheck="false">
                                <input type="hidden" name="id" defaultValue={d.noteData.id} />
                                <input type="hidden" name="mob" defaultValue={d.lastOrder.mob} />
                                <div className="row mingap">
                                    <div className="form-group col-md-6">
                                        <label className="req">{lang('Note')}</label>
                                        <textarea rows="2" className="form-control" name="note" value={d.noteData.note || ''} onChange={fn.handleChange} ></textarea>
                                    </div>
                                 </div>    
                            </form>
                           
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger btn-red" onClick={fn.saveNotes}>{lang('Save')}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="orderDetails" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                    <div className="modal-header bg-white">
                        <h5 className="modal-title fs20 text-agna fw600" id="exampleModalLabel">{lang('Order Details')}</h5>
                    </div>
                    <div className="modal-body p-0" id="orderDetailsView">
                        
                        
                    </div>
                    <div className="modal-footer p-0" >
                        <div className="bg-white border-top p-3 text-right">
                            <button type="button" className="btn btn-danger text-uppercase fw600 btn-height36 fs14 btn-red-outline" data-dismiss="modal">{lang('Cancel')}</button>
                            
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ViewOrders d={d} fn={fn} /> */}
        </>
    )
}