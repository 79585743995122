import React, {useState, useEffect} from 'react';
import util from "../../util/util";
import {If, Paging, Perpagedd} from "../../util/controls";
let $=window.$;

export default function Users() {
    const fn={};
    const [d, setData]=useState({
        list:{result:[], page:{}},
        sdata:{p:1, ps:25},
        roles:[],
        data:{},
    });
    fn.render=()=>{
        setData({...d});
    }

    fn.handleChange=(e)=>{
        d.data[e.target.name]=e.target.value;
        fn.render();
    }

    fn.openForm=(dtl)=>{
        d.data=dtl?{...dtl}:{status:'1'};
        fn.render();
        window.showModal($("#formModal"));
    }

    fn.sdata=(p, ps)=>{
        d.sdata.p=p?p:1;
        d.sdata.ps=ps?ps:d.sdata.ps;
        return $("#sfrm").serialize()+'&p='+d.sdata.p+'&ps='+d.sdata.ps;
    }
    fn.lists=(e, p, ps)=>{
        if(e)e.preventDefault();
        util.ajx("master/users", fn.sdata(p, ps)).then(res=>{
            if(res.code===200){
                d.list=res.result;
                fn.render();
            }
        })
    }

    fn.save=()=>{
        let fd=new FormData($("#frm")[0]);
        util.ajx("master/save_user", fd).then(res=>{
            if(res.code===200){
                fn.lists(0, d.data.id?d.sdata.p:1);
                window.hideModal($("#formModal"));
            }
        })
    }

    fn.deleterec=(id)=>{
        let callback=()=>{
            util.ajx("master/delete_user", {id:id}).then(res=>{
                if(res.code===200){
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }
    
    fn.init=()=>{
        util.ajx("master/init_user").then(res=>{
            if(res.code === 200){
                d.roles=res.roles;
                d.list=res.result;
                fn.render();
            }
        });
    }
    useEffect(()=>{
        fn.init();
        // eslint-disable-next-line
    }, []);

    return View(d, fn);
}

function View(d, fn){
    return(
        <>

            <div className="col-xl-12 col-lg-12">
                <div className="card shadow mb-4 rounded-lg">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 h5 text-dark">Users</h6>
                        <div className="ml-auto mt-auto mb-auto">
                            <button className="btn btn-outline-primary" onClick={() => fn.openForm()}><i className="fa fa-plus"></i> Add</button>
                        </div>
                    </div>
                    <div className="card-body px-0">
                        <div className="main-content">
                            <div className="container-fluid">
                            <If cond={d.list.result.length}>
                                <div className="d-flex">
                                    <div className="text-secondary bold600 mb5">
                                        Showing {d.list.page.start+1} - {d.list.page.start+d.list.page.total} of {d.list.page.total_records} records.
                                    </div>
                                </div>
                            </If>

                            <div className="d-flex tbl-search-head">
                                <div className="form-inline">
                                    <form id="sfrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                                        <div className="input-group">
                                            <input type="search" className="form-control" name="k" placeholder="Search" />
                                            <div className="input-group-append">
                                                <button className="btn btn-light-blue" type="submit"><i className="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div className="ml-auto mt-auto mb-auto">
                                </div>
                            </div>

                            <div>
                                <If cond={d.list.result.length}>
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-sm table-striped table-hover m-0">
                                            <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                                <tr>
                                                    <th className="w20">SN</th>
                                                    <th>Name</th>
                                                    <th className="w200">Email</th>
                                                    <th className="w100">Mobile</th>
                                                    <th className="w100">Role</th>
                                                    <th className="w80">Status</th>
                                                    <th className="w70"></th>
                                                </tr>
                                            </thead>

                                            <tbody className="">
                                                {d.list.result.map((v, i)=>(
                                                    <tr key={i}>
                                                        <td>{d.list.page.start+i+1}.</td>
                                                        <td>
                                                            {v.name}
                                                        </td>
                                                        <td className="breakword">{v.email}</td>
                                                        <td>{v.mobile}</td>
                                                        <td className="nowrap">{v.role}</td>
                                                        <td>
                                                            <If cond={v.status==='1'}>
                                                                <div className="badge badge-success">Active</div>
                                                            </If>
                                                            <If cond={v.status==='0'}>
                                                                <div className="badge badge-danger">Inactive</div>
                                                            </If>
                                                        </td>
                                                        <td className="text-center">
                                                            <div className="btn-group">
                                                                <button type="button" className="btn btn-sm btn-light-blue gr" onClick={()=>fn.openForm(v)}><i className="fa fa-edit"></i></button>
                                                                <button type="button" className="btn btn-sm btn-danger gr" onClick={()=>fn.deleterec(v.id)}><i className="fa fa-trash-o"></i></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                    <div className="d-flex tbl-foot-bx">
                                        <div className="mr5">
                                            <Paging fn={fn.lists} pageob={d.list.page} />
                                        </div>
                                        <div>
                                            <Perpagedd fn={fn.lists} />
                                        </div>
                                    </div>
                                </If>
                                <If cond={!d.list.result.length}>
                                    <div className="no-rec">No record found</div>
                                </If>
                            </div>
                        </div>
                        </div>

                    </div>
                </div>
            </div>


            {/** Form */}
            <div className="modal modal-scroll" id="formModal" data-backdrop="static">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <div className="modal-title uc">{d.data.id?'Edit':'Add'} User</div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <form id="frm" autoComplete="off" spellCheck="false">
                                <input type="hidden" name="id" defaultValue={d.data.id} />
                                <div className="row mingap">
                                    <div className="form-group col-md-4">
                                        <label className="req">Name</label>
                                        <input type="text" className="form-control" name="name" value={d.data.name || ''} onChange={fn.handleChange} />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label className="req">Email</label>
                                        <input type="text" className="form-control" name="email" value={d.data.email || ''} onChange={fn.handleChange} />
                                    </div>
                                    {/* <div className="form-group col-md-4">
                                        <label className="req">Username</label>
                                        <input type="text" className="form-control" name="username" value={d.data.username || ''} onChange={fn.handleChange} />
                                    </div> */}
                                    <div className="form-group col-md-4">
                                        <label className={d.data.id?"":"req"}>{d.data.id?'Change':''} Password</label>
                                        <input type="text" className="form-control" name="password" value={d.data.password || ''} onChange={fn.handleChange} />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label className="req">Mobile</label>
                                        <input type="text" className="form-control" name="mobile" value={d.data.mobile || ''} onChange={fn.handleChange} maxLength="10" />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label className="req">Role</label>
                                        <select className="form-control" name="role_id" value={d.data.role_id || ''} onChange={fn.handleChange}>
                                            <option value="">Select</option>
                                            {d.roles.map((row)=><If cond={row.type==='ADMIN'} key={row.id}><option value={row.id}>{row.title}</option></If>)}
                                        </select>
                                    </div>
                                    
                                    <div className="form-group col-md-4">
                                        <label className="req">Status</label>
                                        <select className="form-control" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={fn.save}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    )
}