import React, { useState, useEffect } from 'react';
import util from "../../util/util";
import { useParams } from 'react-router-dom';
import { sdx } from "../../sdx";
import { If, Paging, Perpagedd } from "../../util/controls";
import fbase from "../../services/firebase";

let $ = window.$;

export default function Permissions(props) {
    const fn = {};
    const { id } = useParams();
    let pfn = props.computedMatch;
    const [d, setData] = useState({
        list: { result: [], page: {} },
        sdata: { p: 1, ps: 25 },
        data: {},
        branches: [],
        roles: [],
        role_id: 0,
        is_arabic: 0,
    });
    fn.render = () => { setData({ ...d }); }
    

    // console.log(pfn.params.arg);

    fn.init = () => {
      let arg =  pfn.params;
        
        util.ajx("Permissions/Permissionsfn",arg).then(res => {

            $('#main').append(res.result);
            
            $('#masterActive').addClass('active');
           // d.list = res.result;
        })
    }
    useEffect(() => {
        fn.init();
        $('#masterActive').removeClass('active');
        // eslint-disable-next-line
    }, []);

    return View(d, fn);

}

function View(d, fn) {
    return (
        <>

            <div className="d-flex p-3 positive-relative" id="main">
                
            </div>

        </>
    )
}


// export default Menu;