import React, { useState, useEffect } from 'react';
import util from "../../util/util";
import { useParams } from 'react-router-dom';
import { sdx } from "../../sdx";
import { If, Paging, Perpagedd } from "../../util/controls";
import fbase from "../../services/firebase";

let $ = window.$;

export default function Menu(props) {
    const fn = {};
    const { id } = useParams();
    let pfn = props.computedMatch;
    const [d, setData] = useState({
        list: { result: [], page: {} },
        sdata: { p: 1, ps: 25 },
        data: {},
        branches: [],
        roles: [],
        role_id: 0,
        is_arabic: 0,
        sub_role :[],
        super_role:[],
        user_type:'',
        subscription_id:[],
    });
    fn.render = () => { setData({ ...d }); }
    

    // console.log(pfn.params.arg);

    fn.init = () => {
      let arg =  pfn.params;
      arg['lang'] = window.localStorage['lang'];
      window.sessionStorage.removeItem('mob_no');
      window.sessionStorage.removeItem('name');
      window.sessionStorage.removeItem('address');
      window.sessionStorage.removeItem('delivery_location');
        
        util.ajx("menu/lists",arg).then(res => {

            d.sub_role = sdx.d.sub_role;
                d.super_role = sdx.d.super_role;
                d.user_type = sdx.d.user_type;
                d.subscription_id = sdx.d.subscription_id;
                

                setData({...d});
                if(sdx.d.sub_role && ( sdx.d.sub_role[17] != 1 && sdx.d.sub_role[25] != 1 && sdx.d.sub_role[26] != 1 && sdx.d.sub_role[27] != 1 && sdx.d.sub_role[28] != 1 && sdx.d.sub_role[35] != 1 )  && sdx.d.user_type =='STAFF'){
                    util.logout('', props.history);
                }

                if(d.subscription_id && ( d.subscription_id[6] != 1)  && d.user_type =='CLIENT'){
                    util.logout('', props.history);
                }

            //$('#main').append(res.result);
            $('#main').html(res.result);
            
    
           // d.list = res.result;
        })
    }
    useEffect(() => {
        fn.init();
        $('#masterActive').removeClass('active');
        clearTimeout(sdx.orderlist);
        clearTimeout(sdx.kitchenlist);
        // eslint-disable-next-line
    }, []);

    return View(d, fn);

}

function View(d, fn) {
    return (
        <>

            <div className="d-flex p-3 positive-relative" id="main">
                
            </div>

        </>
    )
}


// export default Menu;