import React from 'react';
import {Switch} from 'react-router-dom';
import DefaultLayout from '../views/layouts/default';
import LoginLayout from '../views/layouts/login';

import ChangePassword from "../views/auth/change_password";
import Login from "../views/auth/login";
import Dashboard from "../views/dashboard/dashboard";
import Hourlybearkdown from "../views/dashboard/hourly-bearkdown";
import cancelOrder from "../views/dashboard/cancelOrder";
import discount from "../views/dashboard/discount";
import profit from "../views/dashboard/profit";

import Roles from "../views/master/Roles";
import Users from "../views/master/Users";

import Currencies from "../views/master/Currencies";
import Countries from "../views/master/Countries";
import Cities from "../views/master/Cities";
import Clients from "../views/master/Clients";
import CmsPages from "../views/master/CmsPages";
import Smtp from "../views/master/Smtp";
import FoodCategory from "../views/master/FoodCategory";
import SpecialCategory from "../views/master/SpecialCategory";
import Subscription from "../views/master/Subscription";
import MeasuringUnits from "../views/master/MeasuringUnits";

/** For Clients */
import Branches from "../views/cmaster/Branches";
import Staffs from "../views/cmaster/Staffs";
import ItemCats from "../views/cmaster/ItemCats";
import Items from "../views/cmaster/Items";
import Qrcodes from "../views/cmaster/Qrcodes";
import Orders from "../views/order/Orders";
import Others from "../views/cmaster/Others";
import Combo from "../views/cmaster/Combo";
import Pickupmaster from "../views/cmaster/Pickupmaster";
import Menu from "../views/cmaster/Menu";
import recipeIngredients from "../views/cmaster/RecipeIngredients";

import Permissions from "../views/cmaster/Permissions";
import Report from "../views/reports/reportstList";
import sellingItems from "../views/reports/sellingItems";
import expensesDetails from "../views/reports/expensesDetails";
import ingredientsDetails from "../views/reports/ingredientsDetails";
import orderByMode from "../views/reports/orderByMode";
import locations from "../views/reports/locations";
import sales from "../views/reports/sales";
import paymentReport from "../views/reports/paymentReport";
import staffReport from "../views/reports/staffReport";
import salesReport from "../views/reports/salesReport";
import Crm from "../views/crm/crm";
import crmDetails from "../views/crm/crmDetails";

import Roles_permissions from "../views/cmaster/Role_permissions";

import Kitchen from "../views/kitchen/kitchen";

import inventory from "../views/stock_inventory/inventory";
// import dashboard_inventory from "../views/inventory/dashboard_inventory";
// import product_inventory from "../views/inventory/product_inventory";
// import brand_inventory from "../views/inventory/brand_inventory";
// import uom_inventory from "../views/inventory/uom_inventory";
// import LandingPage from "../views/LandingPage";

import SubscriptionNew from '../views/master/SubscriptionNew';

export default function Routes() {
    return (
        <Switch>
            <LoginLayout path="/" exact component={Login} />
            <DefaultLayout path="/change-password" component={ChangePassword} />
            <DefaultLayout path="/dashboard" component={Dashboard} />
            <DefaultLayout path="/hourlyBearkdown" component={Hourlybearkdown} />
            <DefaultLayout path="/role" component={Roles} />
            <DefaultLayout path="/users" component={Users} />
            <DefaultLayout path="/cancelOrder" component={cancelOrder} />
            <DefaultLayout path="/discount" component={discount} />
            <DefaultLayout path="/profit" component={profit} />
            
            
            <DefaultLayout path="/currencies" component={Currencies} />
            <DefaultLayout path="/countries" component={Countries} />
            <DefaultLayout path="/cities" component={Cities} />
            <DefaultLayout path="/clients" component={Clients} />
            <DefaultLayout path="/smtp" component={Smtp} />
            <DefaultLayout path="/cms-pages" component={CmsPages} />
            <DefaultLayout path="/food-category" component={FoodCategory} />
            <DefaultLayout path="/special-category" component={SpecialCategory} />
            <DefaultLayout path="/subscription" component={Subscription} />
            <DefaultLayout path="/subscription-new" component={SubscriptionNew} />
            <DefaultLayout path="/measuring-units" component={MeasuringUnits} />

            {/* For Clients */}
            <DefaultLayout path="/branches" component={Branches} />
            <DefaultLayout path="/staffs" component={Staffs} />
            <DefaultLayout path="/cats" component={ItemCats} />
            <DefaultLayout path="/items" component={Items} />
            <DefaultLayout path="/qrcodes" component={Qrcodes} />
            <DefaultLayout path="/orders" component={Orders} />
            <DefaultLayout path="/others" component={Others} />
            <DefaultLayout path="/combo" component={Combo} />
            <DefaultLayout path="/orderMaster" component={Pickupmaster} />
            {/* <DefaultLayout path="/menu" component={Menu} /> */}
            <DefaultLayout path="/menu/:arg" component={Menu} />
            <DefaultLayout path="/permissions/:arg" component={Permissions} />
            <DefaultLayout path="/reports" component={Report} />
            <DefaultLayout path="/sellingItems" component={sellingItems} />
            <DefaultLayout path="/expensesDetails" component={expensesDetails} />
            <DefaultLayout path="/ingredientsDetails" component={ingredientsDetails} />
            <DefaultLayout path="/orderByMode" component={orderByMode} />
            <DefaultLayout path="/locations" component={locations} />
            <DefaultLayout path="/sales" component={sales} />
            <DefaultLayout path="/paymentReport" component={paymentReport} />
            <DefaultLayout path="/roles" component={Roles_permissions} />
            <DefaultLayout path="/staffReport" component={staffReport} />
            <DefaultLayout path="/salesReport" component={salesReport} />
            <DefaultLayout path="/crm" component={Crm} />
            <DefaultLayout path="/crmDetails/:arg" component={crmDetails} />
            <DefaultLayout path="/recipeIngredients" component={recipeIngredients} />

            <DefaultLayout path="/kitchen" component={Kitchen} />
            <DefaultLayout path="/inventory" component={inventory} />
            {/* <DefaultLayout path="/dashboard-inventory" component={dashboard_inventory} />
            <DefaultLayout path="/product-inventory" component={product_inventory} />
            <DefaultLayout path="/brand" component={brand_inventory} />
            <DefaultLayout path="/uom" component={uom_inventory} /> */}

            {/* <DefaultLayout path="/menu/:id" component={LandingPage} /> */}
        </Switch>
      );
}