import {allwords} from './allwords';
import { sdx } from "./sdx";

export default function lang(w){
    const appLang=window.localStorage['lang'] || 'Eng';
    // const appLang=sdx.d.is_arabic && sdx.d.language == 'Arabic' || 'Eng';
    if(appLang==='Eng'){
        return w;
    }
    console.log("allwords "+w , allwords[w]);
    return allwords[w] || w;
}