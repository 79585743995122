import React, { useState, useEffect } from 'react';
import util from "../../util/util";
import { If, Paging, Perpagedd } from "../../util/controls";
import { sdx } from "../../sdx";
import { NavLink } from 'react-router-dom';
import lang from '../../lang';
let $ = window.$;
let $activeBranchId = '';
let client_id = 0, ref = {};
export default function Branches(props) {



    client_id = props.client_id;
    const fn = {};
    const [d, setData] = useState({
        list: { result: [], page: {} },
        hoursList: [],
        sdata: { p: 1, ps: 25 },
        data: { city: '' },
        countries: '',
        states: [],
        cities1: [],
        cities: [],
        language: [],
        locations: [],
        timezone1: [],
        timezone: '',
        timezones: [],
        food_category: [],
        items: [],
        country_id: '',
        state_id: '--Choose State--',
        sub_role :[],
        super_role:[],
        user_type:'',
        subscription_id:[],
        no_of_branches : 0,
        branchCount : 0,
        phone_code1 : '',
        currency : '',
        mobile_digit_length : 10,
        other_city : 0,
        is_admin : 0,
    }
        // d.handleChangecountry = this.handleChangecountry.bind(d);
        // d.changeState = this.changeState.bind(d);
    );


    fn.render = () => { setData({ ...d }); }

    fn.handleChange = (e) => {
        d.data[e.target.name] = e.target.value;
        fn.render();
    }

    fn.handleChangecountry = (e) => {
        d.data[e.target.name] = e.target.value;

        var phonecode = e.target.value;
        phonecode = phonecode.split('_');
        d.data.phone_code1 = phonecode[1];
        d.data.currency = phonecode[2];
        var country_id = phonecode[0];

        if (country_id != '') {
            // alert("ok");
            util.ajx("cmaster/countrylist", { country_id: country_id }).then(res => {
                // console.log(res);
                // if (res.code === 200) {
                d.states = res.result;
                // d.timezone = res.result;            
                fn.render();
                // }
            })

            util.ajx("cmaster/countrylang", { country_id: country_id }).then(res => {

                d.language = res.result;
                fn.render();
                // }
            })
        }
        fn.render();
    }

    fn.changeState = (e) => {
        d.data[e.target.name] = e.target.value;
        d.data.phone_code1 =  d.phone_code1;
        d.data.currency = d.currency;
        d.data.country_id = d.country_id;

        var state_id = e.target.value;
        if (state_id != '') {
            //  alert("ok");
            util.ajx("cmaster/citylist", { state_id: state_id }).then(res => {
                // console.log(res);
                // if (res.code === 200) {
                d.cities1 = res.result;
                fn.render();
                // }
            })

            util.ajx("cmaster/statetimezone", { state_id: state_id }).then(res => {
               d.data.timezone=res.result[0]['time_zone'];             
                    fn.render();
               
            })
        }



        fn.render();
    }

    fn.handleCity = (e) => {
        if(e.target.value == 'other'){
            d.other_city = 1;
        
        }

        d.data[e.target.name] = e.target.value;
        fn.render();
        
    }

    fn.changeItemBranches = (e, data, indx, type) => {
        if (type == 'bitem') {
            if (e.target.checked) {
                d.items[indx].bitem = 1;
            } else {
                d.items[indx].bitem = 0;
            }

        }
        else if (type == 'online_items') {
            if (e.target.checked) {
                d.items[indx].online_items = 1;
            } else {
                d.items[indx].online_items = 0;
            }

        }

        util.ajx("cmaster/set_item_branch", { item_id: data.id, value: e.target.checked ? 1 : 0, branch_id: $activeBranchId, type: type }).then(res => {
            if (res.code === 200) {
                d.list = res.result;
                fn.render();
            }
        })
        fn.render();
    }

    fn.setCheck = (e) => {
        let bid = e.target.value;
        if (e.target.checked) {
            d.data.branches.push(bid);
        } else {
            d.data.branches.splice(d.data.branches.indexOf(bid), 1);
        }
        fn.render();
    }

    fn.openForm = (dtl) => {
        d.data = dtl ? { ...dtl } : { client_id: props.client_id, food_category: [] };
        
        // d.states =d.data.states ? d.data.states: [];
        d.cities1 =d.data.city ? d.data.city: [];
        // d.mobile_digit_length = sdx.d.mobile_digit_length;
        d.other_city = 0;
        // d.language =d.data.languages ? d.data.languages: [];
        $('input[type="file"]').val('');
        fn.render();
        // window.draggable('#bformModal');
        window.showModal($("#bformModal"), true);
    }

    fn.openOtherCityForm =() => {

    }

    fn.openItemList = (dtl) => {
        d.data = dtl ? { ...dtl } : { client_id: props.client_id };
        $activeBranchId = dtl.id;
        $('input[type="file"]').val('');
        util.ajx("cmaster/list_items_by_branch/" + dtl.id).then(res => {
            if (res.code === 200) {
                d.items = res.result;
                fn.render();
                // window.draggable('#itemModal');
                window.showModal($("#itemModal"), true);
                $('input[type="file"]').val('');
            }
        })

    }

    fn.sdata = (p, ps) => {
        d.sdata.p = p ? p : 1;
        d.sdata.ps = ps ? ps : d.sdata.ps;
        return $("#sbfrm").serialize() + '&p=' + d.sdata.p + '&ps=' + d.sdata.ps + '&client_id=' + props.client_id;
    }

    fn.lists = (e, p, ps) => {
        if (e) e.preventDefault();
        util.ajx("cmaster/branches", fn.sdata(p, ps)).then(res => {
            if (res.code === 200) {
                d.list = res.result;
                if(res.countries){
                    d.countries = res.countries.country_name;
                    d.phone_code1 = res.countries.phone_code;
                    d.currency = res.countries.currency;
                    d.country_id = res.countries.id;
                }
                
                d.no_of_branches = res.no_of_branches;
                d.branchCount = res.branchCount;
                d.mobile_digit_length = res.mobile_digit_length;
                d.states=res.states;
                console.log(res.no_of_branches+'  '+res.branchCount);
                // d.cities1=res.cities1;
                fn.render();
            }
        })
    }

    fn.save = () => {
        let fd = new FormData($("#bfrm")[0]);
        util.ajx("cmaster/save_branch", fd).then(res => {
            if (res.code === 200) {
                $("#bformModal").modal('hide');
                fn.lists();
            }
        })
    }


    fn.remove_image = (id) => {
        let fd = new FormData($("#bfrm")[0]);
        // var myobj = document.getElementById("images");
        // myobj.remove();
        document.getElementById('images').src = "''";
        document.getElementById("removeImage").style.display = "none";
        $('input[type="file"]').val('');
        if (id != '') {
            util.ajx("cmaster/remove_branch_image", fd).then(res => {

            })

        }

    }

    fn.remove_menu_file = (id) => {
        let fd = new FormData($("#bfrm")[0]);
        // var myobj = document.getElementById("images");
        // myobj.remove();
        document.getElementById('view_menu').href = "''";
        $('#view_menu').hide();
        document.getElementById("remove_menu_file").style.display = "none";
        $('input[type="file"]').val('');
        if (id != '') {
            util.ajx("cmaster/remove_branch_menu_file", fd).then(res => {

            })

        }

    }


    fn.deleterec = (id,status) => {
        let callback = () => {
            util.ajx("cmaster/delete_branch", { id: id ,status:status }).then(res => {
                if (res.code === 200) {
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.handleImageChange = (e) => {
        d.data.image_url = URL.createObjectURL(e.target.files[0]);
        document.getElementById("removeImage").style.display = "block";
        fn.render();
    }

    fn.init = () => {
        util.ajx("cmaster/branches?init=1", fn.sdata(1, d.sdata.ps)).then(res => {
            if (res.code === 200) {
                d.list = res.result;
                d.hoursList = res.hours_list;
                d.cities = res.cities.result;
                d.locations = res.locations.result;
                d.timezones = res.timezones;
                d.food_category = res.food_category;
                if(res.countries){
                    d.countries = res.countries.country_name;
                    d.phone_code1 = res.countries.phone_code;
                    d.currency = res.countries.currency;
                    d.country_id = res.countries.id;
                }
                d.no_of_branches = res.no_of_branches;
                d.branchCount = res.branchCount;
                d.states=res.states;
                d.language = res.language;

                d.sub_role = res.logging_data.permission.sub_permission;
                d.super_role = res.logging_data.permission.super_permission;
                d.user_type = res.logging_data.user_type;
                d.subscription_id = res.logging_data.subscription_id;
                d.is_admin = res.is_admin;
                d.mobile_digit_length = res.mobile_digit_length;
                

                fn.render();
              
                if(d.subscription_id){
                    $('.left-sidebar-link').removeClass('d-none');
                }
                $('.branchAdd').removeClass('d-none');

                if(d.sub_role && ( d.sub_role[1] != 1 &&  d.sub_role && d.sub_role[2] != 1)  && d.user_type =='STAFF'){
                    util.logout('', props.history);
                }

                if(d.subscription_id && ( d.subscription_id[1] != 1 && d.subscription_id[7] != 1)  && d.user_type =='CLIENT'){
                    util.logout('', props.history);
                } 
                
                clearInterval(sdx.orderlist);
                clearInterval(sdx.kitchenlist);
            }
        })
    }
    fn.setBranchCheck = (e) => {
        let bid = e.target.value;
        if (e.target.checked) {
            d.data.food_category.push(bid);
        } else {
            d.data.food_category.splice(d.data.food_category.indexOf(bid), 1);
        }
        fn.render();
    }

    useEffect(() => {
        if (typeof props.timeFlg !== "undefined") { return; }
        fn.init();
        d.sub_role = sdx.d.sub_role;
        d.super_role = sdx.d.super_role;
        d.user_type = sdx.d.user_type;
        d.subscription_id = sdx.d.subscription_id;
        fn.render();
        if(d.sub_role && ( d.sub_role[1] != 1 &&  d.sub_role && d.sub_role[2] != 1)  && d.user_type =='STAFF'){
            util.logout('', props.history);
        }
        
        if(d.subscription_id && ( d.subscription_id[1] != 1 && d.subscription_id[7] != 1)  && d.user_type =='CLIENT'){
            util.logout('', props.history);
        } 
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (typeof props.timeFlg === "undefined") { return; }
        fn.init();
        d.sub_role = sdx.d.sub_role;
        d.super_role = sdx.d.super_role;
        d.user_type = sdx.d.user_type;
        d.subscription_id = sdx.d.subscription_id;
        fn.render();
        if(d.sub_role && ( d.sub_role[1] != 1 &&  d.sub_role && d.sub_role[2] != 1)  && d.user_type =='STAFF'){
            util.logout('', props.history);
        }
        
        if(d.subscription_id && ( d.subscription_id[1] != 1 && d.subscription_id[7] != 1)  && d.user_type =='CLIENT'){
            util.logout('', props.history);
        } 

        // eslint-disable-next-line
    }, [props.timeFlg]);


    return (
        <>
            <BranchList d={d} fn={fn} />
            <BranchForm d={d} fn={fn} />
            <ServiceAreas />
            <PrintSetup />
            <ItemList d={d} fn={fn} />
        </>
    )
}

function BranchList(props) {
    let d = props.d, fn = props.fn;
    return (
        <>
            <div className="row">
            <If cond={ d.is_admin == 0}>
                <div className="col-sm-12 col-md-3 col-lg-2 top-master">
                    <div className="mb-3 text-uppercase text-black fw700 fs12">{lang('Masters')}</div>
                    <div className="left-sidebar-link d-none">
                        
                        <If cond={d.sub_role && ( d.sub_role[1] == 1 ||  d.sub_role && d.sub_role[2] == 1)  || d.user_type !='STAFF'  }>
                                <NavLink to="/branches" className="">{lang('Branches')}</NavLink>
                            </If>
                            <If cond={d.user_type != 'STAFF'}> <NavLink to="/roles" className="">{lang('Roles')}</NavLink></If>
                           
                            <If cond={d.sub_role && d.sub_role[4] == 1  || d.user_type !='STAFF'}>
                                <NavLink to="/staffs" className="">{lang('Staffs')}</NavLink>
                            </If>
                            
                            <If cond={( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) ) || d.sub_role && d.sub_role[15] == 1    }>
                                <NavLink to="/cats" className="">{lang('Categories')}</NavLink>
                            </If>

                            <If cond={ d.sub_role && d.sub_role[16] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) ) }>
                                <NavLink to="/items" className="">{lang('Items')}</NavLink>
                            </If>
                            <If cond={ d.sub_role && d.sub_role[40] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) ) }>
                                <NavLink to="/combo" className="">{lang('Combos')}</NavLink>
                            </If>
                            <If cond={d.super_role && d.super_role[2] == 1 ||  d.sub_role && ( d.sub_role[5] == 1 ||  d.sub_role[6] == 1 ||  d.sub_role[8] == 1)   || d.user_type !='STAFF'}>
                                <NavLink to="/others" className="">{lang('Others')}</NavLink>
                            </If>
                            <If cond={ d.sub_role && d.sub_role[48] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) ) }>
                                <NavLink to="/recipeIngredients" className="">{lang('Recipe Ingredients')}</NavLink>
                            </If>
                    </div>
                </div>
                </If>
                <div className={d.is_admin == 0 ? "offset-lg-2 col-lg-10 offset-md-3 col-md-9 col-sm-12 right-master" : ''}>
                    <div className="main-table-body">
                        <If cond={!client_id}>
                            <div className="d-flex flex-row align-items-center justify-content-between pl-4 pr-4 pt-4">
                                <h6 className="m-0 h5 text-dark">{lang('Branches')}</h6>
                                <If cond={d.sub_role && d.sub_role[1] == 1 || d.user_type !='STAFF'}>
                                <If cond={parseInt(d.no_of_branches) == 0 || (parseInt(d.no_of_branches) > 0 && parseInt(d.branchCount) < parseInt(d.no_of_branches)) }>
                                    <div className="ml-auto mt-auto mb-auto branchAdd" >
                                        <button className="btn btn-danger btn-red" onClick={() => fn.openForm()}><i className="fa fa-plus"></i> {lang('Add')}</button>
                                    </div>
                                    </If>
                                </If>
                            </div>
                        </If>
                        <div className={client_id ? "" : "main-content"}>
                            <div className={client_id ? "" : "container-fluid"}>
                                <If cond={d.list.result.length && !client_id}>
                                    {/* <div className="d-flex">
                                            <div className="text-secondary bold600 mb5">
                                                Showing {d.list.page.start + 1} - {d.list.page.start + d.list.page.total} of {d.list.page.total_records} records.
                                </div>
                                        </div> */}
                                </If>

                                <div className="d-flex">
                                    <div className="form-inline">
                                        <form id="sbfrm" onSubmit={fn.lists} autoComplete="on" spellCheck="false">
                                            <div className="input-group search-control">
                                                <input type="search" className="form-control mr-3" name="k" placeholder={lang('Search')} />
                                                <button className="btn btn-red-outline btn-danger" type="submit"><i className="fa fa-search"></i></button>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="ml-auto mt-auto mb-auto">
                                        <If cond={client_id}>
                                            <If cond={d.no_of_branches == 0 || (d.no_of_branches > 0 && d.branchCount < d.no_of_branches) }>
                                                     <button className="btn btn-outline-primary" onClick={() => fn.openForm()}><i className="fa fa-plus"></i> {lang('Add')}</button>
                                            </If>
                                        </If>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <If cond={d.list.result.length}>
                                        <div className="table-responsive">
                                            <table className="table border m-0">
                                                <thead className="bg-light text-uppercase font-sm">
                                                    <tr>
                                                        <th className="w20">{lang('SN')}</th>
                                                        <th className="w20">{lang('Logo')}</th>
                                                        <th>{lang('Branch Name')}</th>
                                                        <th className="w80">{lang('Ratings')}</th>
                                                        <th className="w80"></th>
                                                        <th className="w80"></th>
                                                        <th className="w80">{lang('Timezone')}</th>
                                                        <th className="w80">{lang('Status')}</th>
                                                        <th className="w70"></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-text-vmid">
                                                    {d.list.result.map((v, i) => (
                                                        <tr key={i}>
                                                            <td>{d.list.page.start + i + 1}.</td>
                                                            <td>
                                                                <If cond={v.image_url}>
                                                                    <img className="mx60" src={v.image_url} alt="" />
                                                                </If>
                                                            </td>
                                                            <td className="text-black fw600">{v.title}</td>
                                                            <td>{v.rating}</td>
                                                            <td>
                                                                <If cond={d.sub_role && d.sub_role['16'] == 1 || ( d.user_type !='STAFF' && d.subscription_id && ( (d.subscription_id[2] == 1) ) ) }>
                                                                    
                                                                    <button type="button" className="btn btn-sm btn-danger btn-redgrey-outline btn-block nowrap" onClick={() => fn.openItemList(v)}>{lang('Items')}</button>
                                                                </If>
                                                            </td>
                                                            <td>
                                                                <If cond={d.sub_role && d.sub_role['2'] == 1 || ( d.user_type !='STAFF' && d.subscription_id && ( (d.subscription_id[1] == 1) || (d.subscription_id[7] == 1) ) ) }>
                                                                    <button type="button" className="btn btn-sm btn-danger btn-redgrey-outline btn-block nowrap" onClick={() => ref.openServiceAreas(v)}>{lang('Service Areas')}</button>
                                                                </If>
                                                            </td>
                                                            <td className="nowrap">{v.timezone}</td>

                                                            <td>
                                                                <If cond={v.status === '1'}>
                                                                    <div className="badge badge-success">{lang('Active')}</div>
                                                                </If>
                                                                <If cond={v.status === '0'}>
                                                                    <div className="badge badge-red">{lang('Inactive')}</div>
                                                                </If>
                                                            </td>
                                                            <td className="text-center">
                                                                <div className="btn-group">
                                                                    <If cond={d.sub_role && d.sub_role[1] == 1 || d.user_type !='STAFF'}>
                                                                        <button type="button" data-toggle="tooltip" title="Edit" className="btn btn-sm fs16" onClick={() => fn.openForm(v)}><i className="far fa-edit"></i></button>
                                                                        <button type="button" data-toggle="tooltip" title="Delete" className="btn btn-sm fs16" onClick={() => fn.deleterec(v.id,v.status)}><i className="fa fa-trash-o"></i></button>
                                                                        
                                                                        <button type="button" data-toggle="tooltip" title="Edit" className="btn btn-sm fs16" onClick={() => ref.openPrintSetup(v)}><i className="fa fa-print"></i></button>
                                                                    </If>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <If cond={!client_id}>
                                            <div className="d-flex justify-content-between mt-4 pb-3">
                                                <div className="mr5">
                                                    <Paging fn={fn.lists} pageob={d.list.page} />
                                                </div>
                                                <div>
                                                    <Perpagedd fn={fn.lists} />
                                                </div>
                                            </div>
                                        </If>
                                    </If>
                                    <If cond={!d.list.result.length}>
                                        <div className="no-rec">{lang('No record found')}</div>
                                    </If>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

function BranchForm(props) {
    let d = props.d, fn = props.fn;
    

    return (
        <div className="modal modal-scroll" id="bformModal" data-backdrop="static">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title">{d.data.id ? 'Edit' : 'Add'} {lang('Branch')}</div>
                        <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={() => $("#bformModal").modal('hide')}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form id="bfrm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" defaultValue={d.data.id} />
                            <input type="hidden" name="client_id" defaultValue={d.data.client_id} />
                            <input type="hidden" name="created" defaultValue={d.data.created} />
                            <div className="row mingap">
                                <div className="form-group col-md-4">
                                    <label className="req">{lang('Branch Name')}</label>
                                    <input type="text" className="form-control" name="title" value={d.data.title || ''} onChange={fn.handleChange} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label className="req">{lang('Building/House No')}.</label>
                                    <input type="text" className="form-control" name="build_no" value={d.data.build_no || ''} onChange={fn.handleChange} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label className="">{lang('Building/House Name')}</label>
                                    <input type="text" className="form-control" name="build_name" value={d.data.build_name || ''} onChange={fn.handleChange} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label className="">{lang('Block')}</label>
                                    <input type="text" className="form-control" name="block" value={d.data.block || ''} onChange={fn.handleChange} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label className="">{lang('Street')}</label>
                                    <input type="text" className="form-control" name="street" value={d.data.street || ''} onChange={fn.handleChange} />
                                </div> <div className="form-group col-md-12"></div>

                                <div className="form-group col-md-4">
                                    <If cond={d.data.branch_status == 1}>
                                        <label className="req">{lang('City')}</label>
                                        <select className="form-control" name="city_id" value={d.data.city_id || ''} onChange={fn.handleChange}>
                                            <option value="">{lang('Select')}</option>
                                            {d.cities.map((r, i) => <option key={i} value={r.id}>{r.title}</option>)}
                                        </select>

                                        <label className="req">{lang('Location')}</label>
                                        <select className="form-control" name="loc_id" value={d.data.loc_id || ''} onChange={fn.handleChange}>
                                            <option value="">{lang('Select')}</option>
                                            {util.arr(d.locations[d.data.city_id]).map((r, i) => <option key={i} value={r.id}>{r.title}</option>)}
                                        </select>
                                    </If>

                                    <If cond={d.data.branch_status != 1}>
                                        <label className="req">{lang('Country')}</label>
                                        <input type="text" className="form-control"  value={d.countries || ''} readOnly />
                                        <input type="hidden" className="form-control" name="country_id" value={d.data.country_id  || ''} readOnly />
                                        
                                        {/* <select className="form-control" name="country_id" id="country_id" value={d.data.country_id || ''} onChange={fn.handleChangecountry}>
                                            <option value="">Select Country</option>
                                            {d.countries.map(r => <option key={r.id} value={r.id + '_' + r.phone_code + '_' + r.currency}>{r.country_name}</option>)}
                                        </select> */}
                                    </If>
                                </div>

                                <If cond={d.data.branch_status != 1}>
                                    <div className="form-group col-md-4">
                                        <label className="req">{lang('State')}</label>
                                        <select id="state_id" className="form-control" name="state_id" value={d.data.state_id || ''} onChange={fn.changeState}>
                                            <option value="">{lang('Select')}</option>
                                            {d.states.map((r, i) => <option key={i} value={r.id}>{r.name}</option>)}
                                        </select>
                                    </div>

                                    <div className="form-group col-md-4">
                                        <label className="req">{lang('City')}</label>
                                        <select className="form-control" id="city_id" name="city_id" value={d.data.city_id || ''} onChange={fn.handleCity}>
                                            <option value="">{lang('Select')}</option>
                                            {d.cities1.map((r, i) => <option key={i} value={r.id}>{r.name}</option>)}
                                            <option value="other">{lang('Others')}</option>
                                            
                                        </select>
                                        <If cond={d.other_city == 1}>
                                            <input type="text" className="form-control" name="other_city" placeholder={lang('Enter Other City')}  value={d.data.other_city || ''} onChange={fn.handleChange} />
                                        </If>
                                    </div>

                                    


                                    <div className="form-group col-md-4">
                                        <label className="req">{lang('Language')}</label>
                                        <select className="form-control" name="language" value={d.data.language || ''} onChange={fn.handleChange}>
                                            <option value="">{lang('Select')}</option>
                                            {d.language.map((r, i) => <option key={i} value={r.id}>{r.language}</option>)}
                                        </select>
                                    </div>

                                    <div className="form-group col-md-4">
                                        <label className="">{lang('Timezone')}</label>
                                        <input type="text" readOnly id="timezone1" className="form-control" name="timezone" value={d.data.timezone || ''} onChange={fn.handleChange} />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label className="">{lang('Currency')}</label>
                                        <input type="text" readOnly className="form-control" name="currency" value={d.data.currency || ''} onChange={fn.handleChange} />
                                    </div>
                                </If>

                                <div className="form-group col-md-4">
                                    <label className="">{lang('Latitude')}</label>
                                    <input type="text" className="form-control" name="lat" value={d.data.lat || ''} onChange={fn.handleChange} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label className="">{lang('Longitude')}</label>
                                    <input type="text" className="form-control" name="lng" value={d.data.lng || ''} onChange={fn.handleChange} />
                                </div>
                                <If cond={d.data.branch_status == 1}>
                                    <div className="form-group col-md-4">
                                        <label className="req">{lang('Timezone')}</label>
                                        <select className="form-control" name="timezone" value={d.data.timezone || ''} onChange={fn.handleChange}>
                                            <option value="">{lang('Select')}</option>
                                            {d.timezones.map((r, i) => <option key={i} value={r.id}>{r.id + " (" + r.title + ")"}</option>)}
                                        </select>
                                    </div>
                                </If>

                                <div className="form-group col-md-12">
                                    <label>{lang('Add Menu')}</label>
                                    <input type="file" className="form-control" name="menu_file" />
                                </div>

                                <If cond={d.data.menu_file_url}>
                                    <button type="button" className="close" id="remove_menu_file" onClick={() => fn.remove_menu_file(d.data.menu_file_url)}><span aria-hidden="true">&times;</span></button>

                                    <div className="pt3 w100">
                                        <a href={d.data.menu_file_url} target="_blank" id="view_menu">{lang('View Menu')}</a>

                                    </div>
                                </If>
                            </div>

                            <div className="row mingap">
                                <If cond={d.data.branch_status != 1}>
                                    <div className="form-group col-md-2">
                                        <label className="req">{lang('Code')}</label>
                                        <input type="text" readOnly className="form-control phonecodevalue1" name="phone_code1" value={d.data.phone_code1 || ''} onChange={fn.handleChange} />
                                    </div>
                                </If>

                                <div className="form-group col-md-4">
                                    <label className="req">{lang('Phone 1')}</label>
                                    <input type="text" className="form-control" maxLength={d.mobile_digit_length} name="phone1" value={d.data.phone1 || ''} onChange={fn.handleChange} />
                                </div>
                                <If cond={d.data.branch_status != 1}>
                                    <div className="form-group col-md-2">
                                        <label className="">{lang('Code')}</label>
                                        <input type="text" readOnly className="form-control phonecodevalue1" name="phone_code1" value={d.data.phone_code1 || ''} onChange={fn.handleChange} />
                                    </div>
                                </If>
                                <div className="form-group col-md-4">
                                    <label className="">{lang('Phone 2')}</label>
                                    <input type="text" className="form-control" maxLength={d.mobile_digit_length} name="phone2" value={d.data.phone2 || ''} onChange={fn.handleChange} />
                                </div>
                                <div className="form-group col-md-3">
                                    <label className="req">{lang('Start Time')}</label>
                                    <select className="form-control" name="start_time" value={d.data.start_time || ''} onChange={fn.handleChange}>
                                        <option value="">{lang('Select')}</option>
                                        {d.hoursList.map((r, i) => <option key={i} value={r.k}>{r.v}</option>)}
                                    </select>
                                </div>
                                <div className="form-group col-md-3">
                                    <label className="req">{lang('End Time')}</label>
                                    <select className="form-control" name="end_time" value={d.data.end_time || ''} onChange={fn.handleChange}>
                                        <option value="">{lang('Select')}</option>
                                        {d.hoursList.map((r, i) => <option key={i} value={r.k}>{r.v}</option>)}
                                    </select>
                                </div>

                                <div className="form-group col-md-3">
                                    <label className="req">{lang('Tip')}</label>
                                    <select className="form-control" name="tip" value={d.data.tip || ''} onChange={fn.handleChange}>
                                        <option value="0">{lang('No')}</option>
                                        <option value="1">{lang('Yes')}</option>
                                    </select>
                                </div>

                                <div className="form-group col-md-3">
                                    <label className="req">{lang('Status')}</label>
                                    <select className="form-control" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                        <option value="1">{lang('Active')}</option>
                                        <option value="0">{lang('Inactive')}</option>
                                    </select>
                                </div>

                                <div className="form-group col-md-3">
                                    <label className="">{lang('Hold Order')}</label>
                                    <select className="form-control" name="hold_order" value={d.data.hold_order || ''} onChange={fn.handleChange}>
                                        <option value="0">{lang('No')}</option>
                                        <option value="1">{lang('Yes')}</option>
                                    </select>
                                </div>

                               

                                <div className="form-group col-md-12">
                                    <label>{lang('Branch Logo')}</label>
                                    <input type="file" className="form-control" name="image" onChange={fn.handleImageChange} />
                                    <If cond={d.data.image_url}>
                                        <button type="button" className="close" id="removeImage" onClick={() => fn.remove_image(d.data.image_url)}><span aria-hidden="true">&times;</span></button>

                                    </If>
                                    <If cond={d.data.image_url == ''}>
                                        <button type="button" className="close" style={{ display: 'none' }} id="removeImage" onClick={() => fn.remove_image(d.data.image_url)}><span aria-hidden="true">&times;</span></button>
                                    </If>
                                    <If cond={d.data.image_url}>
                                        <div className="pt3 w100">
                                            <img className="mw-100" id="images" src={d.data.image_url} alt="" />

                                        </div>
                                    </If>
                                </div>
                                <div className="form-group col-md-12 mt-2">
                                    <label className="">{lang('Food Category')}</label> ({lang('Maximimum')})

                                    {d.food_category ? (
                                        d.food_category.map((r, i) => (
                                            <div className="form-control mb5" key={i}>
                                                <label className="m-0 noselect">
                                                    <input type="checkbox" name="food_category[]" value={r.id} checked={d.data.food_category?.includes(r.id) ? true : false} onChange={fn.setBranchCheck} />
                                                    &nbsp;&nbsp;{r.name}
                                                </label>
                                            </div>
                                        ))) : ''}
                                </div>
                                <div className="form-group col-md-4">
                                    <label className="">{lang('Ratings')}</label>
                                    <select className="form-control" name="rating" value={d.data.rating || ''} onChange={fn.handleChange}>
                                        <option value="0">{lang('Select')}</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </div>

                                {/* <div className="form-group col-md-3">
                                    <label className="req">Is Main Branch</label>
                                    <select className="form-control" name="is_main_branch" value={d.data.is_main_branch || ''} onChange={fn.handleChange}>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                </div> */}

                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={() => $("#bformModal").modal('hide')}>Close</button> */}
                        <button type="button" className="btn btn-danger btn-red" onClick={fn.save}>{lang('Save')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


function ServiceAreas() {
    const [d, setData] = useState({
        lists: [{}],
        bdtl: false,
        data: { dining: [] },
        dining: [],
        deliver: [],
        pickup: [],
        subscription_id : [],
    });

    const [checked, setChecked] = React.useState(true);
    const fn = {};
    ref.openServiceAreas = (bdtl) => {
        d.bdtl = bdtl;
        util.ajx("cmaster/service_areas", { branch_id: bdtl.id }).then(res => {
            if (res.code === 200) {
                d.lists = res.result;
                if (!d.lists.length) {
                    d.lists = [{}];
                }
                d.subscription_id = sdx.d.subscription_id;

                setData({ ...d });
                window.showModal($("#sareasModal"), true);
            }
        })
    }


    fn.setPickupCheck = (e,i) => {

        if(e.target.value == 1){
            $(".diningService"+i).attr('readonly',true);
            $(".diningService"+i+" option[value="+1+"]").attr('disabled',true);
            $(".no_of_tables_"+i).attr('readonly',true);
            
        }else{
            $(".diningService"+i).attr('readonly',false);
            $(".diningService"+i+" option[value="+1+"]").attr('disabled',false);
            $(".no_of_tables_"+i).attr('readonly',false);
        }
        
    }

    fn.setDeliverCheck = (e,i) => {


        if(e.target.value == 1){
            $(".diningService"+i).attr('readonly',true);
            // $(".diningService"+i+" option[value="+1+"]").attr('disabled',true);
            // $(".no_of_tables_"+i).attr('readonly',true);
        }else{
            $(".diningService"+i).attr('readonly',false);
            // $(".diningService"+i+" option[value="+1+"]").attr('disabled',false);
            // $(".no_of_tables_"+i).attr('readonly',false);
        }

    }

    fn.setDiningCheck=(e,i)=>{
        // if(e.target.checked){

        //     d[e.target.name] = 1 ;
        // }else{
        //     d[e.target.name] = 0 ;
        // }
        // setData({...d});
        if(e.target.value == 1){
            $(".pickupDelivery"+i).attr('readonly',true);
            $(".pickupDelivery"+i+" option[value="+1+"]").attr('disabled',true);
            $(".no_of_tables_"+i).attr('readonly',false);
        }else{
            $(".pickupDelivery"+i).attr('readonly',false);
            $(".pickupDelivery"+i+" option[value="+1+"]").attr('disabled',false);
            $(".no_of_tables_"+i).attr('readonly',true);
        }

    }

    const closeModal = () => {
        setData({ ...d, bdtl: false });
        $("#sareasModal").modal('hide');
    }

    fn.handleChange = (e) => {
        d.data[e.target.name] = e.target.value;

        setData({ ...d });

    }






    const addNew = () => {
        d.lists.push({});
        setData({ ...d });
    }
    const remove = (id) => () => {
        $('.service'+id).hide();
        // console.log(d.lists.indexOf(id));
        // for(var i = 0; i <= d.lists.length - 1; i++){
          
        //     if(d.lists[i]['id'] == id){
        //         d.lists.splice(i--,1);
        //     }
        // }
          
        // setData({ ...d });

        util.ajx("cmaster/delete_service_areas", {id:id}).then(res => {
            if (res.code === 200) {
                closeModal();
            }
        })

        // ref.openServiceAreas(d.bdtl)
        
        

    }

    const save = () => {
        let fd = new FormData($("#sareaFrm")[0]);
        fd.append("branch_id", d.bdtl.id);
        util.ajx("cmaster/save_service_area", fd).then(res => {
            if (res.code === 200) {
                closeModal();
            }
        })
    }


    return (
        <div className="modal modal-scroll no-backdrop" id="sareasModal" data-backdrop="static">
            <If cond={d.bdtl}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title uc">{d.bdtl.title} - <span className="text-secondary">{lang('Service Areas')}</span></div>
                            <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={closeModal}><span aria-hidden="true">&times;</span></button>
                        </div>

                        <div className="modal-body">
                            <form id="sareaFrm" autoComplete="off" spellCheck="false">
                                <div className="table-responsive mb3">
                                    <table className="table table-sm table-bordered m-0">
                                        <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                            <tr>
                                                <th className="w20">{lang('SN')}</th>
                                                <th>{lang('Service Area')}</th>
                                                <th className="w120 nowrap">{lang('No. of Tables')}</th>
                                                <th className="w120 nowrap">{lang('Dining')}</th>
                                                <If cond={ d.subscription_id &&  (d.subscription_id[7] == 1) }>
                                                <th className="w120 nowrap">{lang('Pickup')}</th>
                                                <th className="w120 nowrap">{lang('Deliver')}</th>
                                                </If>
                                                <th className="w20"></th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-text-vmid">
                                            {d.lists.map((v, i) => (
                                                <tr key={i} className={'service'+v.id}>
                                                    <td>{i + 1}.</td>
                                                    <td>
                                                        <input type="hidden" name="id[]" defaultValue={v.id} />
                                                        <input type="text" className="form-control" name="title[]" defaultValue={v.title} maxLength="255" />
                                                    </td>
                                                    <td>
                                                        <input type="number"  readOnly={v.dining == 1 ? false : true}   className={"form-control no_of_tables_"+i} name="no_of_tables[]" defaultValue={v.no_of_tables} />
                                                    </td>
                                                    <td>
                                                        <select className={"form-control diningService"+i} name="dining[]" id={v.id + '_dining'} readOnly={v.pickup == 1 || v.deliver == 1 ? true : false} onChange={(e) => fn.setDiningCheck(e,i)} >
                                                            <option selected={v.dining == 0 ? true : false} value="0">{lang('No')}</option>
                                                            <option selected={v.dining == 1 ? true : false} value="1" disabled={v.pickup == 1 || v.deliver == 1 ? true : false} >{lang('Yes')}</option>

                                                        </select>

                                                        {/* <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" defaultChecked={v.dining == 1 ?true:false} value={v.dining || d.data.dining} name="dining[]" onChange={fn.setDiningCheck} />
                                                                
                                                        </div> */}
                                                    </td>
                                                    <If cond={ d.subscription_id &&  (d.subscription_id[7] == 1) }>
                                                    <td>
                                                        <select className={"form-control pickupDelivery"+i} name="pickup[]" id={v.id + '_pickup'} readOnly={v.dining == 1 ? true : false}  onChange={(e) => fn.setPickupCheck(e,i)} >
                                                            <option selected={v.pickup == 0 ? true : false} value="0">{lang('No')}</option>
                                                            <option selected={v.pickup == 1 ? true : false} value="1" disabled={v.dining == 1 ? true : false} >{lang('Yes')}</option>

                                                        </select>

                                                        {/* <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" defaultChecked={v.pickup == 1 ?true:false} value={v.pickup || d.pickup} name="pickup[]" onChange={fn.setPickupCheck} />
                                                                
                                                        </div> */}
                                                    </td>
                                                    <td>
                                                        <select className={"form-control pickupDelivery"+i} id={v.id + '_deliver'} readOnly={v.dining == 1 ? true : false} name="deliver[]" onChange={(e) => fn.setDeliverCheck(e,i)} >
                                                            <option selected={v.deliver == 0 ? true : false} value="0">{lang('No')}</option>
                                                            <option selected={v.deliver == 1 ? true : false} value="1" disabled={v.dining == 1 ? true : false}>{lang('Yes')}</option>

                                                        </select>
                                                        {/* <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" defaultChecked={v.deliver == 1 ?true:false} value={v.deliver || d.deliver} name="deliver[]" onChange={fn.setDeliverCheck} />
                                                                
                                                        </div> */}
                                                    </td>
                                                    </If>

                                                    <td className="w20">
                                                        <button type="button" className="btn btn-sm btn-danger" onClick={remove(v.id)} ><i className="fa fa-trash-o"></i></button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <button type="button" className="btn btn-sm btn-light-blue" onClick={addNew}><i className="fa fa-plus"></i> {lang('Add More')}</button>
                            </form>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={closeModal}>{lang('Close')}</button>
                            <button type="button" className="btn btn-primary" onClick={save}>{lang('Save')}</button>
                        </div>
                    </div>
                </div>
            </If>
        </div>
    )
}

function ItemList(props) {
    let d = props.d, fn = props.fn;

    return (
        <div className="modal modal-scroll no-backdrop" id="itemModal" data-backdrop="static">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{lang('Items')}</div>
                        <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={() => $("#itemModal").modal('hide')}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <table className="table table-sm table-bordered m-0">
                            <thead>
                                <tr>
                                    <th className="w20">{lang('SN')}</th>
                                    <th className="w80">{lang('Name')}</th>
                                    <th className="w80">{lang('Dining')}</th>
                                    <th className="w80">{lang('Delivery/Pickup')}</th>
                                </tr>
                            </thead>

                            <tbody className="table-text-top">
                                {d.items.map((v, i) => (
                                    <tr key={i}>
                                        <td className="w20">{i + 1}.</td>
                                        <td>
                                            {v.title}
                                            <div className="pt3 note-text">{v.cat}</div>
                                        </td>

                                        <td className="w50 nowrap">
                                            <div className="status-switch">
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox" id={"ic" + i} className="custom-control-input" checked={v.bitem * 1 === 1} onChange={(e) => fn.changeItemBranches(e, v, i, 'bitem')} />
                                                    <label className="custom-control-label" htmlFor={"ic" + i}>
                                                        <If cond={v.bitem * 1 === 1}><span>{lang('Added')}</span></If>
                                                        <If cond={v.bitem * 1 === 0}><span className="text-danger">{lang('Not Added')}</span></If>
                                                    </label>
                                                </div>
                                            </div>
                                        </td>



                                        <td className="w50 nowrap">
                                            <div className="status-switch">
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox" id={"online_items" + i} className="custom-control-input" checked={v.online_items * 1 === 1} onChange={(e) => fn.changeItemBranches(e, v, i, 'online_items')} />
                                                    <label className="custom-control-label" htmlFor={"online_items" + i}>
                                                        <If cond={v.online_items * 1 === 1}><span>{lang('Added')}</span></If>
                                                        <If cond={v.online_items * 1 === 0}><span className="text-danger">{lang('Not Added')}</span></If>
                                                    </label>
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={() => $("#itemModal").modal('hide')}>{lang('Close')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


function PrintSetup() {
    const [d, setData] = useState({
        lists: [{}],
        bdtl: false,
        data: { dining: [] },
        dining: [],
        deliver: [],
        pickup: [],
        subscription_id : [],
    });

    const [checked, setChecked] = React.useState(true);
    const fn = {};
    ref.openPrintSetup = (bdtl) => {
        d.bdtl = bdtl;
        util.ajx("cmaster/printers", { branch_id: bdtl.id }).then(res => {
            if (res.code === 200) {
                d.lists = res.result;
                if (!d.lists.length) {
                    d.lists = [{}];
                }
                d.subscription_id = sdx.d.subscription_id;

                setData({ ...d });
                window.showModal($("#printSetupModal"), true);
            }
        })
    }


    const closeModal = () => {
        setData({ ...d, bdtl: false });
        $("#printSetupModal").modal('hide');
    }

    fn.handleChange = (e) => {
        d.data[e.target.name] = e.target.value;

        setData({ ...d });

    }






    const addNew = () => {
        d.lists.push({});
        setData({ ...d });
    }
    const remove = (id) => () => {
        $('.printers'+id).hide();
        // console.log(d.lists.indexOf(id));
        // for(var i = 0; i <= d.lists.length - 1; i++){
          
        //     if(d.lists[i]['id'] == id){
        //         d.lists.splice(i--,1);
        //     }
        // }
          
        // setData({ ...d });

        util.ajx("cmaster/delete_printers", {id:id}).then(res => {
            if (res.code === 200) {
                closeModal();
            }
        })

        
        
        

    }

    const save = () => {
        let fd = new FormData($("#printersFrm")[0]);
        fd.append("branch_id", d.bdtl.id);
        util.ajx("cmaster/save_printers", fd).then(res => {
            if (res.code === 200) {
                closeModal();
            }
        })
    }


    return (
        <div className="modal modal-scroll no-backdrop" id="printSetupModal" data-backdrop="static">
            <If cond={d.bdtl}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title uc">{d.bdtl.title} - <span className="text-secondary">{lang('Print Setup')}</span></div>
                            <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={closeModal}><span aria-hidden="true">&times;</span></button>
                        </div>

                        <div className="modal-body">
                            <form id="printersFrm" autoComplete="off" spellCheck="false">
                                <div className="table-responsive mb3">
                                    <table className="table table-sm table-bordered m-0">
                                        <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                            <tr>
                                                <th className="w20">{lang('SN')}</th>
                                                <th className="w120 ">{lang('Title')}</th>
                                                <th className="w120 ">{lang('Printers Name')}</th>
                                                
                                                <th className="w20"></th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-text-vmid">
                                            {d.lists.map((v, i) => (
                                                <tr key={i} className={'printers'+v.id}>
                                                    <td>{i + 1}.</td>
                                                    <td>
                                                        <input type="hidden" name="id[]" defaultValue={v.id} />
                                                        <input type="text" className="form-control" name="title[]" defaultValue={v.title} maxLength="255" />
                                                    </td>
                                                    <td>
                                                        <input type="text"     className={"form-control printer_name"+i} name="printer_name[]" defaultValue={v.printer_name} />
                                                    </td>
                                                    

                                                    <td className="w20">
                                                        <button type="button" className="btn btn-sm btn-danger" onClick={remove(v.id)} ><i className="fa fa-trash-o"></i></button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <button type="button" className="btn btn-sm btn-light-blue" onClick={addNew}><i className="fa fa-plus"></i> {lang('Add More')}</button>
                            </form>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={closeModal}>{lang('Close')}</button>
                            <button type="button" className="btn btn-primary" onClick={save}>{lang('Save')}</button>
                        </div>
                    </div>
                </div>
            </If>
        </div>
    )
}