import React, { useState, useEffect } from 'react';
import util from "../../util/util";
import uuid from "react-uuid";
import { useParams, Link } from 'react-router-dom';
import { sdx } from "../../sdx";
import { If, Paging, Perpagedd ,AmtFormat} from "../../util/controls";
import fbase from "../../services/firebase";
import lang from '../../lang';
let client_id = 0, ref = {};

let $ = window.$;

export default function StaffReport(props) {

    client_id = props.client_id;
    const fn = {};
    const { id } = useParams();
    let pfn = props.computedMatch;
    const [d, setData] = useState({
        list: { result: [], page: {} },
        sdata: { p: 1, ps: 25 },
        data: {},
        branches: [],
        roles: [],
        m_users: [],
        role_id: 0,
        is_arabic: 0,
        curInfo: {},
        download_url : '' ,
        downloadPdf :'',
        print : '',
        branches: [],
        branchesids: [],
        branch_name : '',
        todayDate : '',
        start_date : '',
        end_date : '',
        sub_role :[],
        super_role:[],
        user_type:'',
        branchesidscopy: [],
    });
    fn.render = () => { setData({ ...d }); }

       d.todayDate = new Date();
       d.todayDate = d.todayDate.getFullYear() + "-" + (d.todayDate.getMonth() + 1) + "-" + d.todayDate.getDate();

    fn.sdata = (p, ps) => {
        
        const  start_date = $('#from_date').val();
        const  end_date = $('#to_date').val();
        d.sdata.p = p ? p : 1;
        d.sdata.ps = ps ? ps : d.sdata.ps;
        d.sdata.start_date =  start_date ? start_date : window.sessionStorage['start_date'];
        d.sdata.end_date =  end_date ? end_date : window.sessionStorage['end_date'];
        d.sdata.authtoken = window.localStorage['token'];
        d.sdata.branch_ids = d.branchesids;
        
        return $("#sbfrm").serialize() + '&p=' + d.sdata.p + '&ps=' + d.sdata.ps + '&client_id=' + props.client_id + '&start_date=' +d.sdata.start_date + '&end_date=' +d.sdata.end_date +'&authtoken=' +d.sdata.authtoken+'&branch_ids='+d.sdata.branch_ids;
    }

    fn.lists = (e, p, ps) => {
        if (e) e.preventDefault();
        //let start_date = window.sessionStorage['start_date'];
       // console.log(start_date)
        util.ajx("reports/staffReport", fn.sdata(p, ps)).then(res => {
            if (res.code === 200) {
                d.list = res.result;
                d.curInfo = res.curInfo;
                d.download_url = res.download_url;
                d.downloadPdf = res.downloadPdf;
                d.print = res.print;
                d.roles = res.roles;
                d.m_users = res.m_users;
                d.branches = res.branches;
                d.branchesids = res.branch_ids;
                d.branch_name = res.branch_name;
                var dd = new Date(res.start_date);
                var strDate = dd.getDate()+ "-" + (dd.getMonth() + 1) + "-" +dd.getFullYear();
                d.start_date = strDate;


                var d1 = new Date(res.end_date);
                var end_date = d1.getDate()+ "-" + (d1.getMonth() + 1) + "-" +d1.getFullYear();
                d.end_date = end_date;
                d.sub_role = sdx.d.sub_role;
                d.super_role = sdx.d.super_role;
                d.user_type = sdx.d.user_type;
                d.branchesidscopy = res.branch_ids_copy;
                if(d.branchesids.length ===  d.branchesidscopy.length && d.branchesids.length > 1){
                    d.branch_name = 'All';
                }

                fn.render();
            }
        })
    }
  

    fn.printStaffReport =(e, p, ps) =>{
        if (e) e.preventDefault();
        util.ajx("reports/printStaffReport", fn.sdata(p, ps)).then(res => {
            $("#iframe_print").append(res.result);
            $("#iframe_print").print();
            $("#iframe_print").html('');
        })

    }

    fn.handleChange = (e) => {
        d.data[e.target.name] = e.target.value;
        fn.render();
      }
    
      fn.openForm = (dtl) => {
    
        d.data = dtl ? { ...dtl } : { branches: [] };
        // var branch_ids =d.branchesids;
        // if (branch_ids == '') {
        //      $("#branchall").attr('checked', true);
        // }

        if(d.branchesids.length ===  d.branchesidscopy.length){
            d.branch_name = 'All';
        }
    
        fn.render();
        window.showModal($("#branchModal"), true);
      }

      fn.setAllchecked = (e) => {
        let bid = e.target.value;
        if (!e.target.checked) {
          d.branchesids = [];
          d.branch_name = '';
        }else{
            d.branchesids =  d.branchesidscopy.slice() ;
            d.branch_name = 'All';
        }
        fn.render();
      }
    
      fn.openDateForm = (dtl) => {
    
        d.data = dtl ? { ...dtl } : { branches: [] };
    
        $('#from_date').val('');
        $('#to_date').val('');

        fn.render();
        window.showModal($("#dateModal"), true);
      }
    
    
      fn.setBranchCheck = (e) => {
        let bid = e.target.value;
        if (e.target.checked) {
          d.branchesids.push(bid);
        } else {
          d.branchesids.splice(d.branchesids.indexOf(bid), 1);
        }
        // d.branchesids = d.data.branches;
        d.branch_name = '';
        if(d.branchesids.length ===  d.branchesidscopy.length){
            d.branch_name = 'All';
        }
        fn.render();
      }
    
      fn.save =(e, p, ps) => {
        if (e) e.preventDefault();
        // let fd = new FormData($("#bfrm")[0]);
        util.ajx("reports/staffReport", fn.sdata(p, ps)).then(res => {
          $("#branchModal").modal('hide');
          d.list = res.result;
            d.curInfo = res.curInfo;
            d.download_url = res.download_url;
            d.downloadPdf = res.downloadPdf;
            d.print = res.print;
            d.branches = res.branches;
            d.branchesids = res.branch_ids;
            d.branch_name = res.branch_name;
            var dd = new Date(res.start_date);
            var strDate = dd.getDate()+ "-" + (dd.getMonth() + 1) + "-" +dd.getFullYear();
            d.start_date = strDate;


            var d1 = new Date(res.end_date);
            var end_date = d1.getDate()+ "-" + (d1.getMonth() + 1) + "-" +d1.getFullYear();
            d.end_date = end_date;
            d.sub_role = sdx.d.sub_role;
            d.super_role = sdx.d.super_role;
            d.user_type = sdx.d.user_type;
            d.branchesidscopy = res.branch_ids_copy;
                if(d.branchesids.length ===  d.branchesidscopy.length && d.branchesids.length > 1){
                    d.branch_name = 'All';
                }
          fn.render();
        })
      }

    
    
      fn.applydate = (e, p, ps) => {
        if (e) e.preventDefault();
        // let fd = new FormData($("#datefrm")[0]);
        util.ajx("reports/staffReport", fn.sdata(p, ps)).then(res => {
            d.list = res.result;
            d.curInfo = res.curInfo;
            d.download_url = res.download_url;
            d.downloadPdf = res.downloadPdf;
            d.print = res.print;
            d.branches = res.branches;
            d.branchesids = res.branch_ids;
            d.branch_name = res.branch_name;
            var dd = new Date(res.start_date);
            var strDate = dd.getDate()+ "-" + (dd.getMonth() + 1) + "-" +dd.getFullYear();
            d.start_date = strDate;


            var d1 = new Date(res.end_date);
            var end_date = d1.getDate()+ "-" + (d1.getMonth() + 1) + "-" +d1.getFullYear();
            d.end_date = end_date;
            d.sub_role = sdx.d.sub_role;
            d.super_role = sdx.d.super_role;
            d.user_type = sdx.d.user_type;
            $('#dateModal').modal('hide');
            d.branchesidscopy = res.branch_ids_copy;
                if(d.branchesids.length ===  d.branchesidscopy.length && d.branchesids.length > 1){
                    d.branch_name = 'All';
                }
          fn.render();
        })
      }

      $(".datepicker").datepicker({
        dateFormat: 'dd-mm-yy'
   });

    $("#Today").click(function() {
        var d = new Date();
        var strDate = d.getDate()+ "-" + (d.getMonth() + 1) + "-" +d.getFullYear();

        $('#from_date').val(strDate);
        $('#to_date').val(strDate);
        $('#filter_date_type').val('');

        // $('#datefilter').html(strDate + ' - ' + strDate);
        $('#filter_date_type').val('Today');
        $('li').removeClass('active');
        $('#Today').addClass('active');
        $('.datepicker').attr("disabled",true);

    });

    $("#Yesterday").click(function() {

        var d = new Date(Date.now() - 864e5);
        var strDate = d.getDate()+ "-" + (d.getMonth() + 1) + "-" +d.getFullYear();

        // $('#datefilter').html(strDate + ' - ' + strDate);

        $('#from_date').val(strDate);
        $('#to_date').val(strDate);
        $('#filter_date_type').val('');
        $('#filter_date_type').val('Yesterday');
        $('li').removeClass('active');
        $('#Yesterday').addClass('active');
        $('.datepicker').attr("disabled",true);

    });
    $("#week").click(function() {
        var now = new Date();
        var startDay = 1; //0=sunday, 1=monday etc.
        var d = now.getDay(); //get the current day
        var strDate = new Date(now.valueOf() - (d<=0 ? 7-startDay:d-startDay)*86400000); //rewind to start day
        var endDate = new Date(strDate.valueOf() + 6*86400000); //add 6 days to get last day

        strDate =  strDate.getDate()  + "-" + (strDate.getMonth() + 1) + "-" + strDate.getFullYear();
        endDate = endDate.getDate() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getFullYear() ;

        // $('#datefilter').html(strDate + ' - ' + endDate);

        $('#from_date').val(strDate);
        $('#to_date').val(endDate);

        $('#filter_date_type').val('');
        $('#filter_date_type').val('week');
        $('li').removeClass('active');
        $('#week').addClass('active');
        $('.datepicker').attr("disabled",true);


    });

    $("#month").click(function() {

        var date = new Date();
        var strDate = new Date(date.getFullYear(), date.getMonth(), 1);
        
        var endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        strDate =  strDate.getDate()  + "-" + (strDate.getMonth() + 1) + "-" + strDate.getFullYear();
        endDate = endDate.getDate() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getFullYear() ;

        // $('#datefilter').html(strDate + ' - ' + endDate);

        $('#from_date').val(strDate);
        $('#to_date').val(endDate);
        $('#filter_date_type').val('');
        $('#filter_date_type').val('month');
        $('li').removeClass('active');
        $('#month').addClass('active');
        $('.datepicker').attr("disabled",true);
    });

    $("#last_month").click(function() {

        // var date = new Date();
        // var strDate = new Date(date.getFullYear(), date.getMonth(), 1);
        // var endDate = new Date(date.getFullYear(), date.getMonth() , 0);
        var now = new Date();
        var endDate = new Date(now.getFullYear(), now.getMonth(), 0);
        var strDate = new Date(now.getFullYear() - (now.getMonth() > 0 ? 0 : 1), (now.getMonth() - 1 + 12) % 12, 1);
        strDate =  strDate.getDate()  + "-" + (strDate.getMonth() + 1) + "-" + strDate.getFullYear();
        endDate = endDate.getDate() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getFullYear() ;

        


        // $('#datefilter').html(strDate + ' - ' + endDate);

        $('#from_date').val(strDate);
        $('#to_date').val(endDate);
        $('#filter_date_type').val('');
        $('#filter_date_type').val('last_month');
        $('li').removeClass('active');
        $('#last_month').addClass('active');
        $('.datepicker').attr("disabled",true);
    });

    $("#custom").click(function() {

        var d = new Date();
        var strDate = d.getDate()+ "-" + (d.getMonth() + 1) + "-" +d.getFullYear();

        $('#from_date').val(strDate);
        $('#to_date').val(strDate);
        $('#from_date').addClass('datepicker');
        $('#to_date').addClass('datepicker');
        $('#filter_date_type').val('');
        $('#filter_date_type').val('custom');
        $('li').removeClass('active');
        $('#custom').addClass('active');
        $('.datepicker').attr("disabled",false);

    });



    useEffect(() => {
        fn.lists();
        $('#masterActive').removeClass('active');
        // eslint-disable-next-line
    }, []);

    return (
        <>
    <View d={d} fn={fn} />
    <Detail d={d} fn={fn} />
    <Invoice d={d} fn={fn} />
    </>
    )

}

function View(props) {
    let d = props.d, fn = props.fn;
    return (
         <>
            <div className="">
            <div className="dashboard-top-filter">
             <Link to="/dashboard"><i className="fas fa-arrow-alt-circle-left mr-3"></i></Link>
                <i className="fas fa-filter mr-3"></i>
                <If cond={d.branches.length > 1}>
                <button type="button" id="branch_name" className="btn btn-secondary mr-2" onClick={() => fn.openForm()}>
                { d.branch_name}</button>
                </If>

                <If cond={d.branches.length == 1}>
                <button type="button" id="branch_name" className="btn btn-secondary mr-2" >
                { d.branch_name}</button>
                </If>
                <button type="button" className="btn btn-secondary" id="datefilter" onClick={() => fn.openDateForm()}>
                {/* {d.todayDate} - {d.todayDate}</button> */}
                {d.start_date} - {d.end_date}</button>
            </div>


            <div className="modal fade" id="branchModal" role="dialog" aria-labelledby="branchModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h6 className="modal-title" id="branchModalLabel">{lang('Branch')} </h6>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div className="modal-body">
                    <form id="bfrm" autoComplete="off" spellCheck="false">
                        <div className="form-control mb5" >
                        <label className="m-0 noselect">
                        <input type="checkbox" className="selectall"  value='' id='branchall' checked={d.branch_name == 'All' ? true : false } onClick={fn.setAllchecked}  />
                        &nbsp;&nbsp;{lang('All')}
                    </label>
                        </div>

                        {d.branches.map((r, i) => (
                        <div className="form-control mb5" key={i}>
                            <label className="m-0 noselect">
                            <input type="checkbox" className="selectall" name="branches[]" checked={d.branchesids?.includes(r.id) ? true : false}  id={'branch_' + r.id} value={r.id} onChange={fn.setBranchCheck} />
                            &nbsp;&nbsp;{r.title}
                            </label>
                        </div>
                        ))}

                        <input type="hidden" name="start_date" value={window.sessionStorage['start_date']} />
                        <input type="hidden" name="end_date" value={window.sessionStorage['end_date']} />
                    </form>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-danger btn-red" onClick={fn.save} >{lang('Apply')}</button>
                    </div>
                </div>
                </div>
            </div>
            <div className="modal fade" id="dateModal" role="dialog" aria-labelledby="dateModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="dateModalLabel">{lang('Choose Date')}  </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div className="modal-body">

                        <ul className="nav nav-pills mb-3 order-tab mT12 horizontal-scroll">
                            <li className="nav-item" id='Today' ><a className="nav-link text-dark-grey active" data-toggle="tab">{lang('Today')}</a></li>
                            <li className="nav-item" id='Yesterday' ><a className="nav-link text-dark-grey" data-toggle="tab"  >{lang('Yesterday')}</a></li>
                            <li className="nav-item" id="week" ><a className="nav-link text-dark-grey" data-toggle="tab">{lang('This Week')}</a></li>
                            <li className="nav-item" id="month"><a className="nav-link text-dark-grey"data-toggle="tab"  >{lang('This Month')}</a></li>
                            <li className="nav-item" id="last_month"><a className="nav-link text-dark-grey" data-toggle="tab" >{lang('Last Month')}</a></li>
                            <li className="nav-item" id='custom' ><a className="nav-link text-dark-grey" data-toggle="tab" >{lang('Custom Date')}</a></li>
                            {/* <li id='all' ><a data-toggle="tab" >All</a></li> */}
                        </ul>
                        {/* <form id="datefrm" autoComplete="off" spellCheck="false">
                            <div className="row"> */}
                                {/* <div className="form-group col-md-6">
                                    <div><label className="req">From</label></div>
                                    <input className="datepicker form-control" disabled="true" id="from_date" name="start_date" value={d.start_date} data-date-format="yyyy-mm-dd" />
                                </div>

                                <div className="form-group col-md-6">
                                    <div><label className="req">To</label></div>
                                    <input className="datepicker form-control" id="to_date" disabled="true" name="end_date" value={d.end_date} data-date-format="yyyy-mm-dd" />
                                </div> */}

{/* <div className="row">
                                <div className="form-group col-md-6">
                                    <div><label className="req">From</label></div>
                                    <input className="datepicker form-control" readOnly="readonly" id="from_date" name="start_date" value={d.start_date} data-date-format="yyyy-mm-dd" />
                                </div>

                                <div className="form-group col-md-6">
                                    <div><label className="req">To</label></div>
                                    <input className="datepicker form-control" id="to_date" readOnly="readonly" name="end_date" value={d.end_date} data-date-format="yyyy-mm-dd" />
                                </div>
                            </div>

                            </div>

                        </form> */}

                        <form id="datefrm" autoComplete="off" spellCheck="false">
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <div><label className="req">{lang('From')}</label></div>
                                    <input className="datepicker form-control" disabled="true" id="from_date" name="start_date" value={d.start_date} data-date-format="dd-mm-yy" /> 
                                </div>

                                <div className="form-group col-md-6">
                                    <div><label className="req">{lang('To')}</label></div>
                                    <input className="datepicker form-control" id="to_date" disabled="true" name="end_date" value={d.end_date} data-date-format="dd-mm-yy" />
                                </div>
                            </div>

                        </form>

                    </div>
                    <div className="modal-footer">
                    {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button> */}
                    <button type="button" className="btn btn-danger w120 text-uppercase fw600 btn-height36 fs14 btn-red" onClick={fn.applydate} >{lang('Apply')}</button>
                    </div>
                </div>
                </div>
            </div>

                
                <div className="col-xl-12 col-lg-12">
                    <div className="mt-5">
                        <If cond={!client_id}>
                            <div className="d-flex flex-row align-items-center justify-content-between pl-4 pr-4 pt-4">
                                <h6 className="m-0 h5 text-dark">{lang('Staff Report')}</h6>
                            </div>
                        </If>
                        <div className={client_id ? "" : "main-content"}>
                            <div className={client_id ? "" : "container-fluid"}>
                                <If cond={d.list.result.length && !client_id}>
                                    {/* <div className="d-flex">
                                            <div className="text-secondary bold600 mb5">
                                                Showing {d.list.page.start + 1} - {d.list.page.start + d.list.page.total} of {d.list.page.total_records} records.
                                </div>
                                        </div> */}
                                </If>

                                <div className="d-flex">
                                    <div className="form-inline">
                                        <form id="sbfrm" onSubmit={fn.lists} autoComplete="on" spellCheck="false">
                                            <div className="input-group search-control">
                                                <select className="form-control w160 single-line-dotted mr-3" name="orders_type"
                                                onChange={e => { d.sdata.orders_type = e.target.value; fn.lists(); }}>
                                                    <option value="">{lang('Order Type (All)')}</option>
                                                    <option value="Dining">{lang('Dine-In')} </option>
                                                    <option value="Deliver">{lang('Deliver')}</option>
                                                    <option value="Pickup">{lang('Pickup')}</option>

                                                </select>

                                                {/* <select className="form-control ml5" name="orders_status"
                                                onChange={e => { d.sdata.orders_status = e.target.value; fn.lists(); }}>
                                                    <option value="">All</option>
                                                    <option value="New">New </option>
                                                    <option value="In Progress">In Progress </option>
                                                    <option value="Delivered">Delivered</option>
                                                    <option value="Cancelled">Cancelled</option>

                                                </select> */}
                                                <select className="form-control w130 single-line-dotted mr-3" name="r_id"
                                         onChange={e => { d.sdata.r_id = e.target.value; fn.lists(); }}>
                                            <option value="">{lang('Role (All)')}</option>
                                            {d.roles.map(r => <option key={r.id} value={r.id}>{r.roll_name}</option>)}
                                        </select>

                                        <select className="form-control w130 single-line-dotted mr-3" name="staff_n"
                                         onChange={e => { d.sdata.staff_n = e.target.value; fn.lists(); }}>
                                            <option value="">{lang('Staff (All)')}</option>
                                            {d.m_users.map(y => <option key={y.id} value={y.id}>{y.name}</option>)}
                                        </select>

                                            </div>
                                            

                                        </form>

                                    </div>
                                    {/* <div className="form-inline">
                                        <div className="input-group search-control">
                                        <a className="btn btn-md btn-outline-success noround" href={d.downloadPdf} target="_self">
                                                     
                                                <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                </a>
                                             
                                        </div>
                                    </div> */}
                                    <If cond={d.sub_role && d.sub_role[31] == 1 || d.user_type !='STAFF'}>
                                        {/* <div className="form-inline">
                                            <div className="input-group search-control">
                                                <a className="btn btn-md btn-outline-success noround" href={d.download_url} target="_self">
                                                        <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </div> */}

                                        {/* <div className="form-inline">
                                            <div className="input-group search-control">
                                                <button className="btn btn-md btn-outline-primary noround" onClick={fn.printStaffReport}><i className="fa fa-print"></i> </button>
                                            </div>
                                        </div> */}
                                        <div className="form-inline ml-2">
                                                    <div className="input-group search-control">
                                                        <button className="btn btn-md btn-outline-primary noround" onClick={fn.printStaffReport}><i className="fa fa-print"></i> </button>
                                                    </div>
                                                </div>


                                    </If>
                                    

                                </div>

                                <div className="mt-3">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered m-0">
                                        <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                            <tr>
                                                <th className="w120">{lang('Date')}</th>
                                                <th className="w120">{lang('Paid Order')}</th>
                                                <th className="w120">{lang('Order Taken')}</th>
                                                {/* <th className="w120">Order Completed</th> */}
                                                <th className="w120">{lang('Cash')}</th>
                                                <th className="w120">{lang('Bank')}</th>
                                                <th className="w120">{lang('Total Amount')}</th>
                                            </tr>
                                        </thead>
                                        </table>
                                    </div>
                                    <If cond={d.list.result.length}>
                                    {d.list.result.map((va,ia) => (
                                        <If cond={va.alldata.length}>
                                            <div id={va.name.replace(/ /g, ""),ia}>
                                                <div className="agna-menu-title position-sticky text-uppercase fs14 fw600 text-dark w-100 bg-grey p-2">
                                                    {va["name"]}
                                                </div>
                                            </div>
                                        <div className="table-responsive">
                                            <table className="table border m-0 table-pd-less">
                                                <tbody className="table-text-vmid">
                                                    {va.alldata.map((v,i) => (
                                                    
                                                        <tr key={uuid()}>
                
                                                            {/* <td>{v.name} */}
                                                                {/* {v.name}<input type="hidden" name="id" defaultValue={[v.i]} /> */}
                                                            {/* {v.ro.map((p, q) =>(
                                                                 <div key={q} className="clearfix">
                                                                 {p.name}
                                                             </div>
                                                             )

                                                            )} */}
                                                            {/* </td>
                                                             */}
                                                            <td className="w120">
                                                                {/* {(
                                                          <div id={v.name.replace(/ /g, "")}>
                                                            <div className="agna-menu-title position-sticky">
                                                              {v["name" + localStorage.getItem("agnf_arb")]}
                                                            </div>
                                                            </div>
                                                        )} */}
                                                                {v["created"]}</td>
                                                            {/* <td>
                                                            {v.use.map((m, n) =>(
                                                                 <div key={n} className="clearfix">
                                                                 {m.name}
                                                             </div>
                                                             )

                                                            )}
                                                            </td> */}
                                                            <td className="w120">{v.use.map((o) =>(
                                                                 <div className="clearfix">
                                                                 {o.pay} <If cond={o.pay > 0}> <a className="btn btn-sm btn-link btn-redgrey-outline"  onClick={() => ref.openDetail(v,d.branchesids,d.sdata.orders_type,d.sdata.orders_status)}><i className="bi bi-eye-fill"></i></a>
                                                             </If>
                                                             <If cond={o.pay == 0}> <a className="btn btn-sm btn-link btn-redgrey-outline" ><i className="bi bi-eye-fill"></i></a>
                                                            </If> </div>
                                                             )

                                                            )}
                                                                </td>
                                                            {/* <td>{v.confi}
                                                            <div><button type="button" className="btn btn-sm btn-danger btn-redgrey-outline " onClick={() => ref.openDetai(v,d.branchesids,d.sdata.orders_type,d.sdata.orders_status)}>Order Detail</button>
                                                               </div>
                                                                </td> */}
                                                                <td className="w120">{v.ro.map((p) =>(
                                                                 <div>
                                                                 {p.confi} <If cond={p.confi > 0}> <a className="btn btn-sm btn-link btn-redgrey-outline" onClick={() => ref.openDetai(v,d.branchesids,d.sdata.orders_type,d.sdata.orders_status)}><i className="bi bi-eye-fill"></i></a>
                                                             </If>
                                                             <If cond={p.confi == 0}> <a className="btn btn-sm btn-link btn-redgrey-outline"><i className="bi bi-eye-fill"></i></a>
                                                            </If> </div>
                                                             )

                                                            )}
                                                                </td>
                                                            {/* <td className="w120">{v.cm.map((j) =>(
                                                                 <div>
                                                                 {j.com} <If cond={j.com > 0}> <a className="btn btn-sm btn-link btn-redgrey-outline" onClick={() => ref.openDeta(v,d.branchesids,d.sdata.orders_type,d.sdata.orders_status)}><i className="bi bi-eye-fill"></i></a>
                                                            </If>
                                                            <If cond={j.com == 0}> <a className="btn btn-sm btn-link btn-redgrey-outline"><i className="bi bi-eye-fill"></i></a>
                                                            </If> </div>
                                                             )

                                                            )}
                                                                </td> */}
                                                            <td className="w120">{v.use.map((o) =>(
                                                                 <div>
                                                                     {/* <If cond={o.cash>0}> */}
                                                                        {/* <AmtFormat curInfo={d.curInfo} amt={o.total_cash+o.total_p}/> */}
                                                                        <AmtFormat curInfo={d.curInfo} amt={o.total_cash}/>
                                                                     {/* </If> 
                                                                     <If cond={o.cash==0}>
                                                                        ----
                                                                     </If>
                                                                     <If cond={!o.cash}>
                                                                        ----
                                                                     </If> */}
                                                             </div>
                                                             )

                                                            )}</td>
                                                            <td className="w120">{v.use.map((o) =>(
                                                                 <div>
                                                                     {/* <If cond={o.bank >0}> */}
                                                                         {/* <AmtFormat curInfo={d.curInfo} amt={o.bank-o.bankr}/> */}
                                                                         <AmtFormat curInfo={d.curInfo} amt={o.bank}/>
                                                                     {/* </If> */}
                                                                     
                                                                     {/* <If cond={o.bank==0}>
                                                                        ----
                                                                     </If>
                                                                     <If cond={!o.bank}>
                                                                        ----
                                                                     </If> */}
                                                             </div>
                                                             )

                                                            )}</td>
                                                            <td className="w120">{v.use.map((o) =>(
                                                                 <div>
                                                                    <If cond={o.amount>0}>
                                                                        <AmtFormat curInfo={d.curInfo} amt={o.total_am-o.ramt} />
                                                                    </If>
                                                                

                                                                    <If cond={o.amount==0}>
                                                                        ----
                                                                    </If>
                                                                    <If cond={!o.amount}>
                                                                        ----
                                                                    </If>
                                                             </div>
                                                             )

                                                            )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                
                                                    
                                            
                                                </tbody>
                                            </table> 
                                            
                                        </div>
                                        {/* <If cond={!client_id}>
                                            <div className="d-flex justify-content-between mt-4 pb-3">
                                                <div className="mr5">
                                                    <Paging fn={fn.lists} pageob={d.list.page} />
                                                </div>
                                                <div>
                                                    <Perpagedd fn={fn.lists} />
                                                </div>
                                            </div>
                                        </If> */}
                                        </If>
                                        ))}
                                        
                                    </If>
                                    <If cond={!d.list.result.length}>
                                        <div className="no-rec">{lang('No record found')}</div>
                                    </If>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="iframe_print" ></div>
        </>
    )
}

function Detail() {
    const fn = {};
    const [d, setData] = useState({
        listss: [{}],
        bdtl: false,
    });
    fn.render = () => { setData({ ...d }); }

    //const [checked, setChecked] = React.useState(true);
    
    ref.openDetail = (bdtl,branch_ids, p, q) => {
        d.bdtl = bdtl;
        //console.log(branch_ids);
        util.ajx("cmaster/staff_detail", { branch_id: bdtl.id, created: bdtl.created, id:branch_ids, type:p, status:q}).then(res => {
            console.log(res);
            if (res.code === 200) {
                d.listss = res.result;
                if (!d.listss.length) {
                    d.listss = [{}];
                }
               fn.render();
                window.showModal($("#sareasModal"), true);
            }
        })
    }

    ref.openDetai = (bdtl,branch_ids, p,q) => {
        d.bdtl = bdtl;
        console.log(bdtl);
        util.ajx("cmaster/staff_detai", { branch_id: bdtl.id, created: bdtl.created, id:branch_ids, type:p, status:q}).then(res => {
            if (res.code === 200) {
                d.listss = res.result;
                if (!d.listss.length) {
                    d.listss = [{}];
                }
               setData({ ...d });
                window.showModal($("#sareasModal"), true);
            }
        })
    }

    ref.openDeta = (bdtl,branch_ids, p,q) => {
        d.bdtl = bdtl;
        //console.log(bdtl);
        util.ajx("cmaster/staff_deta", { branch_id: bdtl.id, created: bdtl.created ,id:branch_ids, type:p, status:q}).then(res => {
            if (res.code === 200) {
                d.listss = res.result;
                if (!d.listss.length) {
                    d.listss = [{}];
                }
               setData({ ...d });
                window.showModal($("#sareasModal"), true);
            }
        })
    }


    // ref.invi = (bdtl) => {
    //    // d.bdtl = bdtl;
    //     //console.log(bdtl, branch_ids);
    //     util.ajx("cmaster/invoice", {id:bdtl.id}).then(res => {
    //         console.log(res);
    //         if(res.code===200){
    //             d.lists = res.result;
    //            setData({ ...d });
    //             window.showModal($("#sareasModa"), true);
    //     }
    //     })
    // }
   


    fn.handleChange = (e) => {
        d.data[e.target.name] = e.target.value;

        setData({ ...d });

    }

    return (
        <>
        <div className="modal modal-scroll no-backdrop" id="sareasModal" data-backdrop="static">
            <If cond={d.bdtl}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                            <div className="modal-title uc">{lang('Order Details')}</div>
                            <button type="button" className="btn btn-danger text-uppercase fw600 btn-height36 fs14 btn-red-outline" data-dismiss="modal"><span aria-hidden="true"></span>{lang('Close')}</button>
                            {/* <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={closeModal}><span aria-hidden="true">&times;</span></button> */}
                        </div>

                        <div className="modal-body">
                            <form id="sareaFrm" autoComplete="off" spellCheck="false">
                                <div className="table-responsive mb3">
                                    <table className="table table-sm table-bordered m-0">
                                        <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                            <tr>
                                                <th className="w20">{lang('Branch')}</th>
                                                <th>{lang('Order ID')}</th>
                                                <th className="w120 nowrap">{lang('Date and Time')}</th>
                                                <th className="w120 nowrap">{lang('Order Type')}</th>
                                                <th className="w120 nowrap">{lang('Total Amount')}</th>
                                                <th className="w120 nowrap">{lang('Payment Type')}</th>
                                                <th className="w120 nowrap">{lang('Order Status')}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-text-vmid">
                                            {d.listss.map((v, i) => (
                                                <tr key={i}>
                                                    <td >{v.title}</td>
                                                    <td><p onClick={() => ref.invi(v)}>{v.odr_no}</p></td>
                                                    <td>{v.created}</td>
                                                    <td>{v.order_type}</td>
                                                    <td>{v.total_am}</td>
                                                    <td>{v.pay_mode}</td>
                                                    <td>{v.status}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <If cond={!d.listss.length}>
                                        <div className="no-rec">{lang('No record found')}</div>
                                    </If>
                            </form>
                        </div>
                    </div>
                </div>
            </If>
        </div>


        <div className="modal modal-scroll no-backdrop" id="sareasMod" data-backdrop="static">
            <If cond={d.bdtl}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                            <div className="modal-title uc">{lang('Order Details')}</div>
                            {/* <button type="button" className="btn btn-danger text-uppercase fw600 btn-height36 fs14 btn-red-outline" data-dismiss="modal">Cancel</button>
                            <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={closeModa}><span aria-hidden="true">&times;</span></button> */}
                        </div>

                        <div className="modal-body" id="orderDetailsView">
                        <div></div>
                            
                        </div>

                        <div className="modal-footer p-0" >
        <div className="bg-white border-top p-3 text-right">
            <button type="button" className="btn btn-danger text-uppercase fw600 btn-height36 fs14 btn-red-outline" data-dismiss="modal">{lang('Cancel')}</button>
          </div>
        </div>
                    </div>
                </div>
            </If>
        </div>
        </>
    )
}



function Invoice() {
    const [d, setData] = useState({
        lists: [{}],
       // bdtl: false,
    });

    const fn = {};
    ref.invi = (bdtl) => {
        util.ajx("cmaster/invoice", {id:bdtl.id}).then(res => {
                d.lists = res.result;
                d.lists=[{}];
               setData({ ...d });
                window.showModal($("#sareasModa"), true);
                console.log(res)
            
         $('#invim').html('');
          $('#invim').append(res.result);
        
        
        })
    }
   

    return (
        <>
        <div className="modal modal-scroll no-backdrop" id="sareasModa" data-backdrop="static">
            
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                    <div className="modal-header">
                            <div className="modal-title uc">{lang('Order Details')}</div>
                            <button type="button" className="btn btn-danger text-uppercase fw600 btn-height36 fs14 btn-red-outline" data-dismiss="modal"><span aria-hidden="true"></span>{lang('Close')}</button>
                            </div>

                        <div className="modal-body" id="invim">
                        
                        </div>
                    </div>
                </div>
            
        </div>
        </>
    )
}
