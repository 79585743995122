import React, { useState, useEffect } from 'react';
import util from "../../util/util";
import { If, AmtFormat } from "../../util/controls";
import { sdx } from "../../sdx";
import { NavLink } from 'react-router-dom';
import PaymentGateway from "./PaymentGateway";
import lang from '../../lang';

let $ = window.$;
let $activeBranchId = '';
let client_id = 0, ref = {};
export default function Others(props) {
    client_id = props.client_id;
    const fn = {};
    const [d, setData] = useState({
        list: { result: [], page: {} },
        tax_list: { result: [], page: {} },
        location_list: { result: [], page: {} },
        driver_list: { result: [], page: {} },
        discount_list: { result: [], page: {} },
        payment_list: { result: [], page: {} },
        ingredients_list: { result: [], page: {} },
        source_list: { result: [], page: {} },
        payment_gateway_list: {result: [], page: {} },
        hoursList: [],
        sdata: { p: 1, ps: 25 },
        data: { city: '' },
        cities: [],
        locations: [],
        timezones: [],
        items: [],
        branches: [],
        payments: [],
        discount_type: 1,
        type: 1,
        curInfo: {},
        is_arabic: 0,
        languageset: '',
        sub_role :[],
        super_role:[],
        user_type:'',
        tax_active:'',
        location_active:'',
        discount_active:'',
        source_active:'',
        drivers_active:'',
        payment_active:'',
        addon_active:'',
        payment_gateway_type:0,
        subscription_id:[],
        mobile_digit_length : 10,
    });

    fn.render = () => { setData({ ...d }); }

    fn.handleChange = (e) => {
        d.data[e.target.name] = e.target.value;

        fn.render();
    }

    fn.handleChangeP = (e) => {

        d.data[e.target.name] = e.target.value;

        fn.render();
    }

    fn.handleChangepayment = (e) => {

        d.data[e.target.name] = e.target.value;
        d.payment_gateway_type = e.target.value;

        fn.render();
    }

    fn.setDiscountType = (e) => {
        d.data[e.target.name] = e.target.value;
        setData({ ...d, discount_type: e.target.value });
    }

    fn.setIngType = (e) => {
        d.data[e.target.name] = e.target.value;
        setData({ ...d, type: e.target.value });
    }


    fn.changeItemBranches = (e, data, indx) => {
        if (e.target.checked) {
            d.items[indx].bitem = 1;
        } else {
            d.items[indx].bitem = 0;
        }
        util.ajx("cmaster/set_item_branch", { item_id: data.id, value: e.target.checked ? 1 : 0, branch_id: $activeBranchId }).then(res => {
            if (res.code === 200) {
                d.list = res.result;
                fn.render();
            }
        })
        fn.render();
    }

    fn.openTaxForm = (dtl) => {
        d.data = dtl ? { ...dtl } : { client_id: props.client_id , branches: [],tax_applied_for:[]};
        d.is_arabic = sdx.d.is_arabic;
        d.languageset = sdx.d.language;
        fn.render();
        window.draggable('#taxformModal');
        window.showModal($("#taxformModal"), true);
    }

    fn.openPaymentGatewayForm = (dtl) => { 
        d.data = dtl ? { ...dtl } : { client_id: props.client_id, branches: [] };
        d.is_arabic = sdx.d.is_arabic;
        d.languageset = sdx.d.language;
        d.payment_gateway_type = d.data.payment_gateway;
        // d.data.payment_decode.appId = '';
        fn.render();
        //console.log(d.data);
        window.draggable('#paymentgatewayModal');
        window.showModal($("#paymentgatewayModal"), true);
    }

    fn.openLocationForm = (dtl) => {
        d.data = dtl ? { ...dtl } : { client_id: props.client_id, branches: [] };
        d.is_arabic = sdx.d.is_arabic;
        d.languageset = sdx.d.language;
        fn.render();
        window.draggable('#locationformModal');
        window.showModal($("#locationformModal"), true);
    }

    fn.openDriverForm = (dtl) => {
        d.data = dtl ? { ...dtl } : { client_id: props.client_id };
        d.is_arabic = sdx.d.is_arabic;
        d.languageset = sdx.d.language;
        d.mobile_digit_length = sdx.d.mobile_digit_length;
        fn.render();
        window.draggable('#driverformModal');
        window.showModal($("#driverformModal"), true);
    }

    fn.openDiscountForm = (dtl) => {
        d.data = dtl ? { ...dtl } : { client_id: props.client_id ,branches: []};
        d.discount_type = d.data.discount_type || 1;
        fn.render();
        window.draggable('#discountformModal');
        window.showModal($("#discountformModal"), true);
    }

    fn.openPaymentForm = (dtl) => {
        d.data = dtl ? { ...dtl } : { client_id: props.client_id };
        fn.render();
        window.draggable('#paymentformModal');
        window.showModal($("#paymentformModal"), true);
    }

    fn.openIngredientsForm = (dtl) => {
        d.data = dtl ? { ...dtl } : { client_id: props.client_id };
        d.type = d.data.type || 1;
        d.is_arabic = sdx.d.is_arabic;
        d.languageset = sdx.d.language;
        fn.render();
        window.draggable('#IngredientsformModal');
        window.showModal($("#IngredientsformModal"), true);
    }

    fn.openSourceForm = (dtl) => {
        d.data = dtl ? { ...dtl } : { client_id: props.client_id, branches: [] };
        d.type = d.data.type || 1;
        fn.render();
        window.draggable('#sourceformModal');
        window.showModal($("#sourceformModal"), true);
    }


    fn.sdata = (p, ps) => {
        d.sdata.p = p ? p : 1;
        d.sdata.ps = ps ? ps : d.sdata.ps;
        return $("#sbfrm").serialize() + '&p=' + d.sdata.p + '&ps=' + d.sdata.ps + '&client_id=' + props.client_id;
    }

    fn.lists = (e, p, ps) => {
        if (e) e.preventDefault();
        util.ajx("cmaster/others", fn.sdata(p, ps)).then(res => {
            if (res.code === 200) {
                d.tax_list = res.tax_result;
                d.location_list = res.location_result;
                d.driver_list = res.driver_result;
                d.discount_list = res.discount_result;
                d.payment_list = res.payment_result;
                d.ingredients_list = res.ingredients_result;
                d.source_list = res.source_result;
                d.payment_gateway_list = res.payment_gateway_result;
                d.payments = res.payments;
                d.branches = res.branches;
                d.curInfo = res.curInfo;
                fn.render();
                $('#masterActive').addClass('active');
                
            }
        })
    }

    fn.savetax = () => {
        let fd = new FormData($("#bfrm")[0]);
        util.ajx("cmaster/save_tax_group", fd).then(res => {
            if (res.code === 200) {
                $("#taxformModal").modal('hide');
                fn.lists(0, d.data.id ? d.sdata.p : 1);
            }
        })
    }

    fn.savepaymentgateway = () => {
        let fd = new FormData($("#gfrm")[0]);
        util.ajx("cmaster/save_payment_gateway", fd).then(res => {
            if (res.code === 200) {
                $("#paymentgatewayModal").modal('hide');
                fn.lists(0, d.data.id ? d.sdata.p : 1);
            }
        })
    }

    fn.savelocation = () => {
        let fd = new FormData($("#lfrm")[0]);
        util.ajx("cmaster/save_location", fd).then(res => {
            if (res.code === 200) {
                $("#locationformModal").modal('hide');
                fn.lists(0, d.data.id ? d.sdata.p : 1);
            }
        })
    }

    fn.savedriver = () => {
        let fd = new FormData($("#driverfrm")[0]);
        util.ajx("cmaster/save_driver", fd).then(res => {
            if (res.code === 200) {
                $("#driverformModal").modal('hide');
                fn.lists(0, d.data.id ? d.sdata.p : 1);
            }
        })
    }

    fn.savediscount = () => {
        let fd = new FormData($("#discountfrm")[0]);
        util.ajx("cmaster/save_discount", fd).then(res => {
            // $("#discountformModal").modal('hide');
            if (res.code === 200) {
                $("#discountformModal").modal('hide');
                fn.lists(0, d.data.id ? d.sdata.p : 1);
            }
        })
    }


    fn.savepayment = () => {
        let fd = new FormData($("#paymentfrm")[0]);
        util.ajx("cmaster/save_payment", fd).then(res => {
            if (res.code === 200) {
                $("#paymentformModal").modal('hide');
                fn.lists(0, d.data.id ? d.sdata.p : 1);
            }
        })
    }

    fn.saveIngredients = () => {
        let fd = new FormData($("#ingredientsfrm")[0]);
        util.ajx("cmaster/save_Ingredients", fd).then(res => {
            if (res.code === 200) {
                $("#IngredientsformModal").modal('hide');
                fn.lists(0, d.data.id ? d.sdata.p : 1);
            }
        })
    }

    fn.savesource = () => {
        let fd = new FormData($("#sourcefrm")[0]);
        util.ajx("cmaster/save_source", fd).then(res => {
            if (res.code === 200) {
                $("#sourceformModal").modal('hide');
                fn.lists(0, d.data.id ? d.sdata.p : 1);
            }
        })
    }

    fn.deleteIngredients = (id) => {
        let callback = () => {
            util.ajx("cmaster/delete_Ingredients", { id: id }).then(res => {
                if (res.code === 200) {
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }


    fn.deletetax = (id) => {
        let callback = () => {
            util.ajx("cmaster/delete_tax", { id: id }).then(res => {
                if (res.code === 200) {
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.deletepaymentgateway = (id) => {
        let callback = () => {
            util.ajx("cmaster/delete_payment_gateway", { id: id }).then(res => {
                if (res.code === 200) {
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.deletelocation = (id) => {
        let callback = () => {
            util.ajx("cmaster/delete_location", { id: id }).then(res => {
                if (res.code === 200) {
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.deletedriver = (id) => {
        let callback = () => {
            util.ajx("cmaster/delete_driver", { id: id }).then(res => {
                if (res.code === 200) {
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.deletediscount = (id) => {
        let callback = () => {
            util.ajx("cmaster/delete_discount", { id: id }).then(res => {
                if (res.code === 200) {
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.deletepayment = (id) => {
        let callback = () => {
            util.ajx("cmaster/delete_payment", { id: id }).then(res => {
                if (res.code === 200) {
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.deleteSource = (id) => {
        let callback = () => {
            util.ajx("cmaster/delete_source", { id: id }).then(res => {
                if (res.code === 200) {
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }



    fn.handleImageChange = (e) => {
        d.data.image_url = URL.createObjectURL(e.target.files[0]);
        fn.render();
    }

    fn.setBranchCheck = (e) => {
        let bid = e.target.value;

        if (e.target.checked) {
            d.data.branches.push(bid);
        } else {
            d.data.branches.splice(d.data.branches.indexOf(bid), 1);
        }
        fn.render();
    }

     
    fn.setTaxAppliedCheck = (e) => {
        let bid = e.target.value;

        if (e.target.checked) {
            d.data.tax_applied_for.push(bid);
        } else {
            d.data.tax_applied_for.splice(d.data.tax_applied_for.indexOf(bid), 1);
        }
        console.log(bid);
        fn.render();
    }

    fn.applied_for_dining = (e) => {
        let bid = e.target.value;
        if (e.target.checked) {
            d.data.applied_for_dining = 1;

        } else {
            d.data.applied_for_dining = 0;
        }
        fn.render();
    }

    fn.applied_for_delivery_pickup = (e) => {
        let bid = e.target.value;
        if (e.target.checked) {
            d.data.applied_for_delivery_pickup = 1;

        } else {
            d.data.applied_for_delivery_pickup = 0;
        }
        fn.render();
    }



    fn.init = () => {
        util.ajx("cmaster/others?init=1", fn.sdata(1, d.sdata.ps)).then(res => {
            if (res.code === 200) {
                d.tax_list = res.tax_result;
                d.location_list = res.location_result;
                d.driver_list = res.driver_result;
                d.discount_list = res.discount_result;
                d.payment_list = res.payment_result;
                d.ingredients_list = res.ingredients_result;
                d.source_list = res.source_result;
                d.payment_gateway_list = res.payment_gateway_result;
                d.branches = res.branches;
                d.payments = res.payments;
                d.curInfo = res.curInfo;
                // d.sub_role = sdx.d.sub_role;
                // d.super_role = sdx.d.super_role;
                // d.user_type = sdx.d.user_type;
                // d.subscription_id = sdx.d.subscription_id;

                d.sub_role = res.logging_data.permission.sub_permission;
                d.super_role = res.logging_data.permission.super_permission;
                d.user_type = res.logging_data.user_type;
                d.subscription_id = res.logging_data.subscription_id;
                            

                if(sdx.d.sub_role && sdx.d.sub_role[9] == 1){
                    d.tax_active = 'show active';

                }else if(sdx.d.sub_role && sdx.d.sub_role[14] == 1){
                    d.location_active = 'show active'

                }
                else if(sdx.d.sub_role && sdx.d.sub_role[5] == 1){
                    d.drivers_active='show active'
                }
                else if(sdx.d.sub_role && sdx.d.sub_role[10] == 1){
                    d.discount_active = 'show active'
                }
                else if(sdx.d.sub_role && sdx.d.sub_role[12] == 1){
                    d.payment_active = 'show active'
                }
                else if(sdx.d.sub_role && sdx.d.sub_role[11] == 1){
                    d.addon_active ='show active'
                }
                else if(sdx.d.sub_role && sdx.d.sub_role[13] == 1){
                    d.source_active ='show active'
                }else{
                    d.tax_active = 'show active';
                }


                if(sdx.d.sub_role && ( sdx.d.sub_role[5] != 1 && sdx.d.sub_role[9] != 1 && sdx.d.sub_role[10] != 1 && sdx.d.sub_role[11] != 1 && sdx.d.sub_role[12] != 1 && sdx.d.sub_role[13] != 1 && sdx.d.sub_role[14] != 1 && sdx.d.sub_role[39] != 1)  && sdx.d.user_type =='STAFF'){
                    util.logout('', props.history);
                }

                

                fn.render();
                
                if(d.subscription_id){
                    $('.left-sidebar-link').removeClass('d-none');
                    $('.main-table-body').removeClass('d-none');
                }
                // if(d.subscription_id && ( d.subscription_id[7] != 1 &&  d.subscription_id[8] != 1 )  && d.user_type =='CLIENT'){
                //     util.logout('', props.history);
                // }
                
                $('#masterActive').addClass('active');
            }
        })
    }
    useEffect(() => {
        if (typeof props.timeFlg !== "undefined") { return; }
        fn.init();
        clearTimeout(sdx.orderlist);
        clearTimeout(sdx.kitchenlist);
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (typeof props.timeFlg === "undefined") { return; }
        fn.init();
        clearTimeout(sdx.orderlist);
        clearTimeout(sdx.kitchenlist);
        // eslint-disable-next-line
    }, [props.timeFlg]);

    const [tab, setTab]=useState('');


    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-md-3 col-lg-2 top-master">
                    <div className="mb-3 text-uppercase text-black fw700 fs12">{lang('Masters')}</div>
                    <div className="left-sidebar-link d-none">
                        {/* <If cond={d.user_type != 'STAFF'}>
                            <NavLink to="/branches" className="">Branches</NavLink>
                            <NavLink to="/roles" className="">Roles</NavLink>
                            <NavLink to="/staffs" className="">Staffs</NavLink>
                            <NavLink to="/cats" className="">Categories</NavLink>
                            <NavLink to="/items" className="">Items</NavLink>
                            <NavLink to="/others" className="">Others</NavLink>
                        </If> */}

                        {/* <If cond={d.user_type == 'STAFF'}> */}
                            <If cond={d.sub_role && ( d.sub_role[1] == 1 ||  d.sub_role && d.sub_role[2] == 1)  || d.user_type !='STAFF'  }>
                                <NavLink to="/branches" className="">{lang('Branches')}</NavLink>
                            </If>
                            <If cond={d.user_type != 'STAFF'}> <NavLink to="/roles" className="">{lang('Roles')}</NavLink></If>
                           
                            <If cond={d.sub_role && d.sub_role[4] == 1  || d.user_type !='STAFF'}>
                                <NavLink to="/staffs" className="">{lang('Staffs')}</NavLink>
                            </If>
                            
                            <If cond={ d.sub_role && d.sub_role[15] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) )  }>
                                <NavLink to="/cats" className="">{lang('Categories')}</NavLink>
                            </If>

                            <If cond={ d.sub_role && d.sub_role[16] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) ) }>
                                <NavLink to="/items" className="">{lang('Items')}</NavLink>
                            </If>
                            <If cond={ d.sub_role && d.sub_role[40] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) ) }>
                                <NavLink to="/combo" className="">{lang('Combos')}</NavLink>
                            </If>
                            <If cond={d.super_role && d.super_role[2] == 1 ||  d.sub_role && ( d.sub_role[5] == 1 ||  d.sub_role[6] == 1 ||  d.sub_role[8] == 1)   || d.user_type !='STAFF'}>
                                <NavLink to="/others" className="">{lang('Others')}</NavLink>
                            </If>
                            <If cond={ d.sub_role && d.sub_role[48] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) ) }>
                                <NavLink to="/recipeIngredients" className="">{lang('Recipe Ingredients')}</NavLink>
                            </If>
                        {/* </If> */}
                    </div>
                </div>
                <div className="offset-lg-2 col-lg-10 offset-md-3 col-md-9 col-sm-12 right-master">
                    <div className="main-table-body d-none">
                        <div>
                            <ul className="nav nav-pills mb-3 order-tab mT12 horizontal-scroll" id="pills-tab" role="tablist">
                                <If cond={d.sub_role && d.sub_role[9] == 1 || d.user_type !='STAFF'}>
                                    <li className="nav-item" role="presentation" onClick={()=>setTab('tax-group')}>
                                        <a className={"nav-link text-dark-grey"+d.tax_active} id="tax-group-tab" data-toggle="pill" href="#tax-group" role="tab" aria-controls="tax-group" aria-selected="true">{lang('Tax Group')}</a>
                                    </li>
                                </If>
                                <If cond={d.sub_role && d.sub_role[14] == 1 || (d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[7] == 1) )}>
                                    <li className="nav-item" role="presentation" onClick={()=>setTab('location')}>
                                        <a className={"nav-link text-dark-grey"+d.location_active} id="location-tab" data-toggle="pill" href="#location" role="tab" aria-controls="location" aria-selected="false">{lang('Locations')}</a>
                                    </li>
                                </If>
                                <If cond={d.sub_role && d.sub_role[5] == 1 || (d.user_type !='STAFF'  && d.subscription_id &&  (d.subscription_id[7] == 1) ) }>
                                    <li className="nav-item" role="presentation" onClick={()=>setTab('drivers')}>
                                        <a className={"nav-link text-dark-grey"+d.drivers_active} id="drivers-tab" data-toggle="pill" href="#drivers" role="tab" aria-controls="drivers" aria-selected="false">{lang('Drivers')}</a>
                                    </li>
                                </If>
                                <If cond={d.sub_role && d.sub_role[10] == 1 || (d.user_type !='STAFF'  && d.subscription_id &&  (d.subscription_id[8] == 1) )}>
                                    <li className="nav-item" role="presentation" onClick={()=>setTab('coupon')}>
                                        <a className={"nav-link text-dark-grey"+d.discount_active} id="coupon-tab" data-toggle="pill" href="#coupon" role="tab" aria-controls="coupon" aria-selected="false">{lang('Discount/Coupon')}</a>
                                    </li>
                                </If>
                                <If cond={d.sub_role && d.sub_role[12] == 1 || d.user_type !='STAFF'}>
                                    <li className="nav-item" role="presentation" onClick={()=>setTab('payment')}>
                                        <a className={"nav-link text-dark-grey"+d.payment_active} id="payment-tab" data-toggle="pill" href="#payment" role="tab" aria-controls="payment" aria-selected="false">{lang('Payment')}</a>
                                    </li>
                                </If>
                                <If cond={d.sub_role && (d.sub_role[11] == 1 ||  d.sub_role[8] == 1 ) || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) ) }>
                                    <li className="nav-item" role="presentation" onClick={()=>setTab('ingredients')}>
                                        <a className={"nav-link text-dark-grey"+d.addon_active} id="ingredients-tab" data-toggle="pill" href="#ingredients" role="tab" aria-controls="ingredients" aria-selected="false">{lang('Ingredients/Add On')}</a>
                                    </li>
                                </If>
                                <If cond={d.sub_role && d.sub_role[13] == 1 || (d.user_type !='STAFF'  && d.subscription_id &&  (d.subscription_id[7] == 1) )}>
                                    <li className="nav-item" role="presentation" onClick={()=>setTab('source')}>
                                        <a className={"nav-link text-dark-grey"+d.source_active} id="source-tab" data-toggle="pill" href="#source" role="tab" aria-controls="source" aria-selected="false">{lang('Source')}Source</a>
                                    </li>
                                </If>
                                <If cond={d.sub_role && d.sub_role[39] == 1 || d.user_type !='STAFF'}>
                                    <li className="nav-item" role="presentation" onClick={()=>setTab('payment-gateway')}>
                                        <a className={"nav-link text-dark-grey" + d.payment_gateway_active} id="payment-gateway-tab" data-toggle="pill" href="#payment-gateway" role="tab" aria-controls="payment-gateway" aria-selected="false">{lang('Payment Gateway')}</a>
                                    </li>
                                </If>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <TaxGroup d={d} fn={fn} />
                                <Locations d={d} fn={fn} />
                                <Drivers d={d} fn={fn} />
                                <Discounts d={d} fn={fn} />
                                <Payment d={d} fn={fn} />
                                <Ingredients d={d} fn={fn} />
                                <DeliverySource d={d} fn={fn} />
                                <TaxGroupForm d={d} fn={fn} />
                                <LocationForm d={d} fn={fn} />
                                <DriverForm d={d} fn={fn} />
                                <DiscountForm d={d} fn={fn} />
                                <PaymentForm d={d} fn={fn} />
                                <IngredientsForm d={d} fn={fn} />
                                <DeliverySourceForm d={d} fn={fn} />

                                {/* <PaymentGateway d={d} fn={fn} /> */}
                                {/* <PaymentGatewayForm d={d} fn={fn} /> */}

                                <div className={"tab-pane fade " + d.payment_gateway_active} id="payment-gateway" role="tabpanel" aria-labelledby="payment-gateway-tab">
                                    {tab==='payment-gateway' &&
                                        <PaymentGateway branches={d.branches} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function TaxGroup(props) {
    let d = props.d, fn = props.fn;
    return (
        <>
            <div className={"tab-pane fade " + d.tax_active} id="tax-group" role="tabpanel" aria-labelledby="tax-group-tab">
                <If cond={!client_id}>
                    <div className="d-flex flex-row align-items-center justify-content-between pl-4 pr-4">
                        <h6 className="m-0 h5 text-dark">{lang('Tax Group')}</h6>
                        <div className="ml-auto mt-auto mb-auto">
                            <button className="btn btn-danger btn-red" onClick={() => fn.openTaxForm()}><i className="fa fa-plus"></i> {lang('Add')}</button>
                        </div>
                    </div>
                </If>
                <div className={client_id ? "" : "main-content"}>
                    <div className={client_id ? "" : "container-fluid"}>
                        <If cond={d.tax_list.result.length}>
                            <div className="table-responsive">
                                <table className="table border m-0">
                                    <thead className="bg-light text-uppercase font-sm">
                                        <tr>
                                            <th className="w20">{lang('SN')}</th>
                                            <th className="w80">{lang('Title')}</th>
                                            <th className="w80">{lang('Rate')}</th>
                                            <th className="w80">{lang('Status')}</th>
                                            <th className="w70"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-text-vmid fs16">
                                        {d.tax_list.result.map((v, i) => (
                                            <tr key={i}>
                                                <td>{d.tax_list.page.start + i + 1}.</td>
                                                <td className="text-black fw600">{v.title}</td>

                                                <td className="nowrap">{v.rate} %</td>

                                                <td>
                                                    <If cond={v.status === '1'}>
                                                        <div className="badge badge-success">{lang('Active')}</div>
                                                    </If>
                                                    <If cond={v.status === '0'}>
                                                        <div className="badge badge-danger">{lang('Inactive')}</div>
                                                    </If>
                                                </td>
                                                <td className="text-center">
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-sm fs16" onClick={() => fn.openTaxForm(v)}><i className="far fa-edit"></i></button>
                                                        <button type="button" className="btn btn-sm fs16" onClick={() => fn.deletetax(v.id)}><i className="fa fa-trash-o"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </If>
                        <If cond={!d.tax_list.result.length}>
                            <div className="no-rec fs16">{lang('No record found')}</div>
                        </If>
                    </div>
                </div>
            </div>


        </>
    )
}

function PaymentGateway_notInUsed(props){
    let d = props.d, fn = props.fn;
    return(
        <>
            <div className={"tab-pane fade " + d.payment_gateway_active} id="payment-gateway" role="tabpanel" aria-labelledby="payment-gateway-tab">
                <div className="d-flex flex-row align-items-center justify-content-between pl-4 pr-4">
                    <h6 className="m-0 h5 text-dark">{lang('Payement Gateway')}</h6>
                    <div className="ml-auto mt-auto mb-auto">
                        <button className="btn btn-danger btn-red" onClick={() => fn.openPaymentGatewayForm()}><i className="fa fa-plus"></i> {lang('Add')}</button>
                    </div>
                </div>
                <div className={client_id ? "" : "main-content"}>
                    <div className={client_id ? "" : "container-fluid"}>
                        <If cond={d.payment_gateway_list.result.length}>
                            <div className="table-responsive">
                                <table className="table border m-0">
                                    <thead className="bg-light text-uppercase font-sm">
                                        <tr>
                                            <th className="w20">{lang('SN')}</th>
                                            <th className="w80">{lang('Payment Gateway')}</th>
                                            <th className="w80">{lang('Status')}</th>
                                            <th className="w70"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-text-vmid fs16">
                                        {d.payment_gateway_list.result.map((v, i) => (
                                            <tr key={i}>
                                                <td>{d.payment_gateway_list.page.start + i + 1}.</td>
                                                <td className="text-black fw600">{v.name}</td>
                                                <td>
                                                    <If cond={v.status === '1'}>
                                                        <div className="badge badge-success">{lang('Active')}</div>
                                                    </If>
                                                    <If cond={v.status === '0'}>
                                                        <div className="badge badge-danger">{lang('Inactive')}</div>
                                                    </If>
                                                </td>
                                                <td className="text-center">
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-sm fs16" onClick={() => fn.openPaymentGatewayForm(v)}><i className="far fa-edit"></i></button>
                                                        <button type="button" className="btn btn-sm fs16" onClick={() => fn.deletepaymentgateway(v.id)}><i className="fa fa-trash-o"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </If>
                        <If cond={!d.payment_gateway_list.result.length}>
                            <div className="no-rec fs16">{lang('No record found')}</div>
                        </If>
                    </div>
                </div>
            </div>
        </>
    )
}


function Locations(props) {
    let d = props.d, fn = props.fn;
    return (
        <>
            <div className={"tab-pane fade " + d.location_active} id="location" role="tabpanel" aria-labelledby="location-tab">
                <If cond={!client_id}>
                    <div className="d-flex flex-row align-items-center justify-content-between pl-4 pr-4">
                        <h6 className="m-0 h5 text-dark">{lang('Locations')}</h6>
                        <div className="ml-auto mt-auto mb-auto">
                            <button className="btn btn-danger btn-red" onClick={() => fn.openLocationForm()}><i className="fa fa-plus"></i> {lang('Add')}</button>
                        </div>
                    </div>
                </If>
                <div className={client_id ? "" : "main-content"}>
                    <div className={client_id ? "" : "container-fluid"}>
                        <If cond={d.location_list.result.length}>
                            <div className="table-responsive">
                                <table className="table border m-0">
                                    <thead className="bg-light text-uppercase">
                                        <tr>
                                            <th className="w20">{lang('SN')}</th>
                                            <th className="w80">{lang('Location Name')}</th>
                                            <th className="w80">{lang('Delivery Charges')}</th>
                                            <th className="w80">{lang('Status')}</th>
                                            <th className="w70"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-text-vmid fs16">
                                        {d.location_list.result.map((v, i) => (
                                            <tr key={i}>
                                                <td>{d.location_list.page.start + i + 1}.</td>
                                                <td>
                                                    <div className="text-black fw600">{v.location_name}</div>
                                                    <div className="text-black fw600">{v.location_name_arb}</div>
                                                </td>

                                                <td className="nowrap">
                                                    <If cond={v.delivery_charges && v.delivery_charges != 0}>
                                                        <AmtFormat curInfo={d.curInfo} amt={v.delivery_charges} />
                                                    </If>
                                                    <If cond={v.delivery_charges == 0}>
                                                        0
                                                        </If>
                                                </td>
                                                <td>
                                                    <If cond={v.status === '1'}>
                                                        <div className="badge badge-success">{lang('Active')}</div>
                                                    </If>
                                                    <If cond={v.status === '0'}>
                                                        <div className="badge badge-danger">{lang('Inactive')}</div>
                                                    </If>
                                                </td>
                                                <td className="text-center">
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-sm fs16" data-toggle="tooltip" title="Edit" onClick={() => fn.openLocationForm(v)}><i className="far fa-edit"></i></button>
                                                        <button type="button" className="btn btn-sm fs16" data-toggle="tooltip" title="Delete" onClick={() => fn.deletelocation(v.id)}><i className="fa fa-trash-o"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </If>
                        <If cond={!d.location_list.result.length}>
                            <div className="no-rec fs16">{lang('No record found')}</div>
                        </If>
                    </div>
                </div>
            </div>
        </>
    )
}

function Drivers(props) {
    let d = props.d, fn = props.fn;
    return (
        <>
            <div className={"tab-pane fade " + d.drivers_active} id="drivers" role="tabpanel" aria-labelledby="drivers-tab">
                <If cond={!client_id}>
                    <div className="d-flex flex-row align-items-center justify-content-between pl-4 pr-4">
                        <h6 className="m-0 h5 text-dark">{lang('Drivers')}</h6>
                        <div className="ml-auto mt-auto mb-auto">
                            <button className="btn btn-danger btn-red" onClick={() => fn.openDriverForm()}><i className="fa fa-plus"></i> {lang('Add')}</button>
                        </div>
                    </div>
                </If>
                <div className={client_id ? "" : "main-content"}>
                    <div className={client_id ? "" : "container-fluid"}>
                        <If cond={d.driver_list.result.length}>
                            <div className="table-responsive">
                                <table className="table border m-0">
                                    <thead className="bg-light text-uppercase font-sm">
                                        <tr>
                                            <th className="w20">{lang('SN')}</th>
                                            <th className="w80"> {lang('Driver Name')}</th>
                                            <th className="w80">{lang('Vehicle Number')}</th>
                                            <th className="w80">{lang('Status')}</th>
                                            <th className="w70"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-text-vmid fs16">
                                        {d.driver_list.result.map((v, i) => (
                                            <tr key={i}>
                                                <td>{d.driver_list.page.start + i + 1}.</td>
                                                <td>{v.name}</td>

                                                <td className="nowrap">{v.vehicle_no}</td>

                                                <td>
                                                    <If cond={v.status === '1'}>
                                                        <div className="badge badge-success">{lang('Active')}</div>
                                                    </If>
                                                    <If cond={v.status === '0'}>
                                                        <div className="badge badge-danger">{lang('Inactive')}</div>
                                                    </If>
                                                </td>
                                                <td className="text-center">
                                                    <div className="btn-group">
                                                        <button type="button" data-toggle="tooltip" title="Edit" className="btn btn-sm fs16" onClick={() => fn.openDriverForm(v)}><i className="far fa-edit"></i></button>
                                                        <button type="button" data-toggle="tooltip" title="Delete" className="btn btn-sm fs16" onClick={() => fn.deletedriver(v.id)}><i className="fa fa-trash-o"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </If>
                        <If cond={!d.driver_list.result.length}>
                            <div className="no-rec fs16">{lang('No record found')}</div>
                        </If>
                    </div>
                </div>
            </div>
        </>
    )
}

function Discounts(props) {
    let d = props.d, fn = props.fn;
    return (
        <>
            <div className={"tab-pane fade " + d.discount_active} id="coupon" role="tabpanel" aria-labelledby="coupon-tab" >
                <If cond={!client_id}>
                    <div className="d-flex flex-row align-items-center justify-content-between pl-4 pr-4">
                        <h6 className="m-0 h5 text-dark">{lang('Discount/Coupon')}</h6>
                        <div className="ml-auto mt-auto mb-auto">
                            <button className="btn btn-danger btn-red" onClick={() => fn.openDiscountForm()}><i className="fa fa-plus"></i> {lang('Add')}</button>
                        </div>
                    </div>
                </If>
                <div className={client_id ? "" : "main-content"}>
                    <div className={client_id ? "" : "container-fluid"}>
                        <If cond={d.discount_list.result.length}>
                            <div className="table-responsive">
                                <table className="table border m-0">
                                    <thead className="bg-light text-uppercase font-sm">
                                        <tr>
                                            <th className="w20">{lang('SN')}</th>
                                            <th className="w80">{lang('Title')}</th>
                                            <th className="w80">{lang('Discount')}(%)</th>
                                            <th className="w80">{lang('Coupon Code')}</th>
                                            <th className="w80">{lang('Status')}</th>
                                            <th className="w70"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-text-vmid fs16">
                                        {d.discount_list.result.map((v, i) => (
                                            <tr key={i}>
                                                <td>{d.discount_list.page.start + i + 1}.</td>
                                                <td>{v.title}</td>

                                                <td className="nowrap">{v.discount} %</td>

                                                <td className="nowrap"><If cond={v.discount_type == 2}>{v.coupon_code} </If></td>

                                                <td>
                                                    <If cond={v.status === '1'}>
                                                        <div className="badge badge-success">{lang('Active')}</div>
                                                    </If>
                                                    <If cond={v.status === '0'}>
                                                        <div className="badge badge-danger">{lang('Inactive')}</div>
                                                    </If>
                                                </td>
                                                <td className="text-center">
                                                    <div className="btn-group">
                                                        <button type="button" data-toggle="tooltip" title="Edit" className="btn btn-sm fs16" onClick={() => fn.openDiscountForm(v)}><i className="far fa-edit"></i></button>
                                                        <button type="button" data-toggle="tooltip" title="Delete" className="btn btn-sm fs16" onClick={() => fn.deletediscount(v.id)}><i className="fa fa-trash-o"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </If>
                        <If cond={!d.discount_list.result.length}>
                            <div className="no-rec fs16">{lang('No record found')}</div>
                        </If>
                    </div>
                </div>
            </div>
        </>
    )
}

function Payment(props) {
    let d = props.d, fn = props.fn;
    return (
        <>
            <div className={"tab-pane fade " + d.payment_active} id="payment" role="tabpanel" aria-labelledby="payment-tab">
                <If cond={!client_id}>
                    <div className="d-flex flex-row align-items-center justify-content-between pl-4 pr-4">
                        <h6 className="m-0 h5 text-dark">{lang('Debit card/Credit card/Online Payments')}</h6>
                        <div className="ml-auto mt-auto mb-auto">
                            <button className="btn btn-danger btn-red" onClick={() => fn.openPaymentForm()}><i className="fa fa-plus"></i> {lang('Add')}</button>
                        </div>
                    </div>
                </If>
                <div className={client_id ? "" : "main-content"}>
                    <div className={client_id ? "" : "container-fluid"}>
                        <If cond={d.payment_list.result.length}>
                            <div className="table-responsive">
                                <table className="table border m-0">
                                    <thead className="bg-light text-uppercase font-sm">
                                        <tr>
                                            <th className="w20">{lang('SN')}</th>
                                            <th className="w80">{lang('Name')}</th>
                                            <th className="w80">{lang('Bank Charges')}</th>
                                            <th className="w80">{lang('Status')}</th>
                                            <th className="w70"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-text-vmid fs16">
                                        {d.payment_list.result.map((v, i) => (
                                            <tr key={i}>
                                                <td>{d.payment_list.page.start + i + 1}.</td>
                                                <td>{v.name}</td>

                                                <td className="nowrap">
                                                    <If cond={v.charges}>{v.charges} %
                                                        </If>
                                                </td>

                                                <td>
                                                    <If cond={v.status === '1'}>
                                                        <div className="badge badge-success">{lang('Active')}</div>
                                                    </If>
                                                    <If cond={v.status === '0'}>
                                                        <div className="badge badge-danger">{lang('Inactive')}</div>
                                                    </If>
                                                </td>
                                                <td className="text-center">
                                                    <div className="btn-group">
                                                        <button type="button" data-toggle="tooltip" title="Edit" className="btn btn-sm fs16" onClick={() => fn.openPaymentForm(v)}><i className="far fa-edit"></i></button>
                                                        <button type="button" data-toggle="tooltip" title="Delete" className="btn btn-sm fs16" onClick={() => fn.deletepayment(v.id)}><i className="fa fa-trash-o"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </If>
                        <If cond={!d.payment_list.result.length}>
                            <div className="no-rec fs16">{lang('No record found')}</div>
                        </If>
                    </div>
                </div>
            </div>
        </>
    )
}

function Ingredients(props) {
    let d = props.d, fn = props.fn;
    return (
        <>
            <div className={"tab-pane fade " + d.addon_active} id="ingredients" role="tabpanel" aria-labelledby="ingredients-tab">
                <If cond={!client_id}>
                    <div className="d-flex flex-row align-items-center justify-content-between pl-4 pr-4">
                        <h6 className="m-0 h5 text-dark">{lang('Ingredients/Add On')}</h6>
                        <div className="ml-auto mt-auto mb-auto">
                            <button className="btn btn-danger btn-red" onClick={() => fn.openIngredientsForm()}><i className="fa fa-plus"></i> {lang('Add')}</button>
                        </div>
                    </div>
                </If>
                <div className={client_id ? "" : "main-content"}>
                    <div className={client_id ? "" : "container-fluid"}>
                        <If cond={d.ingredients_list.result.length}>
                            <div className="table-responsive">
                                <table className="table border m-0">
                                    <thead className="bg-light text-uppercase font-sm">
                                        <tr>
                                            <th className="w20">{lang('SN')}</th>
                                            <th className="w80">{lang('Name')}</th>
                                            <th className="w80">{lang('Ingredients/AddOn')}</th>
                                            <th className="w80">{lang('AddOn Price')}</th>
                                            <th className="w80">{lang('Status')}</th>
                                            <th className="w70"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-text-vmid fs16">
                                        {d.ingredients_list.result.map((v, i) => (
                                            <tr key={i}>
                                                <td>{d.ingredients_list.page.start + i + 1}.</td>
                                                <td>
                                                    <div className="text-black fw600">{v.name}</div>
                                                    <div className="text-black fw600">{v.name_arb}</div>
                                                
                                                </td>

                                                <td className="nowrap">
                                                    <If cond={v.type === '1'}>
                                                        <div >{lang('Ingredient')}</div>
                                                    </If>
                                                    <If cond={v.type === '2'}>
                                                        <div >{lang('Add-On')}</div>
                                                    </If>
                                                    <If cond={v.type === '3'}>
                                                        <div>{lang('Ingredient & Add-On')}</div>
                                                    </If>
                                                </td>
                                                <td>
                                                    <If cond={v.type != '1'}>
                                                        <AmtFormat curInfo={d.curInfo} amt={v.add_on_price} />
                                                    </If>
                                                </td>
                                                <td>
                                                    <If cond={v.status === '1'}>
                                                        <div className="badge badge-success">{lang('Active')}</div>
                                                    </If>
                                                    <If cond={v.status === '0'}>
                                                        <div className="badge badge-danger">{lang('Inactive')}</div>
                                                    </If>
                                                </td>
                                                <td className="text-center">
                                                    <div className="btn-group">
                                                        <button type="button" data-toggle="tooltip" title="Edit" className="btn btn-sm fs16" onClick={() => fn.openIngredientsForm(v)}><i className="far fa-edit"></i></button>
                                                        <button type="button" data-toggle="tooltip" title="Delete" className="btn btn-sm fs16" onClick={() => fn.deleteIngredients(v.id)}><i className="fa fa-trash-o"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </If>
                        <If cond={!d.ingredients_list.result.length}>
                            <div className="no-rec fs16">{lang('No record found')}</div>
                        </If>
                    </div>
                </div>
            </div>
        </>
    )
}

function DeliverySource(props) {
    let d = props.d, fn = props.fn;
    return (
        <>
            <div className={"tab-pane fade " + d.source_active} id="source" role="tabpanel" aria-labelledby="source-tab">
                <If cond={!client_id}>
                    <div className="d-flex flex-row align-items-center justify-content-between pl-4 pr-4">
                        <h6 className="m-0 h5 text-dark">{lang('Source')}</h6>
                        <div className="ml-auto mt-auto mb-auto">
                            <button className="btn btn-danger btn-red" onClick={() => fn.openSourceForm()}><i className="fa fa-plus"></i> {lang('Add')}</button>
                        </div>
                    </div>
                </If>
                <div className={client_id ? "" : "main-content"}>
                    <div className={client_id ? "" : "container-fluid"}>
                        <If cond={d.source_list.result.length}>
                            <div className="table-responsive">
                                <table className="table border m-0">
                                    <thead className="bg-light text-uppercase font-sm">
                                        <tr>
                                            <th className="w20">{lang('SN')}</th>
                                            <th className="w80">{lang('Name')}</th>
                                            <th className="w80">{lang('Commission')}</th>
                                            <th className="w80">{lang('Status')}</th>
                                            <th className="w70"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-text-vmid fs16">
                                        {d.source_list.result.map((v, i) => (
                                            <tr key={i}>
                                                <td>{d.source_list.page.start + i + 1}.</td>
                                                <td>{v.name}</td>
                                                <td>
                                                    <If cond={v.commission == '1'}>
                                                        <AmtFormat curInfo={d.curInfo} amt={v.commission_percentage} />
                                                    </If>
                                                    <If cond={v.commission == '2'}>
                                                        {v.commission_percentage} %
                                                        </If>
                                                </td>
                                                <td>
                                                    <If cond={v.status === '1'}>
                                                        <div className="badge badge-success">{lang('Active')}</div>
                                                    </If>
                                                    <If cond={v.status === '0'}>
                                                        <div className="badge badge-danger">{lang('Inactive')}</div>
                                                    </If>
                                                </td>
                                                <td className="text-center">
                                                    <div className="btn-group">
                                                        <button type="button" data-toggle="tooltip" title="Edit" className="btn btn-sm fs16" onClick={() => fn.openSourceForm(v)}><i className="far fa-edit"></i></button>
                                                        <button type="button" data-toggle="tooltip" title="Delete" className="btn btn-sm fs16" onClick={() => fn.deleteSource(v.id)}><i className="fa fa-trash-o"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </If>
                        <If cond={!d.source_list.result.length}>
                            <div className="no-rec fs16">{lang('No record found')}</div>
                        </If>
                    </div>
                </div>
            </div>
        </>
    )
}

function TaxGroupForm(props) {
    let d = props.d, fn = props.fn;

    return (
        <div className="modal modal-scroll no-backdrop" id="taxformModal" data-backdrop="static">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{d.data.id ? 'Edit' : 'Add'} {lang('Tax Group')}</div>
                        <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={() => $("#taxformModal").modal('hide')}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form id="bfrm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" defaultValue={d.data.id} />
                            <input type="hidden" name="client_id" defaultValue={d.data.client_id} />
                            <div className="row mingap">
                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Title')}</label>
                                    <input type="text" className="form-control" name="title" value={d.data.title || ''} onChange={fn.handleChange} />
                                </div>

                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Rate')} (%)</label>
                                    <input type="text" className="form-control" name="rate" value={d.data.rate || ''} onChange={fn.handleChange} />
                                </div>
                                <div className="form-group col-md-12 mt-2">
                                    <label className="">{lang('Branches')}</label>
                                    {d.branches.map((r, i) => (
                                        <div className="form-control mb5" key={i}>
                                            <label className="m-0 noselect">
                                                <input type="checkbox" name="branches[]" value={r.id} checked={d.data.branches?.includes(r.id) ? true : false} onChange={fn.setBranchCheck} />
                                                            &nbsp;&nbsp;{r.title}
                                            </label>
                                        </div>
                                    ))}
                                </div>

                                <div className="form-group col-md-12 mt-2">
                                    <label className="">{lang('Applied For')}</label>
                                    
                                        <div className="form-control mb5" >
                                            <label className="m-0 noselect">
                                                <input type="checkbox" name="tax_applied_for[]" value="1" checked={d.data.tax_applied_for?.includes('1') ? true : false} onChange={fn.setTaxAppliedCheck}  />
                                                            &nbsp;&nbsp;{lang('Dine-In')}
                                            </label>
                                        </div>
                                        <div className="form-control mb5" >
                                            <label className="m-0 noselect">
                                                <input type="checkbox" name="tax_applied_for[]" value="2" checked={d.data.tax_applied_for?.includes('2') ? true : false} onChange={fn.setTaxAppliedCheck} />
                                                            &nbsp;&nbsp;{lang('Pickup & Delivery')}
                                            </label>
                                        </div>
                                        {/* <div className="form-control mb5" >
                                            <label className="m-0 noselect">
                                                <input type="checkbox" name="tax_applied_for[]" value="3" checked={d.data.tax_applied_for?.includes('3') ? true : false} onChange={fn.setTaxAppliedCheck} />
                                                            &nbsp;&nbsp;Delivery
                                            </label>
                                        </div> */}
                                   
                                </div>

                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Status')}</label>
                                    <select className="form-control" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                        <option value="1">{lang('Active')}</option>
                                        <option value="0">{lang('Inactive')}</option>
                                    </select>
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={() => $("#taxformModal").modal('hide')}>{lang('Close')}</button>
                        <button type="button" className="btn btn-primary" onClick={fn.savetax}>{lang('Save')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function PaymentGatewayForm_notInUsed(props){
    let d = props.d, fn = props.fn;
    return (
        <div className="modal modal-scroll no-backdrop" id="paymentgatewayModal" data-backdrop="static">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{d.data.id ? 'Edit' : 'Add'} {lang('Payment Gateway')}</div>
                        <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={() => $("#paymentgatewayModal").modal('hide')}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form id="gfrm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" defaultValue={d.data.id} />
                            <div className="row mingap">
                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Name')}</label>
                                    <select className="form-control" name="payment_gateway" value={d.data.payment_gateway || ''} onChange={fn.handleChangepayment}>
                                        <option value="">{lang('Please Select')}</option>
                                        {d.payments.map((r, i) => (
                                        <option  key={i} value={r.id}>{r.name}</option>
                                        ))}
                                    </select>
                                </div>
                                {d.payment_gateway_type == 1 &&
                                <div id='stripe' className="row mingap">
                                    <div className="form-group col-md-12">
                                        <label className="req">{lang('Publish Key / App Id')}</label>
                                        <input type="text" className="form-control" name="appId" value={d.data.appId || ''} onChange={fn.handleChangeP} />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label className="req">{lang('Secret Key')}</label>
                                        <input type="text" className="form-control" name="secret_key" value={d.data.secret_key || ''} onChange={fn.handleChange} />
                                    </div>
                                </div>}
                                {d.payment_gateway_type == 2 &&
                                <div id='paytm' className="row mingap">
                                    <div className="form-group col-md-12">
                                        <label className="req">{lang('Merchant MID')}</label>
                                        <input type="text" className="form-control" name="merchant_mid" value={d.data.merchant_mid || ''} onChange={fn.handleChange} />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label className="req">{lang('Merchant Key')}</label>
                                        <input type="text" className="form-control" name="merchant_key" value={d.data.merchant_key || ''} onChange={fn.handleChange} />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label className="req">{lang('Merchant Website')}</label>
                                        <input type="text" className="form-control" name="merchant_website" value={d.data.merchant_website || ''} onChange={fn.handleChange} />
                                    </div>
                                </div>}
                                
                                <div className="form-group col-md-12 mt-2">
                                    <label className="req">{lang('Branches')}</label>
                                    {d.branches.map((r, i) => (
                                        <div className="form-control mb5" key={i}>
                                            <label className="m-0 noselect">
                                                <input type="checkbox" name="branches[]" value={r.id} checked={d.data.branches?.includes(r.id) ? true : false} onChange={fn.setBranchCheck}    />
                                                &nbsp;&nbsp;{r.title}
                                            </label>
                                        </div>
                                    ))}
                                </div>

                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Status')}</label>
                                    <select className="form-control" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                        <option value="1">{lang('Active')}</option>
                                        <option value="0">{lang('Inactive')}</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={() => $("#paymentgatewayModal").modal('hide')}>{lang('Close')}</button>
                        <button type="button" className="btn btn-primary" onClick={fn.savepaymentgateway}>{lang('Save')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function IngredientsForm(props) {
    let d = props.d, fn = props.fn;

    return (
        <div className="modal modal-scroll no-backdrop" id="IngredientsformModal" data-backdrop="static">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{d.data.id ? 'Edit' : 'Add'} {lang('Ingredients')} </div>
                        <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={() => $("#IngredientsformModal").modal('hide')}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form id="ingredientsfrm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" defaultValue={d.data.id} />
                            <input type="hidden" name="client_id" defaultValue={d.data.client_id} />
                            <div className="row mingap">
                                <div className="form-group col-md-6">
                                    <label className="req">{lang('Ingredient Name')}</label>
                                    <input type="text" className="form-control" name="name" value={d.data.name || ''} onChange={fn.handleChange} />
                                </div>

                                <If cond={d.is_arabic && d.languageset == 'Arabic' }>
                                    <div className="form-group col-md-6">
                                        <label>{lang('Ingredient Name')} ({d.languageset})</label>
                                        <input type="text" className="form-control" name="name_arb" value={d.data.name_arb || ''} onChange={fn.handleChange} dir="rtl" />
                                    </div>
                                </If>

                                <If cond={d.is_arabic && d.languageset != 'Arabic' }>
                                    <div className="form-group col-md-6">
                                        <label>{lang('Ingredient Name')} ({d.languageset})</label>
                                        <input type="text" className="form-control" name="name_arb" value={d.data.name_arb || ''} onChange={fn.handleChange} />
                                    </div>
                                </If>

                                <div className="form-group col-md-12">
                                    <label className="">{lang('Ingredients Type')}</label>
                                    <select className="form-control" name="type" value={d.data.type || ''} onChange={fn.setIngType}>
                                        <option value="1">{lang('Ingredient')}</option>
                                        <option value="2">{lang('AddOn')}</option>
                                        <option value="3">{lang('Both')}</option>
                                    </select>
                                </div>

                                {d.type != 1 &&
                                    <div className="form-group col-md-12">
                                        <label className="">{lang('AddOn Price')}</label>
                                        <input type="text" className="form-control" name="add_on_price" value={d.data.add_on_price || ''} onChange={fn.handleChange} />
                                    </div>}

                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Status')}</label>
                                    <select className="form-control" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                        <option value="1">{lang('Active')}</option>
                                        <option value="0">{lang('Inactive')}</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={() => $("#IngredientsformModal").modal('hide')}>Close</button> */}
                        <button type="button" className="btn btn-danger btn-red" onClick={fn.saveIngredients}>{lang('Save')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function LocationForm(props) {
    let d = props.d, fn = props.fn;
    return (
        <div className="modal modal-scroll no-backdrop" id="locationformModal" data-backdrop="static">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{d.data.id ? 'Edit' : 'Add'} {lang('Locations')}</div>
                        <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={() => $("#locationformModal").modal('hide')}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form id="lfrm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" defaultValue={d.data.id} />
                            <input type="hidden" name="client_id" defaultValue={d.data.client_id} />
                            <div className="row mingap">
                                <div className="form-group col-md-6">
                                    <label className="req">{d.type === "C" ? 'City' : 'Location'} {lang('Name')}</label>
                                    <input type="text" className="form-control" name="location_name" value={d.data.location_name || ''} onChange={e => fn.handleChange(e, d)} />
                                </div>
                                <If cond={d.is_arabic && d.languageset == 'Arabic' }>
                                    <div className="form-group col-md-6">
                                        <label>{lang('Location Name')} ({d.languageset})</label>
                                        <input type="text" className="form-control" name="location_name_arb" value={d.data.location_name_arb || ''} onChange={fn.handleChange} dir="rtl" />
                                    </div>
                                </If>

                                <If cond={d.is_arabic && d.languageset != 'Arabic' }>
                                    <div className="form-group col-md-6">
                                        <label>{lang('Location Name')} ({d.languageset})</label>
                                        <input type="text" className="form-control" name="location_name_arb" value={d.data.location_name_arb || ''} onChange={fn.handleChange}  />
                                    </div>
                                </If>

                                <div className="form-group col-md-6">
                                    <label className="">{lang('Latitude')}</label>
                                    <input type="number" className="form-control" name="lat" value={d.data.lat || ''} onChange={e => fn.handleChange(e, d)} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label className="">{lang('Longitude')}</label>
                                    <input type="number" className="form-control" name="lng" value={d.data.lng || ''} onChange={e => fn.handleChange(e, d)} />
                                </div>
                                <div className="form-group col-md-12">
                                    <label className="">{lang('Delivery Charges')}</label>
                                    <input type="text" className="form-control" name="delivery_charges" value={d.data.delivery_charges || ''} onChange={e => fn.handleChange(e, d)} />
                                </div>
                                <div className="form-group col-md-12 mt-2">
                                    <label className="">{lang('Branches')}</label>
                                    {d.branches.map((r, i) => (
                                        <div className="form-control mb5" key={i}>
                                            <label className="m-0 noselect">
                                                <input type="checkbox" name="branches[]" value={r.id} checked={d.data.branches?.includes(r.id) ? true : false} onChange={fn.setBranchCheck} />
                                                            &nbsp;&nbsp;{r.title}
                                            </label>
                                        </div>
                                    ))}
                                </div>

                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Status')}</label>
                                    <select className="form-control" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                        <option value="1">{lang('Active')}</option>
                                        <option value="0">{lang('Inactive')}</option>
                                    </select>
                                </div>

                            </div>

                        </form>
                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={() => $("#locationformModal").modal('hide')}>Close</button> */}
                        <button type="button" className="btn btn-danger btn-red" onClick={fn.savelocation}>{lang('Save')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function DriverForm(props) {
    let d = props.d, fn = props.fn;

    return (
        <div className="modal modal-scroll no-backdrop" id="driverformModal" data-backdrop="static">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{d.data.id ? 'Edit' : 'Add'} {lang('Driver')}</div>
                        <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={() => $("#driverformModal").modal('hide')}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form id="driverfrm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" defaultValue={d.data.id} />
                            <input type="hidden" name="client_id" defaultValue={d.data.client_id} />
                            <div className="row mingap">
                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Name')}</label>
                                    <input type="text" className="form-control" name="name" value={d.data.name || ''} onChange={fn.handleChange} />
                                </div>
                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Phone Number')}</label>
                                    <input type="text" className="form-control" maxLength={d.mobile_digit_length} name="mobile" value={d.data.mobile || ''} onChange={fn.handleChange} />
                                </div>
                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Email')}</label>
                                    <input type="text" className="form-control" name="email" value={d.data.email || ''} onChange={fn.handleChange} />
                                </div>
                                <div className="form-group col-md-12">
                                    <label className="">{lang('Password')}</label>
                                    <input type="text" className="form-control" name="password" value={d.data.password || ''} onChange={fn.handleChange} />
                                </div>
                                {/* <div className="form-group col-md-12">
                                        <label className="req">Role</label>
                                        <select className="form-control" name="role_id" value={d.data.role_id || ''} onChange={fn.handleChange}>
                                            <option value="">Select</option>
                                            {d.roles.map(r=><option key={r.id} value={r.id}>{r.title}</option>)}
                                        </select>
                                    </div> */}
                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Branch')}</label>
                                    <select className="form-control" name="branch_id" value={d.data.branch_id || ''} onChange={fn.handleChange}>
                                        <option value="">{lang('Select')}</option>
                                        {d.branches.map(r => <option key={r.id} value={r.id}>{r.title}</option>)}
                                    </select>
                                </div>
                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Language Preferance')}</label>
                                    <select className="form-control" name="lang_preferance" value={d.data.lang_preferance || ''} onChange={fn.handleChange}>
                                        <option value="ENG">{lang('English')}</option>
                                        <If cond={d.is_arabic}>
                                            <option value={(d.languageset== 'Arabic')?'ARB':d.languageset}>{d.languageset}</option>
                                        </If>
                                    </select>
                                </div>
                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Vehicle Number')}</label>
                                    <input type="text" className="form-control" name="vehicle_no" value={d.data.vehicle_no || ''} onChange={fn.handleChange} />
                                </div>
                                <div className="form-group col-md-12">
                                    <label >{lang('Commission')}</label>
                                    <input type="text" className="form-control" name="commission" value={d.data.commission || ''} onChange={fn.handleChange} />
                                </div>
                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Status')}</label>
                                    <select className="form-control" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                        <option value="1">{lang('Active')}</option>
                                        <option value="0">{lang('Inactive')}</option>
                                    </select>
                                </div>

                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={() => $("#driverformModal").modal('hide')}>Close</button> */}
                        <button type="button" className="btn btn-danger btn-red" onClick={fn.savedriver}>{lang('Save')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function DiscountForm(props) {
    let d = props.d, fn = props.fn;
    const date = new Date().getDate(); //Current Date
    var month = new Date().getMonth() + 1; //Current Month
    const year = new Date().getFullYear(); //Current Year

    if (parseInt(month) < 10) {
        month = '0' + month;
    }

    const datetime = year + '-' + month + '-' + date;

    return (
        <div className="modal modal-scroll no-backdrop" id="discountformModal" data-backdrop="static">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{d.data.id ? 'Edit' : 'Add'} {lang('Discount')}</div>
                        <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={() => $("#discountformModal").modal('hide')}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form id="discountfrm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" defaultValue={d.data.id} />
                            <input type="hidden" name="client_id" defaultValue={d.data.client_id} />
                            <div className="row mingap">
                                <div className="form-group col-md-12">
                                    <label className="req"> {lang('Title')}</label>
                                    <input type="text" className="form-control" name="title" value={d.data.title || ''} onChange={e => fn.handleChange(e, d)} />
                                </div>
                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Discount Type')}</label>
                                    <select className="form-control" name="discount_type" value={d.data.discount_type || ''} onChange={(e) => { fn.setDiscountType(e, d) }}>
                                        <option value="1">{lang('Discount')}</option>
                                        <option value="2">{lang('Coupon')}</option>
                                    </select>
                                </div>
                                {d.discount_type == 2 && <div className="form-row col-12 pr-0 pl-1 mb-3">

                                <div className="form-group col-md-12">
                                    <label className=""> {lang('Custome Cupon Code')}</label>
                                    <input type="text" className="form-control" name="coupon_code" value={d.data.coupon_code || ''} maxLength = "10" pattern="[A-Z0-9]{8}" onChange={e => fn.handleChange(e, d)} />
                                    ({lang('Note:This input should be in  English Capital letters and atleast one Number only')}
                                    Eg: ABCD24 )
                                </div>

                                    <div className="col-md-6">
                                        <label className="req">{lang('Start Date')}</label>
                                        <input type="date" className="form-control" id="start_date" name="start_date" value={d.data.start_date || ''} onChange={e => fn.handleChange(e, d)} min={datetime} />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="req">{lang('End Date')}</label>
                                        <input type="date" className="form-control" name="end_date" value={d.data.end_date || ''} onChange={e => fn.handleChange(e, d)} min={datetime} />
                                    </div>
                                </div>}
                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Discount Percentage')} (%)</label>
                                    <input type="text" className="form-control" name="discount" value={d.data.discount || ''} onChange={e => fn.handleChange(e, d)} />
                                </div>
                                {d.discount_type == 2 && <div className="form-row col-12 pr-0 pl-1 ">
                                    <div className="col-md-6">
                                        <label className="">{lang('Maximum amount on price')}</label>
                                        <input type="text" className="form-control" name="maxprice" value={d.data.maxprice || ''} onChange={e => fn.handleChange(e, d)} />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="">{lang('Applicable on Above Price')}</label>
                                        <input type="text" className="form-control" name="applicable_price" value={d.data.applicable_price || ''} onChange={e => fn.handleChange(e, d)} />
                                    </div>
                                    <div className="form-group col-md-12 mt-3">
                                        <label className="">{lang('Discount apply for')}</label>
                                        <div>
                                            <div className="form-control mb5">
                                                <label className="m-0 noselect">
                                                    <input type="checkbox" name="applied_for_dining" value={d.data.applied_for_dining} checked={d.data.applied_for_dining == 1 ? true : false} onChange={fn.applied_for_dining} />
                                                    {/* onChange={() => fn.discountapply('dining',d.data.id)} */}
                                                            &nbsp;&nbsp;{lang('Dine-In')}
                                                        </label>
                                            </div>
                                            <div className="form-control mb5">
                                                <label className="m-0 noselect">
                                                    <input type="checkbox" name="applied_for_delivery_pickup" value={d.data.applied_for_delivery_pickup} checked={d.data.applied_for_delivery_pickup == 1 ? true : false} onChange={fn.applied_for_delivery_pickup} />
                                                    {/* onChange={() => fn.discountapply('delivery',d.data.id)} */}
                                                            &nbsp;&nbsp;{lang('Pickup/Delivery')}
                                                        </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                <div className="form-group col-md-12 mt-2">
                                    <label className="">{lang('Branches')}</label>
                                    {d.branches.map((r, i) => (
                                        <div className="form-control mb5" key={i}>
                                            <label className="m-0 noselect">
                                                <input type="checkbox" name="branches[]" value={r.id} checked={d.data.branches?.includes(r.id) ? true : false} onChange={fn.setBranchCheck} />
                                                            &nbsp;&nbsp;{r.title}
                                            </label>
                                        </div>
                                    ))}
                                </div>

                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Status')}</label>
                                    <select className="form-control" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                        <option value="1">{lang('Active')}</option>
                                        <option value="0">{lang('Inactive')}</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={() => $("#discountformModal").modal('hide')}>Close</button> */}
                        <button type="button" className="btn btn-danger btn-red" onClick={fn.savediscount}>{lang('Save')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function PaymentForm(props) {
    let d = props.d, fn = props.fn;

    return (
        <div className="modal modal-scroll no-backdrop" id="paymentformModal" data-backdrop="static">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{d.data.id ? 'Edit' : 'Add'} {lang('Card')}</div>
                        <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={() => $("#paymentformModal").modal('hide')}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form id="paymentfrm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" defaultValue={d.data.id} />
                            <input type="hidden" name="client_id" defaultValue={d.data.client_id} />
                            <div className="row mingap">
                                <div className="form-group col-md-12">
                                    <label className="req"> {lang('Card Name')}</label>
                                    <input type="text" className="form-control" name="name" value={d.data.name || ''} onChange={e => fn.handleChange(e, d)} />
                                </div>

                                <div className="form-group col-md-12">
                                    <label className="">{lang('Bank Charges')}</label>
                                    <input type="text" className="form-control" name="charges" value={d.data.charges || ''} onChange={e => fn.handleChange(e, d)} />
                                </div>

                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Status')}</label>
                                    <select className="form-control" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                        <option value="1">{lang('Active')}</option>
                                        <option value="0">{lang('Inactive')}</option>
                                    </select>
                                </div>

                            </div>

                        </form>
                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={() => $("#paymentformModal").modal('hide')}>Close</button> */}
                        <button type="button" className="btn btn-danger btn-red" onClick={fn.savepayment}>{lang('Save')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function DeliverySourceForm(props) {
    let d = props.d, fn = props.fn;

    return (
        <div className="modal modal-scroll no-backdrop" id="sourceformModal" data-backdrop="static">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{d.data.id ? 'Edit' : 'Add'} {lang('Delivery Source')}</div>
                        <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={() => $("#sourceformModal").modal('hide')}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form id="sourcefrm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" defaultValue={d.data.id} />
                            <input type="hidden" name="client_id" defaultValue={d.data.client_id} />
                            <div className="row mingap">
                                <div className="form-group col-md-12">
                                    <label className="req"> {lang('Name')}</label>
                                    <input type="text" className="form-control" name="name" value={d.data.name || ''} onChange={e => fn.handleChange(e, d)} />
                                </div>

                                <div className="form-group col-md-12">
                                    <label className="">{lang('Commisions')}</label>
                                    <select className="form-control" name="commission" value={d.data.commission || ''} onChange={fn.handleChange}>
                                        <option value="1">{lang('In Price')}</option>
                                        <option value="2">{lang('In Percentage')}</option>
                                    </select>
                                    <div></div>
                                </div>

                                <div className="form-group col-md-12">
                                    <input type="text" className="form-control" name="commission_percentage" value={d.data.commission_percentage || ''} onChange={e => fn.handleChange(e, d)} />
                                </div>

                                <div className="form-group col-md-12 mt-2">
                                    <label className="">{lang('Branches')}</label>
                                    {d.branches.map((r, i) => (
                                        <div className="form-control mb5" key={i}>
                                            <label className="m-0 noselect">
                                                <input type="checkbox" name="branches[]" value={r.id} checked={d.data.branches?.includes(r.id) ? true : false} onChange={fn.setBranchCheck} />
                                                            &nbsp;&nbsp;{r.title}
                                            </label>
                                        </div>
                                    ))}
                                </div>

                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Status')}</label>
                                    <select className="form-control" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                        <option value="1">{lang('Active')}</option>
                                        <option value="0">{lang('Inactive')}</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={() => $("#sourceformModal").modal('hide')}>Close</button> */}
                        <button type="button" className="btn btn-danger btn-red" onClick={fn.savesource}>{lang('Save')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}