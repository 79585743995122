import React, {useState, useEffect, useRef} from 'react';
import {Route, withRouter, Redirect, useHistory} from 'react-router-dom';
import util from "../../util/util";
import Header from "./tpl_header";
import fbase from "../../services/firebase";
//import {sdx} from "../../sdx";
import {RawHTML} from "../../util/controls";
import {Modal, message, notification} from 'antd';
let $=window.$;

function playNotiSound(){
    $(".notisound")[0].currentTime=0;
    $(".notisound")[0].muted=false;
    $(".notisound")[0].crossOrigin = 'anonymous';
    $(".notisound")[0].play();
}

function Default(props) {
    const history=useHistory();
    const hdrRef=useRef({});
    const {component:Component, ...rest}=props;
    const [fbaseLoggedIn, setFbaseLoggedIn]=useState(fbase.auth().currentUser?true:false);
    const [isAudioAllowed, setAudioAllowed]=useState(false);
    const client_id=util.getClientId();
    const branch_ids=util.getBranchIds();
    const branch_names=util.getBranchNames();
    //console.log("satyendra", fbaseLoggedIn);

    const fbaseSignIn=async()=>{
        if(!fbaseLoggedIn){
            const hide=message.loading('Loading...', 0);
            try{
                await fbase.signin();
                setFbaseLoggedIn(true);

                //if(window.location.href.indexOf('/orders')<0){
                    onNewOrder();
                //}
                onNewMsgFromCustomer();
                allNewOrders();
                allMsgsFromCustomers();
            }catch(e){
                console.log(e)
                Modal.error({
                    title:'Firebase notification error!',
                    okText:"Try Again",
                    closable:true,
                    onOk(){
                        window.location.reload();
                    }
                })
            }finally{
                hide();
            }
        }else{
            onNewOrder();
            onNewMsgFromCustomer();
            allNewOrders();
            allMsgsFromCustomers();
        }
    }

    const openAllowAudio=()=>{
        Modal.info({
            title:'Allow audio notification?',
            closable:true,
            onOk(){
                setAudioAllowed(true);
            }
        })
    }

    const onNewOrder=()=>{
        let fbaseFlg = 0;
        fbase.onNewMsg("agnapos_orders_" + client_id, (msgs) => {
            if (!fbaseFlg) {
                fbaseFlg = 1;
                return;
            }

            //console.log("satyendra", msgs, msgs[0].type, branch_ids.indexOf(msgs[0].branch_id));

            if (msgs.length) {
                switch (msgs[0].type) {
                    case 'New':
                        if(msgs[0].branch_id && branch_ids.indexOf(msgs[0].branch_id+'')>=0){
                            playNotiSound();
                            notification.open({
                                message:(
                                    <div className='bold600 text-primary cpointer' onClick={()=>history.push("/orders")}>
                                        New Order
                                    </div>
                                ),
                                description:"Branch: "+branch_names[msgs[0].branch_id],
                                duration:5
                            });
                        }
                    break;
                    default:
                }
            }
        });
    }

    const onNewMsgFromCustomer=()=>{
        let fbaseFlg=0;
        fbase.onNewMsg("agnapos_cust_msg_" + client_id, (msgs) => {
            if (!fbaseFlg) {
                fbaseFlg = 1;
                return;
            }

            if (msgs.length) {
                if( msgs[0].branch_id && branch_ids.indexOf(msgs[0].branch_id+'')>=0){
                    playNotiSound();
                    notification.open({
                        message:(
                            <div className='bold600 text-danger'>
                                <i className="fa fa-bell mr5"></i> Customer calling. ({branch_names[msgs[0].branch_id]})
                            </div>
                        ),
                        description:(
                            <div>
                                <div>
                                    {msgs[0].odr_no.length>0 && <span>Order# {msgs[0].odr_no} | </span>}
                                    Table# {msgs[0].table_no}
                                </div>
                                {/* <div className='text-secondary pt3'>
                                    <RawHTML html={msgs[0].msg} />
                                </div> */}
                            </div>
                        ),
                        duration:5,
                        placement:'topLeft'
                    });
                }
            }
        });
    }

    const allNewOrders=()=>{
        setTimeout(()=>{
            fbase.onNewMsg("agnapos_orders_" + client_id, (notiMsgs)=>{
                notiMsgs.reverse();
                if( notiMsgs[0] && branch_ids.indexOf(notiMsgs[0].branch_id+'')<0){
                    return;
                }
                let msgs=[];
                notiMsgs.forEach(v=>{
                    if( v.branch_id && branch_ids.indexOf(v.branch_id+'')>=0){
                        msgs.push(v);
                    }
                });

                if(msgs.length>0){
                    msgs.forEach(v=>{
                        v.branch=branch_names[v.branch_id];
                    });
                    hdrRef.current.setNewOdrs(msgs);
                }
            }, 500);
        }, 200);
    }

    const allMsgsFromCustomers=()=>{
        setTimeout(()=>{
            fbase.onNewMsg("agnapos_cust_msg_" + client_id, (notiMsgs)=>{
                notiMsgs.reverse();
                if(notiMsgs[0] && branch_ids.indexOf(notiMsgs[0].branch_id+'')<0){
                    return;
                }
                let msgs=[];
                notiMsgs.forEach(v=>{
                    if(v.branch_id && branch_ids.indexOf(v.branch_id+'')>=0){
                        msgs.push(v);
                    }
                });

                if(msgs.length>0){
                    msgs.forEach(v=>{
                        v.branch=branch_names[v.branch_id];
                    });
                    hdrRef.current.setCustomerMsgs(msgs);
                }
            }, 500);
        }, 200);
    }

    const test=()=>{
        fbase.sendMsg("agnapos_orders1_" + client_id, {
            odr_id: 111,
            branch_id: 1,
            type: "New",
        });
    }

    useEffect(()=>{
        if(util.isLogged()){
            //openAllowAudio();
            fbaseSignIn();
        }
    }, []);


    if(!util.isLogged()){
        return <Redirect to="/" />;
    }

    return (
      <Route {...rest}  render={()=>{
            return(
                <React.Fragment>
                    <iframe id="printiframe" name='printiframe' className="d-none" />
                    <audio src="sound.mp3" loop={true} className="d-none sound" muted></audio>
                    <audio src="sound.mp3" className="d-none notisound" muted></audio>

                    <Header {...rest} hdrRef={hdrRef}>
                        {/* <button onClick={test} style={{position:'fixed', zIndex:1000000}}>Test</button> isAudioAllowed &&  */}

                        {fbaseLoggedIn &&
                            <Component {...rest}  />
                        }
                    </Header> 
                    {/* <Component {...rest}  /> */}
                </React.Fragment>
            )
        }} />
    );
}

export default withRouter(Default);