/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import Services from '../../Services';
import {AntdSelect} from '../../util/Antd';
import{
    Input,
    message,
    Tabs,
    Checkbox
} from 'antd';
const {TabPane}=Tabs;

export default (props)=>{
    const [fd, setFd]=useState({
        stripe:{payment_gateway:1, branch_ids:[], metadata:{}, status:0},
        cashfree:{payment_gateway:2, branch_ids:[], metadata:{}, status:0},
        razorpay:{payment_gateway:3, branch_ids:[], metadata:{}, status:0},
        paypal:{payment_gateway:4, branch_ids:[], metadata:{}, status:0},
    });

    const getPaymentGateways=()=>{
        const hide=message.loading('Loading..', 0);
        Services.clientPaymentGateways().then(({data})=>{
            setFd(data.result);
        }).catch(e=>{
            message.error(e.message);
        }).finally(()=>{
            hide();
        })
    }

    const savePaymentGateways=(pg)=>{
        const hide=message.loading('Saving..', 0);
        Services.saveClientPaymentGateways(fd[pg]).then(({data})=>{
            message.success(data.message);
            setFd(data.result);
        }).catch(e=>{
            message.error(e.message);
        }).finally(()=>{
            hide();
        })
    }

    useEffect(()=>{
        getPaymentGateways();
        return ()=>{message.destroy()}
    }, []);

    return(
        <div className='pl15 pr15 pb15'>
            <Tabs defaultActiveKey="1" type="card" className='antd-bdr-tab'>
                <TabPane key="stripe" tab={<div className='d-flex align-items-center'><div>Stripe</div> {fd.stripe.status===1 && <div className='pl5 text-success'><i className='fa fa-check-circle'></i></div>}</div>}>
                    <div className='form-group'>
                        <label>Branch</label>
                        <BranchesComp pg="stripe" fd={fd} setFd={setFd} branches={props.branches} />
                    </div>

                    <div className='form-group'>
                        <label>App ID/Key</label>
                        <div>
                            <Input
                                allowClear
                                placeholder='Enter App ID/Key'
                                value={fd.stripe.metadata.appId || ''}
                                onChange={e=>{
                                    fd.stripe.metadata.appId=e.target.value;
                                    setFd({...fd});
                                }}
                            />
                        </div>
                    </div>

                    <div className='form-group'>
                        <label>Secret Key</label>
                        <div>
                            <Input
                                allowClear
                                placeholder='Enter Secret Key'
                                value={fd.stripe.metadata.secret_key || ''}
                                onChange={e=>{
                                    fd.stripe.metadata.secret_key=e.target.value;
                                    setFd({...fd});
                                }}
                            />
                        </div>
                    </div>

                    <div className='form-group'>
                        <label>Status</label>
                        <div>
                            <AntdSelect
                                options={[{value:'1', label:"Active"}, {value:'0', label:"Inactive"}]}
                                value={fd.stripe.status}
                                onChange={v=>{
                                    fd.stripe.status=v;
                                    setFd({...fd});
                                }}
                            />
                        </div>
                    </div>

                    <div>
                        <button type='button' className='btn btn-danger btn-red' onClick={()=>savePaymentGateways('stripe')}>Save</button>
                    </div>
                </TabPane>

                <TabPane key="cashfree" tab={<div className='d-flex align-items-center'><div>Cashfree</div> {fd.cashfree.status===1 && <div className='pl5 text-success'><i className='fa fa-check-circle'></i></div>}</div>}>
                    <div className='form-group'>
                        <label>Branch</label>
                        <BranchesComp pg="cashfree" fd={fd} setFd={setFd} branches={props.branches} />
                    </div>

                    <div className='form-group'>
                        <label>App ID/Key</label>
                        <div>
                            <Input
                                allowClear
                                placeholder='Enter App ID/Key'
                                value={fd.cashfree.metadata.appId || ''}
                                onChange={e=>{
                                    fd.cashfree.metadata.appId=e.target.value;
                                    setFd({...fd});
                                }}
                            />
                        </div>
                    </div>

                    <div className='form-group'>
                        <label>Secret Key</label>
                        <div>
                            <Input
                                allowClear
                                placeholder='Enter Secret Key'
                                value={fd.cashfree.metadata.secret_key || ''}
                                onChange={e=>{
                                    fd.cashfree.metadata.secret_key=e.target.value;
                                    setFd({...fd});
                                }}
                            />
                        </div>
                    </div>

                    <div className='form-group'>
                        <label>Status</label>
                        <div>
                            <AntdSelect
                                options={[{value:'1', label:"Active"}, {value:'0', label:"Inactive"}]}
                                value={fd.cashfree.status}
                                onChange={v=>{
                                    fd.cashfree.status=v;
                                    setFd({...fd});
                                }}
                            />
                        </div>
                    </div>

                    <div>
                        <button type='button' className='btn btn-danger btn-red' onClick={()=>savePaymentGateways('cashfree')}>Save</button>
                    </div>
                </TabPane>

                <TabPane key="razorpay" tab={<div className='d-flex align-items-center'><div>Razorpay</div> {fd.razorpay.status===1 && <div className='pl5 text-success'><i className='fa fa-check-circle'></i></div>}</div>}>
                    <div className='form-group'>
                        <label>Branch</label>
                        <BranchesComp pg="razorpay" fd={fd} setFd={setFd} branches={props.branches} />
                    </div>

                    <div className='form-group'>
                        <label>App ID/Key</label>
                        <div>
                            <Input
                                allowClear
                                placeholder='Enter App ID/Key'
                                value={fd.razorpay.metadata.appId || ''}
                                onChange={e=>{
                                    fd.razorpay.metadata.appId=e.target.value;
                                    setFd({...fd});
                                }}
                            />
                        </div>
                    </div>

                    <div className='form-group'>
                        <label>Secret Key</label>
                        <div>
                            <Input
                                allowClear
                                placeholder='Enter Secret Key'
                                value={fd.razorpay.metadata.secret_key || ''}
                                onChange={e=>{
                                    fd.razorpay.metadata.secret_key=e.target.value;
                                    setFd({...fd});
                                }}
                            />
                        </div>
                    </div>

                    <div className='form-group'>
                        <label>Status</label>
                        <div>
                            <AntdSelect
                                options={[{value:'1', label:"Active"}, {value:'0', label:"Inactive"}]}
                                value={fd.razorpay.status}
                                onChange={v=>{
                                    fd.razorpay.status=v;
                                    setFd({...fd});
                                }}
                            />
                        </div>
                    </div>

                    <div>
                        <button type='button' className='btn btn-danger btn-red' onClick={()=>savePaymentGateways('razorpay')}>Save</button>
                    </div>
                </TabPane>

                <TabPane key="paypal" tab={<div className='d-flex align-items-center'><div>Paypal</div> {fd.paypal.status===1 && <div className='pl5 text-success'><i className='fa fa-check-circle'></i></div>}</div>}>
                    <div className='form-group'>
                        <label>Branch</label>
                        <BranchesComp pg="paypal" fd={fd} setFd={setFd} branches={props.branches} />
                    </div>

                    <div className='form-group'>
                        <label>App ID/Key</label>
                        <div>
                            <Input
                                allowClear
                                placeholder='Enter App ID/Key'
                                value={fd.paypal.metadata.appId || ''}
                                onChange={e=>{
                                    fd.paypal.metadata.appId=e.target.value;
                                    setFd({...fd});
                                }}
                            />
                        </div>
                    </div>

                    <div className='form-group'>
                        <label>Secret Key</label>
                        <div>
                            <Input
                                allowClear
                                placeholder='Enter Secret Key'
                                value={fd.paypal.metadata.secret_key || ''}
                                onChange={e=>{
                                    fd.paypal.metadata.secret_key=e.target.value;
                                    setFd({...fd});
                                }}
                            />
                        </div>
                    </div>

                    <div className='form-group'>
                        <label>Status</label>
                        <div>
                            <AntdSelect
                                options={[{value:'1', label:"Active"}, {value:'0', label:"Inactive"}]}
                                value={fd.paypal.status}
                                onChange={v=>{
                                    fd.paypal.status=v;
                                    setFd({...fd});
                                }}
                            />
                        </div>
                    </div>

                    <div>
                        <button type='button' className='btn btn-danger btn-red' onClick={()=>savePaymentGateways('paypal')}>Save</button>
                    </div>
                </TabPane>
            </Tabs>
        </div>
    )
}

const BranchesComp=(props)=>{
    const {pg, fd, setFd}=props;
    const [branches, setBranches]=useState([]);

    useEffect(()=>{
        setBranches(props.branches || []);
    }, [props.branches]);

    return (
        <div className='form-control'>
            <div className='row'>
                {branches.map((v,i)=>{
                    let ind=fd[pg].branch_ids.indexOf(v.id);
                    return(
                        <div className='col-md-3' key={i}>
                            <Checkbox 
                                checked={ind>=0}
                                onChange={(e)=>{
                                    if(ind>=0){
                                        fd[pg].branch_ids.splice(ind, 1);
                                    }
                                    if(e.target.checked){
                                        fd[pg].branch_ids.push(v.id);
                                    }
                                    setFd({...fd});
                                }}
                            >
                                {v.title}
                            </Checkbox>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}