import React, {useState, useEffect} from 'react';
import util from "../../util/util";
import {Button} from "antd";
let $=window.$;

export default function SubscriptionNew(){


    return(
        <div className="col-xl-12 col-lg-12">
            <div className="card shadow mb-4 rounded-lg">
                <div className="card-header py-3 d-flex align-items-center justify-content-between">
                    <h6 className="m-0 h5 text-dark">Subscriptions</h6>
                    <div>
                        <Button><i className="fa fa-plus mr5"></i> Add</Button>
                    </div>
                </div>

                <div className="card-body px-0">
                    <div className="main-content">
                        <div className="container-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
