import axios from "axios";
import util from "./util/util";

const axiosInstance=axios.create({
    baseURL:util.apiUrl
});
  
axiosInstance.interceptors.request.use(
    (config)=>{
        config.headers.authtoken=util.getToken();
        //config.headers.timezoneoffset=util.getTimezoneOffset();
        return config;
    },
    (error)=>{
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response)=>{
        if(response.data.code!==200){
            //throw new Error(response.data?.msg || 'Error!');
            // eslint-disable-next-line
            throw({msg:response.data?.msg || 'Error!', message:response.data?.message || response.data?.msg || 'Error!', response:response});
        }else{
            if(!response.data?.message){
                response.data.message=response.data?.msg;
            }
            return response;
        }
    }, 
    (error)=>{
        if(typeof error.response === "undefined"){
            error.response={};
        }else{
            if(error.response.data?.msg){
                error.msg=error.response.data?.msg;
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;