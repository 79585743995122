//import React from 'react';
import axios from 'axios';
import { sdx } from "../sdx";
let $=window.$;
class Util {
    constructor(){
        this.apiUrl='/';
        let protocol=window.location.protocol;
        if(window.location.href.indexOf("agnapos.com")>=0){
            this.apiUrl=protocol+"//api.agnapos.com/";
        } 
        else if(window.location.href.indexOf("agnapos.shop")>=0){
            this.apiUrl=protocol+"//api.agnapos.shop/";
        }else{
            this.apiUrl=protocol+"//"+window.location.hostname+"/agnapos/api/";
        }
        axios.defaults.baseURL=this.apiUrl;
    }

    arr=(arg)=>{
        return Array.isArray(arg) ? arg : [];
    }
    obj=(arg)=>{
        return (typeof arg != "object" || arg === null) ? {} : arg;
    }

    getDate=(d, df)=>{
        if(!d){
            d=new Date();
        }
        if(df){
            d=window.moment(d).format(df);
        }else{
            d=window.moment(d).format('DD MMM YYYY');
        }
        return d;
    }

    nv=(v)=>{
        v=$.trim(v);
        if(isNaN(v))
            v=0;
        return v*1;
    }

    getTimezoneOffset=()=>{
        var d=new Date();
        return d.getTimezoneOffset();
    }

    getTimeZone=()=>{
        var n=this.getTimezoneOffset();
        var sign=n>=0?'-':'+';
        n=Math.abs(n);

        var h=parseInt(n/60);
        var m=n%60;

        return sign+h+':'+m;
    }
    
    paramSerializeData=(data, method)=>{
        let tzKey='timezone_offset';
        let tzVal=this.getTimezoneOffset();

        if(typeof data === "object"){
            if(typeof data.append !== "function"){
                data[tzKey]=tzVal;
                if(method==='post'){
                    data=$.param(data);
                }
            }else{
                data.append(tzKey, tzVal);
            }
        }else{
            data=data+'&'+tzKey+'='+encodeURIComponent(tzVal);
        }
        return data;
    }

    ajx=(url, data, method, noloader)=>{
        if(!noloader){
            window.showLoader();
            //clearInterval(sdx.orderlist);
            //clearInterval(sdx.kitchenlist);
        }
        method=(method?method:'post').toLowerCase();

        let config={
            url: url,
            method: method,
            //withCredentials: true,
            headers:{
                authtoken:this.getToken()
            },
        }

        if(method==='post'){
            config.data=this.paramSerializeData(data?data:{}, method);
        }else{
            let rdata=this.paramSerializeData(data?data:{}, method);
            if(typeof rdata === "object"){
                config.params=data;
            }else{
                config.url=url+'?'+rdata;
            }
        }

        return axios(config).then((res)=>{
            if(!noloader){
                window.hideLoader();
            }
            if(typeof res.data.loggedOut !== "undefined"){
                this.logout();
            }

            if(typeof res.data.code !== "undefined"){
                if(typeof res.data.msg !== "undefined"){
                    if(res.data.msg){
                        window.showAlertMsg(res.data.msg, res.data.code!==200?'E':'');
                    }
                }
            }

            return this.obj(res.data);
        }).catch((error)=>{
            window.hideLoader();
            window.showAlertMsg(error, 'E');
            return {};
        });
    }
    
    setDatepicker=(obj, callback)=>{
        let ddate;
        if(typeof obj == "undefined" || !obj){
            obj=$('.hasCal');
        }
        obj.each(function() {
            let cob=$(this);
            ddate=cob.val();
            let yRange="1900:2050";
            let yr=cob.attr('year-range');
            if(yr){
                yRange=yr;
            }
            
            let maxdate=cob.attr('maxdate');
            let mindate=cob.attr('mindate');
            
            
            cob.datepicker({
                dateFormat: "dd M yy",
                changeYear: true,
                changeMonth: true,
                yearRange: yRange,
                onSelect: function(date) {
                    if(callback){
                        callback(date);
                    }
                }
            });
            
            if(cob.attr('noweekend')){
                cob.datepicker( "option", "beforeShowDay", $.datepicker.noWeekends);
            }
            if(maxdate && typeof maxdate !="undefined"){
               maxdate=new Date(cob.attr('maxdate'));
               cob.datepicker( "option", "maxDate", maxdate);
            }
            if(mindate && typeof mindate !="undefined"){
               mindate=new Date(cob.attr('mindate'));
               cob.datepicker( "option", "minDate", mindate);
            }
            
            cob.datepicker("setDate", ddate);
        });
    }

    applySelect2=(obj, text, callback)=>{
        if(!text){
            text='Select';
        }
        
        if(obj.hasClass('select2-hidden-accessible')){
            obj.select2('destroy');
        }
        $.fn.select2.defaults.set("theme", "bootstrap");
        //$.fn.select2.defaults.set("theme", "classic");
    
        if(obj.is('[multiple]')){
            obj.select2({placeholder: text, allowClear: true});
        }else{
            obj.select2({placeholder: text, allowClear: true});
        }

        if(callback){
            obj.on('select2:select', (e)=>{
                var data=e.params.data;
                callback(data.element.value);
            });
            obj.on('select2:unselect', (e)=>{
                //var data=e.params.data;
                callback('');
            });
        }
    }

    setDVal=(parentObSelector, callback, delaySeconds)=>{
        let parentOb=$("body");
        if(parentObSelector){
            parentOb=$(parentObSelector);
        }
        setTimeout(()=>{
            parentOb.find("input[dval], select[dval]").each(function(){
                $(this).val($(this).attr('dval'));
            })
            if(callback){
                callback();
            }
        }, delaySeconds || 0);
    }

    printHtmlFromIframe=(html)=>{
        $("#printiframe").attr('srcdoc', '<link rel="stylesheet" href="assets/css/iframe-print-css.css" />'+html);
        setTimeout(()=>{
            document.getElementById('printiframe').contentWindow.print();
        }, 200);
    }

    /** Auth */
    setLoginInfoLocalStorage=(token, name, role_name, client_id, branches)=>{
        window.localStorage['token']=token;
        window.localStorage['login_name']=name;
        window.localStorage['role_name']=role_name;
        window.localStorage['client_id']=client_id || '';
        window.localStorage['lang']= 'Eng';

        let bids=[], bnames={};
        if(branches){
            branches.forEach(v=>{
                bids.push(v.id); 
                bnames[v.id]=v.title;
            });
        }
        window.localStorage['branch_ids']=bids.join(",");
        window.localStorage['branch_names']=JSON.stringify(bnames);
    }
    getToken=()=>{
        return window.localStorage['token'] || '';
    }
    getLoggedName=()=>{
        return window.localStorage['login_name'];
    }
    getRoleName=()=>{
        return window.localStorage['role_name'];
    }
    getClientId=()=>{
        return window.localStorage['client_id'];
    }
    getBranchIds=()=>{
        return window.localStorage['branch_ids']?window.localStorage['branch_ids'].split(","):[];
    }
    getBranchNames=()=>{
        return JSON.parse(window.localStorage['branch_names'] || '{}');
    }
    isLogged=()=>{
        if(typeof window.localStorage['token'] !== "undefined" && window.localStorage['token']!==''){
            return true;
        }
        return false;
    }
    logout=(e, history)=>{
        if(e)e.preventDefault();
       
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('login_name');
        window.localStorage.removeItem('role_name');
        window.localStorage.removeItem('client_id');
        window.localStorage.removeItem('branch_ids');
        window.localStorage.removeItem('branch_names');

        window.sessionStorage.removeItem('start_date');
        window.sessionStorage.removeItem('end_date');
        window.sessionStorage.removeItem('branch_ids');
        window.sessionStorage.removeItem('filter_date_type');
        clearInterval(sdx.orderlist);
        clearInterval(sdx.kitchenlist);

        if(typeof history !== "undefined"){
            history.push('/');
        }else{
            window.location.reload();
        }
    }
}

export default new Util();