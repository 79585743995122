import React, { useState, useEffect } from 'react';
import util from "../../util/util";
import { useParams } from 'react-router-dom';
import { sdx } from "../../sdx";
import { If, Paging, Perpagedd ,AmtFormat} from "../../util/controls";
import fbase from "../../services/firebase";
import lang from '../../lang';
let client_id = 0, ref = {};

let $ = window.$;

export default function Sales(props) {

    client_id = props.client_id;
    const fn = {};
    const { id } = useParams();
    let pfn = props.computedMatch;
    const [d, setData] = useState({
        list: { result: [], page: {} },
        sdata: { p: 1, ps: 25 },
        data: {},
        branches: [],
        roles: [],
        role_id: 0,
        is_arabic: 0,
        curInfo: {},
        download_url : '' ,
        downloadPdf :'',
        print : '',
        branches: [],
        branchesids: [],
        branch_name : '',
        todayDate : '',
        start_date : '',
        end_date : '',
        sub_role :[],
        super_role:[],
        user_type:'',
        branchesidscopy: [],
    });
    fn.render = () => { setData({ ...d }); }

       d.todayDate = new Date();
       d.todayDate = d.todayDate.getFullYear() + "-" + (d.todayDate.getMonth() + 1) + "-" + d.todayDate.getDate();

    fn.sdata = (p, ps) => {
        
        const  start_date = $('#from_date').val();
        const  end_date = $('#to_date').val();
        d.sdata.p = p ? p : 1;
        d.sdata.ps = ps ? ps : d.sdata.ps;
        d.sdata.start_date =  start_date ? start_date : d.todayDate;
        d.sdata.end_date =  end_date ? end_date : d.todayDate;
        d.sdata.authtoken = window.localStorage['token'];
        d.sdata.branch_ids = d.branchesids;
        
        return $("#sbfrm").serialize() + '&p=' + d.sdata.p + '&ps=' + d.sdata.ps + '&client_id=' + props.client_id + '&start_date=' +d.sdata.start_date + '&end_date=' +d.sdata.end_date +'&authtoken=' +d.sdata.authtoken+'&branch_ids='+d.sdata.branch_ids;
    }

    fn.lists = (e, p, ps) => {
        if (e) e.preventDefault();
        util.ajx("reports/salesList", fn.sdata(p, ps)).then(res => {
            if (res.code === 200) {
                d.list = res.result;
                d.curInfo = res.curInfo;
                d.download_url = res.download_url;
                d.downloadPdf = res.downloadPdf;
                d.print = res.print;
                d.branches = res.branches;
                d.branchesids = res.branch_ids;
                d.branch_name = res.branch_name;
                var dd = new Date(res.start_date);
                var strDate = dd.getDate()+ "-" + (dd.getMonth() + 1) + "-" +dd.getFullYear();
                d.start_date = strDate;


                var d1 = new Date(res.end_date);
                var end_date = d1.getDate()+ "-" + (d1.getMonth() + 1) + "-" +d1.getFullYear();
                d.end_date = end_date;
                d.sub_role = sdx.d.sub_role;
                d.super_role = sdx.d.super_role;
                d.user_type = sdx.d.user_type;
                d.branchesidscopy = res.branch_ids_copy;
                if(d.branchesids.length ===  d.branchesidscopy.length  && d.branchesids.length > 1 ){
                    d.branch_name = 'All';
                }

                fn.render();
            }
        })
    }
  

    fn.printSales =(e, p, ps) =>{
        if (e) e.preventDefault();
        util.ajx("reports/printSales", fn.sdata(p, ps)).then(res => {
            $("#iframe_print").append(res.result);
            $("#iframe_print").print();
            $("#iframe_print").html('');
        })

    }

    fn.handleChange = (e) => {
        d.data[e.target.name] = e.target.value;
        fn.render();
      }
    
      fn.openForm = (dtl) => {
    
        d.data = dtl ? { ...dtl } : { branches: [] };
        // var branch_ids =d.branchesids;
        // if (branch_ids == '') {
        //      $("#branchall").attr('checked', true);
        // }
    
        if(d.branchesids.length ===  d.branchesidscopy.length){
            d.branch_name = 'All';
        }
        fn.render();
        window.showModal($("#branchModal"), true);
      }

      fn.setAllchecked = (e) => {
        let bid = e.target.value;
        if (!e.target.checked) {
          d.branchesids = [];
          d.branch_name = '';
        }else{
            d.branchesids =  d.branchesidscopy.slice() ;
            d.branch_name = 'All';
        }
        fn.render();
      }
    
      fn.openDateForm = (dtl) => {
    
        d.data = dtl ? { ...dtl } : { branches: [] };
    
        $('#from_date').val('');
        $('#to_date').val('');

        fn.render();
        window.showModal($("#dateModal"), true);
      }
    
    
      fn.setBranchCheck = (e) => {
        let bid = e.target.value;
        if (e.target.checked) {
          d.branchesids.push(bid);
        } else {
          d.branchesids.splice(d.branchesids.indexOf(bid), 1);
        }
        // d.branchesids = d.data.branches;
        d.branch_name = '';
        if(d.branchesids.length ===  d.branchesidscopy.length){
            d.branch_name = 'All';
        }

        fn.render();
      }
    
      fn.save =(e, p, ps) => {
        if (e) e.preventDefault();
        // let fd = new FormData($("#bfrm")[0]);
        util.ajx("reports/salesList", fn.sdata(p, ps)).then(res => {
          $("#branchModal").modal('hide');
          d.list = res.result;
            d.curInfo = res.curInfo;
            d.download_url = res.download_url;
            d.downloadPdf = res.downloadPdf;
            d.print = res.print;
            d.branches = res.branches;
            d.branchesids = res.branch_ids;
            d.branch_name = res.branch_name;
            var dd = new Date(res.start_date);
            var strDate = dd.getDate()+ "-" + (dd.getMonth() + 1) + "-" +dd.getFullYear();
            d.start_date = strDate;


            var d1 = new Date(res.end_date);
            var end_date = d1.getDate()+ "-" + (d1.getMonth() + 1) + "-" +d1.getFullYear();
            d.end_date = end_date;
            d.sub_role = sdx.d.sub_role;
            d.super_role = sdx.d.super_role;
            d.user_type = sdx.d.user_type;
            d.branchesidscopy = res.branch_ids_copy;
                if(d.branchesids.length ===  d.branchesidscopy.length && d.branchesids.length > 1 ){
                    d.branch_name = 'All';
                }
          fn.render();
        })
      }
    
    
      fn.applydate = (e, p, ps) => {
        if (e) e.preventDefault();
        // let fd = new FormData($("#datefrm")[0]);
        util.ajx("reports/salesList", fn.sdata(p, ps)).then(res => {
            d.list = res.result;
            d.curInfo = res.curInfo;
            d.download_url = res.download_url;
            d.downloadPdf = res.downloadPdf;
            d.print = res.print;
            d.branches = res.branches;
            d.branchesids = res.branch_ids;
            d.branch_name = res.branch_name;
            var dd = new Date(res.start_date);
            var strDate = dd.getDate()+ "-" + (dd.getMonth() + 1) + "-" +dd.getFullYear();
            d.start_date = strDate;


            var d1 = new Date(res.end_date);
            var end_date = d1.getDate()+ "-" + (d1.getMonth() + 1) + "-" +d1.getFullYear();
            d.end_date = end_date;
            d.sub_role = sdx.d.sub_role;
            d.super_role = sdx.d.super_role;
            d.user_type = sdx.d.user_type;
            $('#dateModal').modal('hide');
            d.branchesidscopy = res.branch_ids_copy;
                if(d.branchesids.length ===  d.branchesidscopy.length && d.branchesids.length > 1 ){
                    d.branch_name = 'All';
                }
          fn.render();
        })
      }

      $(".datepicker").datepicker({
        dateFormat: 'dd-mm-yy'
   });

    $("#Today").click(function() {
        var d = new Date();
        var strDate =   d.getDate()+ "-" + (d.getMonth() + 1) + "-" +d.getFullYear();

        $('#from_date').val(strDate);
        $('#to_date').val(strDate);
        $('#filter_date_type').val('');

        $('#datefilter').html(strDate + ' - ' + strDate);
        $('#filter_date_type').val('Today');
        $('li').removeClass('active');
        $('#Today').addClass('active');
        $('.datepicker').attr("disabled",true);
    });

    $("#Yesterday").click(function() {

        var d = new Date(Date.now() - 864e5);
        var strDate =   d.getDate()+ "-" + (d.getMonth() + 1) + "-" +d.getFullYear();

        $('#datefilter').html(strDate + ' - ' + strDate);

        $('#from_date').val(strDate);
        $('#to_date').val(strDate);
        $('#filter_date_type').val('');
        $('#filter_date_type').val('Yesterday');
        $('li').removeClass('active');
        $('#Yesterday').addClass('active');
        $('.datepicker').attr("disabled",true);

    });
    $("#week").click(function() {
        var now = new Date();
        var startDay = 1; //0=sunday, 1=monday etc.
        var d = now.getDay(); //get the current day
        var strDate = new Date(now.valueOf() - (d<=0 ? 7-startDay:d-startDay)*86400000); //rewind to start day
        var endDate = new Date(strDate.valueOf() + 6*86400000); //add 6 days to get last day

        strDate =  strDate.getDate()  + "-" + (strDate.getMonth() + 1) + "-" + strDate.getFullYear();
        endDate = endDate.getDate() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getFullYear() ;

        $('#datefilter').html(strDate + ' - ' + endDate);

        $('#from_date').val(strDate);
        $('#to_date').val(endDate);

        $('#filter_date_type').val('');
        $('#filter_date_type').val('week');
        $('li').removeClass('active');
        $('#week').addClass('active');
        $('.datepicker').attr("disabled",true);


    });

    $("#month").click(function() {

        var date = new Date();
        var strDate = new Date(date.getFullYear(), date.getMonth(), 1);
        
        var endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        strDate =  strDate.getDate()  + "-" + (strDate.getMonth() + 1) + "-" + strDate.getFullYear();
        endDate = endDate.getDate() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getFullYear() ;

        $('#datefilter').html(strDate + ' - ' + endDate);

        $('#from_date').val(strDate);
        $('#to_date').val(endDate);
        $('#filter_date_type').val('');
        $('#filter_date_type').val('month');
        $('li').removeClass('active');
        $('#month').addClass('active');
        $('.datepicker').attr("disabled",true);

    });

    $("#last_month").click(function() {

        // var date = new Date();
        // var strDate = new Date(date.getFullYear(), date.getMonth(), 1);
        // var endDate = new Date(date.getFullYear(), date.getMonth() , 0);
        var now = new Date();
        var endDate = new Date(now.getFullYear(), now.getMonth(), 0);
        var strDate = new Date(now.getFullYear() - (now.getMonth() > 0 ? 0 : 1), (now.getMonth() - 1 + 12) % 12, 1);
        strDate =  strDate.getDate()  + "-" + (strDate.getMonth() + 1) + "-" + strDate.getFullYear();
        endDate = endDate.getDate() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getFullYear() ;

        


        $('#datefilter').html(strDate + ' - ' + endDate);

        $('#from_date').val(strDate);
        $('#to_date').val(endDate);
        $('#filter_date_type').val('');
        $('#filter_date_type').val('last_month');
        $('li').removeClass('active');
        $('#last_month').addClass('active');
        $('.datepicker').attr("disabled",true);

    });

    $("#custom").click(function() {

        var d = new Date();
        var strDate =   d.getDate()+ "-" + (d.getMonth() + 1) + "-" +d.getFullYear();

        $('#from_date').val(strDate);
        $('#to_date').val(strDate);
        $('#from_date').addClass('datepicker');
        $('#to_date').addClass('datepicker');
        $('#filter_date_type').val('');
        $('#filter_date_type').val('custom');
        $('li').removeClass('active');
        $('#custom').addClass('active');
        $('.datepicker').attr("disabled",false);

    });



    useEffect(() => {
        fn.lists();
        $('#masterActive').removeClass('active');
        // eslint-disable-next-line
    }, []);

    return View(d, fn);

}

function View(d, fn) {
    return (
         <>
            <div className="">
            <div className="dashboard-top-filter">
                <i className="fas fa-filter mr-3"></i>
                <If cond={d.branches.length > 1}>
                <button type="button" id="branch_name" className="btn btn-secondary mr-2" onClick={() => fn.openForm()}>
                { d.branch_name}</button>
                </If>

                <If cond={d.branches.length == 1}>
                <button type="button" id="branch_name" className="btn btn-secondary mr-2" >
                { d.branch_name}</button>
                </If>
                <button type="button" className="btn btn-secondary" id="datefilter" onClick={() => fn.openDateForm()}>
                {d.start_date} - {d.end_date}</button>
            </div>


            <div className="modal fade" id="branchModal" role="dialog" aria-labelledby="branchModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="branchModalLabel">{lang('Branch')} </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div className="modal-body">
                    <form id="bfrm" autoComplete="off" spellCheck="false">
                        <div className="form-control mb5" >
                        <label className="m-0 noselect">
                            <input type="checkbox" className="selectall"  value='' id='branchall' checked={d.branch_name == 'All' ? true : false } onClick={fn.setAllchecked}  />
                        &nbsp;&nbsp;{lang('All')}
                    </label>
                        </div>

                        {d.branches.map((r, i) => (
                        <div className="form-control mb5" key={i}>
                            <label className="m-0 noselect">
                            <input type="checkbox" className="selectall" name="branches[]" checked={d.branchesids?.includes(r.id) ? true : false}  id={'branch_' + r.id} value={r.id} onChange={fn.setBranchCheck} />
                            &nbsp;&nbsp;{r.title}
                            </label>
                        </div>
                        ))}

                        <input type="hidden" name="start_date" value={window.sessionStorage['start_date']} />
                        <input type="hidden" name="end_date" value={window.sessionStorage['end_date']} />
                    </form>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-danger btn-red" onClick={fn.save} >{lang('Apply')}</button>
                    </div>
                </div>
                </div>
            </div>

            <div className="modal fade" id="dateModal" role="dialog" aria-labelledby="dateModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="dateModalLabel">Branch </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div className="modal-body">

                        <ul className="nav nav-pills mb-3 order-tab mT12 horizontal-scroll">
                            <li className="nav-item" id='Today' ><a className="nav-link text-dark-grey active" data-toggle="tab">{lang('Today')}</a></li>
                            <li className="nav-item" id='Yesterday' ><a className="nav-link text-dark-grey" data-toggle="tab"  >{lang('Yesterday')}</a></li>
                            <li className="nav-item" id="week" ><a className="nav-link text-dark-grey" data-toggle="tab">{lang('This Week')}</a></li>
                            <li className="nav-item" id="month"><a className="nav-link text-dark-grey"data-toggle="tab"  >{lang('This Month')}</a></li>
                            <li className="nav-item" id="last_month"><a className="nav-link text-dark-grey" data-toggle="tab" >{lang('Last Month')}</a></li>
                            <li className="nav-item" id='custom' ><a className="nav-link text-dark-grey" data-toggle="tab" >{lang('Custom Date')}</a></li>
                            {/* <li id='all' ><a data-toggle="tab" >All</a></li> */}
                        </ul>
                        <form id="datefrm" autoComplete="off" spellCheck="false">
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <div><label className="req">{lang('From')}</label></div>
                                    <input className="datepicker form-control" disabled="true" id="from_date" name="start_date" value={d.start_date} data-date-format="dd-mm-yy" />
                                </div>

                                <div className="form-group col-md-6">
                                    <div><label className="req">{lang('To')}</label></div>
                                    <input className="datepicker form-control" id="to_date" disabled="true" name="end_date" value={d.end_date} data-date-format="dd-mm-yy" />
                                </div>
                            </div>

                        </form>

                    </div>
                    <div className="modal-footer">
                    {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button> */}
                    <button type="button" className="btn btn-danger w120 text-uppercase fw600 btn-height36 fs14 btn-red" onClick={fn.applydate} >{lang('Apply')}</button>
                    </div>
                </div>
                </div>
            </div>

                
                <div className="col-xl-12 col-lg-12">
                    <div className="mt-5">
                        <If cond={!client_id}>
                            <div className="d-flex flex-row align-items-center justify-content-between pl-4 pr-4 pt-4">
                                <h6 className="m-0 h5 text-dark">{lang('Total Sales')}</h6>
                            </div>
                        </If>
                        <div className={client_id ? "" : "main-content"}>
                            <div className={client_id ? "" : "container-fluid"}>
                                <If cond={d.list.result.length && !client_id}>
                                    {/* <div className="d-flex">
                                            <div className="text-secondary bold600 mb5">
                                                Showing {d.list.page.start + 1} - {d.list.page.start + d.list.page.total} of {d.list.page.total_records} records.
                                </div>
                                        </div> */}
                                </If>

                                        <form id="sbfrm" onSubmit={fn.lists} autoComplete="on" spellCheck="false">
                                            
                                <div className="d-flex justify-content-between">
                                    <div className="form-inline">
                                            <div className="input-group search-control">
                                                <input type="search" className="form-control mr-3" name="k" placeholder="Search" />
                                                <button className="btn btn-red-outline btn-danger" type="submit"><i className="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                            
                                            <div className="d-flex">
                                                <div className="form-inline">
                                                    <select className="form-control ml5" name="orders_type"
                                                    onChange={e => { d.sdata.orders_type = e.target.value; fn.lists(); }}>
                                                        <option value="">{lang('All')}</option>
                                                        <option value="Dining">{lang('Dine-In')} </option>
                                                        <option value="Deliver">{lang('Deliver')}</option>
                                                        <option value="Pickup">{lang('Pickup')}</option>

                                                    </select>

                                                    <select className="form-control ml5" name="orders_status"
                                                    onChange={e => { d.sdata.orders_status = e.target.value; fn.lists(); }}>
                                                        <option value="">{lang('All')}</option>
                                                        <option value="New">{lang('New')} </option>
                                                        <option value="In Progress">{lang('In Progress')} </option>
                                                        <option value="Delivered">{lang('Delivered')}</option>
                                                        <option value="Cancelled">{lang('Cancelled')}</option>

                                                    </select>
                                                </div>
                                                <If cond={d.sub_role && d.sub_role[31] == 1 || d.user_type !='STAFF'}>
                                                    <div className="form-inline">
                                                        <div className="input-group search-control">
                                                            <a class="btn btn-md btn-outline-success noround" href={d.download_url} target="_self">
                                                                    <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                                                            </a>
                                                        </div>
                                                    </div>

                                                    <div className="form-inline ml-2">
                                                        <div className="input-group search-control">
                                                            <button className="btn btn-md btn-outline-primary noround" onClick={fn.printSales}><i className="fa fa-print"></i> </button>
                                                        </div>
                                                    </div>

                                                </If>
                                            </div>
                                    </div>
                                </form>

                                    {/* <div className="form-inline">
                                        <div className="input-group search-control">
                                        <a class="btn btn-md btn-outline-success noround" href={d.downloadPdf} target="_self">
                                                     
                                                <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                </a>
                                             
                                        </div>
                                    </div> */}


                                <div className="mt-4">
                                    <If cond={d.list.result.length}>
                                        <div className="table-responsive">
                                            <table className="table border m-0">
                                                <thead className="bg-light text-uppercase font-sm">
                                                    <tr>
                                                        <th className="w20"> {lang('SN')}</th>
                                                        <th className="w20"> {lang('Branch')}</th>
                                                        <th className="w80">{lang('customer Name')}</th>
                                                        <th className="w80">{lang('Order ID')}</th>
                                                        <th className="w80">{lang('Items')}</th>
                                                        <th className="w80">{lang('Quntity')}</th>
                                                        <th className="w80">{lang('Price')}</th>
                                                        <th className="w80">{lang('Order Type')}</th>
                                                        <th className="w80">{lang('Order Status')}</th>
                                                        <th className="w80">{lang('Orderd Date')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-text-vmid">
                                                    {d.list.result.map((v, i) => (
                                                        <tr key={i}>
                                                            <td>{d.list.page.start + i + 1}.</td>
                                                            <td>{v.branchname}</td>
                                                            <td className="text-black fw600">{v.name}</td>
                                                            <td>{v.id}</td>
                                                            <td>
                                                            {v.items.map((r, j) => (
                                                                    <div key={j} className="clearfix">
                                                                        {r.item_name}&nbsp;&nbsp;({r.qty})
                                                                    </div>
                                                                    ))}

                                                            </td>
                                                            <td>{v.qty}</td>
                                                            <td> <AmtFormat curInfo={d.curInfo} amt={v.total_amt} /></td>
                                                            <td >{v.order_type}</td>
                                                            <td >{v.status}</td>
                                                            <td>{v.created}</td>
                                                            
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <If cond={!client_id}>
                                            <div className="d-flex justify-content-between mt-4 pb-3">
                                                <div className="mr5">
                                                    <Paging fn={fn.lists} pageob={d.list.page} />
                                                </div>
                                                <div>
                                                    <Perpagedd fn={fn.lists} />
                                                </div>
                                            </div>
                                        </If>
                                    </If>
                                    <If cond={!d.list.result.length}>
                                        <div className="no-rec">{lang('No record found')}</div>
                                    </If>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="iframe_print" ></div>
        </>
    )
}
