import React, {useState, useEffect} from 'react';
import util from "../../util/util";
import {lang_preferance} from "../../services/data";
import {If, Paging, Perpagedd} from "../../util/controls";
let $=window.$;

export default function PickupMaster() {
    const fn={};
    const [d, setData]=useState({
        list:{result:[], page:{}},
        sdata:{p:1, ps:25},
        data:{},
        branches:[],
        roles:[],
        order_types:[],
        items:{result:[], page:{}},
        dining:[],
        deliver:{result:[], page:{}},
        pickup:{result:[], page:{}},
        totalqty:[],
        totalamt:[],
        lang_preferance: lang_preferance,
        name :[],
        mob :[],
        email :[],

    });
    fn.render=()=>{setData({...d});}

    fn.handleChange=(e)=>{
        d.data[e.target.name]=e.target.value;
        fn.render();
    }

    fn.sdata=(p, ps)=>{
        d.sdata.p=p?p:1;
        d.sdata.ps=ps?ps:d.sdata.ps;
        return $("#sfrm").serialize()+'&p='+d.sdata.p+'&ps='+d.sdata.ps;
    }

    fn.lists=(e, p, ps)=>{
        if(e)e.preventDefault();
        util.ajx("cmaster/pickupMaster", fn.sdata(p, ps)).then(res=>{
            if(res.code===200){
                if(d.sdata.orders_type == 'Deliver' || d.sdata.orders_type == 'Pickup' || d.sdata.orders_type == 'Dining'){
                    d.list=res.alldata;
                }else{
                    d.list=res.alldata;
                }
               // d.list=res.result;
                d.order_types = res.order_types;
                fn.render();
            }
        })
    }

    fn.openForm=(dtl)=>{
        d.data=dtl?{...dtl}:{};
        console.log(dtl);
        util.ajx("cmaster/get_order_details_by_user/"+dtl.uid+'/'+dtl.item_id).then(res=>{
            if (res.code===200) {
                d.items=res.result;
                d.totalamt=res.totalamt;
                d.totalqty=res.totalqty;
                d.dining=res.Dining;
                d.pickup=res.Pickup;
                d.deliver=res.Deliver;
                d.name=res.name;
                d.email=res.email;
                d.mob=res.mob;

                fn.render();fn.render();
                window.showModal($("#formModal"), true);
            }
        })
        
        
    }

    fn.init=()=>{
        util.ajx("cmaster/pickupMaster").then(res=>{
            if(res.code===200){
                if(d.sdata.orders_type == 'Deliver' || d.sdata.orders_type == 'Pickup' || d.sdata.orders_type == 'Dining'){
                    d.list=res.alldata;
                }else{
                    d.list=res.alldata;
                }

                console.log(res);
                d.order_types = res.order_types;
                fn.render();
            }
        })
    }
    useEffect(()=>{
        fn.init();
        // eslint-disable-next-line
    }, []);

    useEffect(()=>{
        setData({...d, lang_preferance: lang_preferance});
    }, [lang_preferance])
    
    return View(d, fn);
}

function View(d, fn){
    return(
        <>

            <div className="col-xl-12 col-lg-12">
                <div className="card shadow mb-4 rounded-lg">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 h5 text-dark">Order Master</h6>
                       
                    </div>
                    <div className="card-body px-0">
                        <div className="main-content">
                            <div className="container-fluid">
                                <If cond={d.list.result.length}>
                                    <div className="d-flex">
                                        <div className="text-secondary bold600 mb5">
                                            Showing {d.list.page.start+1} - {d.list.page.start+d.list.page.total} of {d.list.page.total_records} records.
                                        </div>
                                    </div>
                                </If>

                                <div className="d-flex tbl-search-head">
                                    <div className="form-inline">
                                        <form id="sfrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                                            <input type="search" className="form-control" name="k" placeholder="Search" />
                                            <select className="form-control ml5" name="b_id" onChange={fn.handleChange}>
                                                <option value="">Branch (All)</option>
                                                {d.branches.map(r=><option key={r.id} value={r.id}>{r.title}</option>)}
                                            </select>
                                            <select className="form-control ml5" name="orders_type"
                                                    onChange={e => { d.sdata.orders_type = e.target.value; fn.lists(); }}>
                                                    <option value="">All</option>
                                                     <option value="Dining">Dining</option>
                                                    <option value="Deliver">Deliver</option>
                                                    <option value="Pickup">Pickup</option>
                                                   
                                                </select>
                                            
                                            <button className="btn btn-light-blue ml5" type="submit"> <i className="fa fa-search"></i> Search</button>
                                        </form>
                                    </div>

                                    <div className="ml-auto mt-auto mb-auto">
                                    </div>
                                </div> 
                                <If cond={d.sdata.orders_type != 'Deliver' && d.sdata.orders_type != 'Pickup' && d.sdata.orders_type != 'Dining'}>
                                
                                <div>
                                    <If cond={d.list.result.length}>
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-sm table-striped table-hover m-0">
                                                <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                                    <tr>
                                                        {/* <th className="w20">SN</th> */}
                                                        <th className="w200">Name</th>
                                                        <th className="w200">User ID</th>
                                                        <th className="w200">Email</th>
                                                        <th className="w200">Phone Number</th>
                                                        <th className="w200">Item Name</th>
                                                         <If cond={d.sdata.orders_type == 'Deliver'}>
                                                            <th className="w200">Address</th>
                                                            <th className="w200">Driver</th>
                                                        </If> 
                                                         <If cond={d.sdata.orders_type == 'Pickup'}> 
                                                            <th className="w200">Pickup Type</th>
                                                            <th className="w200">Car Details</th>
                                                        </If> 

                                                        <th className="w80">Order count</th>
                                                        <th className="w80">Total Visit</th>
                                                        <th className="w200">Last order</th>
                                                        <th className="w70">Order Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-text-vmid">
                                                    {d.list.result.map((v, i)=>(
                                                            <tr key={i}  onClick={()=>fn.openForm(v)}>
                                                                {/* <td>{d.list.page.start+i+1}.</td> */}
                                                                <td>{v.name}</td>
                                                                <td>{v.uid}</td>
                                                                <td>{v.email}</td>
                                                                <td>{v.mob}</td>
                                                                <td>{v.item_name}</td>
                                                                <If cond={d.sdata.orders_type == 'Deliver'}>
                                                                    <td>{v.address}</td>
                                                                    <td>{v.driver_name}</td>
                                                                </If>
                                                                <If cond={d.sdata.orders_type == 'Pickup'}>
                                                                    <td>{v.pickup_type}</td>
                                                                    <td>{v.car_detail}</td>
                                                                </If>
                                                                <td>
                                                                {v.ordercount}  
                                                                </td>
                                                                <td>
                                                                {v.total_visit}  
                                                                </td>
                                                                <td> {v.order_date} 
                                                                </td>
                                                                <td>{v.order_type}</td>
                                                            </tr>
                                                      
                                                        
                                                        
                                                            
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex tbl-foot-bx">
                                            <div className="mr5">
                                                <Paging fn={fn.lists} pageob={d.list.page} />
                                            </div>
                                            <div>
                                                <Perpagedd fn={fn.lists} />
                                            </div>
                                        </div>
                                    </If>
                                </div>
                                </If>
                                <If cond={d.sdata.orders_type == 'Deliver' || d.sdata.orders_type == 'Pickup' || d.sdata.orders_type == 'Dining'}>
                                <div>
                                    <If cond={d.list.result.length}>
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-sm table-striped table-hover m-0">
                                                <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                                    <tr>
                                                        <th className="w20">SN</th>
                                                        <th className="w200">Name</th>
                                                        <th className="w200">Email</th>
                                                        <th className="w200">Phone Number</th>
                                                        <th className="w200">Item Name</th>
                                                         <If cond={d.sdata.orders_type == 'Deliver'}>
                                                            <th className="w200">Address</th>
                                                            <th className="w200">Driver</th>
                                                        </If> 
                                                         <If cond={d.sdata.orders_type == 'Pickup'}> 
                                                            <th className="w200">Pickup Type</th>
                                                            <th className="w200">Car Details</th>
                                                        </If> 

                                                        <th className="w80">Order count</th>
                                                        <th className="w80">Total Visit</th>
                                                        <th className="w200">Last order</th>
                                                        <th className="w70">Order Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-text-vmid">
                                                    {d.list.result.map((v, i)=>(
                                                            <tr key={i}>
                                                                <td>{d.list.page.start+i+1}.</td>
                                                                <td>{v.name}</td>
                                                                <td>{v.email}</td>
                                                                <td>{v.mob}</td>
                                                                <td>{v.item_name}</td>
                                                                <If cond={d.sdata.orders_type == 'Deliver'}>
                                                                    <td>{v.address}</td>
                                                                    <td>{v.driver_name}</td>
                                                                </If>
                                                                <If cond={d.sdata.orders_type == 'Pickup'}>
                                                                    <td>{v.pickup_type}</td>
                                                                    <td>{v.car_detail}</td>
                                                                </If>
                                                                <td>
                                                                {v.ordercount}  
                                                                </td>
                                                                <td>
                                                                {v.total_visit}  
                                                                </td>
                                                                <td> {v.order_date} 
                                                                </td>
                                                                <td>{v.order_type}</td>
                                                            </tr>
                                                      
                                                        
                                                        
                                                            
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex tbl-foot-bx">
                                            <div className="mr5">
                                                <Paging fn={fn.lists} pageob={d.list.page} />
                                            </div>
                                            <div>
                                                <Perpagedd fn={fn.lists} />
                                            </div>
                                        </div>
                                    </If>
                                </div>
                            </If>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal modal-scroll" id="formModal" data-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <div className="modal-title uc">Order Details</div> <br /><br />
                        
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                        <span>Name :{d.name}</span> <br /><br />
                        <span>Email :{d.email}</span> <br /><br />
                        <span>Phone :{d.mob}</span> <br /><br />
                        <hr className="minus1rem mt8" />

                                    
                        {d.items.result.map((v, i) => (
                        
                       <div>
                             <If cond={v.item.length}>
                            <span>Date & Time : {v.created}</span> <br />
                            <span>Order No : {v.odr_no} </span> <br />
                            <span>Mode : {v.order_type}</span> <br />
                            <span>Payment Mode : {v.pay_mode}</span> <br />

                          
                            <Items item={v.item} qty={v.qty} amt={v.amt} curInfo={d.curInfo} lang_pref={d.lang_preferance} />
                            
                        

                        <If cond={v.order_type == 'Pickup'}>
                            <span>Pickup Type : ({v.pickup_type || ''})</span><br />
                            <span>Car Details : ({v.car_detail || '' })</span>
                        </If>
                       
                        <If cond={v.order_type == 'Deliver'}>
                            <span>Delivery Address : ({v.address || ''})</span><br />
                            <span>Driver : ({v.driver_name || '' })</span><br />
                            {/* <span>Delivery Charges : ({v.car_detail || '' })</span> */}
                            <span>Driver commission  : ({v.driver_commission || '' })</span><br />
                        </If>
                        <hr className="minus1rem mt8" />
                        </If>
                        </div>
                         ))}    

                        
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
       

          </>
    )
}



function Items(props) {
    return (
        
        <table className="table table-sm table-bordered m-0 fix-tfoot">
                            
        <thead className="thead-white text-uppercase table-text-vmid font-sm">
            <tr>
                <th className="w20">SN</th>
                <th>Item</th>
                <th className="w60 text-center">Qty</th>
                <th className="w60 text-center">Amt</th>
            </tr>
        </thead>

                <tbody className="table-text-vmid">
                    {props.item.map((r, j) => (
                        <tr key={j}>
                            <td>{j + 1}.</td>
                            <td className={`${props.lang_pref == 'ARB' ? 'text-right' : ''}`}>
                                {props.lang_pref.toUpperCase() == 'ARB' && <span>
                                    {r.item_name_arb}
                                </span>}
                                {props.lang_pref.toUpperCase() != 'ARB' &&  <span>
                                    {r.item_name}
                                </span>}

                                <If cond={r.size_name}>
                                    <span> ({r.size_name})</span>
                                </If>
                            </td>
                            <td className="text-center">{r.qty}</td>
                            <td className="text-right">
                            {r.amt} 
                            </td>
                        </tr>
                    ))}
                </tbody>
                 <tfoot className="thead-white">
                 <tr>
                     <th></th>
                     <th>Total</th>
                     <th className="text-center">{props.qty}</th>
                     <th className="text-right nowrap">{props.amt} 
                     </th>
                 </tr>
             </tfoot>
               
             <br />
             </table>
           
    )
}