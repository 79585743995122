import React, { useState, useEffect } from 'react';
import util from "../../util/util";
import { lang_preferance } from "../../services/data";
import PrintInvoice from "./print-invoice";
import RefundPayment from "./refund-payment";
import RecordPayment from "./record-payment";
import { If, Paging, Perpagedd, AmtFormat } from "../../util/controls";
import fbase from "../../services/firebase";
import { sdx } from "../../sdx";
import { Radio } from 'antd';
import lang from '../../lang';

let $ = window.$;
let client_id = 0, ref = {};
let fbaseFlg = 0;

function playAudio(audio_notification) {
    return; //no need more
    $(".new-odr-cover").remove();
    $("#content").append(`<div class="new-odr-cover uc shadow">
        New Order
        <div class="pt10">
            <button class="btn btn-danger btn-block" type="button" onClick='$(".new-odr-cover").remove(); $(".sound")[0].pause()'>OK</button>
        </div>
    </div>`);
    
    $(".sound")[0].muted=false;
    $(".sound")[0].currentTime = 0;
    //if (audio_notification == '0') {
        $(".sound")[0].crossOrigin = 'anonymous';
        $(".sound")[0].play();
    //}
}

export default function Orders(props) {
    client_id = props.client_id;
    const fn = {};
    const urlpath = props.path;
    // console.log(props.path);
    const [fbaseLoggedIn, setFbaseLoggedIn]=useState(fbase.auth().currentUser?true:false);
    const [d, setData] = useState({
        list: { result: [], page: {} },
        sdata: { p: 1, ps: 25, status: 'New' },
        branches: [],
        drivers: [],
        data: [],
        curInfo: {},
        activeOrderId: null,
        printInvoiceTime: null,
        image_url : null,
        activeOrder: { id: null },
        lang_preferance: lang_preferance,
        card_details: [],
        online_payment_details: [],
        online_payment_setup:0,
        refund_payment: 0,
        sub_role :[],
        super_role:[],
        user_type:'',
        new_order :[],
        inProgress_order :[],
        complete_order :[],
        cancelled_order :[],
        hold_order :[],
        ready_order :[],
        items:[],
        audio_notification : 0,
        subscription_id:[],
        date_type:'',
        index : '',
        billing_type : '',
        hold_order_status : 0,
    });


    const sdata = (p, ps) => {
        d.sdata.p = p ? p : 1;
        d.sdata.ps = ps ? ps : d.sdata.ps;
        d.date_type = d.sdata.date_type;
        return $("#sfrm").serialize() + '&p=' + d.sdata.p + '&ps=' + d.sdata.ps + '&status=' + d.sdata.status;
    }

    const lists = (e, p, ps) => {
        if (e) e.preventDefault();
        util.ajx("orders/list", sdata(p, ps)).then(res => {
            if (res.code === 200) {
                d.list = res.result;
                d.card_details = res.card_details;
                d.online_payment_details = res.online_payment_details;
                d.online_payment_setup =res.online_payment_setup;
                $('#masterActive').removeClass('active');
                d.new_order = res.new_order;
                d.inProgress_order = res.inProgress_order;
                d.complete_order = res.complete_order;
                d.cancelled_order = res.cancelled_order;
                d.hold_order = res.hold_order;
                d.ready_order = res.ready_order;
                d.audio_notification = res.audio_notification;
                d.billing_type = res.billing_type;
                d.hold_order_status = res.hold_order_status.hold_order
                /* sdx.orderlist =  setInterval(() => {
                    lists()
                }, 30000); */
                $('.modal-scroll').modal("hide");
                
                setData({ ...d });

            }
        })
    }

    const setStatus = (status) => {
        d.sdata.status = status;
        lists();
    }

    const deleteOrder =(rob)=>{
        let callback = () => {
            util.ajx("orders/deleteOrder", { id: rob.id }).then(res => {
                if (res.code === 200) {
                    lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this order?", callback);

    }

    const updateOrderStatus = (rob, status) => {
        let callback = () => {
            util.ajx("orders/updateOrderStatus", { id: rob.id, status }).then(res => {
                if (res.code === 200) {
                    if(status == "New"){
                        fbase.sendMsg("agnapos_orders_" + res.client_id, { 
                            odr_id: rob.id, 
                            branch_id: rob.branch_id, 
                            type: status,
                            // is_confirm : 0,
                         });
                    
                    }
                    lists();
                }
            })
        }

        window.openConfirmBootbox("Confirmation", "Are you sure to mark as " + (status) + " this order?", callback);
    }

    const onNewOrder=(client_id) => {
        fbaseFlg = 0;
        fbase.onNewMsg("agnapos_orders_" + client_id, (msgs) => {
            if (!fbaseFlg) {
                fbaseFlg = 1;
                return;
            }

            if (msgs.length) {
                if (msgs[0].branch_id * 1 === d.sdata.branch_id * 1 && msgs[0].is_confirm == 0 && sdx.d.role_id != 10) {
                    switch (msgs[0].type) {
                        case 'New':
                            if (d.sdata.status === 'New') {
                                playAudio(d.audio_notification);
                                $("#sfrm [name='k']").val('');
                                lists();
                            }
                            break;
                        default:
                    }
                } else if (msgs[0].branch_id * 1 === d.sdata.branch_id * 1 && msgs[0].is_confirm == 1 && sdx.d.role_id == 10 && msgs[0].driver_id == sdx.d.user_id) {
                    switch (msgs[0].type) {
                        case 'New':
                            if (d.sdata.status === 'New') {
                                playAudio(d.audio_notification);
                                $("#sfrm [name='k']").val('');
                                lists();
                            }
                            break;
                        default:
                    }
                }
            }
        });
    }

    const allowAudio = () => {
        if (d.audio_notification == '0') {
            try {
                $(".sound")[0].muted = true;
                    $(".sound")[0].crossOrigin = 'anonymous';
                    $(".sound")[0].crossOrigin = 'anonymous';
                    $(".sound")[0].play();
                    setTimeout(() => {
                        $(".sound")[0].pause();
                        //$(".sound").prop("muted", false);
                        $(".sound")[0].muted = false;
                    }, 2000);

                /*let callback = () => {
                    //$("body .sound").remove();
                    //$("body").append(`<audio src="sound.mp3" loop="true" className="d-none sound" muted></audio>`);
                    $(".sound")[0].muted = true;
                    $(".sound")[0].play();
                    setTimeout(() => {
                        $(".sound")[0].pause();
                        //$(".sound").prop("muted", false);
                        $(".sound")[0].muted = false;
                    }, 2000);

                    util.ajx("orders/set_audio_notification/" + d.sdata.branch_id).then(res => { })

                }*/
                // window.openConfirmBootbox("Audio", "Off audio notification?", callback);
                // window.bootboxAlert("Audio", "Allow audio notification?", callback);

            } catch (e) {
            }

        } else {
            try {
                $(".sound")[0].muted = true;
                // $(".sound")[0].play();
                setTimeout(() => {
                    $(".sound")[0].pause();
                    //$(".sound").prop("muted", false);
                    $(".sound")[0].muted = false;
                }, 2000);
                
                // window.bootboxAlert("Audio", "Allow audio notification?", callback);

            } catch (e) {
            }

        }
    }
    fn.render = () => { setData({ ...d }); }

    fn.handleChange = (e) => {
        d.data[e.target.name] = e.target.value;

        fn.render();
    }


    fn.openConfirmForm = (dtl) => {
        d.data = dtl ? { ...dtl } : { client_id: props.client_id };


        util.ajx("orders/get_order_details/" + dtl.id).then(res => {
            if (res.code === 200) {
                d.data = res.result;
                d.drivers = res.drivers;
                d.mobile_digit_length = sdx.d.mobile_digit_length;
                fn.render();
                //window.draggable('#confirmModal');
                window.showModal($("#confirmModal"), true);
            }
        })

    }

    fn.openOrderDetails = (dtl) => {
        console.log(dtl);
        d.data = dtl ? { ...dtl } : { client_id: props.client_id };
        d.items = d.data.items;
        fn.render();
        //window.draggable('#viewOrderModal');
        window.showModal($("#viewOrderModal"), true);


        // util.ajx("orders/get_order_details/" + dtl.id).then(res => {
        //     if (res.code === 200) {
        //         d.data = res.result;
        //         d.drivers = res.drivers;
        //         fn.render();
        //     }
        // })

    }

    fn.confirmorder = () => {
        let fd = new FormData($("#confirmorderform")[0]);
        util.ajx("orders/confirmorder", fd).then(res => {
            if (res.code === 200) {
                $("#confirmModal").modal('hide');
                fbase.sendMsg("agnapos_orders_" + res.client_id, 
                { odr_id: res.id,
                     branch_id: res.branch_id, 
                    type: 'New',
                    order_type :res.order_type,
                    confirm_order :res.confirm_order,
                    is_confirm : 1,
                    driver_id : res.driver_id,
                 });
                    

                lists();
            }
        })
    }

     fn.cancelRefresh = (id) => {
        $("#"+id).modal('hide');
        if(id){
            lists();
        }
        
        
    }
    fn.openAddNoteForm = (dtl) => {
        console.log(dtl);
        d.data = dtl ? { ...dtl } : { client_id: props.client_id };
        d.data.add_order_note = '';
        fn.render();
        window.showModal($("#addNoteModal"), true);

    }

    fn.addOrderNote = () => {
        let fd = new FormData($("#addnoteform")[0]);
        util.ajx("orders/addOrderNotes", fd).then(res => {
            
            if (res.code === 200) {
                $("#addNoteModal").modal('hide');
                lists();
            }
        })
    }



    fn.openCancelForm = (dtl) => {
        d.data = dtl ? { ...dtl } : { client_id: props.client_id };
        d.data.cancellation_reason = '';
        d.refund_payment = 0;
        d.return_to_stock='Y';
        fn.render();
        window.showModal($("#cancelModal"), true);

    }

    fn.cancelorder = () => {
        let fd = new FormData($("#cancelorderform")[0]);
        fd.append('return_to_stock', d.return_to_stock);

        let id = $('#cancel_id').val();
        let cancellation_reason = $('#cancellation_reason').val();
        let status = 'Cancelled';
        util.ajx("orders/updateOrderStatus", fd).then(res => {
            if (res.code === 200) {
                $("#cancelModal").modal('hide');
                lists();
            }
        })
    }

    fn.recordPayment = (rob) => {
        window.showLoader();
        util.ajx("orders/record_payment", d).then(res => {
            if (res.code === 200) {
                props.orderList();
                $('#recordPaymentModal').modal('hide');
            }
        })
    }

    fn.set_refund_payment = (e) => {
        if (e.target.checked) {
            d.refund_payment = 1;

        } else {
            d.refund_payment = 0;
        }
        fn.render();
    }

    const init = async () => {
        //let fbaseLogged = window.sessionStorage['fbaseLogged'] || '';
        //if (fbaseLogged !== 'Y') {
            /* window.showLoader();
            await fbase.signin("testagna@agnacare.com", "123456");
            window.sessionStorage['fbaseLogged'] = 'Y'; */
        //}

        util.ajx("orders/list/1", sdata()).then(res => {
            if (res.code === 200) {
                d.list = res.result;
                d.branches = res.branches;
                d.curInfo = res.curInfo;
                d.sdata.branch_id = d.branches[0].id;
                // if(d.list.result[0]){
                    d.audio_notification = res.audio_notification;
                // }
                
                d.card_details = res.card_details;
                d.online_payment_details = res.online_payment_details;
                d.online_payment_setup =res.online_payment_setup;
                d.sub_role = sdx.d.sub_role;
                d.super_role = sdx.d.super_role;
                d.user_type = sdx.d.user_type;
                d.subscription_id = sdx.d.subscription_id;
                d.new_order = res.new_order;
                d.inProgress_order = res.inProgress_order;
                d.complete_order = res.complete_order;
                d.cancelled_order = res.cancelled_order;
                d.hold_order = res.hold_order;
                d.ready_order = res.ready_order;
                d.billing_type = res.billing_type;
                d.hold_order_status = res.hold_order_status.hold_order

                if(sdx.orderlist){
                    clearInterval(sdx.orderlist);
                }
                sdx.orderlist =  setInterval(() => {
                    if(!fbaseLoggedIn){
                        lists()
                    }
                }, 30000);
                
                $('.modal-scroll').modal("hide");

                setData({ ...d });
                if(sdx.d.sub_role && ( sdx.d.sub_role[18] != 1 && sdx.d.sub_role[19] != 1 && sdx.d.sub_role[20] != 1 && sdx.d.sub_role[21] != 1 && sdx.d.sub_role[22] != 1 && sdx.d.sub_role[23] != 1 && sdx.d.sub_role[24] != 1 && sdx.d.sub_role[32] != 1 && sdx.d.sub_role[33] != 1 && sdx.d.sub_role[34] != 1  && sdx.d.sub_role[41] != 1)  && sdx.d.user_type =='STAFF'){
                    util.logout('', props.history);
                }

                if(d.subscription_id && ( d.subscription_id[3] != 1)  && d.user_type =='CLIENT'){
                    util.logout('', props.history);
                }

                if(sdx.d.sub_role && sdx.d.sub_role[23] == 1 || d.user_type !='STAFF'){
                    //allowAudio();
                    if(urlpath == '/orders'){
                        onNewOrder(res.client_id);
                    }
                }
              
                $('#masterActive').removeClass('active');
            }
        })
    }

    useEffect(() => {
        init();
       
        // allowAudio();
        return () => {
            if(sdx.orderlist){
                clearInterval(sdx.orderlist);
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setData({ ...d, lang_preferance: lang_preferance });
    }, [lang_preferance]);




    return (
        <>
            <div className="container-fluid1">
                <div className="clear"></div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="order-tabs">
                            <div className="row">
                                <div className="col-sm-12 col-md-3 col-lg-2 top-master">
                                    <div className="mb-3 text-uppercase text-black fw700 fs12">{lang('Order Status')}</div>
                                    <ul className="nav nav-pills flex-column custom-tab order-tab" id="myTab" role="tablist">
                                        {/* <li className="nav-item positive-relative">
                                            <a className="nav-link" id="" data-toggle="tab" href="" role="tab" aria-controls="app" aria-selected="true">
                                                Active <span className="badge badge-light">5</span>
                                            </a>
                                        </li> */}
                                        <li className="nav-item positive-relative">
                                            <a className="nav-link active" id="app-tab" data-toggle="tab" href="#app" role="tab" aria-controls="app" aria-selected="true" onClick={() => setStatus('New')}>
                                            {lang('Active')} <span className="badge badge-light">{d.new_order.noId}</span>
                                            </a>
                                        </li>
                                        <li className="nav-item positive-relative">
                                            <a className="nav-link" id="app-tab" data-toggle="tab" href="#app" role="tab" aria-controls="app" aria-selected="true" onClick={() => setStatus('In Progress')}>
                                            {lang('In Progress')} <span className="badge badge-light">{d.inProgress_order.noId}</span>
                                            </a>
                                        </li>
                                        <li className="nav-item positive-relative">
                                            <a className="nav-link" id="app-tab" data-toggle="tab" href="" role="tab" aria-controls="app" aria-selected="true" onClick={() => setStatus('Ready')}>
                                            {lang('Ready')} <span className="badge badge-light">{d.ready_order.noId }</span>
                                            </a>
                                        </li>
                                        <li className="nav-item positive-relative">
                                            <a className="nav-link" id="mh-tab" data-toggle="tab" href="#app" role="tab" aria-controls="mh" aria-selected="false" onClick={() => setStatus('Delivered')}>
                                            {lang('Completed')} <span className="badge badge-light">{d.complete_order.noId}</span>
                                            </a>
                                        </li>
                                        <li className="nav-item positive-relative">
                                            <a className="nav-link" id="pres-tab" data-toggle="tab" href="#app" role="tab" aria-controls="pres" aria-selected="false" onClick={() => setStatus('Cancelled')}>
                                            {lang('Cancelled')} <span className="badge badge-light">{d.cancelled_order.noId }</span>
                                            </a>
                                        </li>
                                        <If cond={(d.sub_role && d.sub_role[50] == 1 || d.user_type !='STAFF') && d.hold_order_status == 1 }>
                                            <li className="nav-item positive-relative">
                                                <a className="nav-link" id="pres-tab" data-toggle="tab" href="#app" role="tab" aria-controls="pres" aria-selected="false" onClick={() => setStatus('Hold')}>
                                                {lang('Hold')} <span className="badge badge-light">{d.hold_order.noId }</span>
                                                </a>
                                            </li>

                                        </If>
                                        
                                    </ul>
                                </div>

                                <div className="offset-lg-2 col-lg-10 offset-md-3 col-md-9 col-sm-12 right-master">
                                    <div className="d-flex tbl-search-head1 pb10 pt20 pl20">
                                        <div className="form-inline">
                                            <form id="sfrm" onSubmit={lists} autoComplete="off" spellCheck="false">
                                                <input type="search" className="form-control mr-3" name="k" placeholder="Search" />
                                                <select className="form-control mr-3" name="branch_id" value={d.sdata.branch_id}
                                                    onChange={e => { d.sdata.branch_id = e.target.value; lists(); }}>
                                                    {d.branches.map(r => <option key={r.id} value={r.id}>{r.title}</option>)}
                                                </select>

                                                <select className="form-control mr-3" name="orders_type" value={d.sdata.orders_type}
                                                    onChange={e => { d.sdata.orders_type = e.target.value; lists(); }}>
                                                    <option value="">{lang('All')}</option>
                                                    <option value="Dining">{lang('Dine-In')} </option>
                                                    <option value="Deliver">{lang('Deliver')}</option>
                                                    <option value="Pickup">{lang('Pickup')}</option>

                                                </select>

                                                <select className="form-control mr-3" name="date_type" defaultValue={d.sdata.date_type}
                                                    onChange={e => { d.sdata.date_type = e.target.value; lists(); }}>
                                                    <option value="all">{lang('All')}</option>
                                                    <option value="today" selected>{lang('Today')} </option>
                                                    <option value="yesterday">{lang('Yesterday')}</option>
                                                    <option value="week">{lang('This Week')}</option>
                                                    <option value="months">{lang('This Month')}</option>
                                                    <option value="last_month">{lang('Last Month')}</option>
                                                </select>

                                                <button className="btn btn-red-outline btn-danger" type="submit"> <i className="fa fa-search"></i></button>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="tab-content">
                                        <div className="tab-pane active pr-2" id="app" role="tabpanel" aria-labelledby="app-tab">
                                            <If cond={d.list.result.length}>
                                                <div className="pl20">
                                                    {d.list.result.map((v, i) => (
                                                        <React.Fragment key={i}>
                                                            <div className="border mt-2 p-2 order-box">
                                                                <div className="d-flex justify-content-start align-items-center ">
                                                                        <If cond={ v.uid !='admin'}>
                                                                                <i class="fa fa-qrcode" aria-hidden="true"></i>
                                                                        </If>
                                                                    <div className="custom-w20">
                                                                        <div className="badge-order-position">
                                                                            <If cond={v.payment_status == 1 && v.refund_payment_status != 1}>
                                                                                <span className="badge badge-success badge-sm white-space"><i className="fa fa-check-circle mr-1"></i>{lang('Paid')}</span>
                                                                            </If>
                                                                            <If cond={v.refund_payment_status == 1}>
                                                                                <span className="badge badge-success badge-sm white-space"><i className="fa fa-check-circle mr-1"></i>{lang('Refunded')}</span>
                                                                            </If>
                                                                            <If cond={v.payment_status == 0}>
                                                                                <span className="badge badge-danger badge-sm white-space">{lang('Not-Paid')}</span>
                                                                            </If>
                                                                        </div>
                                                                        <If cond={v.name || v.mob}>
                                                                            <div className="fs16 height28 text-black font-weight-bold m-0 single-line-dotted">{v.name}
                                                                                {/* <If cond={v.mob}>
                                                                                        - {v.mob}
                                                                                    </If> */}
                                                                            </div>
                                                                        </If>
                                                                        <div>#{v.odr_no}</div>
                                                                    </div>
                                                                    <div className="custom-w20">
                                                                        <div>
                                                                            <If cond={v.order_type == 'Dining' && v.status != 'Hold' }>
                                                                                <h6 className="pb-2 text-black font-weight-bold m-0">{v.area_name}&nbsp;&nbsp;<span>Table No: {v.table_no || 'N/A'}</span></h6>
                                                                            </If>
                                                                            <If cond={v.order_type != 'Dining'}>
                                                                                <h6 className="pb-2 text-black font-weight-bold m-0">{v.order_type}</h6>
                                                                            </If>
                                                                        </div>
                                                                        <div>{v.created}</div>
                                                                    </div>
                                                                    <div className="d-flex m-auto">
                                                                        <If cond={v.status != 'Cancelled' && v.status != 'Hold'}>
                                                                            <If cond={d.sub_role && d.sub_role[24] == 1 || d.user_type !='STAFF'}>
                                                                                <If cond={v.order_type != 'Dining' && v.confirm_order == 0 && v.uid !='admin' }>
                                                                                    <button className="rounded-pill btn btn-sm btn-danger btn-red mb-1 white-space" onClick={() => fn.openConfirmForm(v)}>{v.confirm_order == 0 ? 'Accept Order' : ''} </button>
                                                                                </If>
                                                                            </If>
                                                                            <If cond={v.confirm_order == 1 || v.uid =='admin' || v.order_type == 'Dining'}>
                                                                                <button className="rounded-pill btn btn-sm btn-success mb-1 white-space" disabled>{lang('Order confirmed')}</button>
                                                                            </If>
                                                                        </If>
                                                                        {/* d.data.order_type == 'Deliver' */}
                                                                    </div>
                                                                    <div className="d-flex m-auto"> 
                                                                        <If cond={v.order_type == 'Deliver' && v.confirm_order == 1}> 
                                                                        
                                                                        {lang('Driver')} - {v.drivername}
                                                                        
                                                                        </If>

                                                                        <If cond={v.order_type == 'Pickup' && v.confirm_order == 1 && v.pickup_type == 'Drive-through'}> 
                                                                        
                                                                        {lang('Vehicle No')} - {v.car_detail}
                                                                
                                                                        </If>
                                                                    </div>
                                                                    <div className="d-flex ml-auto">
                                                                    <If cond={d.sub_role && d.sub_role[21] == 1 || (d.user_type !='STAFF')}>
                                                                        <If cond={v.status === 'New' || v.status === 'In Progress'}>
                                                                            <div className="btn-group ml-2">
                                                                            
                                                                                <If cond={v.status === 'New' }>
                                                                                    <button className="dropdown-toggle btn btn-sm btn-danger btn-redgrey-outline btn-block nowrap" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                        {v.status} Order
                                                                                    </button>
                                                                                </If>
                                                                                <If cond={v.status === 'In Progress'}>
                                                                                    <button className="dropdown-toggle btn btn-sm btn-danger btn-redgrey-outline btn-block nowrap" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                        {v.status}
                                                                                    </button>
                                                                                </If>
                                                                               
                                                                                    <div className="dropdown-menu">
                                                                                    <If cond={v.confirm_order == 1 || v.uid =='admin' || v.order_type == 'Dining'}>
                                                                                        <If cond={v.status === 'In Progress' && sdx.d.role_id != 10}>
                                                                                            <a className="dropdown-item" onClick={e => updateOrderStatus(v, 'New')} >{lang('New Order')}</a>
                                                                                        </If>
                                                                                        
                                                                                        <If cond={v.status === 'New' && sdx.d.role_id != 10}>
                                                                                            <a className="dropdown-item" onClick={e => updateOrderStatus(v, 'In Progress')} >{lang('In Progress')}</a>
                                                                                        </If>
                                                                                        <a className="dropdown-item" onClick={e => updateOrderStatus(v, 'Ready')} >{lang('Ready')}</a>
                                                                                        <If cond={d.sub_role && d.sub_role[34] == 1 || (d.user_type !='STAFF')}>
                                                                                            <a className="dropdown-item" onClick={e => updateOrderStatus(v, 'Delivered')} >{lang('Delivered')}</a>
                                                                                        </If>
                                                                                       </If>
                                                                                       <If cond={d.sub_role && d.sub_role[20] == 1 || (d.user_type !='STAFF')}>
                                                                                            <a className="dropdown-item" onClick={() => { fn.openCancelForm(v); clearInterval(sdx.orderlist);} } >{lang('Cancelled')}</a>
                                                                                       </If>
                                                                                    </div>

                                                                               
                                                                                
                                                                            </div>
                                                                        </If>
                                                                        <If cond={v.status === 'Ready'}>
                                                                            <div className="btn-group ml-2">
                                                                                <button className="dropdown-toggle btn btn-sm btn-danger btn-redgrey-outline btn-block nowrap" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    {v.status}
                                                                                </button>
                                                                                <div className="dropdown-menu">
                                                                                    <If cond={ sdx.d.role_id != 10}>
                                                                                        <a className="dropdown-item" onClick={e => updateOrderStatus(v, 'New')} >{lang('New Order')}</a>
                                                                                        <a className="dropdown-item" onClick={e => updateOrderStatus(v, 'In Progress')}>{lang('In Progress')}</a>
                                                                                    </If>
                                                                                    <If cond={d.sub_role && d.sub_role[34] == 1 || (d.user_type !='STAFF')}>
                                                                                        <a className="dropdown-item" onClick={e => updateOrderStatus(v, 'Delivered')} >{lang('Delivered')}</a>
                                                                                    </If>
                                                                                    <If cond={d.sub_role && d.sub_role[20] == 1 || (d.user_type !='STAFF')}>
                                                                                        <a className="dropdown-item" onClick={() => { fn.openCancelForm(v); clearInterval(sdx.orderlist);} } >{lang('Cancelled')}</a>
                                                                                    </If>
                                                                                </div>
                                                                            </div>
                                                                        </If>

                                                                        
                                                                        <If cond={v.status === 'Cancelled'}>
                                                                            <div className="btn-group ml-2">
                                                                                <button className="dropdown-toggle btn btn-sm btn-danger btn-redgrey-outline btn-block nowrap" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    {v.status}
                                                                                </button>
                                                                                {/* <If cond={v.refund_payment_status != 1}>
                                                                                    <div className="dropdown-menu">
                                                                                        <If cond={ sdx.d.role_id != 10}>
                                                                                            <a className="dropdown-item" onClick={e => updateOrderStatus(v, 'New')} >New Order</a>
                                                                                            <a className="dropdown-item" onClick={e => updateOrderStatus(v, 'In Progress')}>In Progress</a>
                                                                                        </If>
                                                                                        <a className="dropdown-item" onClick={e => updateOrderStatus(v, 'Ready')} >Ready</a>
                                                                                        <a className="dropdown-item" onClick={e => updateOrderStatus(v, 'Delivered')} >Delivered</a>
                                                                                    </div>
                                                                                </If> */}
                                                                                <If cond={v.refund_payment_status != 1 && v.payment_status == 1}>
                                                                                    <If cond={d.sub_role && d.sub_role[32] == 1 || d.user_type !='STAFF'}>
                                                                                        <div className="dropdown-menu">
                                                                                            <a className="dropdown-item"  onClick={e => { $('#refundPaymentModal').modal(); setData({...d, activeOrder: v});}} >{lang('Refund')}</a>
                                                                                        </div>
                                                                                    </If>
                                                                                </If> 
                                                                            </div>
                                                                        </If>
                                                                        </If>

                                                                        <If cond={v.status === 'Delivered' && (d.sub_role && d.sub_role[41] == 1 || (d.user_type !='STAFF'))}>
                                                                            <div className="btn-group ml-2">
                                                                                <button className="dropdown-toggle btn btn-sm btn-danger btn-redgrey-outline btn-block nowrap" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    {v.status}
                                                                                </button>
                                                                                <div className="dropdown-menu">
                                                                                    <If cond={ sdx.d.role_id != 10}>
                                                                                        <a className="dropdown-item" onClick={e => updateOrderStatus(v, 'New')} >{lang('New Order')}</a>
                                                                                        <a className="dropdown-item" onClick={e => updateOrderStatus(v, 'In Progress')}>{lang('In Progress')}</a>
                                                                                    </If>
                                                                                    <a className="dropdown-item" onClick={e => updateOrderStatus(v, 'Ready')} >{lang('Ready')}</a>
                                                                                    <If cond={d.sub_role && d.sub_role[20] == 1 || (d.user_type !='STAFF')}>
                                                                                        <a className="dropdown-item" onClick={() => { fn.openCancelForm(v); clearInterval(sdx.orderlist);} } >{lang('Cancelled')}</a>
                                                                                    </If>
                                                                                </div>
                                                                            </div>
                                                                        </If>

                                                                        <If cond={d.sub_role && d.sub_role[22] == 1 || d.user_type !='STAFF'}>
                                                                            <div className="ml-2"> <button className="btn btn-red-outline btn-sm"  onClick={() => {fn.openOrderDetails(v); clearInterval(sdx.orderlist);}}>{lang('View Detail')}</button></div>
                                                                        </If>

                                                                        <If cond={v.status != 'Cancelled' }>
                                                                            <If cond={d.sub_role && d.sub_role[33] == 1 || d.user_type !='STAFF'}>
                                                                            <If cond={v.status != 'Hold' }>
                                                                                <div className="ml-2">
                                                                                    <button type="submit" className="btn btn-red-outline btn-sm"
                                                                                        onClick={() => {
                                                                                            setData({ ...d, activeOrderId: v.id, printInvoiceTime: new Date().getTime(),index:i ,date_type : v.date_type,image_url : v.image_url});
                                                                                            sdx.orderlist =  setInterval(() => {
                                                                                                if(!fbaseLoggedIn){
                                                                                                    lists();
                                                                                                }
                                                                                            }, 30000);
                                                                                            
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa fa-print"></i>
                                                                                        <If cond={v.image_url}>
                                                                                            <img style={{display: 'none'}} src={v.image_url} alt="" />
                                                                                        </If>
                                                                                    </button>
                                                                                </div>
                                                                                </If>
                                                                            </If>
                                                                            
                                                                            <div className="ml-2">
                                                                                <div className="dropdown">
                                                                                    <If cond={d.sub_role &&( d.sub_role[19] == 1 || d.sub_role[18] == 1 || d.sub_role[34] == 1|| d.sub_role[20] == 1 )|| d.user_type !='STAFF'}>
                                                                                        <a className="btn btn-red-outline btn-sm" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                            <i className="fas fa-ellipsis-v"></i>
                                                                                        </a>
                                                                                    </If>

                                                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                                        <If cond={v.status != 'Cancelled' && v.status != 'Hold' }>
                                                                                            <If cond={d.sub_role && d.sub_role[19] == 1 || d.user_type !='STAFF'}>
                                                                                                {v.payment_status == 0 && <a className="dropdown-item" onClick={e => { $('#recordPaymentModal').modal();  $('#manualPayment').css('display', 'block');$('#activate_link').css('display', 'none');$(".card_chk").prop( "checked", false ); clearInterval(sdx.orderlist); setData({ ...d, activeOrder: v }); /* updatePaymentStatus(v); */ }}>{lang('Payment')}</a>}
                                                                                            
                                                                                            </If>

                                                                                            <If cond={(v.status != 'Ready' ) || (v.status == 'Ready' && v.order_type == 'Dining')}>

                                                                                                    <If cond={d.sub_role && d.sub_role[18] == 1 || (d.user_type !='STAFF' && sdx.d.role_id != 10)}>
                                                                                                         {  v.pay_mode != 'ONLINE' && v.refund_payment_status != 1  && <a className="dropdown-item" href={"/menu/" + v.id}>{lang('Modify')}</a>}
                                                                                                    </If>
                                                                                            </If>
                                                                                            

                                                                                        </If>
                                                                                        <If cond={v.status == 'Hold'}>
                                                                                            <a className="dropdown-item" href={"/menu/" + v.id}>{lang('Continue Order')}</a>
                                                                                            <a className="dropdown-item" onClick={e => deleteOrder(v)}>{lang('Delete Order')}</a>
                                                                                        </If>
                                                                                        <If cond={v.status == 'New' || v.status === 'In Progress' || v.status === 'Ready'}>
                                                                                            <If cond={v.confirm_order == 1 || v.uid =='admin' || v.order_type == 'Dining'}>

                                                                                                <If cond={d.sub_role && d.sub_role[34] == 1 || d.user_type !='STAFF'}>
                                                                                                    <a className="dropdown-item" onClick={e => updateOrderStatus(v, 'Delivered')}>{lang('Deliver')}</a>
                                                                                                </If>
                                                                                            </If>
                                                                                            <If cond={d.sub_role && d.sub_role[20] == 1 || d.user_type !='STAFF'}>
                                                                                                <a className="dropdown-item" onClick={e =>{ fn.openCancelForm(v); clearInterval(sdx.orderlist);} }>{lang('Cancel')}</a>
                                                                                            </If>
                                                                                            

                                                                                        </If>
                                                                                        <If cond={d.sub_role && d.sub_role[20] == 1 || d.user_type !='STAFF'}>
                                                                                                <a className="dropdown-item" onClick={e =>{ fn.openAddNoteForm(v); clearInterval(sdx.orderlist);} }>{lang('Add Note')}</a>
                                                                                            </If>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </If>
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                                <div className="d-flex justify-content-between mt-4 pb-3 pl20">
                                                    <If cond={d.list.page?.total_pages > 1 || 1}>
                                                        <div className="d-flex tbl-foot-bx1">
                                                            <Paging fn={lists} pageob={d.list.page} />
                                                        </div>
                                                        <div>
                                                            <Perpagedd fn={lists} />
                                                        </div>
                                                    </If>
                                                </div>
                                            </If>
                                            <If cond={!d.list.result.length}>
                                                <div className="no-rec">{lang('No record found')}</div>
                                            </If>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clear"></div>

                    <RecordPayment order={d.activeOrder} orderList={lists} curInfo={d.curInfo} card_details={d.card_details} online_payment_details={d.online_payment_details}  online_payment_setup ={d.online_payment_setup} billing_type={d.billing_type} />
                    <RefundPayment order={d.activeOrder} orderList={lists} curInfo={d.curInfo} card_details={d.card_details} />
                    <PrintInvoice orderId={d.activeOrderId} printInvoiceTime={d.printInvoiceTime} curInfo={d.curInfo} dateType={d.date_type} index={d.index} image_url = {d.image_url} />
                </div>
            </div>

            <ConfirmForm d={d} fn={fn} />
            <CancelForm d={d} fn={fn} />
            <ViewOrders d={d} fn={fn} />
            <AddNoteForm d={d} fn={fn} />

            
            
        </>

    )
}

function Items(props) {
    return (
        <div>
            <hr></hr>
            <div className="fs14 fw600 mb-1">{lang('Items')}</div>
            <If cond={props.items}>
            <div>
                {props.items.map((r, j) => (
                    <div className="pdTB4" key={j}>
                        <span className="text-center text-black fw500">{r.qty} X &nbsp;</span>
                        <span className={`${props.lang_pref == 'ARB' ? 'text-right' : ''}`}>
                            {props.lang_pref.toUpperCase() == 'ARB' && <span>
                                {r.item_name_arb}
                            </span>}
                            {props.lang_pref.toUpperCase() != 'ARB' && <span className="text-black fw500">
                                {r.item_name}
                            </span>}

                            <If cond={r.size_name}>
                                <span className="text-black fw500"> ({r.size_name})</span>
                            </If>


                        </span>
                        <If cond={r.spicy_level}>
                            <div>    <span className="text-black fw500"> (<b>{lang('Spicy Level')}</b> : {r.spicy_level})</span> </div>
                        </If>

                        <If cond={r.add_on_id}>
                            <div>
                                <span className="text-black fw500"> (<b>{lang('Add-Ons')}</b> : {r.add_on_id})</span>
                            </div>
                        </If>


                        <If cond={r.ingredients_id}>
                            <div>
                                <span className="text-black fw500"> (<b>{lang('Excluded Ingredients')}</b> : {r.ingredients_id})</span>
                            </div>
                        </If>


                    </div>
                ))}
            </div>

            </If>
            
            <hr></hr>
            <div>
                <div className="fs14 fw600 mb-1">{lang('Grand Total')}</div>
                <div className="text-black fw600"><AmtFormat curInfo={props.curInfo} amt={props.total_amt} /></div>
            </div>
        </div>
    )
}


function ConfirmForm(props) {
    let d = props.d, fn = props.fn;
    return (
        <div className="modal modal-scroll no-backdrop" id="confirmModal" data-backdrop="static">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{lang('Order Details')}</div>
                        <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={() => { fn.cancelRefresh('confirmModal'); }}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body mxh-auto">
                        <form id="confirmorderform" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" defaultValue={d.data.id} />
                            <input type="hidden" name="branch_id" defaultValue={d.data.branch_id} />
                            <input type="hidden" name="order_type" defaultValue={d.data.order_type} />
                            <div className="row mingap">
                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Customer Name')}</label>
                                    <input type="text" className="form-control" name="name"  readOnly="readOnly"  value={d.data.name || ''} onChange={fn.handleChange} />
                                </div>

                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Mobile Number')}</label>
                                    <input type="text" className="form-control" name="mob"  readOnly="readOnly" maxLength={d.mobile_digit_length} value={d.data.mob || ''} onChange={fn.handleChange} />
                                </div>
                                <div className="form-group col-md-12">
                                    <label className="">{lang('Email')}</label>
                                    <input type="text" className="form-control" name="email" value={d.data.email || ''} onChange={fn.handleChange} />
                                </div>
                                <If cond={d.data.order_type == 'Pickup'}>
                                    <div className="form-group col-md-12">
                                        <label className="">{lang('Pickup Order Type')}</label>
                                        <input type="text" disabled="disabled" className="form-control" name="pickup_type" value={d.data.pickup_type || ''} onChange={fn.handleChange} />
                                    </div>
                                    <If cond={d.data.pickup_type == 'Drive-through'}>
                                        <div className="form-group col-md-12">
                                            <label className="">{lang('Car Details')}</label>
                                            <input type="text" className="form-control" name="car_detail" value={d.data.car_detail || ''} onChange={fn.handleChange} />
                                        </div>
                                    </If>
                                </If>
                                <If cond={d.data.order_type == 'Deliver'}>
                                    <div className="form-group col-md-12">
                                        <label className="">{lang('Area')}</label>
                                        <input type="text" readOnly="readOnly" className="form-control" name="area_name" value={d.data.area_name || ''} onChange={fn.handleChange} />
                                    </div>

                                    <div className="form-group col-md-12">
                                        <label className="req">{lang('Address')}</label>
                                        <input type="text" className="form-control" name="address" value={d.data.address || ''} onChange={fn.handleChange} />
                                    </div>

                                    <div className="form-group col-md-12">
                                        <label className="req">{lang('Pin Code')}</label>
                                        <input type="text" className="form-control" name="pin_code" value={d.data.pin_code || ''} onChange={fn.handleChange} />
                                    </div>

                                    <div className="form-group col-md-12">
                                        <label className="">{lang('Landmark')}</label>
                                        <input type="text" className="form-control" name="landmark" value={d.data.landmark || ''} onChange={fn.handleChange} />
                                    </div>
                                </If>
                                <div className="form-group col-md-12">
                                    <label>{lang('Remarks')}</label>
                                    <input type="text" className="form-control" name="remarks" value={d.data.remarks || ''} onChange={fn.handleChange} />
                                </div>
                                <If cond={d.data.order_type == 'Deliver'}>
                                    <div className="form-group col-md-12">
                                        <label className="">{lang('Driver')}</label>
                                        <select className="form-control" name="driver_id" value={d.data.driver_id || ''} onChange={fn.handleChange}>
                                            <option value="">{lang('Select')}</option>
                                            {d.drivers.map(r => <option key={r.id} value={r.id}>{r.name}</option>)}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label className="">{lang('Driver Commission')}</label>
                                        <input type="text" className="form-control" name="driver_commission" value={d.data.driver_commission || ''} onChange={fn.handleChange} />
                                    </div>
                                </If>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={() => { fn.cancelRefresh('confirmModal'); }} >{lang('Close')}</button>
                        <If cond={d.data.confirm_order == 0}>   <button type="button" className="btn btn-primary" onClick={fn.confirmorder}>{lang('Confirm Order')}</button> </If>
                    </div>
                </div>
            </div>
        </div>
    )
}

//Satyendra
function CancelForm(props) {
    let d = props.d, fn = props.fn;
    return (
        <div className="modal modal-scroll no-backdrop" id="cancelModal" data-backdrop="static">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{lang('Confirmation')}</div>
                        <button type="button" className=""  aria-label="Close" onClick={() => { fn.cancelRefresh('cancelModal'); }} ><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body mxh-auto">
                        <form id="cancelorderform" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" id="cancel_id" defaultValue={d.data.id} />
                            <input type="hidden" name="order_type" defaultValue={d.data.order_type} />
                            <input type="hidden" name="status" defaultValue='Cancelled' />

                            <div className="row mingap">
                                <div>
                                    <span>{lang('Are you sure to mark as Cancelled this order')}?</span>
                                </div>
                                <div></div>
                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Reason for cancellation')}</label>
                                    <input type="text" className="form-control" name="cancellation_reason" id='cancellation_reason' value={d.data.cancellation_reason || ''} onChange={fn.handleChange} />
                                </div>
                            </div>
                            <If cond={d.data.payment_status == 1}>
                                <If cond={d.sub_role && d.sub_role[32] == 1 || d.user_type !='STAFF'}>
                                    <div className="form-control mb5">
                                        <label className="m-0 noselect">
                                            <input type="checkbox" name="refund_payment" value={d.refund_payment} checked={d.refund_payment == 1 ? true : false} onChange={fn.set_refund_payment} />
                                            &nbsp;&nbsp;{lang('Do you want to refund it')}?
                                        </label>
                                    </div>
                                </If>
                            </If>

                            {d.refund_payment == 1 && <div>
                                <div className="">
                                    <h4 className="">{lang('Refund Payment')}</h4>
                                </div>
                                <div className="">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <label>{lang('Customer Name')}</label>
                                                <input type="text" className="form-control" readOnly={true} value={d.data.name || ''} />
                                            </div>
                                            <div className="col-md-4">
                                                <label>{lang('Mobile No')}.</label>
                                                <input type="text" className="form-control" readOnly={true} value={d.data.mob || ''} />
                                            </div>
                                        </div>
                                        <hr className="minus14" />
                                    </div>
                                    <table className="table table-bordered table-sm fc-sm align-middle">
                                        <tbody>
                                            <tr>
                                                <td className="w200">{lang('Total')}</td>
                                                <td className="bold text-right"> <AmtFormat curInfo={d.curInfo} amt={d.data.total_amt} /></td>
                                            </tr>
                                            <tr>
                                                <td>{lang('Cash Refund')}</td>
                                                <td>
                                                    <input type="text" className="form-control numpad text-right" style={{ borderRadius: 0 }} maxLength={10} placeholder="Enter Amount" name="cash_refund" value={d.data.cash_refund || ''} onChange={fn.handleChange} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{lang('Bank Refund')}</td>
                                                <td>
                                                    <input type="text" className="form-control numpad text-right" style={{ borderRadius: 0 }} maxLength={10} placeholder="Enter Amount" name="bank_refund" value={d.data.bank_refund || ''} onChange={fn.handleChange} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{lang('Card Refund')}</td>
                                                <td>
                                                    <div>
                                                        {d.card_details.map((r, i) => (

                                                            <label className="btn btn-md btn-outline-info noround" style={{ margin: '0px 5px 0px 0px' }} key={r.id}>
                                                                <input type="radio" defaultValue={r.id} onChange={fn.handleChange} name="refund_card_id" className="card_chk mr-1" />
                                                                {r.name}
                                                            </label>
                                                        ))}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{lang('Refund Done By')}</td>
                                                <td>
                                                    <input type="text" className="form-control numpad text-right" style={{ borderRadius: 0 }} name="refund_done_by" value={d.data.refund_done_by || localStorage.getItem('login_name')}   readOnly={true} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>}

                            <div className='pt15'>
                                <div className='d-flex'>
                                    <div>
                                        <Radio 
                                            name='return_to_stock' 
                                            checked={d.return_to_stock==='Y'} 
                                            onChange={(e)=>{
                                                d.return_to_stock=e.target.checked?'Y':'N';
                                                fn.render();
                                            }}
                                        >
                                            {lang('Return To Stock')}
                                        </Radio>
                                    </div>
                                    <div>
                                        <Radio 
                                            name='return_to_stock' 
                                            checked={d.return_to_stock==='N'} 
                                            onChange={(e)=>{
                                                d.return_to_stock=e.target.checked?'N':'Y';
                                                fn.render();
                                            }}
                                        >
                                            {lang('Wastage')}
                                        </Radio>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={() => { fn.cancelRefresh('cancelModal'); }}  >{lang('No')}</button>
                        <button type="button" className="btn btn-primary" onClick={fn.cancelorder}>{lang('Yes')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


function AddNoteForm(props) {
    let d = props.d, fn = props.fn;
    return (
        <div className="modal modal-scroll no-backdrop" id="addNoteModal" data-backdrop="static">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{lang('Add Note')}</div>
                        <button type="button" className=""  aria-label="Close" onClick={() => { fn.cancelRefresh('addNoteModal'); }} ><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body mxh-auto">
                        <form id="addnoteform" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" id="order_id" defaultValue={d.data.id} />
                            <input type="hidden" name="order_type" defaultValue={d.data.order_type} />
                            {/* <input type="hidden" name="status" defaultValue='Cancelled' /> */}

                            <div className="row mingap">
                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Note')}</label>
                                    <input type="text" className="form-control" name="add_order_note" id='add_order_note' value={d.data.add_order_note|| ''}   onChange={fn.handleChange} />
                                </div>
                            </div>
                            
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={() => { fn.cancelRefresh('addNoteModal'); }}  >{lang('No')}</button>
                        <button type="button" className="btn btn-primary" onClick={fn.addOrderNote}>{lang('Yes')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


function ViewOrders(props) {
    let d = props.d, fn = props.fn;
    return (
        <div className="modal modal-scroll no-backdrop" id="viewOrderModal" data-backdrop="static">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title">{lang('Order Details')}</div>
                        <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={() => { fn.cancelRefresh('viewOrderModal'); }}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body mxh-auto">
                            <div
                                className="card-header o-t-h p-3 d-flex flex-row align-items-center justify-content-between bg-dark text-white">
                                <div className="m-0 h6 font-weight-bold">
                                    <div>#{d.data.odr_no}</div>
                                </div>
                                <div className="text-right">
                                    <div className="text-xs font-weight-bold">{d.data.created}</div>
                                    <If cond={d.data.payment_status == 1 && d.data.refund_payment_status != 1}>
                                        <button className="rounded-pill btn btn-sm btn-success ml-auto mt-1" disabled><i className="fa fa-check-circle mr-1"></i>{lang('Paid')}</button>
                                    </If>
                                    <If cond={d.data.refund_payment_status == 1}>
                                        <button className="rounded-pill btn btn-sm btn-info ml-auto mt-1" disabled><i className="fa fa-check-circle mr-1"></i>{lang('Refunded')}</button>
                                    </If>
                                    <If cond={d.data.payment_status == 0}>
                                        <button className="rounded-pill btn btn-sm btn-danger btn-red ml-auto mt-1" disabled>{lang('Not-Paid')}</button>
                                    </If>
                                </div>
                            </div>
                            <div className="o-t-b p-0 py-2 border-1 border border-color-info border-radius-bottom-25"
                                style={{ paddingBottom : "0px !important" }}>
                                <div className="col">
                                    <div className="mt-2 d-flex justify-content-between align-items-center">
                                        <div>
                                            <If cond={d.data.order_type == 'Dining'}>
                                                <h6 className="py-2 text-black font-weight-bold m-0">{d.data.area_name}&nbsp;&nbsp;<span>{lang('Table No')}: {d.data.table_no || 'N/A'}</span></h6>
                                            </If>
                                            <If cond={d.data.order_type != 'Dining'}>
                                                <h6 className="py-2 text-black font-weight-bold m-0">{d.data.order_type}</h6>
                                            </If>
                                        </div>
                                    </div>

                                    <div className="fs14 fw600 mb-1">{lang('Items')}</div>
                                        <div>
                                            {d.items.map((r, j) => (
                                                <div className="pdTB4" key={j}>
                                                    
                                                    <span className={`${d.lang_preferance == 'ARB' ? 'text-right' : ''}`}>
                                                        {d.lang_preferance.toUpperCase() == 'ARB' && <span>
                                                            {r.item_name_arb}
                                                        </span>}
                                                        {d.lang_preferance.toUpperCase() != 'ARB' && <span className="text-black fw500">
                                                            {r.item_name}
                                                        </span>}

                                                        <If cond={r.size_name}>
                                                            <span className="text-black fw500"> ({r.size_name})</span>
                                                        </If>


                                                    </span>
                                                    
                                                    <span className="text-center text-black fw500">&nbsp;&nbsp;<AmtFormat curInfo={d.curInfo} amt={r.price} /></span>
                                                    <span className="text-center text-black fw500">&nbsp;&nbsp;x {r.qty} </span>
                                                    <span className="text-center text-black fw500">&nbsp;&nbsp;= <AmtFormat curInfo={d.curInfo} amt={r.qty_price} /> </span>
                                                    

                                                    <If cond={r.combo_id}>
                                                          {r.combo_items.length > 0 && r.combo_items.map((y, u) => (  <div> <span className="text-black fw500"> ( {y.title}  <If cond={y.size_name}>{'- '+y.size_name}</If> )</span> </div>)) } 
                                                    </If>

                                                    <If cond={r.spicy_level}>
                                                        <div>    <span className="text-black fw500"> (<b>{lang('Spicy Level')}</b> : {r.spicy_level})</span> </div>
                                                    </If>

                                                    <If cond={r.add_on_id}>
                                                        <div>
                                                            <span className="text-black fw500"> (<b>{lang('Add-Ons')}</b> : {r.add_on_id})</span>
                                                        </div>
                                                    </If>


                                                    <If cond={r.ingredients_id}>
                                                        <div>
                                                            <span className="text-black fw500"> (<b>{lang('Excluded Ingredients')}</b> : {r.ingredients_id})</span>
                                                        </div>
                                                    </If>


                                                </div>
                                            ))}
                                            
                                        <div className="font-italic fs12 mt-3 text-grey">
                                        {lang('Remarks')} : <span className="text-danger">{d.data.remarks || 'N/A'}</span>
                                        </div>
                                        </div>
                                </div>
            
                                <hr></hr>
                                
                                <If cond={d.data.order_type == 'Deliver'}>
                                    <div className="px-3">
                                        <span className="">{lang('Delivery Charges')} </span>
                                        <span style={{ display: 'inline-block', float: 'right'}}><AmtFormat curInfo={d.curInfo} amt={d.data.delivery_amount} /></span>
                                    </div>
                                </If> 
                                <If cond={d.data.tip_amt}>
                                    <div className="px-3">
                                        <span className="">{lang('Tip')}  </span>
                                        <span style={{ display: 'inline-block', float: 'right'}}><AmtFormat curInfo={d.curInfo} amt={d.data.tip_amt} /></span>
                                    </div>

                                </If> 
                                <div className="px-3">
                                    <span className="">{lang('Tax')}  </span>
                                    <span style={{ display: 'inline-block', float: 'right'}}><AmtFormat curInfo={d.curInfo} amt={d.data.tax} /></span>
                                </div>
                                <div className="px-3">
                                    <span className="">{lang('Discount')}  </span>
                                    <span style={{ display: 'inline-block', float: 'right'}}><AmtFormat curInfo={d.curInfo} amt={d.data.discount} /></span>
                                </div>
                                
                                <div className="d-flex justify-content-between px-3">
                                
                                    <div className="fs16 fw600">{lang('Grand Total')}</div>
                                    <div className="text-black fw600 fs16"><AmtFormat curInfo={d.curInfo} amt={d.data.total_amt} /></div>
                                </div>
                        



                                    {/* <Items items={d.data.items} qty={d.data.qty} amt={d.data.amt} curInfo={d.curInfo} lang_pref={d.lang_preferance} tax={d.data.tax} discount={d.data.discount} delivery_amount={d.data.delivery_amount} total_amt={d.data.total_amt} /> */}
                                    <div className="clear"></div>
                                    <hr />
                                       
                                    <div className="px-3">
                                    {d.data.order_notes && 
                                        <>
                                            <div className="mt-2 fs14 fw600 text-dark ">{lang('Order Notes')}</div>
                                            {d.data.order_notes.map((note, j) =>  {
                                                const date1 = 'test';
                                                return ( 
                                                    <div className="fs16 fw600 text" key={j}>{note.note}  ({note.formatted_date})</div>
                                                )
                                            })}


                                        </>

                                        }
                                     <div className="clear"></div>
                                    <hr />
                                        <If cond={d.data.name || d.data.mob}>
                                            <div className="fs14 fw400 text-grey">{lang('Name')}</div>
                                            <div className="fs16 fw600 text-dark">{d.data.name}</div>

                                            <div className="mt-2 fs14 fw400 text-grey">{lang('Contact Detail')}</div>
                                            <div className="fs16 fw600 text-dark"><If cond={d.data.mob}>{d.data.mob} </If></div>
                                        </If>
                                    
                                        <If cond={d.data.order_taken_by != ''}>
                                            <div className="mt-2 fs14 fw400 text-grey">{lang('Order Taken By')}</div>
                                            <div className="fs16 fw600 text-dark">{d.data.order_taken_by || 'N/A'}</div>
                                        </If>

                                        <If cond={ d.data.refund_done_by != '' && d.data.refund_payment_status == 1}>
                                            <div className="mt-2 fs14 fw400 text-grey">{lang('Refund done By')}</div>
                                            <div className="fs16 fw600 text-dark">{d.data.refund_done_by || 'N/A'}</div>
                                        </If>
                                        <If cond={ d.data.payment_taken_by != '' && d.data.payment_status == 1}>
                                            <div className="mt-2 fs14 fw400 text-grey">{lang('Payment taken By')}</div>
                                            <div className="fs16 fw600 text-dark">{d.data.payment_taken_by || 'N/A'}</div>
                                        </If>
                                        <If cond={d.data.payment_status == 1 && d.data.refund_payment_status != 1}>
                                            <If cond={d.data.cash_paid} >
                                                <div className="mt-2 fs14 fw400 text-grey">{lang('Cash Payment')} </div>
                                                <div class="fs16 fw600 text-dark"><AmtFormat curInfo={d.curInfo} amt={d.data.cash_paid} /></div>
                                            </If>
                                            <If cond={d.data.bank_paid} >
                                                <div className="mt-2 fs14 fw400 text-grey">{lang('Bank Payment')}</div> 
                                                <div className="fs16 fw600 text-dark"><AmtFormat curInfo={d.curInfo} amt={d.data.bank_paid} /></div>
                                            </If>
                                        </If>
                                        <If cond={d.data.refund_payment_status == 1}>
                                        
                                                <div class="mt-2 fs14 fw400 text-grey">{lang('Payment Mode')}</div> 
                                                <div className="fs16 fw600 text-dark">{d.data.pay_mode}</div>

                                            <If cond={d.data.cash_refund} >
                                                <div class="mt-2 fs14 fw400 text-grey">{lang('Cash Refund')}</div> 
                                                <div className="fs16 fw600 text-dark"><AmtFormat curInfo={d.curInfo} amt={d.data.cash_refund} /></div>
                                            </If>
                                            <If cond={d.data.bank_refund} >
                                                <div class="mt-2 fs14 fw400 text-grey">{lang('Bank Refund')}</div> 
                                                <div className="fs16 fw600 text-dark"><AmtFormat curInfo={d.curInfo} amt={d.data.bank_refund} /></div>
                                            </If>
                                        </If>
                                        {d.data.status === 'Cancelled' && d.data.cancellation_reason !== '' &&
                                            <><div class="mt-2 fs14 fw400 text-grey">{lang('Cancellation Reason')}</div>
                                            <div className="fs16 fw600 text-dark">{d.data.cancellation_reason}</div></>
                                        }
                                        
                                    </div>
                                
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}