import React, { useState, useEffect } from "react";
import { AmtFormat ,If} from "../../util/controls";
import util from "../../util/util";
import lang from '../../lang';

let $ = window.$;
export default function RecordPayment(props) {

    const [d, setData] = useState({
        'cash_paid' : 0,
        'currency':'',
        'imgUrl' : '',
        'link_hash' : '',
    });

   //console.log(props);

    const changeHandler = (e) => {
        let changedVal = {
            [e.target.name]: e.target.value
        };

        let amt = d.total_amt*1, cash_paid = d.cash_paid*1, bank_paid = d.bank_paid*1;

        if(e.target.name == 'cash_paid') {
            cash_paid = e.target.value*1;
        } else if(e.target.name == 'bank_paid') {
            bank_paid = e.target.value*1;
        }

        if(e.target.name == 'cash_paid' || e.target.name == 'bank_paid') {
            changedVal.change_amt = (cash_paid+bank_paid) - amt;
        }

        setData({...d, ...changedVal });
    }

    const recordPayment = (rob) => {
        $('#manualPayment').css('display', 'block');

        $('#activate_link').css('display', 'none');
        window.showLoader();
        d.currency = props.curInfo.currency_abbr;
        setData({ ...d });
        util.ajx("orders/record_payment", d).then(res => {
            if (res.code === 200) {
                props.orderList();
                $('#recordPaymentModal').modal('hide');
            }
        })
    }

    // function copyClipboard()
    const copyClipboard = () =>  {
        var elm = document.getElementById("payment_url");
        // for Internet Explorer

        if (document.body.createTextRange) {
            var range = document.body.createTextRange();
            range.moveToElementText(elm);
            range.select();
            document.execCommand("Copy");
            alert("Copied div content to clipboard");
        }
        else if (window.getSelection) {
            // other browsers

            var selection = window.getSelection();
            var range = document.createRange();
            range.selectNodeContents(elm);
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand("Copy");
            alert("Copied div content to clipboard");
        }
    }

   const payOnline = (rob) => {
        //window.showLoader();
        d.currency = props.curInfo.currency_abbr;
        setData({ ...d });
      
       util.ajx("orders/pay_online", d).then(res => { 
           d.imgUrl = res.imgUrl;
           d.link_hash = res.link_hash;
           d.link_url = res.link_url;
           setData({ ...d });

           $('#manualPayment').css('display', 'none');

           $('#activate_link').css('display', 'block');
        //    var html = '<img style="height:160px" src="' + res.imgUrl + '" ><div id="payment_url">http://localhost/agnapos/website/payments/' + res.link_hash +'</div><a href="javascript:void();" onClick={copyClipboard}  >Copy Text</a>';
            // $("#activate_link").html(html);
        });
    }

    
    
    useEffect(()=>{
        if(props.order.id){
            setData({...props.order});
        }
    }, [props.order.id,props.card_details,props.online_payment_details ,props.online_payment_setup,props.billing_type]);


    return <div>
        <div className="modal" id="recordPaymentModal">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{lang('Record Payment')}</h4>
                        <button type="button" className="close" data-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <div className="row">
                                <div className="col-md-8">
                                    <label>{lang('Customer Name')}</label>
                                    <input type="text" className="form-control" readOnly={true} value={d.name || ''} />
                                </div>
                                <div className="col-md-4">
                                    <label>{lang('Mobile No')}.</label>
                                    <input type="text" className="form-control" readOnly={true} value={d.mob || ''} />
                                </div>
                                
                            </div>
                            <hr className="minus14" />
                        </div>
                        <table className="table table-bordered table-sm fc-sm align-middle" id="manualPayment">
                            <tbody>
                                <tr>
                                    <td className="w200">{lang('Total')}</td>
                                    <td className="bold text-right"> <AmtFormat curInfo={props.curInfo} amt={d.total_amt} /></td>
                                </tr>
                                 {/* <If cond={d.tip ==1 && d.order_type != 'Pickup'}  >
                                 <tr>
                                    <td>Tip</td>
                                    <td>
                                        <input type="text" className="form-control numpad text-right" style={{ borderRadius: 0 }} maxLength={10} placeholder="Enter Amount" name="tip_amt" value={d.tip_amt || ''} onChange={changeHandler} />
                                    </td>
                                </tr>
                                </If> */}
                                <If cond={props.billing_type.billing_type == 1}>
                                    <tr>
                                        <td>{lang('Cash Received')}</td>
                                        <td>
                                            <input type="text" className="form-control numpad text-right" style={{ borderRadius: 0 }} maxLength={10} placeholder="Enter Amount" name="cash_paid" value={d.cash_paid || ''} onChange={changeHandler} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{lang('Bank Received')}</td>
                                        <td>
                                            <input type="text" className="form-control numpad text-right" style={{ borderRadius: 0 }} maxLength={10} placeholder="Enter Amount" name="bank_paid" value={d.bank_paid || ''} onChange={changeHandler} />
                                        </td>
                                    </tr>
                                </If>
                                <If cond={props.billing_type.billing_type == 0}>
                                    <tr>
                                        <td>
                                            <label className="btn btn-md btn-outline-info noround" style={{ margin: '0px 5px 0px 0px' }}>
                                                <input type="radio" defaultValue="cash" onChange={changeHandler} name="quickBillingType" className="card_chk mr-1" />
                                                Cash
                                            </label>
                                            <label className="btn btn-md btn-outline-info noround" style={{ margin: '0px 5px 0px 0px' }}>
                                                <input type="radio" defaultValue="bank" onChange={changeHandler} name="quickBillingType" className="card_chk mr-1" />
                                                Bank
                                            </label>

                                        </td>
                                    </tr>
                                </If>
                                
                                <tr>
                                    <td>
                                        <input type="text" className="form-control numpad" maxLength={20} placeholder="Enter Txn Number" name="txn" value={d.txn || ''} onChange={changeHandler} style={{ display: 'inline-block', borderRadius: 0 }} />
                                    </td>
                                    <td>
                                        <div>
                                         {props.card_details.map((r, i)=>(
                                                    
                                                     <label className="btn btn-md btn-outline-info noround" style={{ margin: '0px 5px 0px 0px' }} key={r.id}>
                                                        <input type="radio" defaultValue={r.id} onChange={changeHandler} name="card_id" className="card_chk mr-1" />
                                                        {r.name}
                                                    </label>
                                                ))} 

                                           
                                        </div>
                                    </td>
                                </tr>
                                 {/* <tr>
                                    <td>Online Payment</td>
                                    <td>
                                        <div>
                                         {props.online_payment_details.map((r, i)=>(
                                                    
                                                     <label className="btn btn-md btn-outline-info noround" style={{ margin: '0px 5px 0px 0px' }} key={r.id}>
                                                        <input type="radio" defaultValue={r.id} onChange={changeHandler} name="online_payment_id" className="card_chk mr-1" />
                                                        {r.name}
                                                    </label>
                                                ))} 

                                        </div>
                                    </td>
                                </tr> */}
                                <If cond={props.billing_type.billing_type == 1}>
                                    <tr>
                                        <td>{lang('Balance Amount')}</td>
                                        <td className="text-right">
                                            <AmtFormat curInfo={props.curInfo} amt={d.change_amt || 0} />
                                        </td>
                                    </tr>

                                </If>
                                
                            </tbody>
                        </table>
                        <div id="activate_link" style={{ display : "none"}}>

                            <img style={{height:"160px" }} src={d.imgUrl} alt="" />

                                <div id="payment_url"> {d.link_url }
                                </div>
                                <a onClick={copyClipboard}  >{lang('Copy Text')}</a>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-dismiss="modal">
                        {lang('Close')}
                        </button>
                        <button type="button" className="btn btn-info" onClick={recordPayment}>
                            {lang('Record Payment')}
                        </button>

                        <If cond={props.online_payment_setup == 1}>
                        <button type="button" className="btn btn-info" onClick={payOnline}>
                        {lang('Activate Payment Link')}
                        </button>
                        </If>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>;
}