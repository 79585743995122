import React, { useState, useEffect } from 'react';
import util from "../../util/util";
import { useParams, Link } from 'react-router-dom';
import { sdx } from "../../sdx";
import { If, Paging, Perpagedd } from "../../util/controls";
import fbase from "../../services/firebase";
import lang from '../../lang';

let $ = window.$;

export default function Menu(props) {
    const fn = {};
    const { id } = useParams();
    let pfn = props.computedMatch;
    const [d, setData] = useState({
        list: { result: [], page: {} },
        sdata: { p: 1, ps: 25 },
        data: {},
        branches: [],
        roles: [],
        role_id: 0,
        is_arabic: 0,
        sub_role :[],
        super_role:[],
        user_type:'',
        subscription_id:[],
    });
    fn.render = () => { setData({ ...d }); }


    fn.init = () => {
        let arg = pfn.params;
        d.sub_role = sdx.d.sub_role;
        d.super_role = sdx.d.super_role;
        d.user_type = sdx.d.user_type;
        d.subscription_id = sdx.d.subscription_id;
        fn.render();
        if(d.sub_role && ( d.sub_role[30] != 1 && d.sub_role[31] != 1)  && d.user_type =='STAFF'){
            util.logout('', props.history);
        }

        if(d.subscription_id && ( d.subscription_id[4] != 1)  && d.user_type =='CLIENT'){
            util.logout('', props.history);
        }

        // util.ajx("reports/lists",arg).then(res => {

        //     // $('#main').append(res.result);


        //    // d.list = res.result;
        // })
    }
    useEffect(() => {
        fn.init();
        $('#masterActive').removeClass('active');
        clearTimeout(sdx.orderlist);
        clearTimeout(sdx.kitchenlist);
        // eslint-disable-next-line
    }, []);

    return View(d, fn);

}

function View(d, fn) {
    return (
        <>

            <div className="container-fluid" id="main">
                <div className="" id="reportsList">
                    <div className="row mT20">
                        {/* <div className="col-lg-3 col-md-4 col-sm-6" >
                            <a href="/sellingItems" className="reports-list">  <div><i class="fas fa-chart-line fs36 text-red"></i></div><div className="fs16 fw600 text-black mt-2"> Selling Items</div> </a>
                            <Link to="/sellingItems" className="reports-list"><div><i class="fas fa-chart-line fs36 text-red"></i></div><div className="fs16 fw600 text-black mt-2"> Selling Items</div></Link>
                        </div> */}

                        <div className="col-lg-3 col-md-4 col-sm-6">
                            {/* <a href="/locations" className="reports-list"> <div><i class="fas fa-map-marker-alt fs36 text-red"></i></div><div className="fs16 fw600 text-black mt-2"> Locations</div> </a> */}
                            <Link to="/locations" className="reports-list"><div><i class="fas fa-map-marker-alt fs36 text-red"></i></div><div className="fs16 fw600 text-black mt-2"> {lang('Locations')}</div></Link>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-6">
                            {/* <a href="/sales" className="reports-list"><div><i class="fas fa-chart-pie fs36 text-red"></i></div><div className="fs16 fw600 text-black mt-2">Sales</div></a> */}
                            <Link to="/sales" className="reports-list"><div><i class="fas fa-chart-pie fs36 text-red"></i></div><div className="fs16 fw600 text-black mt-2">{lang('Sales')}</div></Link>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-6">
                            {/* <a href="/orderByMode" className="reports-list"><div><i class="fas fa-sort-amount-down fs36 text-red"></i></div><div className="fs16 fw600 text-black mt-2"> Order By Mode</div> </a> */}
                            <Link to="/orderByMode" className="reports-list"><div><i class="fas fa-sort-amount-down fs36 text-red"></i></div><div className="fs16 fw600 text-black mt-2">{lang('Order By Mode')}</div></Link>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-6">

                            {/* <a href="/paymentReport" className="reports-list"> <div><i class="fas fa-dollar-sign fs36 text-red"></i></div><div className="fs16 fw600 text-black mt-2">Total Payment</div></a> */}
                            <Link to="/paymentReport" className="reports-list"><div><i class="fas fa-dollar-sign fs36 text-red"></i></div><div className="fs16 fw600 text-black mt-2">{lang('Total Payment')}</div></Link>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-6">
                            {/* <a href="/salesReport" className="reports-list"><div><i class="fas fa-chart-pie fs36 text-red"></i></div><div className="fs16 fw600 text-black mt-2">Sales Report</div></a> */}
                        <Link to="/salesReport" className="reports-list"><div><i class="fas fa-chart-pie fs36 text-red"></i></div><div className="fs16 fw600 text-black mt-2">{lang('Sales Report')}</div></Link>
                        </div>
                        
                    </div>

                </div>

            </div>

        </>
    )
}
