import React, { useState, useEffect } from "react";
import { AmtFormat ,If} from "../../util/controls";
import util from "../../util/util";
import lang from '../../lang';

let $ = window.$;
export default function RecordPayment(props) {

    const [d, setData] = useState({
        'cash_paid' : 0,
        'currency':'',
        'imgUrl' : '',
        'link_hash' : '',
        'orderList' : '',
        'amt' : '',
        pay_type :'cash',
        crm_receipt_data : '',

    });

    var crm_receipt_data = '' ;
   //console.log(props);

    const setPayType = (pay_type) => {
        d.pay_type = pay_type;
        setData({ ...d, pay_type: pay_type });
    }


    const changeHandler = (e) => {
        let changedVal = {
            [e.target.name]: e.target.value
        };

        let amt = d.total_amt*1, cash_paid = d.cash_paid*1, bank_paid = d.bank_paid*1;

        if(e.target.name == 'cash_paid') {
            cash_paid = e.target.value*1;
        } else if(e.target.name == 'bank_paid') {
            bank_paid = e.target.value*1;
        }

        if(e.target.name == 'cash_paid' || e.target.name == 'bank_paid') {
            changedVal.change_amt = (cash_paid+bank_paid) - amt;
        }

        setData({...d, ...changedVal });
    }

    const recordPayment = (rob) => {
        $('.manualPayment').css('display', 'block');
        
        window.showLoader();
        d.amt = props.order;
        d.orderList = props.orderList;
        d.currency = props.curInfo.currency_abbr;
        setData({ ...d });
        util.ajx("crm/record_payment", d).then(res => {
            if (res.code === 200) {
                // props.funCall();
                // $('#recordPaymentModal').modal('hide');
                $('#crm_receipt_data').val(res.crm_receipt_data_id);
                $('.manualPayment').css('display', 'none');
                $('#activate_link').css('display', 'block');
            }
            
        })
    }

    const printReceipt = (rob) => {
        d.amt = props.order;
        d.orderList = props.orderList;
        d.currency = props.curInfo.currency_abbr;
        d.crm_receipt_data = $('#crm_receipt_data').val();
        setData({ ...d });
        util.ajx("crm/printReceipt", d).then(res => {
            // props.funCall();
            $('.manualPayment').css('display', 'none');
            $('#activate_link').css('display', 'block');

            var iframe = $('#iframe');
            iframe.contents().find("body").html('').append(res.result);

            $('#iframe').get(0).contentWindow.print();


        })

       
    }

    const closePrintReceipt= () => {
                props.funCall();
                $('#recordPaymentModal').modal('hide');
             
    }
    
    useEffect(()=>{
        if(props.order){
            setData({...props.order});
        }
        $('.manualPayment').css('display', 'block');
    }, [props.order,props.card_details,props.online_payment_details ,props.online_payment_setup]);


    return <div>
        <iframe id="iframe"  style={{display: 'none'}} ></iframe>
        <div className="modal" id="recordPaymentModal">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{lang('Record Payment')}</h4>
                        <button type="button" className="close" data-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="manualPayment">
                            <div className="row">
                                <div className="col-md-8">
                                     <label className="btn btn-md btn-outline-info noround" style={{ margin: '0px 5px 0px 0px' }}>
                                                <input type="radio" onChange={() => { setPayType('cash') }} checked={d.pay_type == 'cash'} value="cash" name="pay_type" className="card_chk mr-1" />
                                                {lang('Cash')}
                                            </label>
                                </div>
                                <div className="col-md-4">
                                     <label className="btn btn-md btn-outline-info noround" style={{ margin: '0px 5px 0px 0px' }}>
                                                <input type="radio" onChange={() => { setPayType('bank') }} checked={d.pay_type == 'bank'} value="bank" name="pay_type" className="card_chk mr-1" />
                                                {lang('Bank')}
                                            </label>
                                </div>
                            </div>
                            <hr className="minus14" />
                        </div>
                        <table className="table table-bordered table-sm fc-sm align-middle manualPayment">
                            <tbody>
                                <tr>
                                    <td className="w200">{lang('Total')}</td>
                                    <td className="bold text-right"> <AmtFormat curInfo={props.curInfo} amt={props.order} /></td>
                                </tr>
                                <If cond={d.pay_type == 'cash'}>
                                    <tr>
                                        <td>{lang('Cash Received')}</td>
                                        <td>
                                            <input type="text" className="form-control numpad text-right" style={{ borderRadius: 0 }} maxLength={10} placeholder="Enter Amount" name="cash_paid" value={d.cash_paid || ''} onChange={changeHandler} />
                                        </td>
                                    </tr>
                                </If>
                                <If cond={d.pay_type == 'bank'}>
                                <tr>
                                    <td>{lang('Bank Received')}</td>
                                    <td>
                                        <input type="text" className="form-control numpad text-right" style={{ borderRadius: 0 }} maxLength={10} placeholder="Enter Amount" name="bank_paid" value={d.bank_paid || ''} onChange={changeHandler} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="text" className="form-control numpad" maxLength={20} placeholder="Enter Txn Number" name="txn" value={d.txn || ''} onChange={changeHandler} style={{ display: 'inline-block', borderRadius: 0 }} />
                                    </td>
                                    <td>
                                        <div>
                                         {props.card_details.map((r, i)=>(
                                                    
                                                     <label className="btn btn-md btn-outline-info noround" style={{ margin: '0px 5px 0px 0px' }} key={r.id}>
                                                        <input type="radio" defaultValue={r.id} onChange={changeHandler} name="card_id" className="card_chk mr-1" />
                                                        {r.name}
                                                    </label>
                                                ))} 
                                        </div>
                                    </td>
                                </tr>
                                </If>
                                
                                {/* <tr>
                                    <td>Balance Amount</td>
                                    <td className="text-right">
                                        <AmtFormat curInfo={props.curInfo} amt={d.change_amt || 0} />
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                        <div id="activate_link" >
                            <input type="hidden" id="crm_receipt_data" value=""/>
                            {lang('Do you want to print Receipt')}?
                                <button type="button" className="btn btn-info" onClick={() => printReceipt(d.crm_receipt_data)}>
                                    {lang('Yes')}
                                </button>
                                <button type="button" className="btn btn-danger"  onClick={closePrintReceipt}>
                                    {lang('No')}
                                </button>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger manualPayment" data-dismiss="modal">
                        {lang('Close')}
                        </button>
                        <button type="button" className="btn btn-info manualPayment" onClick={recordPayment}>
                        {lang('Record Payment')}
                        </button>

                                
                    </div>
                </div>
            </div>
        </div>
        <div id="iframe_print" ></div>
    </div>;
}