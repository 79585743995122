
import React, { useState, useEffect } from "react";
import { AmtFormat ,If} from "../../util/controls";
import util from "../../util/util";
import lang from '../../lang';

let $ = window.$;
export default function RefundPayment(props) {

    const [d, setData] = useState({});

    const changeHandler = (e) => {
        let changedVal = {
            [e.target.name]: e.target.value
        };

       

        

        setData({...d, ...changedVal });
    }

    const refundPayment = (rob) => {
        window.showLoader();
        util.ajx("orders/refund_payment", d).then(res => {
            if (res.code === 200) {
                props.orderList();
                $('#refundPaymentModal').modal('hide');
            }
        })
    }

    useEffect(()=>{
        if(props.order.id){
            setData({...props.order});
        }
    }, [props.order.id,props.card_details]);


    return <div>
        <div className="modal" id="refundPaymentModal">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{lang('Refund Payment')}</h4>
                        <button type="button" className="close" data-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <div className="row">
                                <div className="col-md-8">
                                    <label>{lang('Customer Name')}</label>
                                    <input type="text" className="form-control" readOnly={true} value={d.name || ''} />
                                </div>
                                <div className="col-md-4">
                                    <label>{lang('Mobile No')}.</label>
                                    <input type="text" className="form-control" readOnly={true} value={d.mob || ''} />
                                </div>
                            </div>
                            <hr className="minus14" />
                        </div>
                        <table className="table table-bordered table-sm fc-sm align-middle">
                            <tbody>
                                <tr>
                                    <td className="w200">{lang('Total')}</td>
                                    <td className="bold text-right"> <AmtFormat curInfo={props.curInfo} amt={d.total_amt} /></td>
                                </tr>
                                <tr>
                                    <td>{lang('Cash Refund')}</td>
                                    <td>
                                        <input type="text" className="form-control numpad text-right" style={{ borderRadius: 0 }} maxLength={10} placeholder="Enter Amount" name="cash_refund" value={d.cash_refund || ''} onChange={changeHandler} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>{lang('Bank Refund')}</td>
                                    <td>
                                        <input type="text" className="form-control numpad text-right" style={{ borderRadius: 0 }} maxLength={10} placeholder="Enter Amount" name="bank_refund"  value={d.bank_refund || ''} onChange={changeHandler} />
                                    </td>
                                </tr>
                                <tr>
                                <td>{lang('Card Refund')}</td>
                                    <td>
                                        <div>
                                         {props.card_details.map((r, i)=>(
                                                    
                                                     <label className="btn btn-md btn-outline-info noround" style={{ margin: '0px 5px 0px 0px' }} key={r.id}>
                                                        <input type="radio" defaultValue={r.id} onChange={changeHandler} name="refund_card_id" className="card_chk mr-1" />
                                                        {r.name}
                                                    </label>
                                                ))} 
                                        </div>
                                    </td>
                                </tr>
                               
                                <tr>
                                    <td>{lang('Refund Done By')}</td>
                                    <td>
                                        <input type="text" className="form-control numpad text-right"  readOnly={true} style={{ borderRadius: 0 }}   name="refund_done_by" value={d.refund_done_by || localStorage.getItem('login_name')}  />
                                    </td>
                                </tr>

                                
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-dismiss="modal">
                        {lang('Close')}
                        </button>
                        <button type="button" className="btn btn-info" onClick={refundPayment}>
                        {lang('Refund Payment')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}