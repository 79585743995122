import React, { useState, useEffect } from 'react';
import util from "../../util/util";
import { sdx } from "../../sdx";
import { If, Paging, Perpagedd } from "../../util/controls";
import { NavLink } from 'react-router-dom';
import lang from '../../lang';
let $ = window.$;

export default function Role_permissions() {
    const fn = {};
    const [d, setData] = useState({
        list: { result: [], page: {} },
        sdata: { p: 1, ps: 25 },
        data: {},
        branches: [],
        roles: [],
        role_id: 0,
        is_arabic: 0,
        subscription_id:[],
    });
    fn.render = () => { setData({ ...d }); }

    fn.handleChange = (e) => {
        d.data[e.target.name] = e.target.value;
        fn.render();
    }

    fn.setRole = (e) => {
        d.data[e.target.name] = e.target.value;
        setData({ ...d, role_id: e.target.value });
    }

    fn.openForm = (dtl) => {
        
        d.data = dtl ? { ...dtl } : {};
        // d.role_id = d.data.role_id || 0;
        // d.is_arabic = sdx.d.is_arabic;
        fn.render();

        
        d.id = d.data.id || 0
        let arg =  d.id;
        util.ajx("Permissions/Permissionsfn",{arg:arg}).then(res => {

            // $('#main').html('');
            $('#main').replaceWith(res.result);  
            $('#permissions_form').trigger("reset");
            $('#masterActive').addClass('active');
           // d.list = res.result;
        })
        window.showModal($("#formModal"), true);

    }

    // fn.openForm = (dtl) => {
    //     d.data = dtl ? { ...dtl } : { client_id: props.client_id, food_category: [] };
    //     $('input[type="file"]').val('');
    //     fn.render();
    //     // window.draggable('#bformModal');
    //     window.showModal($("#formModal"), true);
    // }

    fn.sdata = (p, ps) => {
        d.sdata.p = p ? p : 1;
        d.sdata.ps = ps ? ps : d.sdata.ps;
        return $("#sfrm").serialize() + '&p=' + d.sdata.p + '&ps=' + d.sdata.ps;
    }

    fn.lists = (e, p, ps) => {
        if (e) e.preventDefault();
        util.ajx("Permissions/permissions_list", fn.sdata(p, ps)).then(res => {
            if (res.code === 200) {
                d.list = res.result;
                d.branches = res.branches;
                d.sub_role = res.logging_data.permission.sub_permission;
                d.super_role = res.logging_data.permission.super_permission;
                d.user_type = res.logging_data.user_type;
                d.subscription_id = res.logging_data.subscription_id;
                fn.render();
                $('#masterActive').addClass('active');
            }
        })
    }

    fn.save = () => {
        let fd = new FormData($("#permissions_form")[0]);
        util.ajx("permissions/save_permissions", fd).then(res => {
            if (res.code === 200) {
                window.hideModal($("#formModal"));
                fn.lists(0, d.data.id ? d.sdata.p : 1);
            }
        })
    }

    fn.deleterec = (id) => {
        let callback = () => {
            util.ajx("Permissions/delete_role_permissions", { id: id }).then(res => {
                if (res.code === 200) {
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.init = () => {
        util.ajx("permissions/permissions_list").then(res => {
            if (res.code === 200) {
                d.list = res.result;
                d.branches = res.branches;
                d.sub_role = res.logging_data.permission.sub_permission;
                d.super_role = res.logging_data.permission.super_permission;
                d.user_type = res.logging_data.user_type;
                d.subscription_id = res.logging_data.subscription_id;
                // d.roles = res.roles;
                fn.render();
                $('#masterActive').addClass('active');
                if(d.subscription_id){
                    $('.left-sidebar-link').removeClass('d-none');
                }
            }
        })
    }
    useEffect(() => {
        fn.init();
        clearTimeout(sdx.orderlist);
        clearTimeout(sdx.kitchenlist);
        // eslint-disable-next-line
    }, []);

    return View(d, fn);

}

function View(d, fn) {
    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-md-3 col-lg-2 top-master">
                    <div className="mb-3 text-uppercase text-black fw700 fs12">{lang('Masters')}</div>
                    <div className="left-sidebar-link d-none">
                    <If cond={d.list.result}>
                        {/* <If cond={d.user_type == 'STAFF'}> */}
                        <If cond={d.sub_role && ( d.sub_role[1] == 1 ||  d.sub_role && d.sub_role[2] == 1)  || d.user_type !='STAFF'  }>
                                <NavLink to="/branches" className="">{lang('Branches')}</NavLink>
                            </If>
                            <If cond={d.user_type != 'STAFF'}> <NavLink to="/roles" className="">{lang('Roles')}</NavLink></If>
                           
                            <If cond={d.sub_role && d.sub_role[4] == 1  || d.user_type !='STAFF'}>
                                <NavLink to="/staffs" className="">{lang('Staffs')}</NavLink>
                            </If>
                            
                            <If cond={ d.sub_role && d.sub_role[15] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) )  }>
                                <NavLink to="/cats" className="">{lang('Categories')}</NavLink>
                            </If>

                            <If cond={ d.sub_role && d.sub_role[16] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) ) }>
                                <NavLink to="/items" className="">{lang('Items')}</NavLink>
                            </If>
                            <If cond={ d.sub_role && d.sub_role[40] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) ) }>
                                <NavLink to="/combo" className="">{lang('Combos')}</NavLink>
                            </If>
                            
                            <If cond={d.super_role && d.super_role[2] == 1 ||  d.sub_role && ( d.sub_role[5] == 1 ||  d.sub_role[6] == 1 ||  d.sub_role[8] == 1)   || d.user_type !='STAFF'}>
                                <NavLink to="/others" className="">{lang('Others')}</NavLink>
                            </If>
                            <If cond={ d.sub_role && d.sub_role[48] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) ) }>
                                <NavLink to="/recipeIngredients" className="">{lang('Recipe Ingredients')}</NavLink>
                            </If>
                            
                            
                        {/* </If> */}

                    </If>
                    </div>
                </div>
                <div className="offset-lg-2 col-lg-10 offset-md-3 col-md-9 col-sm-12 right-master">
                    <div className="main-table-body">
                        <div className="d-flex flex-row align-items-center justify-content-between pl-4 pr-4 pt-4">
                            <h6 className="m-0 h5 text-dark">{lang('Roles')}</h6>
                            <div className="ml-auto mt-auto mb-auto">
                                <button className="btn btn-danger btn-red" onClick={() => fn.openForm()}><i className="fa fa-plus"></i>{lang('Add')}   </button>
                            </div>
                        </div>
                        {/* <NavLink to="/Permissions/0" className=""> Add</NavLink>  */} 
                        {/* onClick={() => fn.openForm()} */}
                        {/* <If cond={d.list.result.length}>
                            <div className="d-flex">
                                <div className="text-secondary bold600 mb5">
                                    Showing {d.list.page.start + 1} - {d.list.page.start + d.list.page.total} of {d.list.page.total_records} records.
                                        </div>
                            </div>
                        </If> */}
                        <div className="main-content">
                            <div className="container-fluid">
                                <div className="form-inline">
                                    <form id="sfrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                                        <input type="search" className="form-control mr-3" name="k" placeholder={lang('Search')}/>
                                        {/* <select className="form-control w130 single-line-dotted mr-3" name="b_id" onChange={fn.handleChange}>
                                            <option value="">Branch (All)</option>
                                            {d.branches.map(r => <option key={r.id} value={r.id}>{r.title}</option>)}
                                        </select> */}
                                        {/* <select className="form-control w130 single-line-dotted mr-3" name="r_id" onChange={fn.handleChange}>
                                            <option value="">Role (All)</option>
                                            {d.roles.map(r => <option key={r.id} value={r.id}>{r.title}</option>)}
                                        </select> */}
                                        <button className="btn btn-red-outline btn-danger" type="submit"> <i className="fa fa-search"></i></button>
                                    </form>
                                </div>

                                <div className="mt-4">
                                    <If cond={d.list.result.length}>
                                        <div className="table-responsive">
                                            <table className="table border m-0">
                                                <thead className="bg-light text-uppercase font-sm">
                                                    <tr>
                                                        <th className="w20">{lang('SN')}</th>
                                                        {/* <th className="w200">Name</th>
                                                        <th>Email</th> */}
                                                        <th className="w120">{lang('Role')}</th>
                                                        {/* <th className="w200">Branch</th> */}
                                                        <th className="w80">{lang('Status')}</th>
                                                        <th className="w70"></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-text-vmid">
                                                    {d.list.result.map((v, i) => (
                                                        <tr key={i}>
                                                            <td>{d.list.page.start + i + 1}.</td>
                                                            {/* <td className="text-black fw600">{v.name}</td>
                                                            <td>{v.email}</td> */}
                                                            <td>{v.roll_name}</td>
                                                            {/* <td>{v.branch_name}</td> */}
                                                            <td>
                                                                <If cond={v.status === '1'}>
                                                                    <div className="badge badge-success">{lang('Active')}</div>
                                                                </If>
                                                                <If cond={v.status === '0'}>
                                                                    <div className="badge badge-danger">{lang('Inactive')}</div>
                                                                </If>
                                                            </td>
                                                            <td className="text-center">
                                                                <div className="btn-group">
                                                                    {/* <button type="button" className="btn btn-sm fs16" ><NavLink to={'/Permissions/'+v.id}  className=""><i className="far fa-edit"></i></NavLink></button> */}
                                                                    <button type="button" className="btn btn-sm fs16" onClick={() => fn.openForm(v)}><i className="far fa-edit"></i></button>
                                                                   
                                                                    <button type="button" className="btn btn-sm fs16" onClick={() => fn.deleterec(v.id)}><i className="fa fa-trash-o"></i></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex justify-content-between mt-4 pb-3">
                                            <div className="mr5">
                                                <Paging fn={fn.lists} pageob={d.list.page} />
                                            </div>
                                            <div>
                                                <Perpagedd fn={fn.lists} />
                                            </div>
                                        </div>
                                    </If>
                                    <If cond={!d.list.result.length}>
                                        <div className="no-rec">{lang('No record found')}</div>
                                    </If>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/** Form */}
            <div className="modal modal-scroll" id="formModal" data-backdrop="static">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title uc">{d.data.id ? 'Edit' : 'Add'} {lang('Role')}</div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body" >
                            <div id="main">

                            </div>
                            
                          
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">{lang('Close')}</button>
                            <button type="button" className="btn btn-primary" onClick={fn.save}>{lang('Save')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}