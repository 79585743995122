import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import util from "../../util/util";
import { If } from "../../util/controls";
import { sdx } from "../../sdx";
import { changeLangPref } from "../../services/data";
import lang from '../../lang';
import { NavLink } from 'react-router-dom';
import {Avatar, Badge, Popover, List} from 'antd';
// import {ShoppingCartOutlined, BellOutlined} from '@ant-design/icons';

let $ = window.$;

export default function Header(props) {
    const {hdrRef}=props;
    const history=useHistory();
    const fn = {};
    const [d, setData] = useState({
        is_admin: false,
        is_client_admin: false,
        modules: [],
        sub_role :[],
        super_role:[],
        user_type:'',
        master_url:'',
        subscription_id:[],
        role_id:'',
        user_id:'',
        audio_notification:'',
        branches : [],
    });

    const init = () => {
        var master_url = ''
        util.ajx("auth/logged_data", 0, 0, 1).then(res => {
            // console.log(res);
            if (res.code === 200) {
                sdx.d.is_arabic = res.is_arabic;
                sdx.d.language = res.language;
                sdx.d.mobile_digit_length = res.mobile_digit_length;
                sdx.d.sub_role = res.permission.sub_permission;
                sdx.d.super_role =res.permission.super_permission;
                sdx.d.user_type =res.user_type;
                sdx.d.subscription_id =res.subscription_id;
                sdx.d.role_id =res.role_id;
                sdx.d.user_id =res.user_id;
                sdx.d.default_page_url = res.default_page_url;
                
                

                changeLangPref(res.lang_preferance);

                if(res.permission.sub_permission &&  res.permission.sub_permission[1] == 1 ){
                    master_url = '/branches'
        
                }
                else if(res.permission.sub_permission &&  res.permission.sub_permission[2] == 1 ){
                    master_url = '/branches'
        
                }
                else if(res.permission.sub_permission &&  res.permission.sub_permission[4] == 1 ){
                    master_url = '/staffs'
                }
                else if(res.permission.sub_permission &&  res.permission.sub_permission[15] == 1 ){
                    master_url = '/cats'
                }
                else if(res.permission.sub_permission &&  res.permission.sub_permission[16] == 1){
                    master_url = '/items'
                }
                else  {
                    master_url = '/others'
                }

                setData({ ...d, is_admin: res.is_admin, is_client_admin: res.is_client_admin, user_type:res.user_type,
                    modules: res.modules , sub_role: res.permission.sub_permission,super_role: res.permission.super_permission, 
                    master_url:master_url, subscription_id: res.subscription_id,role_id:res.role_id,user_id:res.user_id,
                    audio_notification:res.audio_notification,branches:res.branches});
                    


            }
        })
    }

    const audioNotification = (status) =>{
        try {
            let callback = () => {

                util.ajx("auth/set_audio_notification",{ id: d.user_id, status:status }).then(res => {
                    d.audio_notification = status;
                    // window.location.reload();
                    fn.render();

                 })

            }

            if(status == 1){

                window.openConfirmBootbox("Audio", "Off audio notification?", callback);
            }else{

            
                window.openConfirmBootbox("Audio", "Allow audio notification?", callback);
            }

        } catch (e) {
        }
        
    }

    const openrestaurantList = (dtl) => {
        window.showModal($("#restaurantModal"), true);
    }

    const changeLang = (dtl) =>{
        window.localStorage['lang']= dtl ;
        window.location.reload();
    }
    const changeItemBranches = (e, data, indx, type) => {
       
            if (e.target.checked) {
                d.branches[indx].branch_open_status = 1;
            } else {
                d.branches[indx].branch_open_status = 0;
            }

            fn.render();

        util.ajx("auth/set_branch_open_status", {  value: e.target.checked ? 1 : 0, branch_id: data.id }).then(res => {
            
        })
    }

    fn.render = () => { setData({ ...d }); }

    const [newOdrs, setNewOdrs]=useState({count:0, list:[]});
    const [custMsgs, setCustMsgs]=useState({count:0, list:[]});
    const fbaseCount=useRef({newOdrs:0, custMsgs:0});
    const resetNewOdrsCount=()=>{
        setNewOdrs({
            count:0, 
            list:[...newOdrs.list]
        });
    }
    const resetCustMsgsCount=()=>{
        setCustMsgs({
            count:0, 
            list:[...custMsgs.list]
        });
    }
    hdrRef.current={
        setNewOdrs:(msgs)=>{
            setNewOdrs({
                count:fbaseCount.current.newOdrs>0?(newOdrs.count+1):0, 
                list:msgs
            });
            fbaseCount.current.newOdrs++;
        },
        setCustomerMsgs:(msgs)=>{
            setCustMsgs({
                count:fbaseCount.current.custMsgs>0?(custMsgs.count+1):0, 
                list:msgs
            });
            fbaseCount.current.custMsgs++;
        }
    }

    useEffect(() => {
        init();
        // eslint-disable-next-line
    }, []);

    return (
        <>

            <nav className="navbar navbar-expand-lg navbar-light bg-white border-bottom fixed-top navbar-padding">
                <a className="navbar-brand" href={"/"+sdx.d.default_page_url}>
                    <img src="assets/img/agna-without-tagline.svg" alt="Logo" height="42px" className="custom-logo pd4" />
                </a>

                <ul className="navbar-nav mobile-block">
                    <li className="nav-item dropdown no-arrow">
                        <a className="nav-link dropdown-toggle nav-top-dropdown" href="#" id="userDropdown" role="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">                            
                            <span className="mr-2 d-none d-lg-inline fs14">{localStorage.getItem('login_name') || 'User Profile'}</span>
                            <i className="fas fa-user-circle fs24"></i>
                        </a>

                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in position-absolute" aria-labelledby="userDropdown">
                            <a className="dropdown-item" href="change-password">
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-800"></i>
                                {lang('Change Password')} 
                    </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal" onClick={(e) => util.logout(e, props.history)}>
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-800"></i>
                                {lang('Logout')}
                    </a>
                        </div>
                    </li>
                </ul>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <If cond={d.sub_role && d.sub_role[29] == 1   || d.user_type !='STAFF' && d.role_id != 10}>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/dashboard" activeClassName="active">{lang('Dashboard')} </NavLink>
                            </li>
                        </If>
                            
                        <If cond={d.is_admin}>
                            <If cond={d.modules['manage_roles'] || d.modules['manage_users']}>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {lang('')}Manage Users
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        {/* <h6 className="collapse-header">Sub menu Heading:</h6> */}
                                        <If cond={d.modules['manage_roles']}>
                                            <NavLink className="dropdown-item" to="/role" activeClassName="active">{lang('Manage Roles')}</NavLink>
                                        </If>
                                        <If cond={d.modules['manage_users']}>
                                            <NavLink className="dropdown-item" to="/users" activeClassName="active">{lang('Manage Users')}</NavLink>
                                        </If>
                                    </div>
                                </li>
                            </If>
                           
                            <If cond={d.modules['manage_masters']}>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {lang('')} Masters
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink1">
                                        <NavLink className="dropdown-item" to="/currencies" activeClassName="active">{lang('Currencies')}</NavLink>
                                        <NavLink className="dropdown-item" to="/countries" activeClassName="active">{lang('Countries')}</NavLink>
                                        <NavLink className="dropdown-item" to="/cities" activeClassName="active">{lang('Cities')}</NavLink>
                                        <NavLink className="dropdown-item" to="/cms-pages" activeClassName="active">{lang('Cms Pages')}</NavLink>
                                        <NavLink className="dropdown-item" to="/smtp" activeClassName="active">{lang('SMTP')}</NavLink>
                                        <NavLink className="dropdown-item" to="/food-category" activeClassName="active">{lang('Food Category')}</NavLink>
                                        <NavLink className="dropdown-item" to="/special-category" activeClassName="active">{lang('Special Category')}</NavLink>
                                        <NavLink className="dropdown-item" to="/subscription" activeClassName="active">{lang('Subscription')}</NavLink>
                                        <NavLink className="dropdown-item" to="/subscription-new" activeClassName="active">{lang('Subscription New')}</NavLink>
                                        <NavLink className="dropdown-item" to="/measuring-units" activeClassName="active">{lang('Measuring Units')}</NavLink>

                                    </div>
                                </li>
                            </If>
                            <If cond={d.modules['manage_clients']}>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/clients" activeClassName="active">
                                        <span>{lang('Clients')}</span>
                                    </NavLink>
                                </li>
                            </If>
                        </If>

                        <If cond={!d.is_admin}>
                            <If cond={d.super_role && d.super_role[4] == 1 && d.user_type =='STAFF'}>

                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/menu/0" activeClassName="active">{lang('Menu')} </NavLink>
                                </li>
                            </If>

                            <If cond={d.user_type !='STAFF' && d.subscription_id && (d.subscription_id[6] == 1) && d.role_id != 10}>

                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/menu/0" activeClassName="active">{lang('Menu')} </NavLink>
                                </li>
                            </If>
                            
                            <If cond={d.modules['manage_cmaster'] && d.user_type !='STAFF' && d.role_id != 10}>
                                <li className="nav-item dropdown">
                                    {/* <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Masters
                                    </a>
                                    <div className="dropdown-menu shadow animated--grow-in" aria-labelledby="navbarDropdownMenuLink2">
                                        <NavLink className="dropdown-item" to="/branches" activeClassName="active">Branches</NavLink>
                                        <NavLink className="dropdown-item" to="/staffs" activeClassName="active">Staffs</NavLink>
                                        <NavLink className="dropdown-item" to="/cats" activeClassName="active">Categories</NavLink>
                                        <NavLink className="dropdown-item" to="/items" activeClassName="active">Items</NavLink>
                                        <NavLink className="dropdown-item" to="/others" activeClassName="active">Others</NavLink>
                                    </div>
                                        */}
                                    <NavLink className="nav-link" to="/branches" id="masterActive" activeClassName="active">
                                        <span>{lang('Masters')}</span>
                                    </NavLink >

                                </li>

                                <If cond={d.subscription_id && (d.subscription_id[1] == 1) }>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/qrcodes" activeClassName="active">
                                            <span>{lang('QR-Codes')}</span>
                                        </NavLink >
                                    </li>  
                                </If>
                            </If>

                            <If cond={ d.user_type =='STAFF' && d.super_role && (d.super_role[2] == 1 || d.super_role[3] == 1 || d.super_role[1] == 1 )  }>
                                <li className="nav-item dropdown">
                                    {/* <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Masters
                                    </a>
                                    <div className="dropdown-menu shadow animated--grow-in" aria-labelledby="navbarDropdownMenuLink2">
                                        <NavLink className="dropdown-item" to="/branches" activeClassName="active">Branches</NavLink>
                                        <NavLink className="dropdown-item" to="/staffs" activeClassName="active">Staffs</NavLink>
                                        <NavLink className="dropdown-item" to="/cats" activeClassName="active">Categories</NavLink>
                                        <NavLink className="dropdown-item" to="/items" activeClassName="active">Items</NavLink>
                                        <NavLink className="dropdown-item" to="/others" activeClassName="active">Others</NavLink>
                                    </div>
                                        */}
                                    <NavLink className="nav-link" to={d.master_url} id="masterActive" activeClassName="active">
                                        <span>{lang('Masters')}</span>
                                    </NavLink >

                                </li>
                            </If>
                                
                            <If cond={ d.user_type =='STAFF' && d.sub_role && d.sub_role[3] == 1   }>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/qrcodes" activeClassName="active">
                                        <span>{lang('QR-Codes')}</span>
                                    </NavLink >
                                </li>
                            </If>
                                
                            <If cond={d.modules['view_orders']  && d.user_type !='STAFF' && (d.subscription_id && (d.subscription_id[3] == 1))}>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/orders" activeClassName="active">
                                        <span>{lang('Orders')}</span>
                                    </NavLink>
                                </li>
                            </If>

                            <If cond={d.super_role && d.super_role[6] == 1  && d.user_type =='STAFF' }> 
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/orders" activeClassName="active">
                                        <span>{lang('Orders')}</span>
                                    </NavLink>
                                </li>
                            </If>

                            <If cond={(d.sub_role && d.sub_role[30] == 1 )   || (d.user_type !='STAFF' && (d.subscription_id && (d.subscription_id[4] == 1))  )  && d.role_id != 10}>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/reports" activeClassName="active">
                                        <span>{lang('Reports')}</span>
                                    </NavLink>
                                </li>
                            </If>
                            
                            <If cond={(d.super_role && d.super_role[7] == 1 )   || (d.user_type !='STAFF'  && d.subscription_id && (d.subscription_id[9] == 1) ) && d.role_id != 10 }>
                                    <li className="nav-item">
                                    <NavLink className="nav-link" to="/kitchen" activeClassName="active">
                                        <span>{lang('Kitchen')}</span>
                                    </NavLink>
                                </li>
                            </If>

                        
                            <If cond={ (d.super_role && d.super_role[9] == 1  && d.user_type =='STAFF' ) || (d.user_type !='STAFF'  && d.subscription_id && (d.subscription_id[10] == 1) ) }> 
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/crm" activeClassName="active">
                                        <span>{lang('CRM')}</span>
                                    </NavLink>
                                </li>
                            </If>
                            <If cond={(d.super_role && d.super_role[10] == 1 )   || (d.user_type !='STAFF'  && d.subscription_id && (d.subscription_id[9] == 1) )}>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/inventory" activeClassName="active">
                                        <span>{lang('Inventory')}</span>
                                    </NavLink>
                                </li>
                            </If>
                        </If>
                    </ul>
                </div>
                {/* <a  href="/addOrder">
            <div className="ml-auto mt-auto mb-auto">
                <button className="btn btn-outline-primary"><i className="fa fa-plus"></i> Add order</button>
            </div> 
        </a> */}
                

                <ul className="navbar-nav desktop-block">
                        <li className="nav-item dropdown no-arrow">
                            <a className="nav-link dropdown-toggle align-items-center d-flex nav-top-dropdown" href="#" id="userDropdown" role="button" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <span className="mr-2 d-none d-lg-inline fs14">{(localStorage.getItem('lang') == 'Arb')? 'Arabic' : 'English' || 'Select Language'}</span>
                                {/* <i className="fas fa-user-circle fs24"></i> */}
                            </a>
                            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                                <a className="dropdown-item"  onClick={(e) => changeLang('Eng')}>
                                    {/* <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-800"></i> */}
                                    English
                        </a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item"   onClick={(e) => changeLang('Arb')}>
                                    {/* <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-800"></i> */}
                                    Arabic
                        </a>
                            </div>
                        </li>
                    </ul>

                <If cond={!d.is_admin}>
                    {/* <If cond={d.audio_notification == 0}>
                        <i className="fa fa-bell fs24 mr-2" onClick={(e) => audioNotification(1)} ></i>
                    </If>
                    <If cond={d.audio_notification == 1}>
                        <i className="fa fa-bell-slash fs24 mr-2" onClick={(e) => audioNotification(0)} ></i>
                    </If> */}

                    <div className='position-relative d-flex align-items-center'>
                        <div className='cpointer mr15'>
                            <Popover 
                                placement="bottom" 
                                trigger="click"
                                title="Orders"
                                content={<div className='w3001 row cscroll' style={{maxHeight:200}}>
                                    <List
                                        size="small"
                                        dataSource={newOdrs.list}
                                        renderItem={(item,i)=>(
                                            <List.Item>
                                                <div className='nowrap cpointer' onClick={()=>history.push("/orders")}>
                                                    <div className='d-inline-block w30'>{i+1}.</div> New Order From {item.branch}
                                                </div>
                                            </List.Item>
                                        )}
                                        style={{width:'100%'}}
                                    >
                                    </List>
                                </div>}
                            >
                                <Badge count={newOdrs.count} onClick={resetNewOdrsCount}>
                                    <Avatar icon={<i className="fa fa-cart-plus fs16"></i>} />
                                </Badge>
                            </Popover>
                        </div>
                        <div className='cpointer mr20'>
                            <Popover 
                                placement="bottom" 
                                trigger="click"
                                title="Customer Calling"
                                content={<div className='w3501 row cscroll' style={{maxHeight:200}}>
                                    <List
                                        size="small"
                                        dataSource={custMsgs.list}
                                        renderItem={(item,i)=>(
                                            <List.Item>
                                                <div className='nowrap'>
                                                    <div className='d-inline-block w30'>{i+1}.</div> Table No: <strong>{item.table_no}</strong>
                                                    <span> ({item.branch})</span>
                                                    {item.odr_no.length>0 &&
                                                        <span> | Order No: <strong>{item.odr_no}</strong></span>
                                                    }
                                                </div>
                                            </List.Item>
                                        )}
                                        style={{width:'100%'}}
                                    >
                                    </List>
                                </div>} 
                            >
                                <Badge count={custMsgs.count} onClick={resetCustMsgsCount}>
                                    <Avatar icon={<i className="fa fa-bell fs16"></i>} />
                                </Badge>
                            </Popover>
                        </div>
                    </div>

                    <div>
                        <i className="bi bi-clock-fill fs24 ml-2"  onClick={(e) => openrestaurantList(d)}></i>
                    </div>
                </If>

                
                <ul className="navbar-nav desktop-block">
                    <li className="nav-item dropdown no-arrow">
                        <a className="nav-link dropdown-toggle align-items-center d-flex nav-top-dropdown" href="#" id="userDropdown" role="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <span className="mr-2 d-none d-lg-inline fs14">{localStorage.getItem('login_name') || 'User Profile'}</span>
                            <i className="fas fa-user-circle fs24"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                            <a className="dropdown-item" href="change-password">
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-800"></i>
                                {lang('Change Password')}
                    </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal" onClick={(e) => util.logout(e, props.history)}>
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-800"></i>
                                {lang('Logout')}
                    </a>
                        </div>
                    </li>
                </ul>
                
            </nav>
            <div id="content-wrapper" className="d-flex flex-column body-layout position-relative">
                <div id="content">
                    <div className="">
                        {props.children}
                    </div>
                </div>
                <footer className="sticky-footer bg-white">
                    <div className="container my-auto">
                        <div className="copyright text-center my-auto">
                            <span>© 2021 <a href="https://arwadigitalsystems.com/" target="_blank">Arwa Digital Systems</a>. {lang('All Rights Reserved')}.</span>
                        </div>
                    </div>
                </footer>
            </div>

            <div className="modal modal-scroll no-backdrop" id="restaurantModal" data-backdrop="static">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{lang('Branche Status')}</div>
                        <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={() => $("#restaurantModal").modal('hide')}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <table className="table table-sm table-bordered m-0">
                            <thead>
                                <tr>
                                    <th className="w20">{lang('SN')}</th>
                                    <th className="w80">{lang('Name')}</th>
                                    <th className="w80">{lang('Restaurant Status')}</th>
                                </tr>
                            </thead>

                            <tbody className="table-text-top">
                                {d.branches.map((v, i) => (
                                    <tr key={i}>
                                        <td className="w20">{i + 1}.</td>
                                        <td>
                                            {v.title}
                                        </td>

                                        <td className="w50 nowrap">
                                            <div className="status-switch">
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox" id={"ic" + i} className="custom-control-input" checked={v.branch_open_status * 1 === 1} onChange={(e) => changeItemBranches(e, v, i, 'branch_open_status')} />
                                                    <label className="custom-control-label" htmlFor={"ic" + i}>
                                                        <If cond={v.branch_open_status * 1 === 1}><span>{lang('Open')}</span></If>
                                                        <If cond={v.branch_open_status * 1 === 0}><span className="text-danger">{lang('Closed')}</span></If>
                                                    </label>
                                                </div>
                                            </div>
                                        </td>



                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={() => $("#restaurantModal").modal('hide')}>{lang('Close')}</button>
                    </div>
                </div>
            </div>
        </div>

        </>
    );
}


