/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import util from "../../util/util";
import uuid from "react-uuid";
import { useParams } from 'react-router-dom';
import { sdx } from "../../sdx";
import { If, Paging, Perpagedd, AmtFormat } from "../../util/controls";
//import fbase from "../../services/firebase";
import lang from '../../lang';
import { Modal } from "antd";

let client_id = 0, ref = {};

let $ = window.$;

export default function SalesReport(props) {
    client_id = props.client_id;
    const fn = {};
    const { id } = useParams();
    let pfn = props.computedMatch;
    const [d, setData] = useState({
        list: { result: [], page: {} },
        sdata: { p: 1, ps: 25 },
        data: {},
        listss: [],
        branches: [],
        m_users: [],
        all_data: [],
        role_id: 0,
        is_arabic: 0,
        curInfo: {},
        download_url: '',
        downloadPdf: '',
        print: '',
        branches: [],
        branchesids: [],
        branch_name: '',
        todayDate: '',
        start_date: '',
        end_date: '',
        sub_role: [],
        super_role: [],
        user_type: '',
        branchesidscopy: [],
    });
    fn.render = () => { setData({ ...d }); }

    const [used, setused] = useState({
        list: { result: [], page: {} },
        sdata: { p: 1, ps: 25 },
        data: {},
        listss: [],
        branches: [],
        m_users: [],
        role_id: 0,
        is_arabic: 0,
        curInfo: {},
        download_url: '',
        downloadPdf: '',
        print: '',
        branches: [],
        branchesids: [],
        branch_name: '',
        todayDate: '',
        start_date: '',
        end_date: '',
        sub_role: [],
        super_role: [],
        user_type: '',
        branchesidscopy: [],
    });
    fn.render = () => { setused({ ...used }); }
    d.todayDate = new Date();
    d.todayDate = d.todayDate.getFullYear() + "-" + (d.todayDate.getMonth() + 1) + "-" + d.todayDate.getDate();

    fn.sdata = (p, ps) => {
        const start_date = $('#from_date').val();
        const end_date = $('#to_date').val();
        d.sdata.p = p ? p : 1;
        d.sdata.ps = ps ? ps : d.sdata.ps;
        d.sdata.start_date = start_date ? start_date : d.todayDate;
        d.sdata.end_date = end_date ? end_date : d.todayDate;
        d.sdata.authtoken = window.localStorage['token'];
        d.sdata.branch_ids = d.branchesids;

        return $("#sbfrm").serialize() + '&p=' + d.sdata.p + '&ps=' + d.sdata.ps + '&client_id=' + props.client_id + '&start_date=' + d.sdata.start_date + '&end_date=' + d.sdata.end_date + '&authtoken=' + d.sdata.authtoken + '&branch_ids=' + d.sdata.branch_ids;
    }

    fn.lists = (e, p, ps) => {
        if (e) e.preventDefault();
        util.ajx("reports/salesReport", fn.sdata(p, ps)).then(res => {
            if (res.code === 200) {
                used.list = res.result;
                d.list = res.result;
                d.curInfo = res.curInfo;
                d.download_url = res.download_url;
                d.downloadPdf = res.downloadPdf;
                d.print = res.print;
                d.m_users = res.m_users;
                d.all_data = res.all_data;
                d.branches = res.branches;
                d.branchesids = res.branch_ids;
                d.branch_name = res.branch_name;
                var dd = new Date(res.start_date);
                var strDate = dd.getDate()+ "-" + (dd.getMonth() + 1) + "-" +dd.getFullYear();
                d.start_date = strDate;
    
    
                var d1 = new Date(res.end_date);
                var end_date = d1.getDate()+ "-" + (d1.getMonth() + 1) + "-" +d1.getFullYear();
                d.end_date = end_date;
                d.sub_role = sdx.d.sub_role;
                d.super_role = sdx.d.super_role;
                d.user_type = sdx.d.user_type;
                d.branchesidscopy = res.branch_ids_copy;
                if (d.branchesids.length === d.branchesidscopy.length && d.branchesids.length > 1) {
                    d.branch_name = 'All';
                }

                fn.render();
            }
        })
    }


    fn.printSalesReport = (e, p, ps) => {
        if (e) e.preventDefault();
        util.ajx("reports/printSalesReport", fn.sdata(p, ps)).then(res => {
            $("#iframe_print").append(res.result);
            $("#iframe_print").print();
            $("#iframe_print").html('');
        })

    }

    fn.printSaless = (e, p, ps) => {
        if (e) e.preventDefault();
        util.ajx("reports/printSaless", fn.sdata(p, ps)).then(res => {
            $("#iframe_print").append(res.result);
            $("#iframe_print").print();
            $("#iframe_print").html('');
        })

    }

    fn.handleChange = (e) => {
        d.data[e.target.name] = e.target.value;
        fn.render();
    }

    fn.openForm = (dtl) => {

        d.data = dtl ? { ...dtl } : { branches: [] };
        // var branch_ids =d.branchesids;
        // if (branch_ids == '') {
        //      $("#branchall").attr('checked', true);
        // }

        if (d.branchesids.length === d.branchesidscopy.length) {
            d.branch_name = 'All';
        }

        fn.render();
        window.showModal($("#branchModal"), true);
    }

    fn.setAllchecked = (e) => {
        let bid = e.target.value;
        if (!e.target.checked) {
            d.branchesids = [];
            d.branch_name = '';
        } else {
            d.branchesids = d.branchesidscopy.slice();
            d.branch_name = 'All';
        }
        fn.render();
    }

    fn.openDateForm = (dtl) => {

        d.data = dtl ? { ...dtl } : { branches: [] };

        $('#from_date').val('');
        $('#to_date').val('');

        fn.render();
        window.showModal($("#dateModal"), true);
    }


    fn.setBranchCheck = (e) => {
        let bid = e.target.value;
        if (e.target.checked) {
            d.branchesids.push(bid);
        } else {
            d.branchesids.splice(d.branchesids.indexOf(bid), 1);
        }
        // d.branchesids = d.data.branches;
        d.branch_name = '';
        if (d.branchesids.length === d.branchesidscopy.length) {
            d.branch_name = 'All';
        }
        fn.render();
    }

    fn.save = (e, p, ps) => {
        if (e) e.preventDefault();
        // let fd = new FormData($("#bfrm")[0]);
        util.ajx("reports/salesReport", fn.sdata(p, ps)).then(res => {
            $("#branchModal").modal('hide');
            d.list = res.result;
            d.curInfo = res.curInfo;
            d.all_data = res.all_data;
            d.download_url = res.download_url;
            d.downloadPdf = res.downloadPdf;
            d.print = res.print;
            d.branches = res.branches;
            d.branchesids = res.branch_ids;
            d.branch_name = res.branch_name;
            var dd = new Date(res.start_date);
            var strDate = dd.getDate()+ "-" + (dd.getMonth() + 1) + "-" +dd.getFullYear();
            d.start_date = strDate;


            var d1 = new Date(res.end_date);
            var end_date = d1.getDate()+ "-" + (d1.getMonth() + 1) + "-" +d1.getFullYear();
            d.end_date = end_date;
            d.sub_role = sdx.d.sub_role;
            d.super_role = sdx.d.super_role;
            d.user_type = sdx.d.user_type;
            d.branchesidscopy = res.branch_ids_copy;
            if (d.branchesids.length === d.branchesidscopy.length && d.branchesids.length > 1) {
                d.branch_name = 'All';
            }
            fn.render();
        })
    }



    fn.applydate = (e, p, ps) => {
        if (e) e.preventDefault();
        // let fd = new FormData($("#datefrm")[0]);
        util.ajx("reports/salesReport", fn.sdata(p, ps)).then(res => {
            d.list = res.result;
            d.curInfo = res.curInfo;
            d.download_url = res.download_url;
            d.downloadPdf = res.downloadPdf;
            d.print = res.print;
            d.all_data = res.all_data;
            d.branches = res.branches;
            d.branchesids = res.branch_ids;
            d.branch_name = res.branch_name;
            var dd = new Date(res.start_date);
            var strDate = dd.getDate()+ "-" + (dd.getMonth() + 1) + "-" +dd.getFullYear();
            d.start_date = strDate;


            var d1 = new Date(res.end_date);
            var end_date = d1.getDate()+ "-" + (d1.getMonth() + 1) + "-" +d1.getFullYear();
            d.end_date = end_date;
            d.sub_role = sdx.d.sub_role;
            d.super_role = sdx.d.super_role;
            d.user_type = sdx.d.user_type;
            $('#dateModal').modal('hide');
            d.branchesidscopy = res.branch_ids_copy;
            if (d.branchesids.length === d.branchesidscopy.length && d.branchesids.length > 1) {
                d.branch_name = 'All';
            }
            fn.render();
        })
    }

    $(".datepicker").datepicker({
        dateFormat: 'dd-mm-yy'
   });

    $("#Today").click(function() {
        var d = new Date();
        var strDate = d.getDate()+ "-" + (d.getMonth() + 1) + "-" +d.getFullYear();

        $('#from_date').val(strDate);
        $('#to_date').val(strDate);
        $('#filter_date_type').val('');

        $('#datefilter').html(strDate + ' - ' + strDate);
        $('#filter_date_type').val('Today');
        $('li').removeClass('active');
        $('#Today').addClass('active');
        $('.datepicker').attr("disabled",true);

    });

    $("#Yesterday").click(function() {

        var d = new Date(Date.now() - 864e5);
        var strDate = d.getDate()+ "-" + (d.getMonth() + 1) + "-" +d.getFullYear();

        $('#datefilter').html(strDate + ' - ' + strDate);

        $('#from_date').val(strDate);
        $('#to_date').val(strDate);
        $('#filter_date_type').val('');
        $('#filter_date_type').val('Yesterday');
        $('li').removeClass('active');
        $('#Yesterday').addClass('active');
        $('.datepicker').attr("disabled",true);

    });
    $("#week").click(function() {
        var now = new Date();
        var startDay = 1; //0=sunday, 1=monday etc.
        var d = now.getDay(); //get the current day
        var strDate = new Date(now.valueOf() - (d<=0 ? 7-startDay:d-startDay)*86400000); //rewind to start day
        var endDate = new Date(strDate.valueOf() + 6*86400000); //add 6 days to get last day

        strDate =  strDate.getDate()  + "-" + (strDate.getMonth() + 1) + "-" + strDate.getFullYear();
        endDate = endDate.getDate() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getFullYear() ;

        $('#datefilter').html(strDate + ' - ' + endDate);

        $('#from_date').val(strDate);
        $('#to_date').val(endDate);

        $('#filter_date_type').val('');
        $('#filter_date_type').val('week');
        $('li').removeClass('active');
        $('#week').addClass('active');
        $('.datepicker').attr("disabled",true);


    });

    $("#month").click(function() {

        var date = new Date();
        var strDate = new Date(date.getFullYear(), date.getMonth(), 1);
        
        var endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        strDate =  strDate.getDate()  + "-" + (strDate.getMonth() + 1) + "-" + strDate.getFullYear();
        endDate = endDate.getDate() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getFullYear() ;

        $('#datefilter').html(strDate + ' - ' + endDate);

        $('#from_date').val(strDate);
        $('#to_date').val(endDate);
        $('#filter_date_type').val('');
        $('#filter_date_type').val('month');
        $('li').removeClass('active');
        $('#month').addClass('active');
        $('.datepicker').attr("disabled",true);
    });

    $("#last_month").click(function() {

        // var date = new Date();
        // var strDate = new Date(date.getFullYear(), date.getMonth(), 1);
        // var endDate = new Date(date.getFullYear(), date.getMonth() , 0);
        var now = new Date();
        var endDate = new Date(now.getFullYear(), now.getMonth(), 0);
        var strDate = new Date(now.getFullYear() - (now.getMonth() > 0 ? 0 : 1), (now.getMonth() - 1 + 12) % 12, 1);
        strDate =  strDate.getDate()  + "-" + (strDate.getMonth() + 1) + "-" + strDate.getFullYear();
        endDate = endDate.getDate() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getFullYear() ;

        


        $('#datefilter').html(strDate + ' - ' + endDate);

        $('#from_date').val(strDate);
        $('#to_date').val(endDate);
        $('#filter_date_type').val('');
        $('#filter_date_type').val('last_month');
        $('li').removeClass('active');
        $('#last_month').addClass('active');
        $('.datepicker').attr("disabled",true);
    });

    $("#custom").click(function() {

        var d = new Date();
        var strDate = d.getDate()+ "-" + (d.getMonth() + 1) + "-" +d.getFullYear();

        $('#from_date').val(strDate);
        $('#to_date').val(strDate);
        $('#from_date').addClass('datepicker');
        $('#to_date').addClass('datepicker');
        $('#filter_date_type').val('');
        $('#filter_date_type').val('custom');
        $('li').removeClass('active');
        $('#custom').addClass('active');
        $('.datepicker').attr("disabled",false);

    });



    useEffect(() => {
        fn.lists();
        $('#masterActive').removeClass('active');
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <View d={d} fn={fn} used={used} />
        </>
    )

}

function View(props) {
    let d = props.d, fn = props.fn, used = props.used;

    const [orders, setOrders] = useState([]);
    const [totalByTypes, setTotalByTypes] = useState({});
    const [toDate, setToDate] = useState(new Date());
    const [total, setTotal] = useState({ total: 0, paid: 0, refund: 0, tax: 0, discount: 0, net_paid: 0, net: 0 });
    const [isPrintModalOpen, setPrintModalOpen] = useState(false);

    const openOrdersOfDate = (row,created) => {
        console.log(row);
        let orderTypes = { Dining: 0, Deliver: 0, Pickup: 0 };
        let records = [];
        let  nam=0, total = 0, amt=0, paid = 0, refund = 0, bal=0, cash_refund=0, bank_refund=0, tax = 0, discount = 0, net_paid = 0, cash_paid = 0, bank_paid = 0, net = 0;
        row.ite.forEach(v => {
            if(v){
                let ob = {
                created: created ,
                odr_no: v.odr_no,
                amt: util.nv(v.total_namt),
                am: util.nv(v.total_nam),
                namt: util.nv(v.amt),
                bal: util.nv(v.balance),
                paid_amt: util.nv(v.paid_amt),
                cash_paid: util.nv(v.cash_paid),
                cash_refund: util.nv(v.cash_refund),
                bank_refund: util.nv(v.bank_refund),
                bank_paid: util.nv(v.bank_paid),
                discount: util.nv(v.discount),
                tax: util.nv(v.tax),
                delivery_amount: util.nv(v.delivery_amount),
                tip_amt: util.nv(v.tip_amt),
                refund_amt: util.nv(v.refund_amt),
                order_type: v.order_type,
                branch: v.title,
                staff_name: v.name,
                status: v.status,
            };

            ob.net_paid = ob.amt - ob.refund_amt;
            ob.net = ob.cash_paid - ob.cash_refund;//+ob.tax
            nam += ob.am;
            total += ob.namt;
            amt += ob.amt;
            bal += ob.bal;
            paid += ob.paid_amt;
            cash_paid += ob.cash_paid;
            cash_refund += ob.cash_refund;
            bank_refund += ob.bank_refund;
            bank_paid += ob.bank_paid;
            refund += ob.refund_amt;
            tax += ob.tax;
            discount += ob.discount;
            net_paid += ob.net_paid;
            net += ob.net;
            orderTypes[ob.order_type] += ob.net_paid;
            

            records.push(ob);

            }
            
            
        });
        setOrders(records);
        setTotalByTypes(orderTypes);
        setToDate(records[0].created);
        setTotal({nam, total, amt, paid, refund, tax, discount, net_paid, bal, cash_paid, bank_paid, cash_refund, bank_refund, net });
        setPrintModalOpen(true);

        $("#wrapper").addClass("d-print-none");
    }

    const closePrintModal = () => {
        setPrintModalOpen(false);
        $("#wrapper").removeClass("d-print-none");
    }

    const print = () => {
        util.printHtmlFromIframe($(".spcontent").html());
    }

    let wh = $(window).height();

    return (
        <>
            <div className="">
                <div className="dashboard-top-filter">
                    <i className="fas fa-filter mr-3"></i>
                    <If cond={d.branches.length > 1}>
                        <button type="button" id="branch_name" className="btn btn-secondary mr-2" onClick={() => fn.openForm()}>
                            {d.branch_name}</button>
                    </If>

                    <If cond={d.branches.length == 1}>
                        <button type="button" id="branch_name" className="btn btn-secondary mr-2" >
                            {d.branch_name}</button>
                    </If>
                    <button type="button" className="btn btn-secondary" id="datefilter" onClick={() => fn.openDateForm()}>
                        {/* {d.todayDate} - {d.todayDate}</button> */}
                        {d.start_date} - {d.end_date}</button>
                </div>

                <div className="modal fade" id="branchModal" role="dialog" aria-labelledby="branchModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title" id="branchModalLabel">{lang('Branch')} </h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form id="bfrm" autoComplete="off" spellCheck="false">
                                    <div className="form-control mb5" >
                                        <label className="m-0 noselect">
                                            <input type="checkbox" className="selectall" value='' id='branchall' checked={d.branch_name == 'All' ? true : false} onClick={fn.setAllchecked} />
                                            &nbsp;&nbsp;{lang('All')}
                                        </label>
                                    </div>

                                    {d.branches.map((r, i) => (
                                        <div className="form-control mb5" key={i}>
                                            <label className="m-0 noselect">
                                                <input type="checkbox" className="selectall" name="branches[]" checked={d.branchesids?.includes(r.id) ? true : false} id={'branch_' + r.id} value={r.id} onChange={fn.setBranchCheck} />
                                                &nbsp;&nbsp;{r.title}
                                            </label>
                                        </div>
                                    ))}

                                    <input type="hidden" name="start_date" value={window.sessionStorage['start_date']} />
                                    <input type="hidden" name="end_date" value={window.sessionStorage['end_date']} />
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger btn-red" onClick={fn.save} >{lang('Apply')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="dateModal" role="dialog" aria-labelledby="dateModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="dateModalLabel">{lang('Choose Date')}  </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <ul className="nav nav-pills mb-3 order-tab mT12 horizontal-scroll">
                                    <li className="nav-item" id='Today' ><a className="nav-link text-dark-grey active" data-toggle="tab">{lang('Today')}</a></li>
                                    <li className="nav-item" id='Yesterday' ><a className="nav-link text-dark-grey" data-toggle="tab"  >{lang('Yesterday')}</a></li>
                                    <li className="nav-item" id="week" ><a className="nav-link text-dark-grey" data-toggle="tab">{lang('This Week')}</a></li>
                                    <li className="nav-item" id="month"><a className="nav-link text-dark-grey" data-toggle="tab"  >{lang('This Month')}</a></li>
                                    <li className="nav-item" id="last_month"><a className="nav-link text-dark-grey" data-toggle="tab" >{lang('Last Month')}</a></li>
                                    <li className="nav-item" id='custom' ><a className="nav-link text-dark-grey" data-toggle="tab" >{lang('Custom Date')}</a></li>
                                </ul>
                                <form id="datefrm" autoComplete="off" spellCheck="false">
                                    <div className="row">
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <div><label className="req">{lang('From')}</label></div>
                                                <input className="datepicker form-control" readOnly="readonly" id="from_date" name="start_date" value={d.start_date} data-date-format="dd-mm-yyyy" />
                                            </div>

                                            <div className="form-group col-md-6">
                                                <div><label className="req">{lang('To')}</label></div>
                                                <input className="datepicker form-control" id="to_date" readOnly="readonly" name="end_date" value={d.end_date} data-date-format="dd-mm-yyyy" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button> */}
                                <button type="button" className="btn btn-danger w120 text-uppercase fw600 btn-height36 fs14 btn-red" onClick={fn.applydate} >{lang('Apply')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 col-lg-12">
                    <div className="mt-5">
                        <If cond={!client_id}>
                            <div className="d-flex flex-row align-items-center justify-content-between pl-4 pr-4 pt-4">
                                <h6 className="m-0 h5 text-dark">{lang('Sales Report')}</h6>
                            </div>
                        </If>
                        <div className={client_id ? "" : "main-content"}>
                            <div className={client_id ? "" : "container-fluid"}>
                                <If cond={d.list.result.length && !client_id}>
                                    {/* <div className="d-flex">
                                            <div className="text-secondary bold600 mb5">
                                                Showing {d.list.page.start + 1} - {d.list.page.start + d.list.page.total} of {d.list.page.total_records} records.
                                </div>
                                        </div> */}
                                </If>

                                <div className="d-flex">
                                    <div className="form-inline">
                                        <form id="sbfrm" onSubmit={fn.lists} autoComplete="on" spellCheck="false">
                                            <div className="input-group search-control">
                                                <select className="form-control w160 single-line-dotted mr-3" name="orders_type"
                                                    onChange={e => { d.sdata.orders_type = e.target.value; fn.lists(); }}>
                                                    <option value="">{lang('Order Type (All)')}</option>
                                                    <option value="Dining">{lang('Dine-In')} </option>
                                                    <option value="Deliver">{lang('Deliver')}</option>
                                                    <option value="Pickup">{lang('Pickup')}</option>

                                                </select>

                                                <select className="form-control w180 single-line-dotted mr-3" name="types"
                                                    onChange={e => { d.sdata.types = e.target.value; fn.lists(); }}>
                                                    <option value="">{lang('Payment Type (All)')}</option>
                                                    <option value="cash">{lang('Cash')} </option>
                                                    <option value="bank">{lang('Bank')} </option>
                                                    <option value="cash_bank">{lang('CASH & BANK')} </option>

                                                </select>

                                                {/* <select className="form-control w130 single-line-dotted mr-3" name="staff_n"
                                                    onChange={e => { d.sdata.staff_n = e.target.value; fn.lists(); }}>
                                                    <option value="">Staff (All)</option>
                                                    {d.m_users.map(y => <option key={y.id} value={y.id}>{y.name}</option>)}
                                                </select> */}

                                                <div className="form-inline ml-2">
                                                    <div className="input-group search-control">
                                                        <a class="btn btn-md btn-outline-success noround" href={d.download_url} target="_self">
                                                                <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                                                        </a>
                                                    </div>
                                                </div>

                                                <div className="form-inline ml-2">
                                                    <div className="input-group search-control">
                                                        <button className="btn btn-md btn-outline-primary noround" onClick={fn.printSalesReport}><i className="fa fa-print"></i> </button>
                                                    </div>
                                                </div>

                                                

                                            </div>


                                        </form>

                                    </div>
                                    {/* <div className="form-inline">
                                        <div className="input-group search-control">
                                        <a class="btn btn-md btn-outline-success noround" href={d.downloadPdf} target="_self">
                                                     
                                                <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                </a>
                                             
                                        </div>
                                    </div> */}
                                    {/* <If cond={d.sub_role && d.sub_role[31] == 1 || d.user_type !='STAFF'}>
                                        
                                        <div className="form-inline ml-2">
                                                    <div className="input-group search-control">
                                                        <button className="btn btn-md btn-outline-primary noround" onClick={fn.printStaffReport}><i className="fa fa-print"></i> </button>
                                                    </div>
                                                </div>


                                    </If> */}


                                </div>

                                <div className="mt-4">
                                    <If cond={d.list.result.length}>
                                    {d.all_data.map((p,o) => (
                                        <div class="dashboard-overview mT10 mB20" key={o}>
                                            <div class="dashboard-overview-data w-25">
                                                <div class="fs22 fw600 text-black">{p.con}</div>
                                                <div class="fs14 fw400 text-dark-grey2 pr-4">{lang('Total Order')}</div>
                                            </div>
                                            <div class="dashboard-overview-data w-25">
                                                <div class="fs22 fw600 text-black"><AmtFormat curInfo={d.curInfo} amt={p.total_cash - p.bal} /></div>
                                                <div class="fs14 fw400 text-dark-grey2 pr-4">{lang('Total Cash')}</div>
                                            </div>
                                            <div class="dashboard-overview-data w-25">
                                                <div class="fs22 fw600 text-black"><AmtFormat curInfo={d.curInfo} amt={p.bank - p.bankr} /></div>
                                                <div class="fs14 fw400 text-dark-grey2 pr-4">{lang('Total Bank')}</div>
                                            </div>
                                            <div class="dashboard-overview-data w-25 border-danger">
                                                <div class="fs22 fw600 text-black"><AmtFormat curInfo={d.curInfo} amt={p.total_am} /></div>
                                                <div class="fs14 fw400 pr-4 text-red">{lang('Total Amount')}</div>
                                            </div>
                                        </div>
                                    ))}
                                    </If>
                                    <If cond={d.list.result.length}>
                                        <div className="table-responsive expanded-table-true">
                                            <table className="table table-sm border m-0">
                                                <thead className="bg-light text-uppercase font-sm">
                                                    <tr>
                                                        <th></th>
                                                        <th>{lang('Order Date')}</th>
                                                        <th>{lang('Total Orders')}</th>
                                                        <th>{lang('Total Sales Amount')}</th>
                                                        <th>{lang('Cash')}</th>
                                                        <th>{lang('Bank')}</th>
                                                        <th>{lang('Refund')}</th>
                                                        <th>{lang('Discount')}</th>
                                                        <th>{lang('Tip')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-text-vmid">
                                                    {d.list.result.map((v, i) => (
                                                        <React.Fragment key={i}>
                                                            <tr className="cursor-pointer"
                                                                data-toggle="collapse" data-target={'#demo' + v.created} aria-expanded="false" aria-controls={'demo' + v.created}>
                                                                {/* onClick={() => fn.openol(v)}> */}
                                                                <td><i className="bi bi-chevron-down"></i></td>
                                                                <td>{v.created}</td>
                                                                <td>{v.con}</td>

                                                                <td><div>
                                                                    <If cond={v.total_amt > 0}>
                                                                    
                                                                        <AmtFormat curInfo={d.curInfo} amt={v.refTotal} />
                                                                        {/* <AmtFormat curInfo={d.curInfo} amt={v.total_am-v.refund} /> */}
                                                                    </If>
                                                                    <If cond={v.total_amt == 0}>
                                                                        ----
                                                                    </If>

                                                                </div></td>
                                                                <td><div>
                                                                    <If cond={v.total_cash != 0}>
                                                                        <AmtFormat curInfo={d.curInfo} amt={v.total_cash-v.bal} />
                                                                    </If>
                                                                    <If cond={v.total_cash == 0}>
                                                                        ----
                                                                    </If>

                                                                </div></td>
                                                                <td ><div>
                                                                    <If cond={v.total_bank != 0}>
                                                                        <AmtFormat curInfo={d.curInfo} amt={v.total_bank} />
                                                                    </If>
                                                                    <If cond={v.total_bank == 0}>
                                                                        ----
                                                                    </If>

                                                                </div>
                                                                    {/* {v.items.map((r, j) => (
                                                                    <div key={j} className="clearfix">
                                                                        {r.item_name}&nbsp;&nbsp;({r.qty})
                                                                    </div>
                                                                    ))} */}

                                                                </td>
                                                                <td><If cond={v.refund > 0}>
                                                                    <AmtFormat curInfo={d.curInfo} amt={v.refund} />
                                                                </If>
                                                                    <If cond={v.refund == 0}>
                                                                        ----
                                                                    </If></td>
                                                                <td><If cond={v.discount > 0}>
                                                                    <AmtFormat curInfo={d.curInfo} amt={v.discount} />
                                                                </If>
                                                                    <If cond={v.discount == 0}>
                                                                        ----
                                                                    </If></td>
                                                                <td>
                                                                    <If cond={v.tip > 0}>
                                                                        <AmtFormat curInfo={d.curInfo} amt={v.tip} />
                                                                    </If>
                                                                    <If cond={v.tip == 0}>
                                                                        ----
                                                                    </If>
                                                                </td>

                                                            </tr>
                                                            <tr id={'demo' + v.created} className="collapse p-3">
                                                                <td colspan="9" className="p-3">
                                                                    <div className="mb-2">
                                                                        <button class="btn btn-md btn-outline-primary noround" onClick={() => openOrdersOfDate(v,v.created)}><i class="fa fa-print" aria-hidden="true"></i> </button>
                                                                    </div>
                                                                    <div className="table-responsive mb-3">
                                                                        <table className="table table-striped table-sm border m-0">
                                                                            <thead className="bg-light">
                                                                                <tr>
                                                                                    <th>{lang('Order Date')}</th>
                                                                                    <th>{lang('Order ID')}</th>
                                                                                    <th>{lang('Invoice Amount')}</th>
                                                                                    <th>{lang('Cash')}</th>
                                                                                    <th>{lang('Bank')}</th>
                                                                                    <th>{lang('Refund')}</th>
                                                                                    <th>{lang('Discount')}</th>
                                                                                    <th>{lang('Tip')}</th>
                                                                                    <th>{lang('Branch')}</th>
                                                                                    <th>{lang('Staff Name')}</th>
                                                                                    <th>{lang('Order Type')}</th>
                                                                                    <th>{lang('Status')}</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {v.ite.length && v.ite.map((p) => (
                                                                                    <If cond={p.odr_no}>
                                                                                        <tr id={'demo' + v.created} className="collapse table border m-0">
                                                                                            <td>{p.created}</td>
                                                                                            <td>{p.odr_no}</td>
                                                                                            <td>
                                                                                                <AmtFormat curInfo={d.curInfo} amt={p.total_namt} /></td>
                                                                                            <td><If cond={p.cash_paid > 0}>
                                                                                                <If cond={p.status != 'Cancelled'}>
                                                                                                <AmtFormat curInfo={d.curInfo} amt={p.cash_paid-(-p.total_nam)} />
                                                                                                </If>
                                                                                                <If cond={p.status == 'Cancelled'}>
                                                                                                <AmtFormat curInfo={d.curInfo} amt={p.cash_paid-(-p.total_nam)-p.cash_refund} />
                                                                                                </If>
                                                                                                
                                                                                            </If>
                                                                                                <If cond={p.cash_paid == 0}>
                                                                                                    ----
                                                                                                </If></td>
                                                                                            <td><If cond={p.bank_paid > 0}>
                                                                                                <AmtFormat curInfo={d.curInfo} amt={p.bank_paid-p.bank_refund} />
                                                                                            </If>
                                                                                                <If cond={p.bank_paid == 0}>
                                                                                                    ----
                                                                                                </If></td>
                                                                                            <td><If cond={p.refund_amt > 0}>
                                                                                                <AmtFormat curInfo={d.curInfo} amt={p.refund_amt} />
                                                                                            </If>
                                                                                                <If cond={p.refund_amt == 0}>
                                                                                                    ----
                                                                                                </If></td>
                                                                                            <td><If cond={p.discount > 0}>
                                                                                                <AmtFormat curInfo={d.curInfo} amt={p.discount} />
                                                                                            </If>
                                                                                                <If cond={p.discount == 0}>
                                                                                                    ----
                                                                                                </If></td>
                                                                                            <td><If cond={p.tip_amt > 0}>
                                                                                                <AmtFormat curInfo={d.curInfo} amt={p.tip_amt} />
                                                                                            </If>
                                                                                                <If cond={p.tip_amt == 0}>
                                                                                                    ----
                                                                                                </If></td>
                                                                                            <td>{p.title}</td>
                                                                                            <td>{p.name}</td>
                                                                                            <td>{p.order_type}</td>
                                                                                            <If cond={p.payment_status == 1 && p.paid_amt > 0 && p.status != 'Cancelled'}>
                                                                                                <td>{lang('Paid')}</td>
                                                                                            </If>
                                                                                            <If cond={p.paid_amt == 0 && p.payment_status == 0 && p.status != 'Cancelled'}>
                                                                                                <td>{lang('Unpaid')}</td>
                                                                                            </If>
                                                                                            <If cond={p.paid_amt == 0 && p.payment_status == 1 && p.status != 'Cancelled'}>
                                                                                                <td>{lang('Paid')}</td>
                                                                                            </If>

                                                                                            <If cond={p.pay_mode == null && p.status != 'Cancelled'}>
                                                                                                <td>{lang('Unpaid')}</td>
                                                                                            </If>
                                                                                            <If cond={p.status == 'Cancelled'}>
                                                                                                <td>{lang('cancelled')}</td>
                                                                                            </If>



                                                                                        </tr>

                                                                                    </If>
                                                                                    
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <If cond={!client_id}>
                                            <div className="d-flex justify-content-between mt-4 pb-3">
                                                <div className="mr5">
                                                    <Paging fn={fn.lists} pageob={d.list.page} />
                                                </div>
                                                <div>
                                                    <Perpagedd fn={fn.lists} />
                                                </div>
                                            </div>
                                        </If>
                                    </If>
                                    <If cond={!d.list.result.length}>
                                        <div className="no-rec">{lang('No record found')}</div>
                                    </If>
                                    {/* <div className='center'>{d.all_data.con}</div>
                                    <div>{d.all_data.amt}</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="iframe_print"></div>

            <Modal
                title="Print Sales Orders"
                visible={isPrintModalOpen}
                okText="Print"
                onOk={print}
                cancelText="Close"
                onCancel={closePrintModal}
                destroyOnClose
                maskClosable={false}
                width={"600px"}
                style={{ top: 20 ,fontWeight : 'bold',fontColor :'#000',fontFamily: 'Arial' }}
                bodyStyle={{ height:wh-150 }}
            >
                <div className='spcontent'>
                    <div className='p15'>

                        <div className="text-center">
                            <div   className="bold600 fs20">{lang('Sales Report')}</div>
                            <div>{lang('Date')}: <span className="bold600">{toDate}</span></div>
                            <div>{lang('Sub-Total')}: <span className="bold600"><AmtFormat curInfo={d.curInfo} amt={total.amt} /></span></div>
                            {/* <div>Tax: <span className="bold600"><AmtFormat curInfo={d.curInfo} amt={total.tax} /></span></div> */}
                            <div>{lang('Discount')}: <span className="bold600"><AmtFormat curInfo={d.curInfo} amt={total.discount} /></span></div>
                            <div>{lang('Refund')}: <span className="bold600"><AmtFormat curInfo={d.curInfo} amt={total.refund} /></span></div>
                            <div>{lang('Total')}: <span className="bold600"><AmtFormat curInfo={d.curInfo} amt={total.net_paid} /></span></div>
                            <div>
                                {lang('Cash')}: <span className="bold600"><AmtFormat curInfo={d.curInfo} amt={total.net-total.bal} /></span> |
                                {lang('Bank')}: <span className="bold600"><AmtFormat curInfo={d.curInfo} amt={total.bank_paid-total.bank_refund} /></span>
                            </div>

                            <div className='pt20'>
                            {lang('Dining')}: <span className="bold600"><AmtFormat curInfo={d.curInfo} amt={totalByTypes.Dining} /></span> |
                            {lang('Deliver')}: <span className="bold600"><AmtFormat curInfo={d.curInfo} amt={totalByTypes.Deliver} /></span> |
                            {lang('Pickup')}: <span className="bold600"><AmtFormat curInfo={d.curInfo} amt={totalByTypes.Pickup} /></span>
                            </div>
                        </div>

                        <div className='pt15'>
                            <div className=''>
                                <table className='wper100'>
                                    <thead className='bg-light bold600'>
                                        <tr>
                                            <td>#</td>
                                            <td>{lang('Order')}</td>
                                            <td>{lang('Branch')}</td>
                                            <td>{lang('Staff')}</td>
                                            <td>{lang('Type')}</td>
                                            {/* <td>Status</td> */}
                                            <td className='text-right'>{lang('Amount')}</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders.map((v, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}.</td>
                                                <td>{v.odr_no}</td>
                                                <td>{v.branch}</td>
                                                <td>{v.staff_name}</td>
                                                <td>{v.order_type}</td>
                                                {/* <td>{v.status}</td> */}
                                                <td className='text-right'>
                                                    <AmtFormat curInfo={d.curInfo} amt={v.net_paid} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

