import React, {useState, useEffect} from 'react';
import util from "../../util/util";
import {If} from "../../util/controls";
let $=window.$;

export default function Roles(props) {
    const fn={};
    const [d, setData]=useState({
        result:[],
        modules:[],
        data:{app_modules:[]},
    });
    fn.render=()=>{
        setData({...d});
    }

    fn.handleChange=(e)=>{
        d.data[e.target.name]=e.target.value;
        fn.render();
    }

    fn.openForm=(dtl)=>{
        d.data=dtl?{...dtl}:{app_modules: "", type:'CLIENT'};
        d.data.app_modules=d.data.app_modules.split(",");
        fn.render();
        window.showModal($("#formModal"));
    }

    fn.lists=(e)=>{
        if(e)e.preventDefault();
        util.ajx("master/role_list", $("#sfrm").serialize()).then(res=>{
            if(res.code===200){
                d.result=res.result;
                fn.render();
            }
        })
    }

    fn.deleterec=(id)=>{
        let callback=()=>{
            util.ajx("master/delete_role", {id:id}).then(res=>{
                if(res.code===200){
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.save=()=>{
        let fd=new FormData($("#frm")[0]);
        util.ajx("master/save_role", fd).then(res=>{
            if(res.code===200){
                fn.lists();
                window.hideModal($("#formModal"));
            }
        })
    }

    const init=()=>{
        util.ajx("master/role_init", {}).then(res=>{
            if(res.code===200){
                d.modules=res.modules;
                d.result=res.result;
                fn.render();
            }
        })
    }
    useEffect(()=>{
        init();
        // eslint-disable-next-line
    }, []);

    return View(d, fn, fn.render);
}

function View(d, fn){
    return(
        <>
            <div className="col-xl-12 col-lg-12">
                <div className="card shadow mb-4 rounded-lg">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 h5 text-dark">User Roles</h6>
                        <div className="ml-auto mt-auto mb-auto">
                            <button className="btn btn-outline-primary" onClick={() => fn.openForm()}><i className="fa fa-plus"></i> Add</button>
                        </div>
                    </div>
                    <div className="card-body px-0">
                        <div className="main-content">
                            <div className="container-fluid">
                                <div className="d-flex tbl-search-head">
                                    <div className="form-inline">
                                        <form id="sfrm" onSubmit={fn.lists}>
                                            <div className="input-group">
                                                <input type="search" className="form-control" name="k" placeholder="Search" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-light-blue" type="submit"><i className="fa fa-search"></i></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="ml-auto mt-auto mb-auto">
                                    </div>
                                </div>

                                <div>
                                    <If cond={d.result.length}>
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-sm table-striped table-hover m-0">
                                                <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                                    <tr>
                                                        <th className="w20">SN</th>
                                                        <th>Title</th>
                                                        <th className="w150">Role For</th>
                                                        <th className="w70"></th>
                                                    </tr>
                                                </thead>

                                                <tbody className="table-text-vmid">
                                                    {d.result.map((v, i)=>(
                                                        <tr key={i}>
                                                            <td>{i+1}.</td>
                                                            <td>{v.title}</td>
                                                            <td>{v.type}</td>
                                                            <td className="text-center">
                                                                <div className="btn-group">
                                                                    <button type="button" className="btn btn-sm btn-light-blue gr" onClick={()=>fn.openForm(v)}><i className="fa fa-edit"></i></button>
                                                                    <button type="button" className="btn btn-sm btn-danger gr" onClick={()=>fn.deleterec(v.id)} disabled={v.is_system_defined==='1'}><i className="fa fa-trash-o"></i></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </If>
                                    <If cond={!d.result.length}>
                                        <div className="no-rec">No record found</div>
                                    </If>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/** Form */}
            <div className="modal" id="formModal" data-backdrop="static">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <div className="modal-title uc">{d.data.id?'Edit':'Add'} Role</div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <form id="frm" autoComplete="off" spellCheck="false">
                                <input type="hidden" name="id" defaultValue={d.data.id} />
                                <div className="row mingap">
                                    <div className="form-group col-md-9">
                                        <label className="req">Role Name</label>
                                        <input type="text" className="form-control" name="title" value={d.data.title || ''} onChange={fn.handleChange} />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label className="req">Role For</label>
                                        <select className="form-control" name="type" value={d.data.type || ''} onChange={fn.handleChange}>
                                            <option>CLIENT</option>
                                            <option>ADMIN</option>
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <fieldset>
                                            <legend>Modules</legend>
                                            <div className="p10">
                                                <div className="row mingap">
                                                    {d.modules.map((row)=>{
                                                        return <If cond={row.type===d.data.type} key={row.id}>
                                                                    <div className="col-md-6">
                                                                        <label>
                                                                            <input type="checkbox" value={row.id} checked={d.data.app_modules.indexOf(row.id) >= 0} name="app_modules[]" onChange={(e)=>{
                                                                                if(e.target.checked){
                                                                                    d.data.app_modules.push(e.target.value);
                                                                                }else{
                                                                                    d.data.app_modules.splice(d.data.app_modules.indexOf(e.target.value),1);
                                                                                }
                                                                                fn.render();
                                                                            }} /> {row.title}
                                                                        </label>
                                                                    </div>
                                                                </If>
                                                    })}
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={fn.save}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    )
}