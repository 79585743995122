
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import util from "../../util/util";
import { sdx } from "../../sdx";
import { If, Paging, Perpagedd } from "../../util/controls";
import SalesStat from "./sales-stat";
import lang from '../../lang';

//  import DashboardAccoard from "./accoard";

// import PaymentStat from "./dashboard/payment-stat";
// import WeeklySalesStat from "./dashboard/weekly-sales-stat";
// import ProductStat from "./dashboard/product-stat";
let $=window.$;
export default function Dashboard(props) {
  const fn = {};
  const [d, setData] = useState({
    list: { result: [], page: {} },
    sdata: { p: 1, ps: 25 },
    data: {},
    branches: [],
    roles: [],
    role_id: 0,
    is_arabic: 0,
    sub_role :[],
    super_role:[],
    user_type:'',
    subscription_id:[],
    branchesids: [],
    branch_name : '',
    todayDate : '',
    start_date : '',
    end_date : '',
    branchesidscopy: [],
  });
  fn.render = () => { setData({ ...d }); }

  fn.handleChange = (e) => {
    d.data[e.target.name] = e.target.value;
    fn.render();
  }

  fn.openForm = (dtl) => {

    d.data = dtl ? { ...dtl } : { branches: [] };
    var branch_ids = window.sessionStorage['branch_ids'];
    var branch_ids_array;
    var i;
    if (branch_ids != '') {
      branch_ids_array = branch_ids.split(',');

      if (branch_ids_array.length > 0) {
        for (i = 0; i < branch_ids_array.length; i++) {
          $("#branch_" + branch_ids_array[i]).attr('checked', true);
        }

      }

    }
    else {
      // $("#branchall").attr('checked', true);
    }

    fn.render();
    window.showModal($("#branchModal"), true);
  }

  fn.openDateForm = (dtl) => {

    d.data = dtl ? { ...dtl } : { branches: [] };

    var filter_date_type = window.sessionStorage['filter_date_type'];
    console.log(filter_date_type)

    $('li').removeClass('active');
    if (filter_date_type != '') {
      $('#' + filter_date_type).addClass('active');

    }

    if (filter_date_type != 'all') {
      $('#from_date').val(window.sessionStorage['start_date']);
      $('#to_date').val(window.sessionStorage['end_date']);
    } else {
      $('#from_date').val('');
      $('#to_date').val('');
    }

    // $('#filter_date_type1').val(window.sessionStorage['filter_date_type']);
    fn.render();
    window.showModal($("#dateModal"), true);
  }

  fn.setAllchecked = (e) => {
    let bid = e.target.value;
    if (!e.target.checked) {
      d.branchesids = [];
      d.branch_name = '';
    }else{
        d.branchesids =  d.branchesidscopy.slice() ;
        d.branch_name = 'All';
    }
    fn.render();
  }

  // fn.setBranchCheck = (e) => {
  //   let bid = e.target.value;
  //   if (e.target.checked) {
  //     d.data.branches.push(bid);
  //   } else {
  //     d.data.branches.splice(d.data.branches.indexOf(bid), 1);
  //   }
  //   fn.render();
  // }
  fn.setBranchCheck = (e) => {
    let bid = e.target.value;
    if (e.target.checked) {
      d.branchesids.push(bid);
    } else {
      d.branchesids.splice(d.branchesids.indexOf(bid), 1);
    }
    // d.branchesids = d.data.branches;
    d.branch_name = '';
    if(d.branchesids.length ===  d.branchesidscopy.length){
        d.branch_name = 'All';
    }

    fn.render();
  }

  fn.save = () => {
    let fd = new FormData($("#bfrm")[0]);
    fd.append("lang", window.localStorage['lang']);
    util.ajx("dashboard/list", fd).then(res => {
      $("#branchModal").modal('hide');
      $('#DashboardAccoard').html(res.result);
      d.branches = res.branches;
      d.data.branches = res.branch_ids;
      fn.render();
    })
  }


  fn.applydate = (type) => {
    let fd = new FormData($("#datefrm")[0]);
    if(type){
      fd.append("date_type", type);
    }

    fd.append("lang", window.localStorage['lang']);
   

    util.ajx("dashboard/list", fd).then(res => {
      $("#dateModal").modal('hide');
      $('#DashboardAccoard').html(res.result);
      d.branches = res.branches;
      d.data.branches = res.branch_ids;
      fn.render();
    })
  }

  fn.init = () => {
    let start_date = window.sessionStorage['start_date'];
    let end_date = window.sessionStorage['end_date'];
    let branch_ids = window.sessionStorage['branch_ids'];
    let filter_date_type = window.sessionStorage['filter_date_type'];
    

     $('.btoonActive').removeClass('active');



    util.ajx("dashboard/list", { end_date: end_date, start_date: start_date, branch_ids: branch_ids, filter_date_type: filter_date_type,date_type:'today' ,'lang' : window.localStorage['lang']}).then(res => {
      $('#DashboardAccoard').html(res.result);
      d.branches = res.branches;
      d.data.branches = res.branch_ids;
      d.sub_role = sdx.d.sub_role;
      d.super_role = sdx.d.super_role;
      d.user_type = sdx.d.user_type;
      d.subscription_id = sdx.d.subscription_id;
      d.branchesids = res.branch_ids;
      d.branchesidscopy = res.branch_ids_copy;
      if(d.branchesids.length ===  d.branchesidscopy.length && d.branchesids.length > 1){
          d.branch_name = 'All';
      }
     

      fn.render();
      $('#branch_name').html(res.branch_name);
      if(d.sub_role && ( d.sub_role[29] != 1)  && d.user_type =='STAFF'){
        util.logout('', props.history);
      }

      let date_type = window.sessionStorage['date_type'];
     if(date_type == 'today'){
      $('#dateToday').addClass('active');
     }
     else if(date_type == 'yesterday'){
      $('#dateYesterday').addClass('active');
     }
     else if(date_type == 'week'){
      $('#dateWeek').addClass('active');
     }
     else if(date_type == 'month'){
      $('#dateMonth').addClass('active');
     }
     else if(date_type == 'custom'){
      $('#dateCustom').addClass('active');
     }


    })
  }
  useEffect(() => {
    fn.init();
    $('#masterActive').removeClass('active');
    clearTimeout(sdx.orderlist);
    clearTimeout(sdx.kitchenlist);
    
    // eslint-disable-next-line
  }, []);

  return View(d, fn);
}

function View(d, fn) {
  return <>
    <div className="main-content p-0">
      <div className="sticky-top dashboard-top-filter d-lg-flex d-md-flex d-sm-block justify-content-lg-between justify-content-md-between justify-content-sm-center">
        <div>
          <i className="fas fa-filter mr-3"></i>
          <If cond={d.branches.length > 1}>
              <button type="button" id="branch_name" className="btn btn-red-outline btn-danger" onClick={() => fn.openForm()}>
                All Branch</button>
            </If>

            <If cond={d.branches.length == 1}>
              <button type="button" id="branch_name" className="btn btn-red-outline btn-danger">
                All Branch</button>
            </If>
        </div>
        <div className="btn-group btn-group-toggle" data-toggle="buttons">
          <label className="btn btn-red-outline btn-danger active btoonActive" id="dateToday" onClick={() => fn.applydate('today')}>
            <input type="radio" name="options" id="Today" defaultChecked /> {lang('Today')}
          </label>
          <label className="btn btn-red-outline btn-danger btoonActive" id="dateYesterday" onClick={() => fn.applydate('yesterday')}>
            <input type="radio" name="options" id="Yesterday" /> {lang('Yesterday')}
          </label>
          <label className="btn btn-red-outline btn-danger btoonActive" id="dateWeek" onClick={() => fn.applydate('week')}>
            <input type="radio" name="options" id="week" /> {lang('Week')}
          </label>
          <label className="btn btn-red-outline btn-danger btoonActive" id="dateMonth"  onClick={() => fn.applydate('month')}>
            <input type="radio" name="options" id="month" /> {lang('Month')}
          </label>
          <label className="btn btn-red-outline btn-danger btoonActive"  id="dateCustom" onClick={() => fn.openDateForm()}>
            <input type="radio" name="options" id="datefilter" /> {lang('Custom Date')}
          </label>
        </div>
        {/* <button type="button" className="btn btn-secondary" id="datefilter" onClick={() => fn.openDateForm()}>
          Select Date</button> */}
        {/* <button type="button" id="Today" className="btn btn-secondary" onClick={fn.applydate} >Today</button>
        <button type="button" id="Yesterday" className="btn btn-secondary" onClick={fn.applydate} >Yesterday</button>
        <button type="button" id="week" className="btn btn-secondary" onClick={fn.applydate} >Week</button>
        <button type="button" id="month" className="btn btn-secondary" onClick={fn.applydate} >Month</button>
        <button type="button" className="btn btn-secondary" id="datefilter" onClick={() => fn.openDateForm()}>
          Custom date</button> */}

      </div>


      <div className="modal fade" id="branchModal" role="dialog" aria-labelledby="branchModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="branchModalLabel">{lang('Branch')} </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form id="bfrm" autoComplete="off" spellCheck="false">
                <div className="form-control mb5" >
                  <label className="m-0 noselect">
                    <input type="checkbox" className="selectall" value='' id='branchall' checked={d.branch_name == 'All' ? true : false } onChange={fn.setAllchecked} />
                    &nbsp;&nbsp;All Branch
                  </label>
                </div>

                {d.branches.map((r, i) => (
                  <div className="form-control mb5" key={i}>
                    <label className="m-0 noselect">
                      <input type="checkbox" className="selectall" name="branches[]" checked={d.branchesids?.includes(r.id) ? true : false}  id={'branch_' + r.id} value={r.id} onChange={fn.setBranchCheck} />
                      &nbsp;&nbsp;{r.title}
                    </label>
                  </div>
                ))}

                <input type="hidden" name="start_date" value={window.sessionStorage['start_date'] || ''} />
                <input type="hidden" name="end_date" value={window.sessionStorage['end_date'] || ''} />
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger btn-red" onClick={fn.save} >{lang('Apply')}</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="dateModal" role="dialog" aria-labelledby="dateModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="dateModalLabel">{lang('Select Custom Date')} </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

              <div className="container">
                {/* <ul className="nav nav-tabs">
                  <li id='Today' ><a >Today</a></li>
                  <li id='Yesterday' ><a data-toggle="tab"  >Yesterday</a></li>
                  <li id="week" ><a data-toggle="tab">This Week</a></li>
                  <li id="month"><a data-toggle="tab"  >This Month</a></li>
                  <li id="last_month"><a data-toggle="tab" >Last Month</a></li>
                  <li id='custom' ><a data-toggle="tab" >Custom</a></li>
                  <li id='all' ><a data-toggle="tab" >All</a></li>
                </ul> */}
                <form id="datefrm" autoComplete="off" spellCheck="false">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <div><label className="req">{lang('From')}</label></div>
                      <input className="datepicker form-control" id="from_date" name="start_date" data-date-format="dd-mm-yy" />
                    </div>

                    <div className="form-group col-md-6">
                      <div><label className="req">{lang('To')}</label></div>
                      <input className="datepicker form-control" id="to_date" name="end_date" data-date-format="dd-mm-yy" />
                    </div>

                    <input type="hidden" name="branchesdate" value={window.sessionStorage['branch_ids']} />
                    <input type="hidden" name="filter_date_type" id="filter_date_type" value={window.sessionStorage['filter_date_type']} />
                  </div>
                </form>

              </div>


            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger btn-red" onClick={() => fn.applydate('custom')} >{lang('Apply')}</button>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid" id="DashboardAccoard">


        {/* <div className="mb-3" >
                   
                </div> */}

        {/* <div className="mb-3" >
                   <DashboardAccoard />
                </div> */}

        {/* <div className="mb-3">
                    <SalesStat />    
                </div>                 */}

        {/*  <div className="mb-3">
                    <PaymentStat />    
                </div>

                <div className="mb-3">
                    <ProductStat />    
                </div>

                <div className="mb-3">
                    <WeeklySalesStat />    
                </div> */}

      </div>
    </div>
  </>;
}