import React, { useState, useEffect } from 'react';
import util from "../../util/util";
import { useParams } from 'react-router-dom';
import { sdx } from "../../sdx";
import { If, Paging, Perpagedd } from "../../util/controls";
import fbase from "../../services/firebase";
let fbaseFlg = 0;

let $ = window.$;


export default function Kitchen(props) {
    const fn = {};
    const { id } = useParams();
    let pfn = props.computedMatch;
    const [d, setData] = useState({
        list: { result: [], page: {} },
        sdata: { p: 1, ps: 25, status: 'New' },
        data: {},
        branches: [],
        roles: [],
        role_id: 0,
        is_arabic: 0,
        audio_notification : 0,
        sub_role :[],
        super_role:[],
        user_type:'',
        subscription_id:[],
        branch_id:'',
        date_type : '',
    });

    function playAudio(audio_notification) {
        return; //no need more
        $(".new-odr-cover").remove();
        $("#content").append(`<div class="new-odr-cover uc shadow">
            New Order
            <div class="pt10">
                <button class="btn btn-danger btn-block" type="button" onClick='$(".new-odr-cover").remove(); $(".sound")[0].pause()'>OK</button>
            </div>
        </div>`);
        $(".sound")[0].currentTime = 0;
        if (audio_notification == '1') {
            $(".sound")[0].crossOrigin = 'anonymous';
            $(".sound")[0].play();
        }
    }

    

    const allowAudio = () => {
        if (d.audio_notification == '0') {
            try {
                $(".sound")[0].muted = true;
                    $(".sound")[0].crossOrigin = 'anonymous';
                    $(".sound")[0].play();
                    setTimeout(() => {
                        $(".sound")[0].pause();
                        //$(".sound").prop("muted", false);
                        $(".sound")[0].muted = false;
                    }, 2000);
                    
                /*let callback = () => {
                    //$("body .sound").remove();
                    //$("body").append(`<audio src="sound.mp3" loop="true" className="d-none sound" muted></audio>`);
                    $(".sound")[0].muted = true;
                    $(".sound")[0].play();
                    setTimeout(() => {
                        $(".sound")[0].pause();
                        //$(".sound").prop("muted", false);
                        $(".sound")[0].muted = false;
                    }, 2000);


                    util.ajx("orders/set_audio_notification").then(res => { })



                }
                window.openConfirmBootbox("Audio", "Off audio notification?", callback);*/
                // window.bootboxAlert("Audio", "Allow audio notification?", callback);

            } catch (e) {
            }

        } else {

            try {
                $(".sound")[0].muted = true;
                // $(".sound")[0].play();
                setTimeout(() => {
                    $(".sound")[0].pause();
                    //$(".sound").prop("muted", false);
                    $(".sound")[0].muted = false;
                }, 2000);


            } catch (e) {
            }

        }

    }

    const sdata = (p, ps) => {
        d.sdata.p = p ? p : 1;
        d.sdata.ps = ps ? ps : d.sdata.ps;
        return $("#sfrm").serialize() + '&p=' + d.sdata.p + '&ps=' + d.sdata.ps + '&status=' + d.sdata.status;
    }

    const onNewOrder = (client_id,branch_id) => {
        fbaseFlg = 0;

        fbase.onNewMsg("agnapos_orders_" + client_id, (msgs) => {
            if (!fbaseFlg) {
                fbaseFlg = 1;
                return;
            }
            
            if (msgs[0].branch_id * 1 === branch_id * 1 && msgs[0].confirm_order == 1) {


                switch (msgs[0].type) {
                    case 'New':
                        if (d.sdata.status === 'New') {

                            playAudio(d.audio_notification);

                            // $("#sfrm [name='k']").val('');
                            lists();
                        }
                        break;
                    default:
                }
            }
        });
    }

    
    fn.render = () => { setData({ ...d }); }
    
    const lists = () => {
        let arg =  pfn.params;
        let branch_id_val = $('#branch_id_val').val();

        if(branch_id_val){
            arg['branch_id'] = branch_id_val;
        }

        if($('#date_type_val').val()){
            arg['date_type'] = $('#date_type_val').val();
        }
        arg['lang'] = window.localStorage['lang'];

        

        util.ajx("kitchen/lists", arg).then(res => {
            $('#main').html('');
            $('#main').append(res.result);

            d.audio_notification = res.audio_notification;
            d.sub_role = sdx.d.sub_role;
            d.super_role = sdx.d.super_role;
            d.user_type = sdx.d.user_type;
            d.subscription_id = sdx.d.subscription_id;
            d.branch_id = res.branch_id;
            d.date_type = res.date_type;

            setData({ ...d });
            // sdx.kitchenlist =  setInterval(() => {
            //     lists();
            // }, 30000)

            setData({ ...d });
            if(sdx.d.sub_role && ( sdx.d.sub_role[36] != 1 && sdx.d.sub_role[37] != 1 && sdx.d.sub_role[38] != 1  )  && sdx.d.user_type =='STAFF'){
                util.logout('', props.history);
            }

            if(d.subscription_id && ( d.subscription_id[9] != 1)  && d.user_type =='CLIENT'){
                util.logout('', props.history);
            }

        })
    }

    fn.init = () => {
      let arg =  pfn.params;
      arg['lang'] = window.localStorage['lang'];
        util.ajx("kitchen/lists",arg).then(res => {

            $('#main').html('');
            $('#main').append(res.result);
            d.audio_notification = res.audio_notification;
            d.branch_id = res.branch_id;
            d.date_type = res.date_type;
            setData({ ...d });

            d.sub_role = sdx.d.sub_role;
            d.super_role = sdx.d.super_role;
            d.user_type = sdx.d.user_type;
            d.subscription_id = sdx.d.subscription_id;

            setData({ ...d });
            allowAudio();
            onNewOrder(res.client_id,res.branch_id);

            if(sdx.kitchenlist){
                clearInterval(sdx.kitchenlist);
            }
            sdx.kitchenlist =  setInterval(() => {
                lists();
            }, 30000)

            setData({ ...d });
            if(sdx.d.sub_role && ( sdx.d.sub_role[36] != 1 && sdx.d.sub_role[37] != 1 && sdx.d.sub_role[38] != 1  )  && sdx.d.user_type =='STAFF'){
                util.logout('', props.history);
            }

            if(d.subscription_id && ( d.subscription_id[9] != 1)  && d.user_type =='CLIENT'){
                util.logout('', props.history);
            }
        })
    }
    useEffect(() => {
        fn.init();
        $('#masterActive').removeClass('active');
        clearTimeout(sdx.orderlist);
        return () => {
            if(sdx.kitchenlist){
                clearInterval(sdx.kitchenlist);
            }
        }
        
    }, []);



    return View(d, fn);

}

function View(d, fn) {
    return (
        <>
            <div>
                <input type="hidden" id="branch_id_val" value={d.branch_id} />
                <input type="hidden" id="date_type_val" value={d.date_type} />
                <div className="p-3 positive-relative" id="main">
                    
                </div>
            </div>
        </>
    )
}


// export default Menu;