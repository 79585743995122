
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import util from "../../util/util";
import { sdx } from "../../sdx";
import { If, Paging, Perpagedd } from "../../util/controls";
import SalesStat from "./sales-stat";

let $ = window.$;
export default function Dashboard() {

    const fn = {};
    const [d, setData] = useState({
        list: { result: [], page: {} },
        sdata: { p: 1, ps: 25 },
        data: {},
        branches: [],
        roles: [],
        role_id: 0,
        is_arabic: 0,
    });
    fn.render = () => { setData({ ...d }); }

    fn.init = () => {
       let start_date = window.sessionStorage['start_date'];
       let end_date = window.sessionStorage['end_date'];
       let branch_ids = window.sessionStorage['branch_ids'];
       let filter_date_type = window.sessionStorage['filter_date_type'];
        util.ajx("dashboard/horly_breakdown",{end_date:end_date,start_date:start_date,branch_ids:branch_ids,filter_date_type:filter_date_type,'lang' : window.localStorage['lang']}).then(res => {
            $('#DashboardAccoard').html(res.result);
           
            fn.render();
        })
    }
    useEffect(() => {
        fn.init();
        $('#masterActive').removeClass('active');
        // eslint-disable-next-line
    }, []);

    return View(d, fn);
}
    
function View(d, fn) {
    return <>
        <div className="main-content p-0">
            
            <div className="container-fluid" id="DashboardAccoard">


            </div>
        </div>
    </>;
}