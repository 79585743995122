import React, { useState, useEffect } from 'react';
import {NavLink} from 'react-router-dom';
import util from "../../util/util";
import {If, Paging, Perpagedd, AmtFormat} from "../../util/controls";
import {sdx} from "../../sdx";
import Services from "../../Services";
import {message} from "antd";
import lang from '../../lang';
let $=window.$;

let cat_id = 0, ref = {};
export default function Items(props) {
    cat_id = props.cat_id || 0;
    const fn = {};
    const [d, setData] = useState({
        list: { result: [], page: {} },
        sdata: { p: 1, ps: 25 },
        data: {},
        branches: [],
        branchesCopy: [],
        // spicy_level:[],
        item_cats: [],
        curInfo: {},
        special_category: [],
        ingredients: [],
        add_ons: [],
        spicy_level: [],
        is_arabic: 0,
        languageset: '',
        size_type: 'S',
        sub_role :[],
        super_role:[],
        user_type:'',
        setDiscount:0,
        setStock:0,
        subscription_id:[],
        recipeIng:[],
        ingLists:[],
    });
    fn.render = () => { setData({ ...d }); }

    fn.changeCategory = (e) =>{
        d.data[e.target.name] = e.target.value;
        var id = e.target.value;
        if (id != '') {
            //  alert("ok");
            util.ajx("cmaster/branchlist", { id: id }).then(res => {
                
                    d.branches = res.result;
                fn.render();
            })
        }else{
            util.ajx("cmaster/branchlist", { id: id }).then(res => {
               
                    d.branches = res.result;
                fn.render();
            })
            
        }
        fn.render();
 
    }


    fn.handleChange = (e) => {
        d.data[e.target.name] = e.target.value;
        fn.render();
    }

    fn.changeToPercent = (e) => {
        var splitVal = e.target.name.split('price');
        d.data[e.target.name] = e.target.value;

        var rate = (parseFloat(d.data['discount_price' + splitVal[1]]) / parseFloat(e.target.value)) * 100;
        d.data['discount_persentage' + splitVal[1]] = Math.round(rate);
        fn.render();
    }

    fn.changePercentageToPrice = (e) => {
        var splitVal = e.target.name.split('discount_persentage');
        d.data[e.target.name] = e.target.value;
        if (parseFloat(e.target.value) > 100) {
            alert('Discount Persentage should be less than 100'); return false;
        }

        var rate = (parseFloat(e.target.value) / 100) * parseFloat(d.data['price' + splitVal[1]]);
        d.data['discount_price' + splitVal[1]] = Math.round(parseFloat(d.data['price' + splitVal[1]]) - rate);
        fn.render();
    }

    fn.changePriceToPercentage = (e) => {
        var splitVal = e.target.name.split('discount_price');
        d.data[e.target.name] = e.target.value;
        var cost = parseFloat(e.target.value) + 0.001 ;
        if (parseFloat(cost) > parseFloat(d.data['price' + splitVal[1]])) {
            alert('Discount Price should be less than Price'); return false;
        }

        // var rate =  (parseFloat(e.target.value)/parseFloat(d.data['price'+splitVal[1]]))*100;
        //  d.data['discount_persentage'+splitVal[1]] = Math.round(rate);
        fn.render();
    }

    fn.handleImageChange = (e) => {
        d.data.image_url = URL.createObjectURL(e.target.files[0]);

        // document.getElementById("removeImage").style.display = "block";
        fn.render();
    }

    fn.setDiscount= (e) => {
        if($('#setDiscount').is(":checked")){
            setData({ ...d, setDiscount: 1 });
        }else{
            setData({ ...d, setDiscount: 0 });
        }
    }

    fn.setStock= (e) => {
        if($('#setStock').is(":checked")){
            setData({ ...d, setStock: 1 });
        }else{
            setData({ ...d, setStock: 0 });
        }
    }

    
    fn.addNewRecipe= (e) => {

        d.ingLists.push({});
        setData({ ...d });
    }

    fn.openForm = (dtl) => {
        d.data = dtl ? { ...dtl } : { branches: [], spicy_level: [], add_ons: [], ingredients: [],dine_in_items:[],online_items:[],ingLists:[]};
        d.is_arabic = sdx.d.is_arabic;
        d.languageset = sdx.d.language;
        d.size_type = d.data.size_type || 'S';
        d.branches = d.data.branchList ||  d.branchesCopy;
        var i;

        // for(i = 1;i <= 4;i++){ 
            if (d.data['discount_price1'] != "0.00" || d.data['discount_price2'] != "0.00" || d.data['discount_price3'] != "0.00" || d.data['discount_price4'] != "0.00" ){
                d.setDiscount =  1;

            }else{
                d.setDiscount =  0;
            }
            

        // }
        d.setStock = d.data.display_stock || 0;
        
        $('input[type="file"]').val('');
        fn.render();
        $('.nav-link').removeClass('active');
        $('#tax-group-tab').addClass('active');

        $('.tab-pane').removeClass('show');
        $('.tab-pane').removeClass('active show');
        $('#tax-group').addClass('active');
        $('#tax-group').addClass('show');

        window.showModal($("#itemFormModal"), true);
    }

    fn.sdata = (p, ps) => {
        d.sdata.p = p ? p : 1;
        d.sdata.ps = ps ? ps : d.sdata.ps;
        let qs = '&p=' + d.sdata.p + '&ps=' + d.sdata.ps;
        if (cat_id) {
            qs += '&cat_id=' + cat_id;
        }
        return $("#sifrm").serialize() + qs;
    }

    fn.lists = (e, p, ps) => {
        if (e) e.preventDefault();
        util.ajx("cmaster/items", fn.sdata(p, ps)).then(res => {
            if (res.code === 200) {
                d.list = res.result;
                d.special_category = res.special_category;
                d.ingredients = res.ingredients;
                d.add_ons = res.add_ons;
                d.spicy_level = res.spicy_level;
                d.curInfo = res.curInfo;
                d.recipeIng = res.recipeIng;
                d.sub_role = sdx.d.sub_role;
                d.super_role = sdx.d.super_role;
                d.user_type = sdx.d.user_type;
                d.subscription_id = sdx.d.subscription_id;
                fn.render();
            }
        })
    }

    fn.save = () => {
        let fd = new FormData($("#ifrm")[0]);
        util.ajx("cmaster/save_item", fd).then(res => {
            if (res.code === 200) {
                $("#itemFormModal").modal('hide');
                fn.lists(0, d.data.id ? d.sdata.p : 1);
            }
        })
    }

    fn.deleterec = (id) => {
        let callback = () => {
            util.ajx("cmaster/delete_item", { id: id }).then(res => {
                if (res.code === 200) {
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.remove_image = (id) => {
        let fd = new FormData($("#ifrm")[0]);
        // var myobj = document.getElementById("images");
        // myobj.remove();
        document.getElementById('images').src = "''";
        document.getElementById("removeImage").style.display = "none";
        $('input[type="file"]').val('');
        if (id != '') {
            util.ajx("cmaster/remove_item_image", fd).then(res => {
                d.data.image_url = '';
            })

        }

    }

    fn.setBranchCheck = (e) => {
        let bid = e.target.value;
        
        if (e.target.checked) {
            d.data.branches.push(bid);
            $("."+bid+"_dineinclass").prop('disabled', false);
            d.data.dine_in_items.push(bid);
            d.data.online_items.push(bid);
            $("."+bid+"_dineinclass").prop( "checked", true );

        } else {

            d.data.branches.splice(d.data.branches.indexOf(bid), 1);
            $("."+bid+"_dineinclass").prop('disabled', true);
            $("."+bid+"_dineinclass").prop( "checked", false );
            if(d.data.dine_in_items.indexOf(bid) != -1){
                d.data.dine_in_items.splice(d.data.dine_in_items.indexOf(bid), 1);

            }
            
            if( d.data.online_items.indexOf(bid) != -1){
                d.data.online_items.splice(d.data.online_items.indexOf(bid), 1);

            }
            
        }
        fn.render();
    }
    
    fn.setDineInCheck = (e) => {
        let bid = e.target.value;
        if (e.target.checked) {
            d.data.dine_in_items.push(bid);

        } else {
            d.data.dine_in_items.splice(d.data.dine_in_items.indexOf(bid), 1);
            if(!d.data.online_items.includes(bid)){
                d.data.branches.splice(d.data.branches.indexOf(bid), 1);
            }
        }
        fn.render();
    }
    fn.setDeliveryCheck = (e) => {
        let bid = e.target.value;
        if (e.target.checked) {
            d.data.online_items.push(bid);

        } else {
            d.data.online_items.splice(d.data.online_items.indexOf(bid), 1);
            if(!d.data.dine_in_items.includes(bid)){
                d.data.branches.splice(d.data.branches.indexOf(bid), 1);
            }
        }
        fn.render();
    }

    fn.applied_for_dining = (e) => {
        let bid = e.target.value;
        if (e.target.checked) {
            d.data.applied_for_dining = 1;

        } else {
            d.data.applied_for_dining = 0;
        }
        fn.render();
    }

    fn.applied_for_delivery_pickup = (e) => {
        let bid = e.target.value;
        if (e.target.checked) {
            d.data.applied_for_delivery_pickup = 1;

        } else {
            d.data.applied_for_delivery_pickup = 0;
        }
        fn.render();
    }

    fn.setAddOnCheck = (e) => {
        let bid = e.target.value;
        if (e.target.checked) {
            d.data.add_ons.push(bid);

        } else {
            d.data.add_ons.splice(d.data.add_ons.indexOf(bid), 1);
        }
        fn.render();
    }

    fn.setIngredientsCheck = (e) => {
        let bid = e.target.value;
        if (e.target.checked) {
            d.data.ingredients.push(bid);

        } else {
            d.data.ingredients.splice(d.data.ingredients.indexOf(bid), 1);
        }
        fn.render();
    }
    fn.setSpiceCheck = (e) => {
        let bid = e.target.value;
        if (e.target.checked) {
            d.data.spicy_level.push(bid);

        } else {
            d.data.spicy_level.splice(d.data.spicy_level.indexOf(bid), 1);
        }
        fn.render();
    }

    // fn.discountapply=(type,id)=>{

    //    let fd = "id="+ id + "&type=" + type;
    //    if(id != ''){
    //      util.ajx("cmaster/update_discount_apply", fd).then(res => {

    //      })

    //     }

    // }

    fn.setSizeType = (size_type) => {
        setData({ ...d, size_type: size_type });
    }

    fn.openIngredientsForm = (dtl) => {
        //  d.data = dtl ? { ...dtl } : { client_id: props.client_id };
        
        d.type = d.data.type || 1;
        d.is_arabic = sdx.d.is_arabic;
        d.languageset = sdx.d.language;
        fn.render();
        window.draggable('#IngredientsformModal');
        window.showModal($("#IngredientsformModal"), true);
    }

    fn.openAddonForm = (dtl) => {
        //  d.data = dtl ? { ...dtl } : { client_id: props.client_id };
        
        d.type = d.data.type || 1;
        d.is_arabic = sdx.d.is_arabic;
        d.languageset = sdx.d.language;
        fn.render();
        window.draggable('#addonformModal');
        window.showModal($("#addonformModal"), true);
    }

    // fn.openRecipeForm = (dtl) => {
    //     //  d.data = dtl ? { ...dtl } : { client_id: props.client_id };
    //     
    //     d.type = d.data.type || 1;
    //     d.is_arabic = sdx.d.is_arabic;
    //     d.languageset = sdx.d.language;
    //     fn.render();
    //     window.draggable('#recipeformModal');
    //     window.showModal($("#recipeformModal"), true);
    // }

    fn.saveIngredients = () => {
        let fd = new FormData($("#ingredientsfrm")[0]);

        util.ajx("cmaster/save_Ingredients", fd).then(res => {
            if (res.code === 200) {
                $("#IngredientsformModal").modal('hide');
                // d.data =  { branches: [], spicy_level: [], add_ons: [], ingredients: [] };
                // d.data.ingredients = res.ingredients;
                // d.data.add_ons = res.add_ons;
                
                // fn.render();
                fn.lists(0, d.data.id ? d.sdata.p : 1);
            }
        })
    }
    fn.saveAddons = () => {
        let fd = new FormData($("#addonsfrm")[0]);
        util.ajx("cmaster/save_Ingredients", fd).then(res => {
            if (res.code === 200) {
                $("#addonformModal").modal('hide');
                // d.data.ingredients = res.ingredients;
                // d.data.add_ons = res.add_ons;
                // fn.render();
                fn.lists(0, d.data.id ? d.sdata.p : 1);
            }
        })
    }
    fn.setIngType = (e) => {
        d.data[e.target.name] = e.target.value;
        setData({ ...d, type: e.target.value });
    }


    fn.init = () => {
        util.ajx("cmaster/items?init=1", fn.sdata(1, d.sdata.ps)).then(res => {
            if (res.code === 200) {
                d.list = res.result;
                d.branches = res.branches;
                d.branchesCopy = res.branches;
                d.item_cats = res.item_cats;
                d.special_category = res.special_category;
                d.ingredients = res.ingredients;
                d.add_ons = res.add_ons;
                d.spicy_level = res.spicy_level;
                d.curInfo = res.curInfo;
                d.recipeIng = res.recipeIng;
                d.ingLists =     res.ingLists;
                // d.sub_role = sdx.d.sub_role;
                // d.super_role = sdx.d.super_role;
                // d.user_type = sdx.d.user_type;
                // d.subscription_id = sdx.d.subscription_id;

                d.sub_role = res.logging_data.permission.sub_permission;
                d.super_role = res.logging_data.permission.super_permission;
                d.user_type = res.logging_data.user_type;
                d.subscription_id = res.logging_data.subscription_id;
                
                fn.render();
                if(d.sub_role && ( d.sub_role[16] != 1)  && d.user_type =='STAFF'){
                    util.logout('', props.history);
                }
                if(d.subscription_id && ( d.subscription_id[2] != 1)  && d.user_type =='CLIENT'){
                    util.logout('', props.history);
                } 
                if(d.subscription_id){
                    $('.left-sidebar-link').removeClass('d-none');
                }
            }
        })
        // fn.lists();
    }

    fn.itemBarcodes=(item_id)=>{
        const hide=message.loading('Loading..', 0);
        Services.itemBarcodes(item_id || 0, 'PRINT').then(({data})=>{
            util.printHtmlFromIframe(data.result);
        }).catch(e=>{
            message.error(e.message);
        }).finally(()=>{
            hide();
        })
    }

    useEffect(() => {
        fn.init();
        $('#masterActive').addClass('active');
        d.sub_role = sdx.d.sub_role;
        d.super_role = sdx.d.super_role;
        d.user_type = sdx.d.user_type;
        d.subscription_id = sdx.d.subscription_id;
        
        fn.render();
        if(d.sub_role && ( d.sub_role[16] != 1)  && d.user_type =='STAFF'){
            util.logout('', props.history);
        }
        if(d.subscription_id && ( d.subscription_id[2] != 1)  && d.user_type =='CLIENT'){
            util.logout('', props.history);
        } 

        
        fn.render();
        // eslint-disable-next-line
    }, []);


    return (
        <>
            <List d={d} fn={fn} />
            <Form d={d} fn={fn} />
            <IngredientsForm d={d} fn={fn} />
            <AddonForm d={d} fn={fn} />
            <RecipeForm d={d} fn={fn} />
        </>
    )
}

function List(props) {
    let { d, fn } = props;
    const no_of_decimal = d.curInfo['no_of_decimal'];
    const currency = '0.' + String(0).padStart(no_of_decimal, '0')
    // num.toFixed(1)

    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-md-3 col-lg-2 top-master">
                    <div className="mb-3 text-uppercase text-black fw700 fs12">{lang('Masters')}</div>
                    <div className="left-sidebar-link d-none">
                        {/* <If cond={d.user_type != 'STAFF'}>
                            <NavLink to="/branches" className="">Branches</NavLink>
                            <NavLink to="/roles" className="">Roles</NavLink>
                            <NavLink to="/staffs" className="">Staffs</NavLink>
                            <NavLink to="/cats" className="">Categories</NavLink>
                            <NavLink to="/items" className="">Items</NavLink>
                            <NavLink to="/others" className="">Others</NavLink>
                        </If> */}

                    <If cond={d.list.result}>
                        {/* <If cond={d.user_type == 'STAFF'}> */}
                            <If cond={d.sub_role && ( d.sub_role[1] == 1 ||  d.sub_role && d.sub_role[2] == 1)  || d.user_type !='STAFF'  }>
                                <NavLink to="/branches" className="">{lang('Branches')}</NavLink>
                            </If>
                            <If cond={d.user_type != 'STAFF'}> <NavLink to="/roles" className="">{lang('Roles')}</NavLink></If>
                           
                            <If cond={d.sub_role && d.sub_role[4] == 1  || d.user_type !='STAFF'}>
                                <NavLink to="/staffs" className="">{lang('Staffs')}</NavLink>
                            </If>
                            
                            <If cond={ d.sub_role && d.sub_role[15] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) )  }>
                                <NavLink to="/cats" className="">{lang('Categories')}</NavLink>
                            </If>

                            <If cond={ d.sub_role && d.sub_role[16] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) ) }>
                                <NavLink to="/items" className="">{lang('Items')}</NavLink>
                            </If>

                            <If cond={ d.sub_role && d.sub_role[40] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) ) }>
                                <NavLink to="/combo" className="">{lang('Combos')}</NavLink>
                            </If>
                            
                            <If cond={d.super_role && d.super_role[2] == 1 ||  d.sub_role && ( d.sub_role[5] == 1 ||  d.sub_role[6] == 1 ||  d.sub_role[8] == 1)   || d.user_type !='STAFF'}>
                                <NavLink to="/others" className="">{lang('Others')}</NavLink>
                            </If>
                            <If cond={ d.sub_role && d.sub_role[48] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) ) }>
                                <NavLink to="/recipeIngredients" className="">{lang('Recipe Ingredients')}</NavLink>
                            </If>
                        {/* </If> */}

                    </If>

                    </div>
                </div>
                <div className="offset-lg-2 col-lg-10 offset-md-3 col-md-9 col-sm-12 right-master">
                    <div className="main-table-body">
                        <If cond={!cat_id}>
                            <div className="d-flex flex-row align-items-center justify-content-between pl-4 pr-4 pt-4">
                                <h6 className="m-0 h5 text-dark">{lang('Items')}</h6>
                                <If cond={d.sub_role && d.sub_role[16] == 1 || d.user_type !='STAFF'}>

                                    <div className="ml-auto mt-auto mb-auto">
                                        <button className="btn btn-danger btn-red" onClick={() => fn.openForm()}><i className="fa fa-plus"></i> {lang('Add')}</button>
                                    </div>
                                </If>
                            </div>
                        </If>

                        <div className={cat_id ? "" : "main-content"}>
                            <div className={cat_id ? "" : "container-fluid"}>
                                {/* <If cond={d.list.result.length && !cat_id}>
                                    <div className="d-flex">
                                        <div className="text-secondary bold600 mb5">
                                            Showing {d.list.page.start + 1} - {d.list.page.start + d.list.page.total} of {d.list.page.total_records} records.
                                        </div>
                                    </div>
                                </If> */}

                                <div className="d-flex">
                                    <div className="form-inline">
                                        <form id="sifrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                                            <input type="search" className="form-control w200 mr-3" name="k" placeholder={lang('Search')} />
                                            <If cond={!cat_id}>
                                                <select className="form-control w200 single-line-dotted mr-3" name="cat_id">
                                                    <option value="">{lang('Category (All)')}</option>
                                                    {d.item_cats.map((r, i) => <option key={i} value={r.id}>{r.title}</option>)}
                                                </select>
                                            </If>
                                            <button className="btn btn-red-outline btn-danger" type="submit"><i className="fa fa-search"></i></button>
                                        </form>
                                    </div>

                                    <div className="ml-auto mt-auto mb-auto">
                                        {/* <If cond={cat_id}>
                                            <button className="btn btn-outline-primary" onClick={() => fn.openForm()}><i className="fa fa-plus"></i> Add</button>
                                        </If> */}
                                        <button className="btn btn-outline-primary" onClick={()=>fn.itemBarcodes()}><i className="fa fa-print"></i> {lang('Print All Barcodes')}</button>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <If cond={d.list.result.length}>
                                        <div className="table-responsive">
                                            <table className="table border m-0">
                                                <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                                    <tr>
                                                        <th className="w20">{lang('SN')}</th>
                                                        <th className="w70">{lang('Photo')}</th>
                                                        <th>{lang('Item Name')}</th>
                                                        <th>{lang('Categories')}</th>
                                                        <th>{lang('Price')}</th>
                                                        <th>{lang('Discount Price')}</th>
                                                        <th className="text-center">{lang('Dis. Odr')}</th>
                                                        <th className="w80">{lang('Status')}</th>
                                                        <th className="w70"></th>
                                                        <th className="w70"></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-text-top">
                                                    {d.list.result.map((v, i) => (
                                                        <tr key={i}>
                                                            <td>{d.list.page.start + i + 1}.</td>
                                                            <td>
                                                                <If cond={v.image_url}>
                                                                    <img className="mw-100" src={v.image_url} alt="" />
                                                                </If>
                                                            </td>
                                                            <td>
                                                                <div className="text-black fw600">{v.title}</div>
                                                                <div className="text-black fw600">{v.title_arb}</div>
                                                                <div className="note-text pt3">
                                                                    Branches:&nbsp;
                                                                    {v.branch_name?.map((v, j) => <span className="bold600" key={j}>
                                                                        <If cond={j > 0}>, </If>
                                                                        {v.title}
                                                                    </span>)}
                                                                </div>
                                                            </td>

                                                            <td>{v.cat_title}</td>

                                                            <td className="nowrap text-left">
                                                                {[1, 2, 3, 4].map((i) => (
                                                                    <div key={i} className="clearfix">
                                                                        <If cond={(v.size_type == 'S' && i == 1) || v['size_name' + i]}>
                                                                            <AmtFormat curInfo={d.curInfo} amt={v['price' + i]} />
                                                                            {v['size_name' + i] && <><span className="fs12">&nbsp;({v['size_name' + i]})</span> </>}
                                                                        </If>
                                                                    </div>
                                                                ))}
                                                            </td>

                                                            <td className="nowrap text-left">
                                                                {[1, 2, 3, 4].map((i) => (
                                                                    <div key={i} className="clearfix">
                                                                        <If cond={(v.size_type == 'S' && i == 1) || v['size_name' + i]}>
                                                                            <If cond={v['discount_price' + i] != currency}>
                                                                                <div>
                                                                                    <AmtFormat curInfo={d.curInfo} amt={v['discount_price' + i] || 0} />
                                                                                </div>
                                                                            </If>

                                                                        </If>
                                                                    </div>
                                                                ))}
                                                            </td>


                                                            <td className="text-center">{v.disp_odr}</td>
                                                            <td>
                                                                <If cond={v.status === '1'}>
                                                                    <div className="badge badge-success">{lang('Active')}</div>
                                                                </If>
                                                                <If cond={v.status === '0'}>
                                                                    <div className="badge badge-danger">{lang('Inactive')}</div>
                                                                </If>
                                                            </td>
                                                            <td>
                                                                <If cond={ d.sub_role && d.sub_role[49] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) ) }>
                                
                                                                    <button type="button" className="btn btn-danger btn-red btn-sm" onClick={() => ref.openRecipeForm(v)}> {lang('Add')} </button>
                                                                </If>
                                                            </td>
                                                            <td className="text-center">
                                                                <div className="btn-group">
                                                                    <If cond={d.sub_role && d.sub_role[16] == 1 || d.user_type !='STAFF'}>

                                                                        <button type="button" className="btn btn-sm fs16" onClick={() => fn.openForm(v)}><i className="far fa-edit"></i></button>
                                                                        <button type="button" className="btn btn-sm fs16" onClick={() => fn.deleterec(v.id)}><i className="fa fa-trash-o"></i></button>
                                                                        <button className="btn btn-sm fs16 text-primary" onClick={()=>fn.itemBarcodes(v.id)}><i className="fa fa-print"></i></button>

                                                                    </If>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        {/* <If cond={!cat_id}> */}
                                            <div className="d-flex justify-content-between mt-4 pb-3">
                                                <div className="mr5">
                                                    <Paging fn={fn.lists} pageob={d.list.page} />
                                                </div>
                                                <div>
                                                    <Perpagedd fn={fn.lists} />
                                                </div>
                                            </div>
                                        {/* </If> */}
                                    </If>
                                    <If cond={!d.list.result.length}>
                                        <div className="no-rec">{lang('No record found')}</div>
                                    </If>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

function Form(props) {
    let { d, fn } = props;
    return (
        <div className="modal no-backdrop" id="itemFormModal" data-backdrop="static">
            <div className="modal-dialog modal-l modal-dialog-centered modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{d.data.id ? 'Edit' : 'Add'} {lang('Item')}</div>
                        <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={() => $("#itemFormModal").modal('hide')}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body p-0">
                        <form id="ifrm" autoComplete="off" spellCheck="false" className="form-font-label-set">
                            <input type="hidden" name="id" defaultValue={d.data.id} />
                            <If cond={cat_id}>
                                <input type="hidden" name="cat_id" defaultValue={cat_id} />
                            </If>
                            <ul className="nav nav-pills modal-nav nav-justified border-bottom m-0 bg-grey sticky-top" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link active" id="tax-group-tab" data-toggle="pill" href="#tax-group" role="tab" aria-controls="tax-group" aria-selected="true">{lang('Item Information')}</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="location-tab" data-toggle="pill" href="#location" role="tab" aria-controls="location" aria-selected="false">{lang('Advanced Details')}</a>
                                </li>
                            </ul>

                            <div className="tab-content p-3" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="tax-group" role="tabpanel" aria-labelledby="tax-group-tab">
                                    <div className="row mingap">
                                        <If cond={!cat_id}>
                                            <div className="form-group col-md-12">
                                                <label className="req">{lang('Category')}</label>
                                                <select className="form-control" name="cat_id" value={d.data.cat_id || ''} onChange={fn.changeCategory}>
                                                    <option value="">{lang('Select')}</option>
                                                    {d.item_cats.map((r, i) => <option key={i} value={r.id}>{r.title}</option>)}
                                                </select>
                                            </div>
                                        </If>

                                        <div className="form-group col-md-6">
                                            <label className="req" >{lang('Item Name')}</label>
                                            <input type="text" className="form-control" name="title" value={d.data.title || ''} onChange={fn.handleChange} />
                                        </div>
                                        <If cond={d.is_arabic && d.languageset == 'Arabic'}>
                                            <div className="form-group col-md-6">
                                                <label>{lang('Item Name')} ({d.languageset})</label>
                                                <input type="text" className="form-control" name="title_arb" value={d.data.title_arb || ''} onChange={fn.handleChange} dir="rtl" />
                                            </div>
                                        </If>
                                        <If cond={d.is_arabic && d.languageset != 'Arabic'}>
                                            <div className="form-group col-md-6">
                                                <label>{lang('Item Name')} ({d.languageset})</label>
                                                <input type="text" className="form-control" name="title_arb" value={d.data.title_arb || ''} onChange={fn.handleChange} />
                                            </div>
                                        </If>

                                        <div className="form-group col-md-12">
                                            <label>{lang('Image')}</label>
                                            <input type="file" className="form-control" name="image" onChange={fn.handleImageChange} />

                                            <If cond={d.data.image_url}>
                                                <button type="button" className="close" id="removeImage" onClick={() => fn.remove_image(d.data.image_url)}><span aria-hidden="true">&times;</span></button>

                                            </If>
                                            <If cond={d.data.image_url == ''}>
                                                <button type="button" className="close" style={{ display: 'none' }} id="removeImage" onClick={() => fn.remove_image(d.data.image_url)}><span aria-hidden="true">&times;</span></button>
                                            </If>
                                            <If cond={d.data.image_url}>

                                                <div className="pt3 w100">
                                                    <img className="mw-100" id="images" src={d.data.image_url} alt="" />

                                                </div>
                                            </If>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="">{lang('Description')}</label>
                                            <textarea rows="2" className="form-control" name="description" value={d.data.description || ''} onChange={fn.handleChange}></textarea>
                                        </div>

                                        <If cond={d.is_arabic && d.languageset == 'Arabic'}>
                                            <div className="form-group col-md-6">
                                                <label className="">{lang('Description')} ({d.languageset})</label>
                                                <textarea rows="2" className="form-control" name="description_arb" value={d.data.description_arb || ''} onChange={fn.handleChange} dir="rtl"></textarea>
                                            </div>
                                        </If>

                                        <If cond={d.is_arabic && d.languageset != 'Arabic'}>
                                            <div className="form-group col-md-6">
                                                <label className="">{lang('Description')} ({d.languageset})</label>
                                                <textarea rows="2" className="form-control" name="description_arb" value={d.data.description_arb || ''} onChange={fn.handleChange}></textarea>
                                            </div>
                                        </If>

                                        <div className="form-group col-md-12 mb20">
                                            <div className="form-inline">
                                                <label className="mr-2"><b>{lang('Item Size')} :</b></label>

                                                <div className="form-check-inline">
                                                    <label className="form-check-label">
                                                        <input type="radio" className="form-check-input" value="S" onChange={() => { fn.setSizeType('S') }} name="size_type" checked={d.size_type == 'S'} />{lang('Single')}
                                                    </label>
                                                </div>
                                                <div className="form-check-inline">
                                                    <label className="form-check-label">
                                                        <input type="radio" className="form-check-input" value="M" onChange={() => { fn.setSizeType('M') }} name="size_type" checked={d.size_type == 'M'} />{lang('Multiple')}
                                                    </label>
                                                </div>
                                            </div>
                                           
                                            

                                        </div>

                                        <div className="form-group col-md-12 mb20">
                                            <div className="form-check-inline">
                                                    <label className="form-check-label">
                                                        <input type="checkbox" className="form-check-input" id="setDiscount" value="" onChange={(e) => { fn.setDiscount(e, d) }} name="display_discount" checked={d.setDiscount == 1} />{lang('Do you want to add Discount')}?
                                                    </label>
                                            </div>
                                            <div className="form-check-inline">
                                                    <label className="form-check-label">
                                                        <input type="checkbox" className="form-check-input" id="setStock" value={d.setStock || ''} onChange={(e) => { fn.setStock(e, d) }}  name="display_stock" checked={d.setStock == 1}  />{lang('Do you want to add Stocks')}?
                                                    </label>
                                            </div>
                                            
                                        </div>


                                        {d.size_type == 'M' && [1, 2, 3, 4].map((i) => (
                                            <div className="form-group col-md-12" key={i}>
                                                <input type="hidden" name={"size_id" + i} defaultValue={d.data['size_id' + i] || ''} />
                                                <input type="hidden" name={"stock_id" + i} defaultValue={d.data['stock_id' + i] || ''} />
                                                <div className="">
                                                    <label className="fw600">{lang('Size')} {i}</label>

                                                    <div className="row p-2">
                                                    
                                                        <div className="mb10 col-md-3">
                                                            <label className="">{lang('Size Name')}</label>
                                                            <input type="text" className="form-control" name={"size_name" + i} value={d.data['size_name' + i] || ''} onChange={fn.handleChange} />
                                                        </div>
                                                        <If cond={d.is_arabic && d.languageset == 'Arabic'}>
                                                            <div className="mb10 col-md-3">
                                                                <label className="">{lang('Size Name')} ({d.languageset})</label>
                                                                <input type="text" className="form-control" name={'size_name_arb' + i} value={d.data['size_name_arb' + i] || ''} onChange={fn.handleChange} dir="rtl" />
                                                            </div>
                                                        </If>
                                                        <If cond={d.is_arabic && d.languageset != 'Arabic'}>
                                                            <div className="mb10 col-md-3">
                                                                <label className="">{lang('Size Name')} ({d.languageset})</label>
                                                                <input type="text" className="form-control" name={'size_name_arb' + i} value={d.data['size_name_arb' + i] || ''} onChange={fn.handleChange} />
                                                            </div>
                                                        </If>
                                                        <div className="col-md-3 mb10">
                                                            <label className="">{lang('Price')}</label>
                                                            <input type="text" className="form-control" name={'price' + i} value={d.data['price' + i] || ''} onChange={fn.handleChange} />
                                                        </div>
                                                        {/* <div className="">
                    <label className="">Discount Persentage</label>
                    <input type="text" className="form-control" name={'discount_persentage'+i} value={d.data['discount_persentage'+i] || ''}  onChange={fn.changePercentageToPrice} />
                </div> */}

                                                        <div className="mb10 col-md-3">
                                                            <label>{lang('Cost Price')}</label>
                                                            <input type="text" className="form-control" placeholder={lang('Enter Price')} name={'cost_price' + i} value={d.data['cost_price' + i] || ''} onChange={fn.handleChange} />
                                                        </div>
                                                        
                                                        <If cond={d.setDiscount == 1}>
                                                            <div className="mb10 col-md-3">
                                                                <label className="">{lang('Discounted Selling Price')}</label>
                                                                <input type="text" className="form-control" name={'discount_price' + i} value={d.data['discount_price' + i] || ''} onChange={fn.changePriceToPercentage} />
                                                            </div>

                                                        </If>
                                                        
                                                        <If cond={d.setStock == 1}>
                                                            <div className="mb10 col-md-3">
                                                                <label className="">{lang('Stock')}</label>
                                                                <input type="text" className="form-control" name={'stocks' + i} value={d.data['stocks' + i]} onChange={fn.handleChange} />
                                                            </div>

                                                        </If>
                                                        
                                                        <div className="col-md-6">
                                                            <label className="req">{lang('Expiry Date')}</label>
                                                            <input type="date" className="form-control"  name={"expiry_date"+i} value={d.data['expiry_date'+i] || ''} onChange={e => fn.handleChange(e, d)}  />
                                                        </div>

                                                    </div>
                                                    
                                                    {d.ingLists && d.ingLists.map((v, i) => (
                                                        <div className="col-md-6">
                                                                <label className="req">{lang('Expiry Date')}</label>
                                                                <input type="date" className="form-control"  name={"expiry_date"+i} value={d.data['expiry_date'+i] || ''} onChange={e => fn.handleChange(e, d)}  />
                                                        </div>   
                                                     ))}                                                 
                                                </div>
                                            </div>
                                        ))}

                                        {d.size_type == 'S' && <div className="row m-0">
                                            
                                            <div className="form-group col-md-6">
                                                <label>{lang('Selling Price')}</label>
                                                <input type="hidden" name={"size_id1"} defaultValue={d.data['size_id1'] || ''} />
                                                <input type="hidden" name={"stock_id1"} defaultValue={d.data['stock_id1'] || ''} />
                                                <input type="hidden" className="form-control" name={"size_name1"} value={d.data['size_name1'] || ''} />
                                                <input type="hidden" className="form-control" name={"size_name_arb1"} value={d.data['size_name_arb1'] || ''} />
                                                <input type="text" className="form-control" placeholder={lang('Enter Price')} name={"price1"} value={d.data['price1'] || ''} onChange={fn.handleChange} />
                                            </div>
                                            {/* <div className="">
                    <label className="">Discount Persentage</label>
                    <input type="text" className="form-control" name={'discount_persentage1'} value={d.data['discount_persentage1'] || ''}  onChange={fn.changePercentageToPrice}/>
                </div> */}
                                            <div className="form-group col-md-6">
                                                <label>{lang('Cost Price')}</label>
                                                <input type="text" className="form-control" placeholder={lang('Enter Price')} name="cost_price1" value={d.data['cost_price1'] || ''} onChange={fn.handleChange} />
                                            </div>
                                            <If cond={d.setDiscount == 1}>
                                                <div className="form-group col-md-6">
                                                    <label>{lang('Discounted Selling Price')}</label>
                                                    <input type="text" className="form-control" placeholder={lang('Enter Price')} name="discount_price1" value={d.data['discount_price1'] || ''} onChange={fn.changePriceToPercentage} />
                                                </div>
                                            </If>
                                            <If cond={d.setStock == 1}>
                                                <div className="form-group col-md-6">
                                                    <label>{lang('Stock')}</label>
                                                    <input type="text" className="form-control" placeholder={lang('Enter Stocks')} name="stocks1" value={d.data['stocks1']} onChange={fn.handleChange} />
                                                </div>

                                            </If>
                                            <div className="col-md-6">
                                                <label className="req">{lang('Expiry Date')}</label>
                                                <input type="date" className="form-control" id="exp_date" name="expiry_date1" value={d.data['expiry_date1'] || ''} onChange={e => fn.handleChange(e, d)}  />
                                            </div>
                                            

                                        </div>}
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="location" role="tabpanel" aria-labelledby="location-tab">
                                    <div className="row mingap">

                                        {/* <div className="form-group col-md-12">
                                            <label className="">Display Stock</label>
                                            <select className="form-control" name="display_stock" value={d.data.display_stock || ''} onChange={fn.handleChange}>
                                                <option value="">Select</option>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
                                        </div> */}

                                        <If cond={d.setDiscount == 1}>
                                            <div className="form-group col-md-12 mt-2">
                                                <label className="">{lang('Discount apply for')}</label>
                                                <div className="form-control mb5">
                                                    <label className="m-0 noselect">
                                                        <input type="checkbox" name="applied_for_dining" value={d.data.applied_for_dining} checked={d.data.applied_for_dining == 1 ? true : false} onChange={fn.applied_for_dining} />
                                                        {/* onChange={() => fn.discountapply('dining',d.data.id)} */}
                                                        &nbsp;&nbsp;{lang('Dine-In')}
                                                    </label>
                                                </div>
                                            

                                                <div className="form-control mb5">
                                                    <label className="m-0 noselect">
                                                        <input type="checkbox" name="applied_for_delivery_pickup" value={d.data.applied_for_delivery_pickup} checked={d.data.applied_for_delivery_pickup == 1 ? true : false} onChange={fn.applied_for_delivery_pickup} />
                                                        {/* onChange={() => fn.discountapply('delivery',d.data.id)} */}
                                                        &nbsp;&nbsp;{lang('Pickup/Delivery')}
                                                    </label>
                                                </div>
                                            </div>
                                        </If>

                                        <div className="form-group col-md-12 mt-2">
                                            <label className="req">{lang('Branches')}</label>
                                            {d.branches.map((r, i) => (
                                                <div className="form-control mb5" key={i}>
                                                    <label className="m-0 noselect">
                                                        <input type="checkbox" name="branches[]" value={r.id} checked={d.data.branches?.includes(r.id) ? true : false} onChange={fn.setBranchCheck} />
                                                        &nbsp;&nbsp;{r.title}
                                                        <br></br>  &nbsp;&nbsp;&nbsp;&nbsp;<input type="checkbox" className={r.id+"_dineinclass"} disabled={d.data.branches?.includes(r.id) ? false : true} name="dine_in_items[]" value={r.id} checked={d.data.dine_in_items?.includes(r.id) ? true : false} onChange={fn.setDineInCheck} />
                                                        &nbsp;&nbsp;{lang('Visible For Dining')}
                                                        <br></br>  &nbsp;&nbsp;&nbsp;&nbsp;<input type="checkbox" className={r.id+"_dineinclass"} disabled={d.data.branches?.includes(r.id) ? false : true} name="online_items[]" value={r.id} checked={d.data.online_items?.includes(r.id)? true : false} onChange={fn.setDeliveryCheck} />
                                                        &nbsp;&nbsp;{lang('Visible For Pickup Delivery')}

                                                    </label>
                                                </div>
                                            ))}
                                        </div>



                                        <div className="form-group col-md-12">
                                            <label className="">{lang('Special Category')}</label>
                                            <select className="form-control" name="special_category_id" value={d.data.special_category_id || ''} onChange={fn.handleChange}>
                                                <option value="">{lang('Select')}</option>
                                                {d.special_category.map(r => <option key={r.id} value={r.id}>{r.name}</option>)}
                                            </select>
                                        </div>

                                        <div className="form-group col-md-12">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <label className="">{lang('Ingredients')}</label>
                                                </div>
                                                <If cond={d.sub_role && d.sub_role[8] == 1 || d.user_type !='STAFF'}>
                                                    <div>
                                                        <button type="button" className="btn btn-danger btn-red btn-sm" onClick={() => fn.openIngredientsForm()}><i className="fa fa-plus" aria-hidden="true"></i> Add </button>
                                                    </div>
                                                </If>
                                            </div>
                                            {/* <select className="form-control" name="ingredients_id" value={d.data.ingredients_id || ''} onChange={fn.handleChange}>
                    <option value="">Select</option>
                    {d.ingredients.map(r=><option key={r.id} value={r.id}>{r.name}</option>)}
                </select> */}

                                            {d.ingredients.map((r, i) => (
                                                <div className="form-control mb5" key={i}>
                                                    <label className="m-0 noselect">
                                                        <input type="checkbox" name="ingredients[]" value={r.id} checked={d.data.ingredients?.includes(r.id) ? true : false} onChange={fn.setIngredientsCheck} />
                                                        &nbsp;&nbsp;{r.name} <br></br>&nbsp;&nbsp;&nbsp;&nbsp;{r.name_arb}
                                                    </label>
                                                    
                                                </div>
                                            ))}
                                        </div>

                                        <div className="form-group col-md-12">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <label className="">{lang('ADD-Ons')}</label>
                                                </div>
                                                <If cond={d.sub_role && d.sub_role[8] == 1 || d.user_type !='STAFF'}>
                                                    <div>
                                                        <button type="button" className="btn btn-danger btn-red btn-sm" onClick={() => fn.openAddonForm()}><i className="fa fa-plus" aria-hidden="true"></i> Add</button>
                                                    </div>
                                                </If>
                                            </div>
                                            {/* <select className="form-control" name="add_on_id" value={d.data.add_on_id || ''} onChange={fn.handleChange}>
                <option value="">Select</option>
                {d.add_ons.map(r=><option key={r.id} value={r.id}>{r.name} - {r.add_on_price}</option>)}
            </select> */}
                                            {d.add_ons.map((r, i) => (
                                                <div className="form-control mb5" key={i}>
                                                    <label className="m-0 noselect">
                                                        <input type="checkbox" name="add_ons[]" value={r.id} checked={d.data.add_ons?.includes(r.id) ? true : false} onChange={fn.setAddOnCheck} />
                                                        &nbsp;&nbsp;{r.name}  :   <AmtFormat curInfo={d.curInfo} amt={r.add_on_price} /> <br></br>  &nbsp;&nbsp;&nbsp;&nbsp;{r.name_arb}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label className="">{lang('Item Type')}</label>
                                            <select className="form-control" name="item_type" value={d.data.item_type || ''} onChange={fn.handleChange}>
                                                <option value="">{lang('Select')}</option>
                                                <option value="1">{lang('Veg')}</option>
                                                <option value="2">{lang('Non-Veg')}</option>
                                                <option value="3">{lang('Egg Only')}</option>
                                            </select>
                                        </div>

                                        <div className="form-group col-md-12 mt-2">
                                            <label className="">{lang('Spicy Level')}</label>
                                            {d.spicy_level.map((r, i) => (
                                                <div className="form-control mb5" key={i}>
                                                    <label className="m-0 noselect">
                                                        <input type="checkbox" name="spicy_level[]" value={r.id} checked={d.data.spicy_level?.includes(r.id) ? true : false} onChange={fn.setSpiceCheck} />
                                                        &nbsp;&nbsp;{r.name}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>

                                       

                                        <div className="form-group col-md-6">
                                            <label className="">{lang('Display Order')}</label>
                                            <input type="text" className="form-control" name="disp_odr" value={d.data.disp_odr || ''} onChange={fn.handleChange} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="req">{lang('Status')}</label>
                                            <select className="form-control" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                                <option value="1">{lang('Active')}</option>
                                                <option value="0">{lang('Inactive')}</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-red" onClick={fn.save}>{lang('Save')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}



function IngredientsForm(props) {
    let d = props.d, fn = props.fn;

    return (
        <div className="modal modal-scroll no-backdrop" id="IngredientsformModal" data-backdrop="static">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{d.data.id ? 'Edit' : 'Add'} {lang('Ingredients')} </div>
                        <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={() => $("#IngredientsformModal").modal('hide')}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form id="ingredientsfrm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="itemid" defaultValue={d.data.id} />
                            {/* <input type="hidden" name="client_id" defaultValue={d.data.client_id} /> */}
                            <div className="row mingap">
                                <div className="form-group col-md-6">
                                    <label className="req">{lang('Ingredient Name')}</label>
                                    <input type="text" className="form-control" name="name" value={d.data.name || ''} onChange={fn.handleChange} />
                                </div>
                                <If cond={d.is_arabic && d.languageset == 'Arabic'}>
                                    <div className="form-group col-md-6">
                                        <label>{lang('Ingredient Name')} ({d.languageset})</label>
                                        <input type="text" className="form-control" name="name_arb" value={d.data.name_arb || ''} onChange={fn.handleChange} dir="rtl" />
                                    </div>
                                </If>
                                <If cond={d.is_arabic && d.languageset != 'Arabic'}>
                                    <div className="form-group col-md-6">
                                        <label>{lang('Ingredient Name')} ({d.languageset})</label>
                                        <input type="text" className="form-control" name="name_arb" value={d.data.name_arb || ''} onChange={fn.handleChange} />
                                    </div>
                                </If>

                                <div className="form-group col-md-12">
                                    <label className="">{lang('Ingredients Type')}</label>
                                    <select className="form-control" name="type" value={d.data.type || ''} onChange={fn.setIngType}>
                                        <option value="1">{lang('')}</option>
                                        <option value="2">{lang('AddOn')}</option>
                                        <option value="3">{lang('Both')}</option>
                                    </select>
                                </div>

                                {d.type != 1 &&
                                    <div className="form-group col-md-12">
                                        <label className="req">{lang('AddOn Price')}</label>
                                        <input type="text" className="form-control" name="add_on_price" value={d.data.add_on_price || ''} onChange={fn.handleChange} />
                                    </div>}

                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Status')}</label>
                                    <select className="form-control" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                        <option value="1">{lang('Active')}</option>
                                        <option value="0">{lang('Inactive')}</option>
                                    </select>
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={() => $("#IngredientsformModal").modal('hide')}>Close</button> */}
                        <button type="button" className="btn btn-danger btn-red" onClick={fn.saveIngredients}>{lang('Save')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


function AddonForm(props) {
    let d = props.d, fn = props.fn;

    return (
        <div className="modal modal-scroll no-backdrop" id="addonformModal" data-backdrop="static">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{d.data.id ? 'Edit' : 'Add'} {lang('Add-on')} </div>
                        <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={() => $("#addonformModal").modal('hide')}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form id="addonsfrm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="itemid" defaultValue={d.data.id} />
                            {/* <input type="hidden" name="client_id" defaultValue={d.data.client_id} /> */}
                            <div className="row mingap">
                                <div className="form-group col-md-6">
                                    <label className="req">{lang('Addon Name')}</label>
                                    <input type="text" className="form-control" name="name" value={d.data.name || ''} onChange={fn.handleChange} />
                                </div>
                                <If cond={d.is_arabic && d.languageset == 'Arabic'}>
                                    <div className="form-group col-md-6">
                                        <label>{lang('Addon Name')} ({d.languageset})</label>
                                        <input type="text" className="form-control" name="name_arb" value={d.data.name_arb || ''} onChange={fn.handleChange} dir="rtl" />
                                    </div>
                                </If>

                                <If cond={d.is_arabic && d.languageset != 'Arabic'}>
                                    <div className="form-group col-md-6">
                                        <label>{lang('Addon Name')} ({d.languageset})</label>
                                        <input type="text" className="form-control" name="name_arb" value={d.data.name_arb || ''} onChange={fn.handleChange} />
                                    </div>
                                </If>

                                <div className="form-group col-md-6">
                                    <label className="">{lang('Ingredients Type')}</label>
                                    <select className="form-control" name="type" value={d.data.type || ''} onChange={fn.setIngType}>
                                        {/* <option value="1">Ingredient</option> */}
                                        <option value="2">{lang('AddOn')}</option>
                                        <option value="3">{lang('Both')}</option>
                                    </select>
                                </div>


                                <div className="form-group col-md-6">
                                    <label className="">{lang('AddOn Price')}</label>
                                    <input type="text" className="form-control" name="add_on_price" value={d.data.add_on_price || ''} onChange={fn.handleChange} />
                                </div>

                                <div className="form-group col-md-12">
                                    <label className="req">{lang('Status')}</label>
                                    <select className="form-control" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                        <option value="1">{lang('Active')}</option>
                                        <option value="0">{lang('Inactive')}</option>
                                    </select>
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={() => $("#IngredientsformModal").modal('hide')}>Close</button> */}
                        <button type="button" className="btn btn-danger btn-red" onClick={fn.saveAddons}>{lang('Save')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


function RecipeForm(){
    const [d, setData] = useState({
        lists: [{}],
        bdtl: false,
        data: { dining: [] },
        dining: [],
        deliver: [],
        pickup: [],
        subscription_id : [],
        item_size_details : [],
        recipeIngredients : [],
        unitname : '',
    });

    const [checked, setChecked] = React.useState(true);
    const fn = {};
    ref.openRecipeForm = (bdtl) => {
        d.bdtl = bdtl;
        
        util.ajx("cmaster/item_recipe", { id: bdtl.id }).then(res => {
            if (res.code === 200) {
                d.lists = res.result;
                d.recipeIngredients = res.recipeIngredients;
                d.item_size_details = res.item_size_details;

                if (!d.lists.length) {
                    d.lists = [{}];
                }
                d.subscription_id = sdx.d.subscription_id;

                setData({ ...d });
                window.showModal($("#recipeModal"), true);
            }
        })
    }




    const closeModal = () => {
        setData({ ...d, bdtl: false });
        $("#recipeModal").modal('hide');
    }

    const addNew = (i) => {
       
        d.item_size_details[i]['item_recipe'].push({});
        // d.lists.push({});
        setData({ ...d });
    }
    const remove = (id,m) => () => {
        $('.ingredientsrow'+id+'_'+m).hide();

        util.ajx("cmaster/delete_item_recipe", {id:id}).then(res => {
            if (res.code === 200) {
                // ref.openRecipeForm(d.bdtl);
            }
        })

    }

    const save = () => {
        let fd = new FormData($("#recipeFrm")[0]);
        fd.append("item_id", d.bdtl.id);
        util.ajx("cmaster/save_item_recipe", fd).then(res => {
            if (res.code === 200) {
                closeModal();
            }
        })
    }

    // const changeVal = (e,id) =>{
    //     $('#quant_'+id).val(e.target.value)
    // }

    const get_ing_units = (e,key,itmID) => {
        let data = e.target.value;
        // var dataId = $('#recipe_ingredient_id_'+itmID+'_'+key).attr("data-value")
       
        let data1 = data.split("_");
        d.unitname= data1[1];
        
        setData({ ...d });

        $('#unit_name_'+itmID+'_'+key).html(d.unitname);
        $('#recipe_ingredient_id_'+itmID+'_'+key).val(data);

        // console.log(e.target.parentNode.getAttribute("data-value"))
    }


    return (
        <div className="modal modal-scroll no-backdrop" id="recipeModal" data-backdrop="static">
            <If cond={d.bdtl}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title uc">{d.bdtl.title} - <span className="text-secondary">{lang('Items')}</span></div>
                            <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={closeModal}><span aria-hidden="true">&times;</span></button>
                        </div>

                        <div className="modal-body">
                            <form id="recipeFrm" autoComplete="off" spellCheck="false">
                                <div className="table-responsive mb3">
                                    <table className="table table-sm table-bordered m-0">
                                        <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                            <tr>
                                                <th className="w20">{lang('SN')}</th>
                                                <th className="w20">{lang('Items')}</th>
                                                <th className="w20">{lang('Ingredients')}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-text-vmid">
                                        {d.item_size_details.map((v, i) => (
                                                <tr key={i} className={'printers'+v.itemId}>
                                                    <td style={{width : '10%'}}>{i + 1}.</td>
                                                    <td style={{width : '20%'}}  >
                                                        
                                                        {(v.size_name)?v.title+' ('+v.size_name+')': v.title}
                                                       
                                                       
                                                    </td>
                                                    <td style={{width : '70%'}} >
                                                    
                                                    {v.item_recipe.map((k, m) => (
                                                        <div key={m} className={' ingredients'+k.id}>
                                                            <input type="hidden" name="id[]" defaultValue={k.id} />
                                                            <input type="hidden" name="itemId[]" defaultValue={v.itemId} />
                                                            <input type="hidden" name="sizeId[]" defaultValue={v.sizeId} />
                                                            <table className={'table table-sm table-bordered m-0 ingredientsrow'+k.id+'_'+m}>
                                                                <tr>
                                                                    <td>
                                                                        <select  className="form-control  single-line-dotted mr-3" name="recipe_ingredient_id[]" value={k.recipe_ingredient_id} id={'recipe_ingredient_id_'+v.sizeId+'_'+m}   onChange={(e) => { get_ing_units(e,m,v.sizeId) } }  >
                                                                            <option value="">{lang('Select')}</option>
                                                                            {d.recipeIngredients && d.recipeIngredients.map(r => <option key={r.recipeId} value={r.recipeId+'_'+r.unitname+'_'+r.units} data-value={r.unitname}>{r.name}</option>)}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <div><input type="text" className={" 2q"+i} name="quantity[]" value={k.quantity} id={'quant_'+k.id} style={{border : '1px solid #d1d3e2' , outline: 'none'}}  /><lable style={{position : 'fixed'}} id={'unit_name_'+v.sizeId+'_'+m}>{k.unitname}</lable></div>
                                                                    </td>
                                                                    {/* onChange={(e) => { changeVal(e,k.id) } } */}
                                                                    <td>
                                                                        <button type="button" className="btn btn-sm btn-danger" onClick={remove(k.id,m)} ><i className="fa fa-trash-o"></i></button>
                                                                    </td>
                                                                            
                                                                

                                                                </tr>

                                                            </table>
                                                            

                                                           
                                                           
                                                        </div>
                                                           
                                                    ))}   
                                                    <button type="button" className="btn btn-sm btn-light-blue" onClick={(e) => { addNew(i) }}><i className="fa fa-plus"></i> {lang('Add More')}</button>

                                                    
                                                        {/* <input type="text"     className={"form-control printer_name"+i} name="printer_name[]" defaultValue={v.printer_name} /> */}
                                                    </td>
                                                    
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                
                            </form>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={closeModal}>{lang('Close')}</button>
                            <button type="button" className="btn btn-primary" onClick={save}>{lang('Save')}</button>
                        </div>
                    </div>
                </div>
            </If>
        </div>
    )
}