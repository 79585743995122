import React from 'react';
import {
    Select,
    DatePicker,
    Pagination,
    Tag
} from 'antd';

let moment=window.moment;

export function AntdSelect(props){
    let {
        placeholder, 
        options,
        value,
        sort,
        ...rest
    }=props;

    if(typeof options!== "object"){
        options=[];
    }
    if(typeof options[0]!== "object"){
        let ops=[...options];
        options=[];
        for(let ind in ops){
            options.push({value:ops[ind], label:ops[ind]});
        }
    }
    if(typeof options[0]?.value === "undefined"){
        let ops=[...options];
        options=[];
        ops.forEach(v=>{
            options.push({value:v.id, label:v.title || v.name});
        })
    }

    options.forEach(v=>{
        v.value+='';
        v.label+='';
    })

    if(sort){
        options.sort((a,b)=>{
            return (''+a.label).localeCompare(b.label);
        })
    }

    return(
        <>
            <Select
                style={{width:'100%'}}
                placeholder={placeholder || "Select"}
                options={options}
                value={(value!==null && typeof value!=="undefined")?(value+''):null}
                filterOption={
                    (input, option)=>{
                        return option.label.toLowerCase().indexOf(input.toLowerCase())>=0
                    }
                }
                
                {...rest}
            >
            </Select>
        </>
    )
}
/* How to use: 
    <AntdSelect
        options={[{value:1, label:"Title1"}, {value:2, label:"Title2"}, {value:3, label:"Title3"}]}
        showSearch
        allowClear
        onChange={e=>{
            console.log(e)
        }}
    /> 
*/

export function AntdDatepicker(props){
    let {
        format, 
        inputReadOnly,
        value,
        defaultValue,
        onChange,
        ...rest
    }=props;

    let otherProps={};
    if(typeof value !== "undefined"){
        otherProps.value=value?moment(new Date(value)):null
    }
    if(typeof defaultValue !== "undefined"){
        otherProps.defaultValue=defaultValue?moment(new Date(defaultValue)):null
    }
    if(typeof onChange !== "undefined"){
        otherProps.onChange=(dt)=>{
            onChange(dt?moment(dt).format(format || 'DD MMM YYYY'):null);
        }
    }

    return(
        <DatePicker 
            format={format || 'DD MMM YYYY'} 
            inputReadOnly={inputReadOnly || true}
            {...otherProps}
            {...rest}
            style={{width:'100%'}}
        />
    )
}

export function AntdPaging(props){
    let {onChange, total, pageSize, ...rest}=props;
    let jumpOn1st=false;
    const handlePaseSizeChange=(n, ps)=>{
        jumpOn1st=true;
    }
    
    const handleOnChange=(n, ps)=>{
        onChange(jumpOn1st?1:n, ps);
        jumpOn1st=false;
    }
    
    return(
        <Pagination 
            defaultCurrent={1} 
            total={total} 
            defaultPageSize={pageSize || 25}
            pageSizeOptions={[10, 20, 25, 50, 100]}
            onChange={handleOnChange}
            onShowSizeChange={handlePaseSizeChange}
            {...rest}
        />
    )
}

export function AntdTag(props) {
    const { type, ...rest } = props;
    const [tagType, setTagType] = React.useState(type);
    const types = {
        danger: '#ff4d4f',
        info: '#2db7f5',
        success: '#87d068',
        primary: '#108ee9',
    };

    React.useEffect(() => {
        setTagType(type);
    }, [type]);

    return (
        <Tag color={types[tagType]} {...rest}>{props.children}</Tag>
    )
}