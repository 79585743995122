import axios from "./axios";

class Services{
    clientPaymentGateways(){
        return axios.post("cmaster/paymentGateways");
    }
    saveClientPaymentGateways(data){
        return axios.post("cmaster/savePaymentGateways", data);
    }

    itemBarcodes(item_id, action){
        return axios.post(`cmaster/itemBarcodes/${item_id}/${action}`);
    }
}

export default new Services();