import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from "./util/routes";
import { sdx } from "./sdx";

function App() {
    const [state, changeState] = useState(false);
    sdx.changeState = () => {
        changeState(!state);
    }

    return (
        <BrowserRouter basename={"/"}>
            <Routes></Routes>
        </BrowserRouter>
    );
}

export default App;