import React, {useState, useEffect} from 'react';
import util from "../../util/util";
import { sdx } from "../../sdx";
import lang from '../../lang';
//import {If} from "../../util/controls";
let $=window.$;

export default function Qrcodes(props) {
    const [d, setData] = useState({
        lists:[{}],
        branches:[],
        sub_role :[],
        super_role:[],
        user_type:'',
        subscription_id:[],
    });

    const lists=(branch_id)=>{
        util.ajx("common/qr_codes/"+branch_id).then(res=>{
            //console.log(res.result)
            if (res.code === 200){
                

                d.lists=res.result;
                d.sub_role = sdx.d.sub_role;
                d.super_role = sdx.d.super_role;
                d.user_type = sdx.d.user_type;
                d.subscription_id = sdx.d.subscription_id;
                

                setData({...d});
                if(d.sub_role && ( d.sub_role[3] != 1)  && d.user_type =='STAFF'){
                    util.logout('', props.history);
                }

                if(d.subscription_id && ( d.subscription_id[1] != 1)  && d.user_type =='CLIENT'){
                    util.logout('', props.history);
                }
            

            }
        })
    }

    const init=()=>{
        util.ajx("cmaster/all_branches").then(res=>{
            if (res.code === 200){
                d.branches=res.result;
                if(d.branches.length){
                    lists(d.branches[0].id);
                }
            }
        })
    }

    useEffect(()=>{ 
       
        init(); 
        
        $('#masterActive').removeClass('active');
        clearInterval(sdx.orderlist);
        clearInterval(sdx.kitchenlist);
        // eslint-disable-next-line
    }, []);

    return(
        <>
            <div className="col-xl-12 col-lg-12 d-print-none">
                <div className="card shadow mb-4 rounded-lg">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 h5 text-dark">{lang('QR-Codes')}</h6>
                        <div className="ml-auto mt-auto mb-auto">
                            <button className="btn btn-outline-primary" onClick={()=>window.print()}><i className="fa fa-plus"></i> {lang('Print')}</button>
                        </div>
                    </div>
                    <div className="card-body p-0">
                        <div className="main-content p-0">
                            <div className="p-2">
                                <div className="tabbable-custom">
                                    <ul className="nav nav-tabs uc d-print-none">
                                        {d.branches.map((v,i)=>(
                                            <li key={i} className="nav-item">
                                                <a className={"nav-link "+(i===0?'active':'')} data-toggle="tab" href="#tab1" onClick={()=>lists(v.id)}>{v.title}</a>
                                            </li>
                                        ))}
                                    </ul>

                                    <div className="tab-content">
                                        {/* <div id="tab1" className="tab-pane fade1 show active">
                                            {d.lists.map((v,i)=>(
                                                <div key={i}>
                                                    <div className="mb15 m-auto qrcodebx pagebreak position-relative">
                                                        <div className="mb5">
                                                            <img className="mw-100" src={v.qrcode_image} alt="" />
                                                        </div>
                                                        <div className="text-center" style={{marginTop:'-25px'}}>
                                                            <a href={v.dwn_url}>{v.title}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div> */}

                                        <div id="tab1" className="tab-pane fade1 show active">
                                            <div className="row mingap">
                                                {d.lists.map((v,i)=>(
                                                    <div key={i} className="col-md-4 col-lg-3 col-sm-12 mb15">
                                                        <div className="card border-pos">
                                                            <div className="card-header">
                                                                {v.title}      ({v.branchname})
                                                            </div>
                                                            <div className="card-body text-primary text-center p-0" id={'qbx'+i}>
                                                                <img height="160px" src={v.qrcode_image} alt="" />
                                                            </div>
                                                            <div className="card-footer clearfix">
                                                                {/* <button type="button" className="btn btn-sm btn-danger mr5"><i className="fa fa-print"></i> Print</button> */}
                                                                <a href={v.dwn_url} className="btn btn-sm btn-primary float-left"><i className="fa fa-download"></i> {lang('Download')}</a>
                                                                <a href={v.appUrl} target="blank" className="btn btn-sm btn-danger float-right">{lang('Open Application')}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>

            {d.lists.map((v,i)=>(
            <div key={i} className="d-none d-print-block col-12 text-center border p-5">
                <div><img src="assets/img/agna-without-tagline.svg" width="360px" height="240px" /></div>
                <div className="fs42 fw500">Scan QR Code</div>
                <div className="fs36 fw400">and check our menu</div>
                <div className="fs60 fw600 mT20">{v.branchname}</div>
                <div><img height="720px" src={v.qrcode_image} alt="" /></div>
                <div className="fs36 fw500">{v.title}</div>
            </div>
            ))}
        </>
    )
}