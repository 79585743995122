import React from 'react';
import util from "./util";
import lang from '../lang';
//let $=window.$;

export function Input(props) {
    const {value, onChange, ...rest} = props;
    const [d, setData]=React.useState(value);
    React.useEffect(()=>{
        setData(value);
    }, [value]);
    
    return (
        <input value={d || ''} onChange={e=>setData(e.target.value)} {...rest} />
    );
}

export function If(props){
    const {cond, children} = props; return cond?(<>{children}</>):(<></>);
}

export function Paging(props){
    let c=props.pageob.cur_page;
    let s=props.pageob.page_start;
    let e=props.pageob.page_end;
    let total_pages=props.pageob.total_pages;
    let ranges=[];
    for(let i=s; i<=e; i++){
        ranges.push(i);
    }
    return (
        <ul className="pagination noselect m-0">
            <li className={'page-item '+(c<=1?'disabled':'')}>
                <a href="!#" className="page-link" onClick={(e)=>props.fn(e, c-1)}> &lt; </a>
            </li>

            {ranges.map(i=>(
                <li key={i} className={'page-item '+(c===i?'active':'')}>
                    <a href="!#" className="page-link" onClick={(e)=>props.fn(e, i)}> {i} </a>
                </li>
            ))}

            <li className={'page-item '+(c>=total_pages?'disabled':'')}>
                <a href="!#" className="page-link" onClick={(e)=>props.fn(e, c+1)}> &gt; </a>
            </li>
        </ul>
    );
}

export function Perpagedd(props){
    return (
        <select className="form-control" onChange={(e)=>props.fn(0, 1, e.target.value)}>
            {[25, 50, 100, 200, 500].map(ps=><option key={ps} value={ps}>{lang('Show')} {ps} {lang('Records')}</option>)}
        </select>
    );
}

export function Htmlvideo(props){
    return props.src?(
        <video className={props.className} id={props.id} autoPlay={props.autoPlay} controls>
            <source src={props.src} />
            Your browser does not support the video tag.
        </video>
    ):<></>;
}

export function RawHTML(props){
    let html=props.html.replace(/\n/g, '<br />');
    return <div dangerouslySetInnerHTML={{ __html: html}}></div>;
}

export function Tinymce(props){
    const [initiated, setInitiated]=React.useState(false);

    const init=()=>{
        let height=props.height?props.height:300;
        let fullpage=props.fullpage?props.fullpage:false;

        window.tinymce.EditorManager.execCommand('mceRemoveEditor', true, props.id);
        window.tinymce.init({
            selector: "#"+props.id,
            height: height,
            menubar: false,
            //forced_root_block:'div',
            fontsize_formats: "8px 9px 10px 11px 12px 13px 14px 15px 16px 18px 20px 22px 24px 26px 28px 30px 36px 40px 50px",
            plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen '+(fullpage?'fullpage':''),
            'insertdatetime media table paste code', 'responsivefilemanager'
            ],
    
            //alignleft aligncenter alignright alignjustify
            toolbar: 'insert | styleselect | bold italic | fontselect fontsizeselect | forecolor backcolor | bullist numlist outdent indent | link unlink | image responsivefilemanager table | removeformat code | fullscreen',
            //content_css: '//www.tinymce.com/css/codepen.min.css',

            external_filemanager_path:util.apiUrl+"filemanager/",
            filemanager_title:"File Manager", //"Responsive Filemanager" ,
            external_plugins: { "filemanager" : util.apiUrl+"filemanager/plugin.min.js"},
            filemanager_crossdomain:true,
                
            paste_auto_cleanup_on_paste: true,
            paste_remove_styles: true,
            paste_remove_styles_if_webkit: true,
            paste_strip_class_attributes: true,
            
            valid_elements: '*[*]',
            
            relative_urls: false,
            remove_script_host: false,
            convert_urls: false,
            
            init_instance_callback:(editor)=>{
                editor.setContent(props.data || '');
                editor.getDoc().body.style.fontSize = '14px';
                setInitiated(true);
            }
        });
    }

    React.useEffect(()=>{
        init();
        // eslint-disable-next-line
    }, []);
    
    React.useEffect(()=>{
        if(initiated){
            window.tinymce.EditorManager.get(props.id).setContent(props.data || '');
        }
        // eslint-disable-next-line
    }, [props.data]);
    
    return <input type="text" id={props.id} name={props.name} />;
}
export function GetTinymceContent(id){
    return window.tinymce.get(id).getContent();
}
export function SetTinymceContent(id, data){
    window.tinymce.get(id).setContent(data);
}
export function SubmitTinymce(id){
    window.tinyMCE.triggerSave(false, true);
}

export function SvgPlusIcon(){
    return <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 24 24" role="img" aria-hidden="true" focusable="false"><path d="M10 1c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm0 16c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7zm1-11H9v3H6v2h3v3h2v-3h3V9h-3V6zM10 1c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm0 16c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7zm1-11H9v3H6v2h3v3h2v-3h3V9h-3V6z"></path></svg>;
}

/** */
export function BranchesDD(props){
    let {data, ...rest}=props;
    const [branch_id, setData]=React.useState(data.branch_id);
    const handleChange=(e)=>{
        setData(e.target.value);
    }
    React.useEffect(()=>{setData(props.data.branch_id)}, [props.data]);
    return(
        <select {...rest} value={branch_id} onChange={handleChange}>
            {data.branches.map((v, i)=><option key={i} value={v.id}>{v.title}</option>)}
        </select>
    )
}

export function AmtFormat(props){
    let {curInfo, amt}=props;

    if(amt == 0){
        amt = 0;
    }else{
        amt=parseFloat(amt);
    }
    
    // amt=amt?amt.toFixed(curInfo?.no_of_decimal):'';   //this commented bcz for 0 also we want output
    amt=amt.toFixed(curInfo?.no_of_decimal);
    if(curInfo?.currency_place==='After'){
        amt=amt+' '+curInfo?.currency_abbr;
    }else{
        amt=curInfo?.currency_abbr+' '+amt;
    }
    return (
        <>
            {amt}
        </>
    )
}