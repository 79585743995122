import React, { useState, useEffect } from 'react';
import util from "../../util/util";
import { useParams, Link } from 'react-router-dom';
import { sdx } from "../../sdx";
import { If, Paging, Perpagedd ,AmtFormat} from "../../util/controls";
import lang from '../../lang';
import fbase from "../../services/firebase";
let client_id = 0, ref = {};

let $ = window.$;

export default function Crm(props) {

    client_id = props.client_id;
    const fn = {};
    const { id } = useParams();
    let pfn = props.computedMatch;
    const [d, setData] = useState({
        list: { result: [], page: {} },
        sdata: { p: 1, ps: 25 },
        data: {},
        branches: [],
        roles: [],
        role_id: 0,
        is_arabic: 0,
        curInfo: {},
        download_url : '' ,
        downloadPdf :'',
        print : '',
        branches: [],
        branchesids: [],
        branch_name : '',
        todayDate : '',
        start_date : '',
        end_date : '',
        sub_role :[],
        super_role:[],
        user_type:'',
        branchesidscopy: [],
    });
    fn.render = () => { setData({ ...d }); }

       d.todayDate = new Date();
       d.todayDate = d.todayDate.getFullYear() + "-" + (d.todayDate.getMonth() + 1) + "-" + d.todayDate.getDate();

    fn.sdata = (p, ps) => {
        d.sdata.p = p ? p : 1;
        d.sdata.ps = ps ? ps : d.sdata.ps;
        d.sdata.branch_ids = d.branchesids;
        
        return $("#sbfrm").serialize() + '&p=' + d.sdata.p + '&ps=' + d.sdata.ps + '&client_id=' + props.client_id+'&branch_ids='+d.sdata.branch_ids ;
    }

    fn.lists = (e, p, ps) => {
        
        if (e) e.preventDefault();
        util.ajx("crm/customerList", fn.sdata(p, ps)).then(res => {
            if (res.code === 200) {
                
                d.list = res.result;
                // d.list.sort((a,b) => b.totalorder - a.totalorder)

                // d.curInfo = res.curInfo;
                d.branches = res.branches;
                d.branchesids = res.branch_ids;
                d.branch_name = res.branch_name;
                d.start_date = res.start_date;
                d.end_date = res.end_date;
                d.sub_role = sdx.d.sub_role;
                d.super_role = sdx.d.super_role;
                d.user_type = sdx.d.user_type;
                d.branchesidscopy = res.branch_ids_copy;
                if(d.branchesids.length ===  d.branchesidscopy.length){
                    d.branch_name = 'All';
                }

                fn.render();

                
            }
        })
    }
  

    fn.handleChange = (e) => {
        d.data[e.target.name] = e.target.value;
        fn.render();
      }
    
      fn.openForm = (dtl) => {
    
        d.data = dtl ? { ...dtl } : { branches: [] };
        // var branch_ids =d.branchesids;
        // if (branch_ids == '') {
        //      $("#branchall").attr('checked', true);
        // }
    
        if(d.branchesids.length ===  d.branchesidscopy.length){
            d.branch_name = 'All';
        }
        fn.render();
        window.showModal($("#branchModal"), true);
      }

      fn.setAllchecked = (e) => {
        let bid = e.target.value;
        if (!e.target.checked) {
          d.branchesids = [];
          d.branch_name = '';
        }else{
            d.branchesids =  d.branchesidscopy.slice() ;
            d.branch_name = 'All';
        }
        fn.render();
      }
    
      fn.openDateForm = (dtl) => {
    
        d.data = dtl ? { ...dtl } : { branches: [] };
    
        $('#from_date').val('');
        $('#to_date').val('');

        fn.render();
        window.showModal($("#dateModal"), true);
      }
    
    
      fn.setBranchCheck = (e) => {
        let bid = e.target.value;
        if (e.target.checked) {
          d.branchesids.push(bid);
        } else {
          d.branchesids.splice(d.branchesids.indexOf(bid), 1);
        }
        // d.branchesids = d.data.branches;
        d.branch_name = '';
        if(d.branchesids.length ===  d.branchesidscopy.length){
            d.branch_name = 'All';
        }

        fn.render();
      }
    
      fn.save =(e, p, ps) => {
        if (e) e.preventDefault();
        // let fd = new FormData($("#bfrm")[0]);
        util.ajx("crm/customerList", fn.sdata(p, ps)).then(res => {
          $("#branchModal").modal('hide');
          d.list = res.result;
            // d.curInfo = res.curInfo;
            d.branches = res.branches;
            d.branchesids = res.branch_ids;
            d.branch_name = res.branch_name;
            // d.start_date = res.start_date;
            // d.end_date = res.end_date;
            d.sub_role = sdx.d.sub_role;
            d.super_role = sdx.d.super_role;
            d.user_type = sdx.d.user_type;
            d.branchesidscopy = res.branch_ids_copy;
                if(d.branchesids.length ===  d.branchesidscopy.length){
                    d.branch_name = 'All';
                }
          fn.render();
        })
      }


    useEffect(() => {
        fn.lists();
        $('#masterActive').removeClass('active');
        // eslint-disable-next-line
    }, []);

    return View(d, fn);

}

function View(d, fn) {
    return (
         <>
            <div className="">
            
            <div className="dashboard-top-filter">
                <i className="fas fa-filter mr-3"></i>
                <If cond={d.branches.length > 1}>
                <button type="button" id="branch_name" className="btn btn-secondary mr-2" onClick={() => fn.openForm()}>
                { d.branch_name}</button>
                </If>

                <If cond={d.branches.length == 1}>
                <button type="button" id="branch_name" className="btn btn-secondary mr-2" >
                { d.branch_name}</button>
                </If>
            </div>


            <div className="modal fade" id="branchModal" role="dialog" aria-labelledby="branchModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="branchModalLabel">{lang('Branch')} </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div className="modal-body">
                    <form id="bfrm" autoComplete="off" spellCheck="false">
                        <div className="form-control mb5" >
                        <label className="m-0 noselect">
                            <input type="checkbox" className="selectall"  value='' id='branchall' checked={d.branch_name == 'All' ? true : false } onClick={fn.setAllchecked} />
                        &nbsp;&nbsp;{lang('All')}
                    </label>
                        </div>

                        {d.branches.map((r, i) => (
                        <div className="form-control mb5" key={i}>
                            <label className="m-0 noselect">
                            <input type="checkbox" className="selectall" name="branches[]" checked={d.branchesids?.includes(r.id) ? true : false}  id={'branch_' + r.id} value={r.id} onChange={fn.setBranchCheck} />
                            &nbsp;&nbsp;{r.title}
                            </label>
                        </div>
                        ))}

                        <input type="hidden" name="start_date" value={window.sessionStorage['start_date']} />
                        <input type="hidden" name="end_date" value={window.sessionStorage['end_date']} />
                    </form>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-danger btn-red" onClick={fn.save} >{lang('Apply')}</button>
                    </div>
                </div>
                </div>
            </div>

           


                
                <div className="col-xl-12 col-lg-12">
                    <div className="mt-5">
                        <If cond={!client_id}>
                            <div className="d-flex flex-row align-items-center justify-content-between pl-4 pr-4 pt-4">
                                <h6 className="m-0 h5 text-dark">{lang('CRM')}</h6>
                            </div>
                        </If>
                        <div className={client_id ? "" : "main-content"}>
                            <div className={client_id ? "" : "container-fluid"}>
                                <If cond={d.list.result.length && !client_id}>
                                    {/* <div className="d-flex">
                                            <div className="text-secondary bold600 mb5">
                                                Showing {d.list.page.start + 1} - {d.list.page.start + d.list.page.total} of {d.list.page.total_records} records.
                                </div>
                                        </div> */}
                                </If>

                                <div className="form-inline">
                                    <form id="sbfrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                                        <input type="search" className="form-control mr-3" name="k" placeholder="Search By Mobile Number" />
                                        <button className="btn btn-red-outline btn-danger" type="submit"> <i className="fa fa-search"></i></button>

                                        

                                        <select className="form-control w180 single-line-dotted mr-3" name="pay_types"
                                                    onChange={e => { d.sdata.pay_types = e.target.value; fn.lists(); }}>
                                            <option value="">{lang('Customer Type (All)')}</option>
                                             <option value="unpaid">{lang('Unpaid')} </option>
                                             <option value="topOrders">{lang('Orders')} </option>

                                        </select>


                                        
                                    </form>
                                </div>



                                <div className="mt-4">
                                    <If cond={d.list.result.length}>
                                        <div className="table-responsive">
                                            <table className="table table-sm border m-0">
                                                <thead className="bg-light text-uppercase font-sm">
                                                    <tr>
                                                        <th className="w20">{lang('SN')}</th>
                                                        <th className="w80">{lang('Mobile Number')}</th>
                                                        <th className="w20"> {lang('Name')}</th> 
                                                        <th className="w80">{lang('Total Order')}</th>
                                                        <th className="w80">{lang('Unpaid Order')}</th>
                                                        <th className="w20"> </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-text-vmid">
                                                    {d.list.result.map((v, i) => (
                                                        <tr key={i}>
                                                            <td>{d.list.page.start + i + 1}.</td>
                                                            <td className="text-black fw600">{v.mob}</td>
                                                            <td>{v.name}</td>
                                                            <td className="text-black fw600">{v.totalorder}</td>
                                                            <td className="text-black fw600">{v.unpaidorder}</td>
                                                            <td>
                                                            <If cond={d.sub_role && d.sub_role[43] == 1 || d.user_type !='STAFF'}>
                                                                <Link to={"/crmDetails/"+v.mob} className="">{lang('View Detail')}</Link>
                                                            </If>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <If cond={!client_id}>
                                            <div className="d-flex justify-content-between mt-4 pb-3">
                                                <div className="mr5">
                                                    <Paging fn={fn.lists} pageob={d.list.page} />
                                                </div>
                                                <div>
                                                    <Perpagedd fn={fn.lists} />
                                                </div>
                                            </div>
                                        </If>
                                    </If>
                                    <If cond={!d.list.result.length}>
                                        <div className="no-rec">{lang('No record found')}</div>
                                    </If>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="iframe_print" ></div>
        </>
    )
}
