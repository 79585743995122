import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import util from "../../util/util";
import { AmtFormat, If } from "../../util/controls";
import lang from '../../lang';

let JQuery = window.$;

export default function PrintInvoice(props) {

    const [d, setData] = useState();
    let counter = 0;
    let receipt = [1, 2];
    let image_url = '';

    useEffect(() => {
        if (props.orderId) {
            window.showLoader();
            util.ajx(`orders/get_order_slip_data/${props.orderId}`).then(res => {
                // if (res.code === 200) {
                setData({ ...res.result });
                // console.log(window.navigator.userAgent.indexOf("Mac"));

                //if(res.result.branch.image_url){
                var iframe = JQuery('#iframe');
                iframe.contents().find("body").append(JQuery('.pdfbody'));

                JQuery('#iframe').get(0).contentWindow.print();


                //}



                // JQuery('#pdfbody').printThis();
                // }
            })




        }
    }, [props.orderId, props.printInvoiceTime, props.dateType, props.index, props.image_url])

    if (d) {
        // counter = d.count - props.index;
        counter = d.id_count + 1;
        

        if (parseInt(counter) < 10) {
            counter = '00' + counter;
        } else if (parseInt(counter) < 100) {
            counter = '0' + counter;
        } else {
            counter = counter;
        }

        if (d.client.no_receipt == 1) {
            receipt = [1];
        }


        if (props.image_url) {
            image_url = props.image_url;
        }

    }


    return (<div className="col-md-12" style={{ display: 'none' }}>
        <iframe id="iframe" style={{ display: 'none' }} ></iframe>



        {d && receipt.map((i) => (<div id="Y" key={i} style={{ width: '100%' }}>
            <div className="card pdfbody">
                <div className="card-body">
                    <div className="" id="pdfbody" style={{ width: '100%' }}>
                        <div className={'order-bill pdfc '} style={{ color: '#000000 !important', padding: '0 5px' }}>
                            <div className="bill-head" style={{ letterSpacing: '.1rm' }}>
                                <div className="text-center mb10 uc" style={{ marginBottom: '10px', fontSize: 20, textAlign: 'center', fontWeight: 'bold', textTransform: 'uppercase' }}>{d.order_type}</div>
                                <If cond={d.order_type == 'Dining'}><div className="text-center mb10 uc" style={{ fontSize: '15px', textAlign: 'center', fontWeight: 'bold', textTransform: 'uppercase' }}>{d.area}</div></If>
                                <If cond={props.dateType == 'today'}>
                                    <div style={{ marginBottom: '10px', marginTop: '15px', fontWeight: 'bold', borderTop: '1px dashed #000', borderBottom: '1px dashed #000', padding: '8px', textAlign: 'center' }}>
                                        <div className="uc mb5 text-center bold" style={{ fontSize: 20, textAlign: 'center', fontWeight: 'bold', textTransform: 'uppercase' }}>
                                            {lang('Order Number')}
                                        </div>
                                        <div><span style={{ fontSize: '36px' }}>{counter}</span></div>
                                    </div>
                                </If>

                                {/* <div className="uc mb5 text-center bold" style={{ fontSize: 20 , textAlign:'center',fontWeight : 'bold',textTransform: 'uppercase' }}>
                                    Order Number : <span style={{ fontSize: 22 }}>{d.odr_no}</span>
                                </div> */}
                                {/* <If cond={d.branch.image_url}>
                                <img className="mx60" src={d.branch.image_url} alt="" style={{height : '120px' ,display: 'block', marginLeft: 'auto' ,  marginRight: 'auto'}}/>
                                </If> */}
                                <If cond={image_url}>
                                    <img className="mx60" src={image_url} alt="" style={{ height: '120px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                                </If>


                                <div className="mb15 text-center" style={{ marginBottom: '15px', textAlign: 'center' }}>
                                    <div className="bold uc" style={{ fontSize: 20, textAlign: 'center', fontWeight: 'bold', textTransform: 'uppercase' }}><big>{d.branch.title}</big></div>
                                    <div className="grey uc" style={{ fontSize: 16, textAlign: 'center', textTransform: 'uppercase' }}>{d.branch.address}</div>
                                    <div className="bold" style={{ fontSize: 14, textAlign: 'center', fontWeight: 'bold', }} >{lang('Phone')}: {d.branch.phone1 || d.branch.phone2}</div>
                                </div>
                                <div className={' uc mb15'} style={{ marginBottom: '15px' }}>
                                    <div>
                                        <div className="pull-left" style={{ width: '40%', float: 'left' }}>
                                            <div>
                                                <span style={{ display: 'inline-block', width: 80, fontSize: '16px' }}>{lang('Bill No')}</span>
                                                <div><span style={{ fontSize: '20px' }}>{d.odr_no}</span></div>
                                            </div>
                                            <div style={{ marginTop: '10px' }}>
                                                <span style={{ display: 'inline-block', width: 80, fontSize: '16px' }}>{lang('Payment')}</span>
                                                <If cond={d.payment_status == 1}>
                                                    <div style={{ fontSize: '20px' }}>{d.pay_mode}</div>
                                                </If>
                                                <If cond={d.payment_status != 1}>
                                                    <div style={{ fontSize: '20px' }}>{lang('NOT PAID')}</div>
                                                </If>
                                            </div>
                                        </div>
                                        <div className="pull-right" style={{ width: 124, float: 'right' }}>
                                            <div>
                                                <span style={{ display: 'inline-block', width: 40, fontSize: '16px' }}>{lang('Date')}</span>
                                                <div style={{ fontSize: '20px' }}>{d.odr_date}</div>
                                            </div>
                                            <div style={{ marginTop: '10px' }}>
                                                <span style={{ display: 'inline-block', width: 40, fontSize: '16px' }}>{lang('Time')}</span>
                                                <div style={{ fontSize: '20px' }}>{d.odr_time}</div>
                                            </div>
                                        </div>
                                        <div className="clr" style={{ clear: 'both', lineHeight: 0, fontSize: 0, height: 0, margin: 0, padding: 0, overflow: 'hidden' }} />
                                    </div>
                                </div>
                                <div>
                                    <table className="bill-tbl">
                                        <thead >
                                            <tr>
                                                <td style={{ width: 10, padding: '3px 5px ', borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc' }}>#</td>
                                                <td style={{ borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc' }}>ITEM</td>
                                                <td className="w20 text-right" style={{ borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc', textAlign: 'right', padding: '3px 5px', width: '20px !important' }} >{lang('QTY')}</td>
                                                <td className="w20 text-right" style={{ borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc', textAlign: 'right', padding: '3px 5px', width: '20px !important' }}>{lang('RATE')}</td>
                                                <td className="w20 text-right" style={{ borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc', textAlign: 'right', padding: '3px 5px', width: '20px !important' }}>{lang('AMT')}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {d.items && d.items.map((row, ind) => {
                                                return <tr key={ind}>
                                                    <td className="w20">{ind + 1}.</td>
                                                    <td style={{ padding: '3px 5px' }}>
                                                        {row.item_name} &nbsp;&nbsp;{(row.size_name) ? '(' + row.size_name + ')' : ''}
                                                        <div style={{ paddingLeft: 5, color: '#777' }} />
                                                        {row.add_on_price && row.add_on_price.map((row1) => {
                                                            return <p key={ind}> {row1.name} </p>
                                                        })}

                                                    </td>
                                                    <td className="text-right" style={{ textAlign: 'right', padding: '3px 5px' }}>{row.qty}</td>
                                                    <td className="text-right" style={{ textAlign: 'right', padding: '3px 5px' }}>
                                                        <AmtFormat curInfo={props.curInfo} amt={row.amt / row.qty} />
                                                        {row.add_on_price && row.add_on_price.map((row1) => {
                                                            return <p key={ind}> <AmtFormat curInfo={props.curInfo} amt={row1.add_on_price} /> </p>
                                                        })}

                                                    </td>
                                                    <td className="text-right" style={{ textAlign: 'right', padding: '3px 5px' }}><AmtFormat curInfo={props.curInfo} amt={row.amt} />
                                                        {row.add_on_price && row.add_on_price.map((row1) => {
                                                            return <p key={ind}> <AmtFormat curInfo={props.curInfo} amt={row1.add_on_price * row.qty} /> </p>
                                                        })}
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                        <tfoot>
                                            {/* <tr>
                                                <td style={{padding: '3px 5px' ,  borderTop: '1px dashed #ccc' ,borderBottom: '1px dashed #ccc'}} colSpan={5}>Total Items Sold: <span style={{ display: 'inline-block', float: 'right'}}>{d.qty} </span></td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: '3px 5px' ,  borderTop: '1px dashed #ccc' ,borderBottom: '1px dashed #ccc'}} colSpan={5}>Subtotal: <span style={{ display: 'inline-block', float: 'right'}}><AmtFormat curInfo={props.curInfo} amt={d.amt} /></span></td>
                                            </tr>
                                            <If cond={d.order_type == 'Deliver'}>
                                                <tr>
                                                    <td style={{padding: '3px 5px' ,  borderTop: '1px dashed #ccc' ,borderBottom: '1px dashed #ccc'}} colSpan={5}>Delivery Charge: <span style={{ display: 'inline-block', float: 'right'}}><AmtFormat curInfo={props.curInfo} amt={d.delivery_amount} /></span></td>
                                                </tr>
                                            </If> 
                                            <If cond={d.tip_amt!=0}>
                                                <tr>
                                                    <td style={{padding: '3px 5px' ,  borderTop: '1px dashed #ccc' ,borderBottom: '1px dashed #ccc'}} colSpan={5}>Tip: <span style={{ display: 'inline-block', float: 'right'}}><AmtFormat curInfo={props.curInfo} amt={d.tip_amt} /></span></td>
                                                </tr>
                                            </If>
                                            <If cond={d.tax!=0}> 
                                            {d.tax_rate.map((row, ind) => {
                                             return<tr key={ind}>
                                                <td  style={{padding: '3px 5px' ,  borderTop: '1px dashed #ccc' ,borderBottom: '1px dashed #ccc'}} colSpan={5}>
                                                <span> {row.title} </span>: <span style={{ display: 'inline-block', float: 'right'}}><AmtFormat curInfo={props.curInfo} amt={row.tot} /></span></td>
                                            </tr>
                                            })}
                                            </If>
                                            <If cond={d.discount!=0}>
                                            <tr>
                                                <td  style={{padding: '3px 5px' ,  borderTop: '1px dashed #ccc' ,borderBottom: '1px dashed #ccc'}} colSpan={5}>Discount: <span style={{ display: 'inline-block', float: 'right'}}><AmtFormat curInfo={props.curInfo} amt={d.discount} /></span></td>
                                            </tr>
                                            </If>

                                            <tr>
                                                <td style={{padding: '3px 5px' , fontWeight: 'bold', borderTop: '1px dashed #ccc' ,borderBottom: '1px dashed #ccc'}} className="bold" colSpan={5}>Total: <span style={{ display: 'inline-block', float: 'right'}}><AmtFormat curInfo={props.curInfo} amt={d.total_amt} /></span></td>
                                            </tr>
                                            <If cond={d.payment_status!=0}>
                                            <tr>
                                                <td style={{padding: '3px 5px' ,  borderTop: '1px dashed #ccc' ,borderBottom: '1px dashed #ccc'}} colSpan={5}>Cash Tendered: <span style={{ display: 'inline-block', float: 'right'}}><AmtFormat curInfo={props.curInfo} amt={d.paid_amt} /></span></td>
                                            </tr>
                                            </If>
                                            <If cond={d.payment_status==0}>
                                            <tr>
                                                <td style={{padding: '3px 5px' ,  borderTop: '1px dashed #ccc' ,borderBottom: '1px dashed #ccc'}} colSpan={5}>Cash Tendered: <span style={{ display: 'inline-block', float: 'right'}}><AmtFormat curInfo={props.curInfo} amt={0} /></span></td>
                                            </tr>
                                            </If>
                                            <If cond={d.payment_status!=0}>
                                            <tr>
                                                <td style={{padding: '3px 5px' ,  borderTop: '1px dashed #ccc' ,borderBottom: '1px dashed #ccc'}} colSpan={5}>Change: <span style={{ display: 'inline-block', float: 'right'}}><AmtFormat curInfo={props.curInfo} amt={d.paid_amt-d.total_amt} /></span></td>
                                            </tr>
                                            </If> */}
                                        </tfoot>
                                    </table>
                                </div>
                                <div style={{ paddingTop: '25px' }}>
                                    <div>
                                        <div style={{ padding: '3px 5px', borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc' }} colSpan={5}>{lang('Total Items Sold')}: <span style={{ display: 'inline-block', float: 'right' }}>{d.qty} </span></div>
                                    </div>
                                    <div>
                                        <div style={{ padding: '3px 5px', borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc' }} colSpan={5}>{lang('Subtotal')}: <span style={{ display: 'inline-block', float: 'right' }}><AmtFormat curInfo={props.curInfo} amt={d.amt} /></span></div>
                                    </div>
                                    <If cond={d.order_type == 'Deliver'}>
                                        <div>
                                            <div style={{ padding: '3px 5px', borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc' }} colSpan={5}>{lang('Delivery Charge')}: <span style={{ display: 'inline-block', float: 'right' }}><AmtFormat curInfo={props.curInfo} amt={d.delivery_amount} /></span></div>
                                        </div>
                                    </If>
                                    <If cond={d.tip_amt != 0}>
                                        <div>
                                            <div style={{ padding: '3px 5px', borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc' }} colSpan={5}>{lang('Tip')}: <span style={{ display: 'inline-block', float: 'right' }}><AmtFormat curInfo={props.curInfo} amt={d.tip_amt} /></span></div>
                                        </div>
                                    </If>
                                    <If cond={d.tax != 0}>
                                        {d.tax_rate.map((row, ind) => {
                                            return <div key={ind}>
                                                <div style={{ padding: '3px 5px', borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc' }} colSpan={5}>
                                                    <span> {row.title} </span>: <span style={{ display: 'inline-block', float: 'right' }}><AmtFormat curInfo={props.curInfo} amt={row.tot} /></span></div>
                                            </div>
                                        })}
                                    </If>
                                    <If cond={d.discount != 0}>
                                        <div>
                                            <div style={{ padding: '3px 5px', borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc' }} colSpan={5}>{lang('Discount')}: <span style={{ display: 'inline-block', float: 'right' }}><AmtFormat curInfo={props.curInfo} amt={d.discount} /></span></div>
                                        </div>
                                    </If>

                                    <div>
                                        <div style={{ padding: '3px 5px', fontWeight: 'bold', borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc' }} className="bold" colSpan={5}>{lang('Total')}: <span style={{ display: 'inline-block', float: 'right' }}><AmtFormat curInfo={props.curInfo} amt={d.total_amt} /></span></div>
                                    </div>
                                    <If cond={d.payment_status != 0}>
                                        <div>
                                            <div style={{ padding: '3px 5px', borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc' }} colSpan={5}>{lang('Cash Tendered')}: <span style={{ display: 'inline-block', float: 'right' }}><AmtFormat curInfo={props.curInfo} amt={d.paid_amt} /></span></div>
                                        </div>
                                    </If>
                                    <If cond={d.payment_status == 0}>
                                        <div>
                                            <div style={{ padding: '3px 5px', borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc' }} colSpan={5}>{lang('Cash Tendered')}: <span style={{ display: 'inline-block', float: 'right' }}><AmtFormat curInfo={props.curInfo} amt={0} /></span></div>
                                        </div>
                                    </If>
                                    <If cond={d.payment_status != 0}>
                                        <div>
                                            <div style={{ padding: '3px 5px', borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc' }} colSpan={5}>{lang('Change')}: <span style={{ display: 'inline-block', float: 'right' }}><AmtFormat curInfo={props.curInfo} amt={d.paid_amt - d.total_amt - d.refund_amt} /></span></div>
                                        </div>
                                    </If>
                                    <If cond={d.refund_amt != 0}>
                                        <div>
                                            <div style={{ padding: '3px 5px', borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc' }} colSpan={5}>{lang('Refund')}: <span style={{ display: 'inline-block', float: 'right' }}><AmtFormat curInfo={props.curInfo} amt={d.refund_amt} /></span></div>
                                        </div>
                                    </If>

                                </div>
                            </div>

                            <div style={{ paddingTop: '25px' }}>
                                <div className="bold" style={{ fontWeight: 'bold' }}>{lang('Bill To')}:</div>
                                <div>{d.name} ({lang('Mob')}: {d.mob})</div>
                                <div> {d.area_name}, {d.address || ''} </div>
                            </div>
                        </div>
                        <div style={{ breakAfter: 'page' }}></div>
                    </div>
                </div>
            </div>
        </div>))}


    </div>



    )
}