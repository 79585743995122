import React, {useState, useEffect} from 'react';
import util from "../../util/util";
import Branch from "../cmaster/Branches";
import {If, Paging, Perpagedd} from "../../util/controls";
let $=window.$;

let clientDepts=[];
export default function Clients() {
    const fn={};
    const [d, setData]=useState({
        list:{result:[], page:{}},
        sdata:{p:1, ps:25},
        data:{},
        countries: [],
        newcountries: [],
        client_bussiness :[],
        subscriptions :[],
        language:[],
        client_id:0,
        timeFlg:0,
        mobile_max_digit : 10,
    });
    fn.render=()=>{setData({...d});}

    fn.handleChange=(e)=>{
        d.data[e.target.name]=e.target.value;
        // var phonecode = e.target.value;
        // phonecode = phonecode.split('_');
        // d.data.phone_code1=phonecode[1];
        fn.render();       

    }
    fn.handleChangecountry=(e)=>{
        d.data[e.target.name]=e.target.value;
        var phonecode = e.target.value;
        phonecode = phonecode.split('_');
        d.data.phone_code1=phonecode[1];
        d.data.phone_code2=phonecode[1];
        d.data.mobile_max_digit = phonecode[2];
        fn.render();      

        util.ajx("cmaster/countrylang", { country_id: phonecode[0] }).then(res => {
             
            d.language = res.result;                
                    fn.render();
                // }
            })


        

    }

    fn.openForm=(dtl)=>{
        d.data=dtl?{...dtl}:{};
        d.language = d.data.languages? d.data.languages:[];
        fn.render();
        $("#frm input[type='file']").val('');
        window.draggable('#branchModal');
        window.showModal($("#formModal"), true);
    }

   
    fn.openDeptForm=(id)=>{
        clientDepts=[];
        util.ajx("master/get_client_depts/" + id).then(res=>{
            if(res.code===200){
                res.result.forEach(v=>{
                    clientDepts.push(v.dept_id);
                });
                fn.render();
                window.showModal($("#deptModal"), true);
            }
        })
    }

    fn.openBranchModal=(id)=>{
        setData({...d, client_id:id, timeFlg:new Date().getTime()});
        window.showModal($("#branchModal"), true);
    }
   
    fn.openSubscriptionForm = (id,client_id) => {
        
        // d.data = dtl ? { ...dtl } : {};
        // fn.render();

        
        // d.id = d.data.id || 0
        let arg =  id;
        util.ajx("subscription/create_subscription",{arg:arg,client:'client',client_id:client_id}).then(res => {

            $('#main').html(''); 
            $('#main').append(res.result);  
            $('#subscription').trigger("reset");
        })
        window.showModal($("#subscriptionformModal"), true);

    }


    fn.sdata=(p, ps)=>{
        d.sdata.p=p?p:1;
        d.sdata.ps=ps?ps:d.sdata.ps;
        return $("#sfrm").serialize()+'&p='+d.sdata.p+'&ps='+d.sdata.ps;
    }
    fn.lists=(e, p, ps)=>{
        if(e)e.preventDefault();
        util.ajx("master/clients", fn.sdata(p, ps)).then(res=>{
            if(res.code===200){
                d.list=res.result;
                fn.render();
            }
        })
    }

    fn.save=()=>{
        let fd=new FormData($("#frm")[0]);
        util.ajx("master/save_client", fd).then(res=>{
            if(res.code===200){
                window.hideModal($("#formModal"));
                fn.lists(0, d.data.id?d.sdata.p:1);
            }
        })
    }

    fn.saveClientDepts=()=>{
        let fd=new FormData($("#clientFrm")[0]);
        util.ajx("master/save_client_depts", fd).then(res=>{
            if(res.code===200){
                window.hideModal($("#deptModal"));
                fn.lists(0, d.data.id?d.sdata.p:1);
            }
        })
    }

    fn.saveSubscription=()=>{
        let fd = new FormData($("#subscription")[0]);
        util.ajx("subscription/save_client_subscription", fd).then(res => {
            if (res.code === 200) {
                window.hideModal($("#subscriptionformModal"));
                fn.lists(0, d.data.id ? d.sdata.p : 1);
            }
        })
    }

    fn.deleterec=(id)=>{
        // let callback=()=>{
            util.ajx("master/delete_client", {id:id}).then(res=>{
                if(res.code===200){
                    fn.lists();
                }
            })
        // }
        // window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.onCloseBranchModal=()=>{
        fn.lists(0, d.sdata.p);
    }

    fn.handleImageChange = (e) => {
        d.data.image_url = URL.createObjectURL(e.target.files[0]);

        // document.getElementById("removeImage").style.display = "block";
        fn.render();
    }

    fn.remove_image = (id) => {
        // var myobj = document.getElementById("images");
        // myobj.remove();
        document.getElementById('images').src = "''";
        document.getElementById("removeImage").style.display = "none";
        $('input[type="file"]').val('');
        if (id != '') {
            util.ajx("master/remove_client_menu_image", id).then(res => {
                d.data.image_url = '';
            })

        }

    }

    fn.init=()=>{
        util.ajx("master/init_clients").then(res=>{
            if(res.code===200){
                d.list=res.result;
                d.countries=res.countries;
                d.newcountries=res.allnewcountries;
                d.client_bussiness=res.client_bussiness;
                d.subscriptions = res.subscriptions;
                fn.render();
            }
        })
    }
    useEffect(()=>{
        fn.init();
        // eslint-disable-next-line
    }, []);
    
    return View(d, fn);
}

function View(d, fn){
   

    return(
        <>
            <div className="col-xl-12 col-lg-12">
                <div className="card shadow mb-4 rounded-lg">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 h5 text-dark">Clients</h6>
                        <div className="ml-auto mt-auto mb-auto">
                            
                            <button className="btn btn-outline-primary" onClick={() => fn.openForm()}><i className="fa fa-plus"></i> Add</button>
                        </div>

                    </div>
                    <div className="card-body px-0">
                        <div className="main-content">
                            <div className="container-fluid">
                                <If cond={d.list.result.length}>
                                    <div className="d-flex">
                                        <div className="text-secondary bold600 mb5">
                                            Showing {d.list.page.start+1} - {d.list.page.start+d.list.page.total} of {d.list.page.total_records} records.
                                        </div>
                                    </div>
                                </If>

                                <div className="d-flex tbl-search-head">
                                    <div className="form-inline">
                                        <form id="sfrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                                            <div className="input-group">
                                                <input type="search" className="form-control" name="k" placeholder="Search" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-light-blue" type="submit"><i className="fa fa-search"></i></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="ml-auto mt-auto mb-auto">
                                    </div>
                                </div>

                                <div>
                                    <If cond={d.list.result.length}>
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-sm table-striped table-hover m-0">
                                                <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                                    <tr>
                                                        <th className="w20">SN</th>
                                                        {/* <th className="w40"></th> */}
                                                        <th colSpan="2">Clinic/Hospital Name</th>
                                                        <th className="w70"></th>
                                                        <th className="w100">Country</th>
                                                        <th className="w60">Status</th>
                                                        <th className="w70"></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-text-top">
                                                    {d.list.result.map((v, i)=>(
                                                        <tr key={i}>
                                                            <td>{d.list.page.start+i+1}.</td>
                                                            {/* <td>
                                                                <If cond={v.image_url}>
                                                                    <img src={v.image_url} className="mw-100" alt="" />
                                                                </If>
                                                            </td> */}
                                                            <td>
                                                                <div className="bold600 mb3">{v.company}</div>
                                                                <div className="note-text">
                                                                    Admin: <strong>{v.u_name} ({v.u_email})</strong>
                                                                </div>
                                                            </td>
                                                            
                                                            <td className="nowrap w80">
                                                               
                                                                <button type="button" className="btn btn-sm btn-info gr nowrap" onClick={()=>fn.openSubscriptionForm(v.subscription_id,v.id)}>
                                                                    Subscription <span className="badge badge-warning"></span>
                                                                </button>
                                                            </td>

                                                            <td className="nowrap w80">
                                                               
                                                                <button type="button" className="btn btn-sm btn-info gr nowrap" onClick={()=>fn.openBranchModal(v.id)}>
                                                                    Branches <span className="badge badge-warning">{v.branch_count}</span>
                                                                </button>
                                                            </td>
                                                            
                                                            <td className="nowrap">{v.c_title}</td>
                                                            <td className="nowrap">
                                                                <If cond={v.status==='1'}>
                                                                    <div className="badge badge-success">Active</div>
                                                                </If>
                                                                <If cond={v.status==='0'}>
                                                                    <div className="badge badge-danger">Inactive</div>
                                                                </If>    
                                                            </td>
                                                            <td className="text-center">
                                                                <div className="btn-group">
                                                                    <button type="button" className="btn btn-sm btn-light-blue gr" onClick={()=>fn.openForm(v)}><i className="fa fa-edit"></i></button>
                                                                    <button type="button" className="btn btn-sm btn-danger gr" onClick={()=>fn.deleterec(v.id)}><i className="fa fa-trash-o"></i></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex tbl-foot-bx">
                                            <div className="mr5">
                                                <Paging fn={fn.lists} pageob={d.list.page} />
                                            </div>
                                            <div>
                                                <Perpagedd fn={fn.lists} />
                                            </div>
                                        </div>
                                    </If>
                                    <If cond={!d.list.result.length}>
                                        <div className="no-rec">No record found</div>
                                    </If>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal modal-scroll" id="subscriptionformModal" data-backdrop="static">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <div className="modal-title uc">{d.data.id?'Edit':'Add'} Subscription</div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <div id="main">

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={fn.saveSubscription}>Save</button>
                        </div>
                    </div>
                </div>
            </div>


            {/** Form */}
            <div className="modal modal-scroll no-backdrop" id="formModal" data-backdrop="static">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <div className="modal-title uc">{d.data.id?'Edit':'Add'} Client</div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <form id="frm" autoComplete="off" spellCheck="false">
                                <input type="hidden" name="id" defaultValue={d.data.id} />
                                <input type="hidden" name="created" defaultValue={d.data.created} />
                                <ul className="nav nav-pills modal-nav nav-justified border-bottom m-0 bg-grey sticky-top" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link active" id="tax-group-tab" data-toggle="pill" href="#tax-group" role="tab" aria-controls="tax-group" aria-selected="true">Client Information</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="location-tab" data-toggle="pill" href="#location" role="tab" aria-controls="location" aria-selected="false">Menu Setting</a>
                                    </li>
                                </ul>
                                
                                <div className="tab-content p-3" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="tax-group" role="tabpanel" aria-labelledby="tax-group-tab">
                                        <div className="row mingap">
                                            <div className="form-group col-md-4">
                                                <label className="req">Company Name</label>
                                                <input type="text" className="form-control" name="company" value={d.data.company || ''} onChange={fn.handleChange} />
                                            </div>

                                            
                                            <If cond={d.data.client_status == 1}>
                                                <div className="form-group col-md-4">
                                                    <label className="req">Country</label>
                                                    <select className="form-control" name="country_id" value={d.data.country_id || ''} onChange={fn.handleChange}>
                                                        {d.countries.map(r=><option key={r.id} value={r.id}>{r.title}</option>)}
                                                    </select>
                                                </div>
                                            </If>

                                            <If cond={d.data.client_status != 1}>
                                                <div className="form-group col-md-4">
                                                    <label className="req">Type Of Business</label>
                                                    
                                                    <select className="form-control" name="type_of_business" value={d.data.type_of_business || ''} onChange={fn.handleChange}>
                                                    <option value="">Select Type Of Business</option>
                                                    {d.client_bussiness.map(r=><option key={r.id} value={r.id}>{r.business_type}</option>)}
                                                    </select>
                                                    {/* <input type="text" className="form-control" name="type_of_business" value={d.data.type_of_business || ''} onChange={fn.handleChange} /> */}
                                                
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label className="req">Subscription</label>
                                                    
                                                    <select className="form-control" name="subscription_id" value={d.data.subscription_id || ''} onChange={fn.handleChange}>
                                                    <option value="">Select Subscription</option>
                                                    {d.subscriptions.map(r=><option key={r.id} value={r.id}>{r.plan_name}</option>)}
                                                    </select>
                                                    {/* <input type="text" className="form-control" name="type_of_business" value={d.data.type_of_business || ''} onChange={fn.handleChange} /> */}
                                                
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label className="req">Country</label>
                                                    <select className="form-control" name="country_id"   value={d.data.country_id || ''} onChange={fn.handleChangecountry}>
                                                    <option value="">Select Country</option>
                                                        {d.newcountries.map(r=><option key={r.id} data-id={r.phone_code} value={r.id+'_'+r.phone_code+'_'+r.mobile_digits}>{r.country_name}</option>)}
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label className="req">Language</label>
                                                    <select className="form-control" name="language" value={d.data.language || ''} onChange={fn.handleChange}>
                                                        <option value="">Select</option>
                                                        {d.language.map((r, i) => <option key={i} value={r.language}>{r.language}</option>)}
                                                    </select>
                                                </div> 


                                            </If>

                                            <div className="form-group col-md-3">
                                                <label className="req">Number of Branches</label>
                                                <input type="text" className="form-control " name="no_of_branches" value={d.data.no_of_branches || ''} onChange={fn.handleChange} />
                                            </div>

                                            

                                            
                                            <If cond={d.data.client_status != 1}>
                                                <div className="form-group col-md-2">
                                                    <label className="req">Code</label>
                                                    <input type="text" readOnly className="form-control phonecodevalue1" name="phone_code1" value={d.data.phone_code1 || ''} onChange={fn.handleChange} />
                                                </div>

                                            </If>
                                        
                                        
                                            <div className="form-group col-md-3">
                                                <label className="req">Phone 1</label>
                                                <input type="text" className="form-control phonecodevalue1" name="company_phone1" maxLength={d.data.mobile_max_digit}  value={d.data.company_phone1 || ''} onChange={fn.handleChange} />
                                            </div>
                                            <If cond={d.data.client_status != 1}>
                                                <div className="form-group col-md-2">
                                                    <label className="">Code</label>
                                                    <input type="text" readOnly className="form-control" name="phone_code2" value={d.data.phone_code2 || ''} onChange={fn.handleChange} />
                                                </div>

                                            </If>
                                            
                                            <div className="form-group col-md-3">
                                                <label className="">Phone 2</label>
                                                <input type="text" className="form-control" name="company_phone2" maxLength={d.data.mobile_max_digit} value={d.data.company_phone2 || ''} onChange={fn.handleChange} />
                                            </div>
                                            {/* <div className="form-group col-md-4">
                                                <label className="req">Phone 1</label>
                                                <input type="text" className="form-control" name="company_phone1" value={d.data.company_phone1 || ''} onChange={fn.handleChange} />
                                            </div> */}

                                            
                                            {/* <div className="form-group col-md-4">
                                                <label className="">Phone 2</label>
                                                <input type="text" className="form-control" name="company_phone2" value={d.data.company_phone2 || ''} onChange={fn.handleChange} />
                                            </div> */}
                                            
                                            {/* <div className="form-group col-md-3">
                                                <label className="req">Photo</label>
                                                <input type="file" className="form-control form-control-sm" name="image" accept="image/*" onChange={e=>window.checkImage(e.target)} />
                                                <If cond={d.data.image}>
                                                    <div className="pt5 w100">
                                                        <img src={d.data.image_url} className="mw-100" alt="" />
                                                    </div>
                                                </If>
                                            </div> */}

                                            <div className="form-group col-md-2"> 
                                                <label className="req">Status</label>
                                                <select className="form-control" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                                    <option value="1">Active</option>
                                                    <option value="0">Inactive</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-md-3"> 
                                                <label className="req">No of Receipt Print</label>
                                                <select className="form-control" name="no_receipt" value={d.data.no_receipt || ''} onChange={fn.handleChange}>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                </select>
                                            </div>

                                            <div className="form-group col-md-3"> 
                                                <label className="req">Billing Type</label>
                                                <select className="form-control" name="billing_type" value={d.data.billing_type || ''} onChange={fn.handleChange}>
                                                    <option value="1">Manual Billing</option>
                                                    <option value="0">Quick Billing</option>
                                                </select>
                                            </div>

                                            {/* <div className="form-group col-md-3">
                                                <label className="">Payment Type</label>
                                                <select className="form-control" name="payment_type" value={d.data.payment_type || ''} onChange={fn.handleChange}>
                                                    <option value="">Select Type </option>
                                                    <option value="1">Monthaly</option>
                                                    <option value="2">Yearly</option>
                                                    <option value="3">One Time</option>
                                                    <option value="4">Free Trial</option>
                                                </select>
                                            </div>

                                            <div className="form-group col-md-3">
                                                <label className="">Payment Amount</label>
                                                <input type="text" className="form-control" name="payment_amount"  value={d.data.payment_amount || ''} onChange={fn.handleChange} />
                                            </div> */}

                                        </div>

                                        <fieldset>
                                            <legend>Client Admin Details</legend>
                                            <div className="p10">
                                                <div className="row mingap">
                                                    <div className="form-group col-md-3">
                                                        <input type="hidden" name="u_id" value={d.data.u_id || ''} />
                                                        <label className="req">Name</label>
                                                        <input type="text" className="form-control" name="u_name" value={d.data.u_name || ''} onChange={fn.handleChange} />
                                                    </div>
                                                    {/* <div className="form-group col-md-3">
                                                        <label className="req">User Name</label>
                                                        <input type="text" className="form-control" name="u_username" value={d.data.u_username || ''} onChange={fn.handleChange} />
                                                    </div> */}
                                                    <div className="form-group col-md-6">
                                                        <label className="req">Email</label>
                                                        <input type="email" className="form-control" name="u_email" value={d.data.u_email || ''} onChange={fn.handleChange} />
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <label className="">Password</label>
                                                        <input type="text" className="form-control" name="u_password" value={d.data.u_password || ''} onChange={fn.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        </div>
                                        <div className="tab-pane fade" id="location" role="tabpanel" aria-labelledby="location-tab">
                                                    <div className="form-group col-md-3">
                                                        <input type="hidden" name="menu_id" value={d.data.menu_id || ''} />
                                                        <label className="req">Menu Colour</label>
                                                        <input type="text" className="form-control" name="color_code" maxLength={7} value={d.data.color_code || '#'} onChange={fn.handleChange} />
                                                    </div>
                                                    <div className="form-group col-md-2"> 
                                                        <label className="req">Display Copy Right</label>
                                                        <select className="form-control" name="display_copyright" value={d.data.display_copyright || ''} onChange={fn.handleChange}>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </div>

                                                    <div className="form-group col-md-2"> 
                                                        <label className="req">Is Bell Require?</label>
                                                        <select className="form-control" name="bell_require" value={d.data.bell_require || ''} onChange={fn.handleChange}>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </div>

                                                    <div className="form-group col-md-12">
                                                        <label>Image</label>
                                                        <input type="file" className="form-control" name="menu_image" onChange={fn.handleImageChange} />

                                                        <If cond={d.data.menu_image_url}>
                                                            <button type="button" className="close" id="removeImage" onClick={() => fn.remove_image(d.data.menu_id)}><span aria-hidden="true">&times;</span></button>

                                                        </If>
                                                        <If cond={d.data.menu_image_url == ''}>
                                                            <button type="button" className="close" style={{ display: 'none' }} id="removeImage" onClick={() => fn.remove_image(d.data.menu_id)}><span aria-hidden="true">&times;</span></button>
                                                        </If>
                                                        <If cond={d.data.menu_image_url}>

                                                            <div className="pt3 w100">
                                                                <img className="mw-100" id="images" src={d.data.menu_image_url} alt="" />

                                                            </div>
                                                        </If>
                                                    </div>
                                                
                                        </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={fn.save}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Branches */}
            <div className="modal modal-scroll no-backdrop" id="branchModal" data-backdrop="static">
                <div className="modal-dialog modal-xl"  role="document">
                    <div className="modal-content" >
                        <div className="modal-header">
                        <div className="modal-title uc">Branch</div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={fn.onCloseBranchModal}><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <Branch client_id={d.client_id} timeFlg={d.timeFlg} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={fn.onCloseBranchModal}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}