import React, {useState, useEffect} from 'react';
import util from "../../util/util";
import {If, Paging, Perpagedd} from "../../util/controls";
let $=window.$;

export default function Countries() {
    const fn={};
    const [d, setData]=useState({
        list:{result:[], page:{}},
        sdata:{p:1, ps:25},
        data:{timezones:[]},
        currencies: [],
        timeZones:[]
    });
    fn.render=()=>{setData({...d});}

    fn.handleChange=(e)=>{
        d.data[e.target.name]=e.target.value;
        fn.render();
    }

    fn.openForm=(dtl)=>{
        d.data=dtl?{...dtl}:{timezones:[]};
        fn.render();
        // console.log(d.data.timezones)
        window.showModal($("#formModal"));
    }

    fn.sdata=(p, ps)=>{
        d.sdata.p=p?p:1;
        d.sdata.ps=ps?ps:d.sdata.ps;
        return $("#sfrm").serialize()+'&p='+d.sdata.p+'&ps='+d.sdata.ps;
    }
    fn.lists=(e, p, ps)=>{
        if(e)e.preventDefault();
        util.ajx("master/countries", fn.sdata(p, ps)).then(res=>{
            if(res.code===200){
                d.list=res.result;
                fn.render();
            }
        })
    }

    fn.save=()=>{
        let fd=new FormData($("#frm")[0]);
        util.ajx("master/save_country", fd).then(res=>{
            if(res.code===200){
                window.hideModal($("#formModal"));
                fn.lists(0, d.data.id?d.sdata.p:1);
            }
        })
    }

    fn.deleterec=(id)=>{
        let callback=()=>{
            util.ajx("master/delete_country", {id:id}).then(res=>{
                if(res.code===200){
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.init=()=>{
        util.ajx("master/init_countries").then(res=>{
            if(res.code===200){
                d.list=res.result;
                d.currencies=res.currencies;
                d.timeZones=res.timezones;
                fn.render();
            }
        })
    }
    useEffect(()=>{
        fn.init();
        // eslint-disable-next-line
    }, []);

    return View(d, fn);
}

function View(d, fn){
    return(
        <>
            <div className="col-xl-12 col-lg-12">
                <div className="card shadow mb-4 rounded-lg">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 h5 text-dark">Countries</h6>
                            <div className="ml-auto mt-auto mb-auto">
                                <button className="btn btn-outline-primary" onClick={() => fn.openForm()}><i className="fa fa-plus"></i> Add</button>
                            </div>
                        </div>
                    <div className="card-body px-0">
                        <div className="main-content">
                            <div className="container-fluid">
                                <If cond={d.list.result.length}>
                                    <div className="d-flex">
                                        <div className="text-secondary bold600 mb5">
                                            Showing {d.list.page.start+1} - {d.list.page.start+d.list.page.total} of {d.list.page.total_records} records.
                                        </div>
                                    </div>
                                </If>

                                <div className="d-flex tbl-search-head">
                                    <div className="form-inline">
                                        <form id="sfrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                                            <div className="input-group">
                                                <input type="search" className="form-control" name="k" placeholder="Search" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-light-blue" type="submit"><i className="fa fa-search"></i></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="ml-auto mt-auto mb-auto">
                                    </div>
                                </div>

                                <div>
                                    <If cond={d.list.result.length}>
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-sm table-striped table-hover m-0">
                                                <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                                    <tr>
                                                        <th className="w20">SN</th>
                                                        <th colSpan="2">Country Name</th>
                                                        <th className="w100 nowrap">Country Code</th>
                                                        <th className="w100">ISD Code</th>
                                                        <th className="w80">Currency</th>
                                                        <th className="w80">Status</th>
                                                        <th className="w70"></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-text-vmid">
                                                    {d.list.result.map((v, i)=>(
                                                        <tr key={i}>
                                                            <td>{d.list.page.start+i+1}.</td>
                                                            <td className="w30 text-center"><img src={v.icon_url} className="mw-100" alt="" /></td>
                                                            <td>{v.title}</td>
                                                            <td>{v.code}</td>
                                                            <td>{v.isd_code}</td>
                                                            <td className="nowrap">{v.currency_code}</td>
                                                            <td>
                                                                <If cond={v.status==='1'}>
                                                                    <div className="badge badge-success">Active</div>
                                                                </If>
                                                                <If cond={v.status==='0'}>
                                                                    <div className="badge badge-danger">Inactive</div>
                                                                </If>    
                                                            </td>
                                                            <td className="text-center">
                                                                <div className="btn-group">
                                                                    <button type="button" className="btn btn-sm btn-light-blue gr" onClick={()=>fn.openForm(v)}><i className="fa fa-edit"></i></button>
                                                                    <button type="button" className="btn btn-sm btn-danger gr" onClick={()=>fn.deleterec(v.id)}><i className="fa fa-trash-o"></i></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex tbl-foot-bx">
                                            <div className="mr5">
                                                <Paging fn={fn.lists} pageob={d.list.page} />
                                            </div>
                                            <div>
                                                <Perpagedd fn={fn.lists} />
                                            </div>
                                        </div>
                                    </If>
                                    <If cond={!d.list.result.length}>
                                        <div className="no-rec">No record found</div>
                                    </If>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/** Form */}
            <div className="modal modal-scroll" id="formModal" data-backdrop="static">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <div className="modal-title uc">{d.data.id?'Edit':'Add'} Country</div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <form id="frm" autoComplete="off" spellCheck="false">
                                <input type="hidden" name="id" defaultValue={d.data.id} />
                                <div className="row mingap">
                                    <div className="form-group col-md-8">
                                        <label className="req">Country Name</label>
                                        <input type="text" className="form-control" name="title" value={d.data.title || ''} onChange={fn.handleChange} />
                                    </div>

                                    <div className="form-group col-md-4">
                                        <label className="req">Country Code (2 Letters)</label>
                                        <input type="text" className="form-control" name="code" value={d.data.code || ''} onChange={fn.handleChange} onKeyDown={e=>{if(e.keyCode===32)e.preventDefault();}} maxLength="2" />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label className="req">Currency</label>
                                        <select className="form-control" name="currency_id" value={d.data.currency_id || ''} onChange={fn.handleChange}>
                                            {d.currencies.map(r=><option key={r.id} value={r.id}>{r.code} - {r.title}</option>)}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label className="req">ISD Code</label>
                                        <input type="text" className="form-control" name="isd_code" value={d.data.isd_code || ''} onChange={fn.handleChange} />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label className="req">Id Card Name</label>
                                        <input type="text" className="form-control" name="idcard_name" value={d.data.idcard_name || ''} onChange={fn.handleChange} />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label className="req">Id No. Length</label>
                                        <input type="number" className="form-control" name="idno_length" value={d.data.idno_length || ''} onChange={fn.handleChange} />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label className="req">Mobile No. Length</label>
                                        <input type="number" className="form-control" name="mob_no_length" value={d.data.mob_no_length || ''} onChange={fn.handleChange} />
                                    </div>
                                    <div className="form-group col-md-4"> 
                                        <label className="req">Payment Gateway</label>
                                        <select className="form-control" name="payment_gateway" value={d.data.payment_gateway || ''} onChange={fn.handleChange}>
                                            <option value="cashfree">Cashfree</option>
                                            <option value="stripe">Stripe</option>
                                        </select>
                                    </div>
                                    
                                    <div className="form-group col-md-12"> 
                                        <label className="req">Timezones</label>
                                        <div>
                                            <TimezonesForm allTimeZones={d.timeZones} timezones={d.data.timezones} />
                                        </div>
                                    </div>

                                    <div className="form-group col-md-4"> 
                                        <label className="req">Status</label>
                                        <select className="form-control" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={fn.save}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function TimezonesForm(props){
    let allTimeZones=props.allTimeZones;
    let dataTimezones=props.timezones;

    if(dataTimezones.length===0){
        dataTimezones=[];
    }
    const [d, setData]=useState({
        timezones:dataTimezones
    });

    const addNew=()=>{
        d.timezones.push('');
        setData({...d});
    }
    const remove=(i)=>()=>{
        d.timezones.splice(i, 1);
        setData({...d});
    }

    const changeOption =(event, i)=>{
        let dt=d.timezones;
        dt[i]=event.target.value;
        setData({...d, timezones:dt})    
    }
    useEffect(()=>{
        setData({...d, timezones:dataTimezones});
        // eslint-disable-next-line
    }, [props.timezones]);


    return(
        <div>
            <div className="table-responsive mb3">
                <table className="table table-sm table-bordered m-0">
                    <tbody className="table-text-vmid">
                        {d.timezones.map((v, i)=>(
                            <tr key={i}>
                                <td>
                                    <select className="form-control" name="timezones[]" value={v} onChange={(e)=>{changeOption(e, i)}}>
                                        <option value="">Select</option>
                                        {allTimeZones.map((t, j)=> <option key={j} value={t.id}>{t.id} ({t.title})</option>)}
                                    </select>
                                </td>
                                <td className="w20">
                                    <button type="button" className="btn btn-sm btn-danger" onClick={remove(i)} disabled={i===0}><i className="fa fa-trash-o"></i></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <button type="button" className="btn btn-sm btn-light-blue" onClick={addNew}><i className="fa fa-plus"></i> Add More</button>
        </div>
    )
}