import React, { useState, useEffect } from 'react';
import util from "../../util/util";
import { If } from "../../util/controls";
import { changeLangPref } from "../../services/data";
import lang from '../../lang';
import { sdx } from "../../sdx";
let $ = window.$;

export default function Login(props) {
    const fn = {};
    const [d, setData] = useState({
        showloader: false,
        sub_role :[],
        super_role:[],
        user_type:'',
        subscription_id:[],
        role_id:'',
    });
    const reRender = () => {
        setData({ ...d });
    }

    fn.login = (e) => {
        if (e) e.preventDefault();
        d.showloader = true;
        reRender();

        let fd = new FormData($("#frm")[0]);
        util.ajx("auth/login/", fd, 0, 1).then(res => {
            d.showloader = false;
            reRender();
            if (res.code === 200) {
                changeLangPref(res.user.lang_preferance);
                sdx.d.sub_role = res.permission.sub_permission;
                sdx.d.super_role =res.permission.super_permission;
                sdx.d.user_type =res.user_type;
                sdx.d.subscription_id =res.subscription_modules;
                sdx.d.role_id =res.role_id;
                sdx.d.default_page_url =res.default_page_url;
                
                util.setLoginInfoLocalStorage(res.authtoken, res.user.name, res.user.role_name, res.user.client_id, res.user.branches);
                var default_page_url = res.default_page_url;
                props.history.push('/'+default_page_url);
                // props.history.push('/dashboard');
                //window.location.href='dashboard';
            }
        })
    }

    const init = () => {
    }
    useEffect(() => { init(); }, []);

    return View(d, fn);
}

function View(d, fn) {
    return (
        <>
            <div id="content-wrapper" className="d-flex flex-column login-screen">
                <div id="content">
                    <div className="row full-width bg-light min-h100">
                        <div className="col-sm-12 col-md-4 col-lg-5 custom-pd-login m-none">
                            <div id="demo" className="carousel slide h-100" data-ride="carousel" data-interval="3000">
                                <ul className="carousel-indicators">
                                    <li data-target="#demo" data-slide-to="0" className="active"></li>
                                    <li data-target="#demo" data-slide-to="1"></li>
                                    <li data-target="#demo" data-slide-to="2"></li>
                                </ul>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src="assets1/images/l-1.png" alt="" className="img-fluid" />
                                        <div className="col text-center text-white">
                                            <h5 className="mt-2 text-black">Delicious meal with an attractive menu.</h5>
                                            <p className="mb-5"></p>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <img src="assets1/images/l-2.png" alt="" className="img-fluid" />
                                        <div className="col text-center text-white">
                                            <h5 className="mt-2 text-black">Track the orders.</h5>
                                            <p className="mb-5"></p>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <img src="assets1/images/l-3.png" alt="" className="img-fluid" />
                                        <div className="col text-center text-white">
                                            <h5 className="mt-2 text-black">Safer dining experience.</h5>
                                            <p className="mb-5"></p>
                                        </div>
                                    </div>
                                </div>
                                <a className="carousel-control-prev" href="#demo" data-slide="prev">
                                    <span className="carousel-control-prev-icon"></span>
                                </a>
                                <a className="carousel-control-next" href="#demo" data-slide="next">
                                    <span className="carousel-control-next-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-8 col-lg-7 custom-pd-login">
                            <div className="sign-in-from">
                                <div className="mylogininput">
                                    <img src="assets/img/agna-without-tagline.svg" alt="" className="img-fluid login-logo mb-5" />
                                    <h2 className="mb-0 fw600 text-black mb-2">Sign in to your account</h2>
                                    <p>Welcome back. Want to check your account?</p>
                                    <form className="mt-4" id="frm" onSubmit={fn.login} autoComplete="off" spellCheck="false">
                                        <div className="form-group">
                                            <label>Email address</label>
                                            <input type="text" className="form-control mb-0" name="username" defaultValue="" placeholder="Email address" />
                                        </div>
                                        <div className="form-group">
                                            <label >Password</label>
                                            <input type="password" className="form-control mb-0" name="password" defaultValue="" placeholder="Password" />
                                        </div>
                                        <div className="d-inline-block w-100" style={{ marginBottom: '60px' }}>
                                            <If cond={!d.showloader}>
                                                <button type="submit" className="btn btn-primary btn-red btn-block1"> Sign In</button>
                                            </If>
                                            <If cond={d.showloader}>
                                                <div className="text-center">
                                                    <div className="spinner-grow text-primary" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-secondary" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-success" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-danger" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-warning" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-info" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-light" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-dark" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </If>
                                            <div className="clear"></div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}