import React, { useState, useEffect } from 'react';
import util from "../../util/util";
import { sdx } from "../../sdx";
import { useParams } from 'react-router-dom';
import { If, Paging, Perpagedd } from "../../util/controls";
import lang from '../../lang';
//import { NavLink } from 'react-router-dom';

//let client_id = 0, ref = {};
let $ = window.$;

export default function Inventory(props) {
    const fn = {};
   // const { id } = useParams();
    //let pfn = props.computedMatch;
    const [d, setData] = useState({
        list: { result: [], page: {} },
        sdata: { p: 1, ps: 25 },
        data: {},
        branches: [],
        roles: [],
        new_roles: [],
        role_id: 0,
        is_arabic: 0,
        languageset: '',
        subscription_id:[],
        mobile_digit_length : 10,
    });
    fn.render = () => { setData({ ...d }); }

    fn.handleChange = (e) => {
        d.data[e.target.name] = e.target.value;
        fn.render();
    }

    fn.openForm = (dtl) => {
        d.data = dtl ? { ...dtl } : {branches: []};
        d.role_id = d.data.role_id || 0;
        d.is_arabic = sdx.d.is_arabic;
        d.mobile_digit_length = sdx.d.mobile_digit_length;
        d.languageset = sdx.d.language;
        fn.render();
        window.showModal($("#formModal"), true);
    }

    fn.sdata = (p, ps) => {
        d.sdata.p = p ? p : 1;
        d.sdata.ps = ps ? ps : d.sdata.ps;
        return $("#sfrm").serialize() + '&p=' + d.sdata.p + '&ps=' + d.sdata.ps;
    }

    fn.lists = (e, p, ps) => {
        if (e) e.preventDefault();
        util.ajx("stock_inventory/init_stocks", fn.sdata(p, ps)).then(res => {
            if (res.code === 200) {
                d.list = res.result;
                d.cat = res.cat;
                d.new_roles = res.new_roles;
                fn.render();
            }
        })
    }

    fn.save = () => {
        let fd = new FormData($("#frm")[0]);
        util.ajx("stock_inventory/save_stocks", fd).then(res => {
            if (res.code === 200) {
                window.hideModal($("#formModal"));
                fn.lists(0, d.data.id ? d.sdata.p : 1);
            }
        })
    }

    

    fn.init = () => {

 util.ajx("stock_inventory/init_stocks").then(res => {
            if (res.code === 200) {
                d.list = res.result;
                d.cat = res.cat;
                d.new_roles = res.new_roles;

                fn.render();
               
            }
        })


    }
    useEffect(() => {
        fn.lists();
        $('#masterActive').removeClass('active');
       
       // fn.render();
       
    }, []);

    return View(d, fn);

}

function View(d, fn) {
    return (
        <>
               
                        <div className="d-flex flex-row align-items-center justify-content-between pl-4 pr-4 pt-4">
                            <h6 className="m-0 h5 text-dark">{lang('Inventory Details')}</h6>
                            
                        </div>
                        <div className="main-content">
                            <div className="container-fluid">
                                <div className="form-inline">
                                    <form id="sfrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                                        <select className="form-control w180 single-line-dotted mr-3" name="r_id" 
                                        onChange={e => { d.sdata.r_id = e.target.value; fn.lists(); }}>
                                            <option value="">{lang('Category (All)')}</option>
                                            {d.new_roles.map(r => <option key={r.id} value={r.id}>{r.title}</option>)}
                                        </select>
                                       
                                    </form>
                                </div>

                                <div className="mt-4">
                                    <If cond={d.list.result.length}>
                                        <div className="table-responsive">
                                            <table className="table border m-0">
                                                <thead className="bg-light text-uppercase font-sm">
                                                    <tr>
                                                        <th className="w20">{lang('SN')}</th>
                                                        <th className="w200">{lang('Item')}</th>
                                                       
                                                        <th className="w120">{lang('Stock Count')}</th>
                                                       
                                                        <th className="w70"></th> 
                                                    </tr>
                                                </thead>
                                                <tbody className="table-text-vmid">
                                                    {d.list.result.map((v, i) => (
                                                        <tr key={i}>
                                                            <td>{d.list.page.start + i + 1}.</td>
                                                            <td className="text-black fw600">{v.title} {v.name}</td>
                                                            <td>{v.stocks}</td>
                                                            <td className="text-center">
                                                                <div className="btn-group">
                                                                    <button type="button" className="btn btn-sm fs16" onClick={() => fn.openForm(v)}><i className="far fa-edit"></i></button>
                                                                     </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex justify-content-between mt-4 pb-3">
                                            <div className="mr5">
                                                <Paging fn={fn.lists} pageob={d.list.page} />
                                            </div>
                                            <div>
                                                <Perpagedd fn={fn.lists} />
                                            </div>
                                        </div>
                                    </If>
                                    <If cond={!d.list.result.length}>
                                        <div className="no-rec">{lang('No record found')}</div>
                                    </If>
                                </div>
                            </div>
                        </div>
                
            

            {/** Form */}
            <div className="modal modal-scroll" id="formModal" data-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title uc">{lang('Edit Stocks')}</div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <form id="frm" autoComplete="off" spellCheck="false">
                                <input type="hidden" name="id" defaultValue={d.data.id} />
                                <div className="row mingap">
                                    <div className="form-group col-md-6">
                                        <label>{lang('Name')}</label>
                                        <div>{d.data.title} {d.data.name}</div>
                                         </div>
                                    <div className="form-group col-md-6">
                                        <label className="req">{lang('Stocks')}</label>
                                        <input type="text" className="form-control" name="stocks" value={d.data.stocks || ''} onChange={fn.handleChange} />
                                    </div>

                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger btn-red" onClick={fn.save}>{lang('Save')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}