import React, {useState, useEffect} from 'react';
import util from "../../util/util";
import {If, Paging, Perpagedd} from "../../util/controls";
let $=window.$;
let activeCid=0;
let activeCityId = 0, activeCityName = '';
export default function Cities(){
    const fn={};
    const [d, setData]=useState({
        list:{result:[], page:{}},
        sdata:{p:1, ps:25},
        data:{},
        countries: [],
        type:'C',
    });
    fn.render=()=>{setData({...d});}

    const [ld, setLdData]=useState({
        list:{result:[], page:{}},
        sdata:{p:1, ps:25},
        data:{},
        type:'L'
    });
    fn.ldRender=()=>{setLdData({...ld});}

    fn.handleChange=(e, obj)=>{
        if(!obj){
            obj=d;
        }
        obj.data[e.target.name]=e.target.value;
        if(obj.type==='C')
            fn.render();
        else
            fn.ldRender()
    }

    fn.openForm=(dtl)=>{
        d.data=dtl?{...dtl}:{};
        fn.render();
        window.showModal($("#formModal"));
    }

    fn.sdata=(p, ps)=>{
        d.sdata.p=p?p:1;
        d.sdata.ps=ps?ps:d.sdata.ps;
        return $("#sfrmC").serialize()+'&p='+d.sdata.p+'&ps='+d.sdata.ps+'&country_id='+activeCid;
    }
    fn.lists=(e, p, ps)=>{
        if(e)e.preventDefault();
        util.ajx("master/cities", fn.sdata(p, ps)).then(res=>{
            if(res.code===200){
                d.list=res.result;
                fn.render();
            }
        })
    }

    fn.save=()=>{
        let fd=new FormData($("#frm")[0]);
        util.ajx("master/save_city", fd).then(res=>{
            if(res.code===200){
                window.hideModal($("#formModal"));
                fn.lists(0, d.data.id?d.sdata.p:1);
            }
        })
    }

    fn.deleterec=(id)=>{
        let callback=()=>{
            util.ajx("master/delete_city", {id:id}).then(res=>{
                if(res.code===200){
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.locationModal=(id, name)=>{
        activeCityId = id;
        activeCityName = name;
        fn.listLocations();
        window.showModal($("#locationModal"));
    }

    fn.closeLocationModal=()=>{
        fn.lists(0, d.sdata.p);
    }

    fn.sdataLd=(p, ps)=>{
        ld.sdata.p=p?p:1;
        ld.sdata.ps=ps?ps:ld.sdata.ps;
        return $("#sfrmL").serialize()+'&p='+ld.sdata.p+'&ps='+ld.sdata.ps;
    }
    fn.listLocations=(e, p, ps)=>{
        if(e)e.preventDefault();
        util.ajx("master/locations/"+activeCityId, fn.sdataLd(p, ps)).then(res=>{
            if(res.code===200){
                ld.list=res.result;
                fn.ldRender();
            }
        })
    }

    fn.openLocationForm=(dtl)=>{
        ld.data=dtl?{...dtl}:{};
        window.showModal($("#locationFormModal"));
        fn.ldRender();
    }

    fn.deleteLoc = (id) =>{
        let callback=()=>{
            util.ajx("master/delete_location", {id:id}).then(res=>{
                if(res.code===200){
                    fn.listLocations();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this recordasdad?", callback);
    }

    fn.saveLocation = () => {
        let fd=new FormData($("#locationForm")[0]);
        util.ajx("master/save_location", fd).then(res=>{
            if(res.code===200){
                $('#locationFormModal').modal('hide');
                fn.listLocations();
            }
        })
    }

    fn.init=()=>{
        util.ajx("master/init_cities").then(res=>{
            if(res.code===200){
                d.list=res.result;
                d.countries=res.countries;
                activeCid=d.countries[0].id;
                fn.render();
            }
        })
    }
    useEffect(()=>{
        fn.init();
        // eslint-disable-next-line
    }, []);

    return View(d,ld, fn);
}

function View(d,ld, fn){
    return(
        <>

            <div className="col-xl-12 col-lg-12">
                <div className="card shadow mb-4 rounded-lg">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 h5 text-dark">Cities</h6>
                    </div>
                    <div className="card-body px-0">
                        <div className="main-content">
                            <div className="container-fluid">
                                <div className="tabbable-custom">
                                    <ul className="nav nav-tabs uc">
                                        {d.countries.map((r, i)=><li key={i} className="nav-item" onClick={()=>{activeCid = r.id;fn.lists();}}><a className={i===0?'nav-link active':'nav-link'} data-toggle="tab" href="#tab1">{r.title}</a></li>)}
                                    </ul>

                                    <div className="tab-content">
                                        <div id="tab1" className="tab-pane fade show active">
                                            {CitiesList(d, fn)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/** Form */}
            {CityForm(ld, fn, 'locationFormModal', 'locationForm')}
            {CityForm(d, fn)}

            {/* Locations */}
            <div className="modal modal-scroll" id="locationModal" data-backdrop="static">
                <div className="modal-dialog modal-l" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title uc">Locations of {activeCityName}</div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={fn.closeLocationModal}><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {CitiesList(ld, fn)}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={fn.closeLocationModal}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={fn.save}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function CitiesList(d, fn){
    if(!activeCid){
        return <></>
    }

    return <>
        <If cond={d.list.result.length}>
            <div className="d-flex">
                <div className="text-secondary bold600 mb5">
                    Showing {d.list.page.start+1} - {d.list.page.start+d.list.page.total} of {d.list.page.total_records} records.
                </div>
            </div>
        </If>
        <div className="d-flex tbl-search-head">
            <div className="form-inline">
                <form id={"sfrm"+d.type} onSubmit={d.type === "C"?fn.lists:fn.listLocations} autoComplete="off" spellCheck="false">
                    <div className="input-group">
                        <input type="search" className="form-control" name="k" placeholder="Search" />
                        <div className="input-group-append">
                            <button className="btn btn-light-blue" type="submit"><i className="fa fa-search"></i></button>
                        </div>
                    </div>
                </form>
            </div>

            <div className="ml-auto mt-auto mb-auto">
                <button className="btn btn-outline-primary" onClick={()=>d.type === "C"?fn.openForm():fn.openLocationForm()}><i className="fa fa-plus"></i> Add</button>
            </div>
        </div>

        <div>
            <If cond={d.list.result.length}>
                <div className="table-responsive">
                    <table className="table table-bordered table-sm table-striped table-hover m-0">
                        <thead className="thead-light text-uppercase table-text-vmid font-sm">
                            <tr>
                                <th className="w20">SN</th>
                                <th>{d.type==="C"?'City':'Location'} Name</th>
                                <If cond={d.type === "L"}>
                                    <th className="w100">Pincode</th>
                                </If>
                                <th className="w100">Latitude</th>
                                <th className="w100">Longitude</th>
                                <If cond={d.type === "C"}>
                                    <th className="w70"></th>
                                </If>
                                <th className="w70"></th>
                            </tr>
                        </thead>
                        <tbody className="table-text-vmid">
                            {d.list.result.map((v, i)=>(
                                <tr key={i}>
                                    <td>{d.list.page.start+i+1}.</td>
                                    <td>{v.title}</td>
                                    <If cond={d.type === "L"}>
                                        <td>{v.pincode}</td>
                                    </If>
                                    <td>{v.lat}</td>
                                    <td>{v.lng}</td>
                                    <If cond={d.type === "C"}>
                                        <td className="text-center">
                                            <button type="button" className="btn btn-sm btn-secondary gr nowrap" onClick={()=>fn.locationModal(v.id, v.title)}>
                                                Locations <span className="badge badge-warning">{v.locations_count}</span>
                                            </button>
                                        </td>
                                    </If>
                                    <td className="text-center">
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-sm btn-light-blue gr" onClick={()=>d.type === "C"?fn.openForm(v):fn.openLocationForm(v)}><i className="fa fa-edit"></i></button>
                                            <button type="button" className="btn btn-sm btn-danger gr" onClick={()=>d.type === "C"?fn.deleterec(v.id):fn.deleteLoc(v.id)}><i className="fa fa-trash-o"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="d-flex tbl-foot-bx">
                    <div className="mr5">
                        <Paging fn={fn.lists} pageob={d.list.page} />
                    </div>
                    <div>
                        <Perpagedd fn={fn.lists} />
                    </div>
                </div>
            </If>
            <If cond={!d.list.result.length}>
                <div className="no-rec">No record found</div>
            </If>
        </div>
    </>
}

function CityForm(d, fn, modalId, formId){
    return <>
        <div className="modal modal-scroll" id={modalId?modalId:"formModal"} data-backdrop="static">
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{d.data.id?'Edit':'Add'} {d.type === "L"?"Location":"City"}</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form id={formId?formId:"frm"} autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" defaultValue={d.data.id} />
                            <input type="hidden" name="country_id" defaultValue={activeCid} />
                            <If cond={d.type === "L"}>
                                <input type="hidden" name="city_id" defaultValue={activeCityId} />
                            </If>
                            <div className="row mingap">
                                <div className="form-group col-md-12">
                                    <label className="req">{d.type==="C"?'City':'Location'} Name</label>
                                    <input type="text" className="form-control" name="title" value={d.data.title || ''} onChange={e=>fn.handleChange(e, d)} />
                                </div>
                                <If cond={d.type === "L"}>
                                    <div className="form-group col-md-12">
                                        <label className="req">Pincode</label>
                                        <input type="text" className="form-control" name="pincode" value={d.data.pincode || ''} onChange={e=>fn.handleChange(e, d)} maxLength="6" />
                                    </div>
                                </If>
                                <div className="form-group col-md-6">
                                    <label className="req">Latitude</label>
                                    <input type="number" className="form-control" name="lat" value={d.data.lat || ''} onChange={e=>fn.handleChange(e, d)} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label className="req">Longitude</label>
                                    <input type="number" className="form-control" name="lng" value={d.data.lng || ''} onChange={e=>fn.handleChange(e, d)} />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={d.type === "L"?fn.saveLocation:fn.save}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    </>
}