import React, { useState, useEffect } from 'react';
import util from "../../util/util";
import { If, Paging, Perpagedd } from "../../util/controls";
import { sdx } from "../../sdx";
import { NavLink } from 'react-router-dom';
import Items from './Items';
import lang from '../../lang';
let $ = window.$;
let  ref = {};

export default function ItemCats(props) {
    const fn = {};
    const [d, setData] = useState({
        list: { result: [], page: {} },
        hoursList: [],
        sdata: { p: 1, ps: 25 },
        data: { city: '' },
        cities: [],
        locations: [],
        timezones: [],
        branches: [],
        currentCategory: false,
        is_arabic: 0,
        languageset: '',
        sub_role :[],
        super_role:[],
        user_type:'',
        subscription_id:[],
        printers:[],
    });
    fn.render = () => { setData({ ...d }); }

    fn.handleChange = (e) => {
        d.data[e.target.name] = e.target.value;
        fn.render();
    }

    fn.handleImageChange = (e) => {
        d.data.image_url = URL.createObjectURL(e.target.files[0]);

        document.getElementById("removeImage").style.display = "block";
        fn.render();
    }

    fn.openForm = (dtl) => {
        d.data = dtl ? { ...dtl } : { branches: [],printers:[] };
        d.is_arabic = sdx.d.is_arabic;
        d.languageset = sdx.d.language;
        fn.render();
        $('input[type="file"]').val('');
        window.showModal($("#catFormModal"), true);
    }

    fn.catItems = (dtl) => {
        d.currentCategory = dtl;
        window.showModal($("#catitems"), true);
        fn.render();
    }
    fn.closeItemModal = () => {
        $("#catitems").modal('hide');
        d.currentCategory = false;
        fn.lists(0, d.sdata.p);
    }

    fn.sdata = (p, ps) => {
        d.sdata.p = p ? p : 1;
        d.sdata.ps = ps ? ps : d.sdata.ps;
        return $("#scfrm").serialize() + '&p=' + d.sdata.p + '&ps=' + d.sdata.ps;
    }

    fn.lists = (e, p, ps) => {
        if (e) e.preventDefault();
        util.ajx("cmaster/item_cats", fn.sdata(p, ps)).then(res => {
            if (res.code === 200) {
                d.list = res.result;
                fn.render();
            }
        })
    }

    fn.save = () => {
        let fd = new FormData($("#cfrm")[0]);
        util.ajx("cmaster/save_item_cat", fd).then(res => {
            if (res.code === 200) {
                $("#catFormModal").modal('hide');
                fn.lists(0, d.data.id ? d.sdata.p : 1);
            }
        })
    }

    fn.deleterec = (id,no_of_items) => {
        
            let callback = () => {
                util.ajx("cmaster/delete_item_cat", { id: id, no_of_items : no_of_items }).then(res => {
                    if (res.code === 200) {
                        fn.lists();
                    }
                })
            }
            window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);

        
        
    }

    fn.remove_image = (id) => {
        let fd = new FormData($("#cfrm")[0]);
        // var myobj = document.getElementById("images");
        // myobj.remove();
        document.getElementById('images').src = "''";
        document.getElementById("removeImage").style.display = "none";
        $('input[type="file"]').val('');
        if (id != '') {
            util.ajx("cmaster/remove_item_cat_image", fd).then(res => {

            })

        }

    }

    fn.setBranchCheck = (e) => {
        let bid = e.target.value;
        if (e.target.checked) {
            d.data.branches.push(bid);
        } else {
            d.data.branches.splice(d.data.branches.indexOf(bid), 1);
        }
        fn.render();
    }

    fn.setPrinter = (e) => {
        let bid = e.target.value;
        console.log(bid);
        $('.printerDrop ').val(bid);
        // if (e.target.checked) {
            // d.data.printers.push(bid);
        // } else {
        //     d.data.branches.splice(d.data.branches.indexOf(bid), 1);
        // }
        fn.render();
    }
    fn.init = () => {
        util.ajx("cmaster/item_cats?init=1", fn.sdata(1, d.sdata.ps)).then(res => {
            if (res.code === 200) {
                d.list = res.result;
                d.branches = res.branches;
                d.printers = res.printers;
                // d.sub_role = sdx.d.sub_role;
                // d.super_role = sdx.d.super_role;
                // d.user_type = sdx.d.user_type;
                // d.subscription_id = sdx.d.subscription_id;
                
                d.sub_role = res.logging_data.permission.sub_permission;
                d.super_role = res.logging_data.permission.super_permission;
                d.user_type = res.logging_data.user_type;
                d.subscription_id = res.logging_data.subscription_id;

                fn.render();
                if(d.sub_role && ( d.sub_role[15] != 1)  && d.user_type =='STAFF'){
                    util.logout('', props.history);
                }
                if(d.subscription_id && ( d.subscription_id[2] != 1)  && d.user_type =='CLIENT'){
                    util.logout('', props.history);
                } 
                if(d.subscription_id){
                    $('.left-sidebar-link').removeClass('d-none');
                }

            }
        })
    }

    useEffect(() => {
        fn.init();
        $('#masterActive').addClass('active');
        d.sub_role = sdx.d.sub_role;
        d.super_role = sdx.d.super_role;
        d.user_type = sdx.d.user_type;
        d.subscription_id = sdx.d.subscription_id;
        

        fn.render();
        if(d.sub_role && ( d.sub_role[15] != 1)  && d.user_type =='STAFF'){
            util.logout('', props.history);
        }
        if(d.subscription_id && ( d.subscription_id[2] != 1)  && d.user_type =='CLIENT'){
            util.logout('', props.history);
        }
        // eslint-disable-next-line
    }, []);

    // return View(d, fn);
    return (
        <>
            <View d={d} fn={fn} />
            <PrintSetup />
        </>
    )
}

function View(props) {
    let d = props.d, fn = props.fn;
    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-md-3 col-lg-2 top-master">
                    <div className="mb-3 text-uppercase text-black fw700 fs12">{lang('Masters')}</div>
                    <div className="left-sidebar-link d-none">
                    {/* <If cond={d.user_type != 'STAFF'}>
                            <NavLink to="/branches" className="">Branches</NavLink>
                            <NavLink to="/roles" className="">Roles</NavLink>
                            <NavLink to="/staffs" className="">Staffs</NavLink>
                            <NavLink to="/cats" className="">Categories</NavLink>
                            <NavLink to="/items" className="">Items</NavLink>
                            <NavLink to="/others" className="">Others</NavLink>
                        </If> */}

                    <If cond={d.list.result}>
                        {/* <If cond={d.user_type == 'STAFF'}> */}
                        <If cond={d.sub_role && ( d.sub_role[1] == 1 ||  d.sub_role && d.sub_role[2] == 1)  || d.user_type !='STAFF'  }>
                                <NavLink to="/branches" className="">{lang('Branches')}</NavLink>
                            </If>
                            <If cond={d.user_type != 'STAFF'}> <NavLink to="/roles" className="">{lang('Roles')}</NavLink></If>
                           
                            <If cond={d.sub_role && d.sub_role[4] == 1  || d.user_type !='STAFF'}>
                                <NavLink to="/staffs" className="">{lang('Staffs')}</NavLink>
                            </If>
                            
                            <If cond={ d.sub_role && d.sub_role[15] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) )  }>
                                <NavLink to="/cats" className="">{lang('Categories')}</NavLink>
                            </If>

                            <If cond={ d.sub_role && d.sub_role[16] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) ) }>
                                <NavLink to="/items" className="">{lang('Items')}</NavLink>
                            </If>
                            <If cond={ d.sub_role && d.sub_role[40] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) ) }>
                                <NavLink to="/combo" className="">{lang('Combos')}</NavLink>
                            </If>
                            <If cond={d.super_role && d.super_role[2] == 1 ||  d.sub_role && ( d.sub_role[5] == 1 ||  d.sub_role[6] == 1 ||  d.sub_role[8] == 1)   || d.user_type !='STAFF'}>
                                <NavLink to="/others" className="">{lang('Others')}</NavLink>
                            </If>
                            <If cond={ d.sub_role && d.sub_role[48] == 1  || ( d.user_type !='STAFF' && d.subscription_id &&  (d.subscription_id[2] == 1) ) }>
                                <NavLink to="/recipeIngredients" className="">{lang('Recipe Ingredients')}</NavLink>
                            </If>
                            
                        {/* </If> */}

                    </If>
                    </div>
                </div>
                <div className="offset-lg-2 col-lg-10 offset-md-3 col-md-9 col-sm-12 right-master">
                    <div className="main-table-body">
                        <div className="d-flex flex-row align-items-center justify-content-between pl-4 pr-4 pt-4">
                            <h6 className="m-0 h5 text-dark">{lang('Categories')}</h6>
                            <If cond={d.sub_role && d.sub_role[15] == 1 || d.user_type !='STAFF'}>
                                <div className="ml-auto mt-auto mb-auto">
                                    <button className="btn btn-danger btn-red" onClick={() => fn.openForm()}><i className="fa fa-plus"></i> {lang('Add')}</button>
                                </div>
                            </If>
                        </div>

                        {/* <If cond={d.list.result.length}>
                            <div className="d-flex">
                                <div className="text-secondary bold600 mb5">
                                    Showing {d.list.page.start + 1} - {d.list.page.start + d.list.page.total} of {d.list.page.total_records} records.
                                        </div>
                            </div>
                        </If> */}
                        <div className="main-content">
                            <div className="container-fluid">
                                <div className="d-flex">
                                    <div className="form-inline">
                                        <form id="scfrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                                            <div className="input-group">
                                                <input type="search" className="form-control mr-3" name="k" placeholder={lang('Search')} />
                                                <div className="input-group-append">
                                                    <button className="btn btn-red-outline btn-danger" type="submit"><i className="fa fa-search"></i></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <If cond={d.list.result.length}>
                                        <div className="table-responsive">
                                            <table className="table border m-0">
                                                <thead className="bg-light text-uppercase font-sm">
                                                    <tr>
                                                        <th className="w20">{lang('SN')}</th>
                                                        <th className="w70">{lang('Photo')}</th>
                                                        <th>{lang('Category Name')}</th>
                                                        <th>{lang('Branches')}</th>
                                                        <th className="w80"></th>
                                                        <th className="w100 nowrap text-center">{lang('Display Order')}</th>
                                                        <th className="w80">{lang('Status')}</th>
                                                        <th className="w70"></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-text-top">
                                                    {d.list.result.map((v, i) => (
                                                        <tr key={i}>
                                                            <td>{d.list.page.start + i + 1}.</td>
                                                            <td>
                                                                <If cond={v.image_url}>
                                                                    <img className="mx60" src={v.image_url} alt="" />
                                                                </If>
                                                            </td>
                                                            <td className="text-black fw600">
                                                                {v.title}
                                                                <div>
                                                                    {v.title_arb}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {v.branch_name?.map((v, j) => <span key={j}>
                                                                    <If cond={j > 0}>, </If>
                                                                    {v.title}
                                                                </span>)}
                                                            </td>
                                                            <td>
                                                                <If cond={d.sub_role && d.sub_role[16] == 1 || d.user_type !='STAFF'}>
                                                                    <button type="button" className="btn btn-sm btn-danger btn-redgrey-outline btn-block nowrap" onClick={() => fn.catItems(v)}>{lang('Items')} &nbsp; <span className="badge badge-red">{v.no_of_items}</span></button>
                                                                </If>
                                                            </td>
                                                            <td className="text-center">{v.disp_odr}</td>
                                                            <td>
                                                                <If cond={v.status === '1'}>
                                                                    <div className="badge badge-success">{lang('Active')}</div>
                                                                </If>
                                                                <If cond={v.status === '0'}>
                                                                    <div className="badge badge-danger">{lang('Inactive')}</div>
                                                                </If>
                                                            </td>
                                                            <td className="text-center">
                                                                <div className="btn-group">
                                                                    <If cond={d.sub_role && d.sub_role[15] == 1 || d.user_type !='STAFF'}>
                                                                        <button type="button" className="btn btn-sm fs16" onClick={() => fn.openForm(v)}><i className="far fa-edit"></i></button>
                                                                        <button type="button" className="btn btn-sm fs16" onClick={() => fn.deleterec(v.id,v.no_of_items)}><i className="fa fa-trash-o"></i></button>
                                                                        <button type="button" data-toggle="tooltip" title="Edit" className="btn btn-sm fs16" onClick={() => ref.openPrintSetup(v)}><i className="fa fa-print"></i></button>
                                                                    </If>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="d-flex justify-content-between mt-4 pb-3">
                                            <div className="mr5">
                                                <Paging fn={fn.lists} pageob={d.list.page} />
                                            </div>
                                            <div>
                                                <Perpagedd fn={fn.lists} />
                                            </div>
                                        </div>
                                    </If>
                                    <If cond={!d.list.result.length}>
                                        <div className="no-rec">{lang('No record found')}</div>
                                    </If>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/** Form */}
            <div className="modal modal-scroll no-backdrop" id="catFormModal" data-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title uc">{d.data.id ? 'Edit' : 'Add'} {lang('Item Category')}</div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <form id="cfrm" autoComplete="off" spellCheck="false">
                                <input type="hidden" name="id" defaultValue={d.data.id} />
                                <input type="hidden" name="client_id" defaultValue={d.data.client_id} />
                                <div className="row mingap">
                                    <div className="form-group col-md-6">
                                        <label className="req">{lang('Category Name')}</label>
                                        <input type="text" className="form-control" name="title" value={d.data.title || ''} onChange={fn.handleChange} />
                                    </div>
                                    <If cond={d.is_arabic && d.languageset == 'Arabic'}>
                                        <div className="form-group col-md-6">
                                            <label className="">{lang('Category Name')} ({d.languageset})</label>
                                            <input type="text" className="form-control" name="title_arb" value={d.data.title_arb || ''} onChange={fn.handleChange} dir="rtl" />
                                        </div>
                                    </If>
                                    <If cond={d.is_arabic && d.languageset != 'Arabic'}>
                                        <div className="form-group col-md-6">
                                            <label className="">{lang('Category Name')} ({d.languageset})</label>
                                            <input type="text" className="form-control" name="title_arb" value={d.data.title_arb || ''} onChange={fn.handleChange} />
                                        </div>
                                    </If>

                                    <div className="form-group col-md-12">
                                        <label className="">{lang('Image')}</label>
                                        <input type="file" className="form-control" name="image" onChange={fn.handleImageChange} />
                                        <If cond={d.data.image_url}>
                                            <button type="button" className="close" id="removeImage" onClick={() => fn.remove_image(d.data.image_url)}><span aria-hidden="true">&times;</span></button>

                                        </If>
                                        <If cond={d.data.image_url == ''}>
                                            <button type="button" className="close" style={{ display: 'none' }} id="removeImage" onClick={() => fn.remove_image(d.data.image_url)}><span aria-hidden="true">&times;</span></button>
                                        </If>

                                        <If cond={d.data.image_url}>
                                            <div className="pt3 w100">
                                                <img className="mw-100" id="images" src={d.data.image_url} alt="" />

                                            </div>
                                        </If>
                                    </div>

                                    <div className="form-group col-md-12">
                                        <label className="req">{lang('Branches')}</label>
                                        {d.branches.map((r, i) => (
                                            <div className="form-control mb5" key={i}>
                                                <label className="m-0 noselect">
                                                    <input type="checkbox" name="branches[]" value={r.id} checked={d.data.branches?.includes(r.id) ? true : false} onChange={fn.setBranchCheck} />
                                                    &nbsp;&nbsp;{r.title}
                                                    <br></br>
                                                    {/* <select className="form-control printerDrop" name="printers" value={''}  selected={d.data.printers?.includes(r.id) ? true : false} onChange={fn.setPrinter}>
                                                    <option value="">{lang('Select')}</option>
                                                    {r.printers && r.printers.map((p, j) => <option key={j} value={p.id}>{p.title}</option>)}
                                                </select> */}
                                                </label>
                                            </div>

                                        ))}
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label className="">{lang('Display Order')}</label>
                                        <input type="text" className="form-control" name="disp_odr" value={d.data.disp_odr || ''} onChange={fn.handleChange} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className="req">{lang('Status')}</label>
                                        <select className="form-control" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                            <option value="1">{lang('Active')}</option>
                                            <option value="0">{lang('Inactive')}</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={fn.save}>{lang('Save')}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Items */}
            <div className="modal modal-scroll no-backdrop" id="catitems" data-backdrop="static">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title uc">{lang('Items under')} "{d.currentCategory.title}"</div>
                            <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={fn.closeItemModal}><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <If cond={d.currentCategory}>
                                <Items cat_id={d.currentCategory.id} />
                            </If>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={fn.closeItemModal}>{lang('Close')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function PrintSetup() {
    const [d, setData] = useState({
        lists: [{}],
        bdtl: false,
        data: { dining: [] },
        dining: [],
        deliver: [],
        pickup: [],
        subscription_id : [],
        branches : [],
        printers : [],
        branch_id :'',
    });

    const [checked, setChecked] = React.useState(true);
    const fn = {};
    ref.openPrintSetup = (bdtl) => {
        d.bdtl = bdtl;
        util.ajx("cmaster/cat_printers", { id: bdtl.id }).then(res => {
            if (res.code === 200) {
               
                d.lists = res.branches.prints;
                d.branches = res.branches.branch_name;
                d.printers =  res.printers;
                // if (!d.lists.length) {
                //     d.lists = [{}];
                // }
                d.subscription_id = sdx.d.subscription_id;

                setData({ ...d });
                window.showModal($("#printSetupModal"), true);
                console.log(d);
            }
        })
    }


    const closeModal = () => {
        setData({ ...d, bdtl: false });
        $("#printSetupModal").modal('hide');
    }

    fn.handleChange = (e) => {
        d.data[e.target.name] = e.target.value;

        setData({ ...d });

    }


    const save = (printer_id,branch_id,id) => {
       
        let fd =  new FormData($("#printersFrm")[0]);
        fd.append("item_cat_id", d.bdtl.id);
        fd.append("printer_id", printer_id);
        fd.append("branch_id", branch_id);
        fd.append("id", id);
        util.ajx("cmaster/save_cat_printers", fd).then(res => {
            if (res.code === 200) {
                closeModal();
            }
        })
    }

   


    return (
        <div className="modal modal-scroll no-backdrop" id="printSetupModal" data-backdrop="static">
            <If cond={d.bdtl}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title uc">{d.bdtl.title} - <span className="text-secondary">{lang('Print Setup')}</span></div>
                            <button type="button" className="close" data-dismiss1="modal" aria-label="Close" onClick={closeModal}><span aria-hidden="true">&times;</span></button>
                        </div>

                        <div className="modal-body">
                            <form id="printersFrm" autoComplete="off" spellCheck="false">
                                <div className="table-responsive mb3">
                                    <table className="table table-sm table-bordered m-0">
                                        <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                            <tr>
                                                <th className="w20">{lang('SN')}</th>
                                                <th className="w120 ">{lang('Branch')}</th>
                                                <th className="w120 ">{lang('Printers Name')}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-text-vmid">
                                            {d.branches.map((v, i) => (
                                                <tr key={i} className={'printers'+v.id}>
                                                    <td>{i + 1}.</td>
                                                    <td>
                                                        <input type="hidden" name="branch_ids" defaultValue={v.id} />
                                                        {v.title}
                                                        {/* <select className="form-control w130 single-line-dotted mr-3" name="branch_print_id[]" onChange={e => { getPrinters(e.target.value) } } >
                                                            <option value="">{lang('Select')}</option>
                                                            {d.branches.map(r => <option key={r.id} value={r.id}>{r.title}</option>)}
                                                        </select> */}
                                                    </td>
                                                    <td>
                                                    {/* {d.lists.map((l, j) => ( */}
                                                        
                                                            <select  className="form-control w130 single-line-dotted mr-3" name="printer_id" value={(d.lists[v.id])? d.lists[v.id]['printer_id'] : ''  || ''}  onChange={e => { save(e.target.value, v.id,(d.lists[v.id])? d.lists[v.id]['id'] : '' ) } }  >
                                                                <option value="">{lang('Select')}</option>
                                                                {d.printers && d.printers.map(r => <If cond={ v.id == r.branch_id}><option key={r.id} value={r.id}>{r.printer_name}</option></If>)}
                                                            </select>
                                                         

                                                       
                                                     {/* ))} */}
                                                        {/* <input type="text"     className={"form-control printer_name"+i} name="printer_name[]" defaultValue={v.printer_name} /> */}
                                                    </td>
                                                    
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss1="modal" onClick={closeModal}>{lang('Close')}</button>
                            {/* <button type="button" className="btn btn-primary" onClick={save}>{lang('Save')}</button> */}
                        </div>
                    </div>
                </div>
            </If>
        </div>
    )
}