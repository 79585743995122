import React, {useState, useEffect} from 'react';
import util from "../../util/util";
import lang from '../../lang';
let $=window.$;

export default function ChangePassword(props) {
    const fn={};
    const [data, setData]=useState({});

    fn.handleChange=(e)=>{
        data[e.target.name]=e.target.value;
        setData({...data});
    }
    
    fn.changePassword=(e)=>{
        if(e)e.preventDefault();
        let fd=new FormData($("#adjfrm")[0]);
        util.ajx("auth/change_password", fd).then(res=>{
            if(res.code===200){
                setData({});
            }
        })
    }

    const init=()=>{
    }
    useEffect(()=>{init();}, []);

    return View(data, fn);
}

function View(data, fn){
    return(
        <>
            <div className="page-head">
                <div className="container-fluid d-flex">
                    <h5 className="mt-auto mb-auto">{lang('Change Password')}</h5>
                </div>
            </div>

            <div className="main-content">
                <div className="container-fluid">
                    <div className="d-flex">
                        <div className="ml-auto mr-auto mt-auto mb-auto">
                            <form id="adjfrm" onSubmit={fn.changePassword} autoComplete="off" spellCheck="false">
                                <div className="row mingap">
                                    <div className="form-group col-md-12">
                                        <label>{lang('Current Password')}</label>
                                        <input type="password" className="form-control" name="current_password" value={data.current_password || ''} onChange={fn.handleChange} />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>{lang('New Password')}</label>
                                        <input type="password" className="form-control" name="new_password" value={data.new_password || ''} onChange={fn.handleChange} />
                                    </div>

                                    <div className="form-group col-md-12">
                                        <label>{lang('Confirm Password')}</label>
                                        <input type="password" className="form-control" name="confirm_password" value={data.confirm_password || ''} onChange={fn.handleChange} />
                                    </div>

                                    <div className="col-md-12">
                                        <button type="submit" className="btn btn-primary">{lang('Change Password')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}