import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

class fbase{
    constructor(){
        this.config = {
            apiKey: "AIzaSyDC1F664wtv4uOk5Ni4oanBdhPSM2TCOH4",
            authDomain: "agna-care.firebaseapp.com",
            databaseURL: "https://agna-care.firebaseio.com",
            projectId: "agna-care",
            storageBucket: "agna-care.appspot.com",
            messagingSenderId: "1019873367818",
            appId: "1:1019873367818:web:202f80d790a550e6e2ccf3",
            measurementId: "G-GKKBFGJL6M"
        };
        firebase.initializeApp(this.config);
        this.auth=firebase.auth;
        this.db=firebase.database();

        this.user="testagna@agnacare.com";
        this.pwd="123456";
    }

    signup=(email, password)=>{
        return this.auth().createUserWithEmailAndPassword(email, password);
    }
    signin=(email, password)=>{
        email=email || this.user;
        password=password || this.pwd;
        return this.auth().signInWithEmailAndPassword(email, password);
    }

    onNewMsg=(ref, callback, limit)=>{
        limit=limit || 1;
        this.db.ref(ref).limitToLast(limit).on("value", snapshot=>{
            let msgs=[];
            snapshot.forEach((snap)=>{
                msgs.push(snap.val());
            });
            if(callback){
                callback(msgs);
            }
        });
    }
    allMsg=(ref, callback, limit)=>{
        limit=limit || 1000;
        this.db.ref(ref).limitToLast(limit).once("value", snapshot=>{
            let msgs=[];
            snapshot.forEach((snap)=>{
                msgs.push(snap.val());
            });
            if(callback){
                callback(msgs);
            }
        });
    }
    sendMsg=(ref, msg, errCallback)=>{
        try {
            this.db.ref(ref).push(msg);
        }catch(error){
            if(errCallback){
                errCallback(error);
            }
        }
    }
    offDb=()=>{
        try{
            this.db.off();
        }catch(e){

        }
    }
}

export default new fbase();