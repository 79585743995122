import React, { useState, useEffect } from 'react';
import util from "../../util/util";
import { sdx } from "../../sdx";
import { If, Paging, Perpagedd } from "../../util/controls";
import { NavLink } from 'react-router-dom';
let $ = window.$;

export default function Role_permissions() {
    const fn = {};
    const [d, setData] = useState({
        list: { result: [], page: {} },
        sdata: { p: 1, ps: 25 },
        data: {},
        branches: [],
        roles: [],
        role_id: 0,
        is_arabic: 0,
    });
    fn.render = () => { setData({ ...d }); }

    fn.handleChange = (e) => {
        d.data[e.target.name] = e.target.value;
        fn.render();
    }

    fn.setRole = (e) => {
        d.data[e.target.name] = e.target.value;
        setData({ ...d, role_id: e.target.value });
    }

    fn.openForm = (dtl) => {
        
        d.data = dtl ? { ...dtl } : {};
        fn.render();

        
        d.id = d.data.id || 0
        let arg =  d.id;
        util.ajx("subscription/create_subscription",{arg:arg}).then(res => {

            $('#main').html(''); 
           $('#main').append(res.result);  
            $('#subscription').trigger("reset");
        })
        window.showModal($("#formModal"), true);

    }


    fn.sdata = (p, ps) => {
        d.sdata.p = p ? p : 1;
        d.sdata.ps = ps ? ps : d.sdata.ps;
        return $("#sfrm").serialize() + '&p=' + d.sdata.p + '&ps=' + d.sdata.ps;
    }

    fn.lists = (e, p, ps) => {
        if (e) e.preventDefault();
        util.ajx("subscription/list", fn.sdata(p, ps)).then(res => {
            if (res.code === 200) {
                d.list = res.result;
                fn.render();
            }
        })
    }

    fn.save = () => {
        let fd = new FormData($("#subscription")[0]);
        util.ajx("subscription/process", fd).then(res => {
            if (res.code === 200) {
                window.hideModal($("#formModal"));
                fn.lists(0, d.data.id ? d.sdata.p : 1);
            }
        })
    }

    fn.deleterec = (id) => {
        let callback = () => {
            util.ajx("subscription/delete_subscription", { id: id }).then(res => {
                if (res.code === 200) {
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.init = () => {
        util.ajx("subscription/list").then(res => {
            if (res.code === 200) {
                d.list = res.result;
                fn.render();
                // $('#masterActive').addClass('active');
            }
        })
    }
    useEffect(() => {
        fn.init();
        // eslint-disable-next-line
    }, []);

    return View(d, fn);

}

function View(d, fn) {
    return (
        <>
            <div className="col-xl-12 col-lg-12">
                <div className="card shadow mb-4 rounded-lg">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 h5 text-dark">Subscriptions</h6>
                            <div className="ml-auto mt-auto mb-auto">
                                <button className="btn btn-outline-primary" onClick={() => fn.openForm()}><i className="fa fa-plus"></i> Add</button>
                            </div>
                        </div>
                    <div className="card-body px-0">
                        <div className="main-content">
                            <div className="container-fluid">
                                <If cond={d.list.result.length}>
                                    <div className="d-flex">
                                        <div className="text-secondary bold600 mb5">
                                            Showing {d.list.page.start+1} - {d.list.page.start+d.list.page.total} of {d.list.page.total_records} records.
                                        </div>
                                    </div>
                                </If>

                                <div className="d-flex tbl-search-head">
                                    <div className="form-inline">
                                        <form id="sfrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                                            <div className="input-group">
                                                <input type="search" className="form-control" name="k" placeholder="Search" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-light-blue" type="submit"><i className="fa fa-search"></i></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="ml-auto mt-auto mb-auto">
                                    </div>
                                </div>

                                <div>
                                    <If cond={d.list.result.length}>
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-sm table-striped table-hover m-0">
                                                <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                                    <tr>
                                                        <th className="w20">SN</th>
                                                        <th className="w80">Subscription Name</th>
                                                        <th className="w80">Monthaly Charges</th>
                                                        <th className="w80">Yearly Charges</th>
                                                        <th className="w20">Status</th>
                                                        <th className="w70"></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-text-vmid">
                                                    {d.list.result.map((v, i)=>(
                                                        <tr key={i}>
                                                            <td>{d.list.page.start+i+1}.</td>
                                                            <td>{v.plan_name}</td>
                                                            
                                                            <td>{v.price_monthly}</td>
                                                            <td>{v.price_yearly}</td>
                                                            <td>
                                                                <If cond={v.status==='1'}>
                                                                    <div className="badge badge-success">Active</div>
                                                                </If>
                                                                <If cond={v.status==='0'}>
                                                                    <div className="badge badge-danger">Inactive</div>
                                                                </If>    
                                                            </td>
                                                            <td className="text-center">
                                                                <div className="btn-group">
                                                                    <button type="button" className="btn btn-sm btn-light-blue gr" onClick={()=>fn.openForm(v)}><i className="fa fa-edit"></i></button>
                                                                    <button type="button" className="btn btn-sm btn-danger gr" onClick={()=>fn.deleterec(v.id)}><i className="fa fa-trash-o"></i></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex tbl-foot-bx">
                                            <div className="mr5">
                                                <Paging fn={fn.lists} pageob={d.list.page} />
                                            </div>
                                            <div>
                                                <Perpagedd fn={fn.lists} />
                                            </div>
                                        </div>
                                    </If>
                                    <If cond={!d.list.result.length}>
                                        <div className="no-rec">No record found</div>
                                    </If>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/** Form */}
            <div className="modal modal-scroll" id="formModal" data-backdrop="static">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <div className="modal-title uc">{d.data.id?'Edit':'Add'} Subscription</div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <div id="main">

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={fn.save}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}