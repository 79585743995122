export const allwords={
    "Dashboard":"لوحة العرض",
    "Manage Users" : "ادارة المستخدمين",
    "Manage Roles" : "ادارة القواعد",
    "Masters" : "الادارة",
    "Currencies" : "العملات",
    "Countries" : "الدول",
    "Cities" : "المدن",
    "Cms Pages" : "صفحات cmc",
    "SMTP" : "smtp",
    "Food Category" : "فئة الطعام",
    "Special Category" : "فئة خاصه",
    "Subscription" : "اشتراك",
    "Menu" : "قائمة الطعام",
    "Clients" : "عملاء",
    "Branches" : "الفروع",
    "Staffs" : "العاملين",
    "Categories" : "فئات",
    "Items" : "العناصر",
    "Others" : "اخري",
    "QR-Codes" : "رموز qr",
    "Orders" : "طلبات",
    "Reports" : "تقارير",
    "Kitchen" : "مطبخ",
    "CRM" : "CRM",
    "Inventory" : "المخزون",
    "Change Password" : "تغيير كلممة السر",
    "Logout" : "تسجيل الخروج",
    "Branche Status" : "حالة الفروع",
    "Restaurant Status" : 	"حالة المطعم",
    "SN" : "sn" ,
    "Active Sessions" : "الطلبات النشطه",
    "Sales" : "مبيعات" ,
    "Product Sold" : "المنتج المباع" ,
    "Hourly Break Down" : "الكسب في الساعه",
    "Cancelled Order" : "الطلب الملغي" ,
    "Total Discount" : "اجمالي الخصم",
    "New Customer" : "عميل جديد" ,
    "Top Selling Items" : "العناصر الاكثر مبيعا",
    "No item yet sold" : "لم يتم بيع اي منتج بعد" ,
    "Staff Report" : "تقرير الموظفين" ,
    "Total Expenses" : "المصروفات الكلية",
    "No Expenses" : "لا توجد مصاريف",
    "Order by Location" : "طلب حسب الموقع" ,
    "Order By Mode" : "نوع الطلب"	,
    "Order Count" : "عدد الطلبات",
    "Dine-In" : "تناول الطعام داخل المكان" ,
    "Running Order" : "طلب قيد التنفيذ",
    "Completed Order" : "طلب مكتمل",
    "Deliver" : "ارسل",
    "Item Stocks" : "مخزون العنصر",
    "Total Payment" : "المبلغ الاجمالي",
    "Today" : "اليوم",
    "Yesterday" : "الامس" ,
    "Week" : "اسبوع"	,
    "Month" : "شهر",
    "Custom Date" : "تاريخ مخصص",
    "Select Custom Date" : "تحديد التاريخ المخصص",
    "From" : "من",
    "To" : "الي" ,
    "Apply" : 	"تطبيق"	,
    "All Branch" : "كل الفروع",
    "Hourly Breakdown by Orders" : "الكسب في الساعه حسب الطلب",
    "This Week" : "هذا الاسبوع" ,
    "This Month" : "هذا الشهر",
    "Last Month" :  "الشهر الماضي",
    "Cancelled Orders" : "الطلبات الملغاه", 
    "Order Type (All)" : "نوع الطلب (كل)",
    "No record found" : "لا توجد سجلات ",
    "Order Date" : "تاريخ الطلب ",
    "Order Amount" : 	"كمية الطلب",
    "Refund Amount" : "المبلغ المسترد",
    "Refund Mode" : "وضع الاسترداد",
    "Refund Done by" : "الاسترداد تم ب" ,
    "Reason" : "السبب",
    "PAID" : "مدفوع",
    "CASH" :"نقدا",
    "Show" : "اظهر",
    "Records" : "السجلات",
    "Show 25 Records" : "اظهر 25 من السجلات",
    "Show 50 Records" : "اظهر 50 من السجلات",
    "Show 100 Records" : "اظهر 100 من السجلات",
    "Show 200 Records" : "اظهر 200 من السجلات" ,
    "Show 500 Records" : "اظهر 500 من السجلات",
    "Type" : "النوع",
    "Total" : "المجموع",
    "Item Name" : 	"اسم العنصر" ,
    "Total Sold" : "اجمالي البيع",
    "Total Amount" : 	"اجمال التكلفة",
    "Total COGS" : "اجمالي COGS ",
    "Profit" : "الربح",
    "Role (All)" : "وظيفة ( الكل )",
    "Staff (All)" : "العاملين ( الكل)",
    "Date" : "اليوم",
    "Paid Order" : "طلب مدفوع"	,
    "Order Taken" : "طلب ماخوذ",
    "Cash" : "نقدا",
    "Bank" : "بنك",
    "Order Details" : "تفاصيل الطلب",
    "Close" : "مغلق",
    "Order ID" : "رقم التعريف الخاص بالطلب",
    "Date and Time" : "التاريخ و الوقت",
    "Order Type" : "نوع الطلب",
    "Payment Type" : "نوع الدفع",
    "Add Staff" : 	"اضافة موظفين" ,
    "Edit Staff" : "تعديل الموظفين",
    "Applicant Name" : 	"اسم التطبيق",
    "Select" : "تحديد",
    "Save" : "حفظ",
    "Total Sales" : "اجمالي المبيعات",
    "Cash Amount" : "مبلغ نقدي"	,
    "Bank amount" : "مبلغ بنكي",
    "Remaining Sales(Cash -Total Expenses )" : "المبيعات المتبقيه(نقدي - المصروفات الكليه )",
    "Expenses Details" : "تفاصيل المصاريف",
    "Expenses" : " المصاريف",
    "Coupons/Discount Code" : "كوبون/كود الخصم",
    "Select Coupons/Discount Code" : "تحديد كوبون/كود الخصم",
    "Order Summary" : "ملخص الطلب",
    "Search menu items" : "البحث في عناصر القائمه",
    "Barcode" : "باركود",
    "Items Total" : "مجموع العناصر",
    "Discount" : "خصم",
    "Tip" : "اكرامية",
    "Tax" : "ضريبة"	,
    "Grand Total" : "المبلغ الاجمالي",
    "Cancel" : "الغاء" ,
    "Place" : 	"تأكيد",
    "Personal Information" : "معلومات شخصيه"	,
    "Table Number" : "رقم الترابيزه",
    "Customer Name" : "اسم العميل" ,
    "Mobile No" : "رقم التليفون",
    "Add Special Instruction" : "اضف تعليمات خاصه" ,
    "Any suggestion? we will pass on it" : "اي اقتراح ؟" ,
    "Proceed without Pay" : "تنفيذ بدون دفع",
    "Proceed" : "تنفيذ"	,
    "Cash Received" : "النقديه المستلمه" ,
    "Bank Received" : "البنك المستلم",
    "Enter Amount" : "ادخل المبلغ",
    "TXN Number" : "رقم TXN",
    "Balance Amount" : "مقدار الرصيد",
    "Back" : "الرجوع",
    "Pay Online" : "الدفع  عبر الانترنت" ,
    "Pickup" :  "استلام",
    "Drive Through" : "الطلب من السياره",
    "Delivery Charge" : "رسوم التوصيل" ,
    "Source" : "المصدر" ,
    "Select Source" :  "اختيار المصدر",
    "Location" : 	"الموقع"  ,
    "Select Location" : "اختيار الموقع",
    "Complete Address" : "اكمال العنوان" ,
    "Pincode" : "الرمز السري" ,
    "Landmark" : "علامه مميزه",
    "How to reach" : "كيفية الوصول",
    "Driver" : "السائق",
    "Driver Commission" : "عمولة السائق",
    "Select Driver" : "اختيار السائق",
    "Dou you want to print recipet" : "هل تريد طباعة ايصال",
    "Yes" :"نعم",
    "No" : "لا",
    "Open Application" : "فتح التطبيق",
    "Download" : "تنزيل",
    "Print" : "طباعة",
    "Search" : "بحث",
    "Active" : "نشيط",
    "In Progress" : "قيد التجهيز",
    "Ready" :  "جاهز",
    "Completed" : "اكتمل" ,
    "Cancelled" : "الغاء",
    "Order confirmed" : "تاكيد الطلب",
    "View Detail" : "عرض التفاصيل",
    "Modify" : "تعديل",
    "Confirmation" : "تاكيد",
    "Are you sure to mark as Delivered this order" : 	"هل انت متاكد من تحديد هذا الطلب علي انه تم تسليمه ؟",
    "Are you sure to mark as Ready this order" : "هل انت متاكد من وضع علامة جاهز علي هذا الطلب ؟",
    "Are you sure to mark as In Progress this order" : "هل انت متاكد من وضع علامة قيد التجهيز علي هذا الطلب ؟"	,
    "Are you sure to mark as Cancelled this order" : "هل انت متاكد من وضع علامة الغاء علي هذا الطلب ؟"	,
    "Reason for cancellation" : "سبب الالغاء",
    "Do you want to refund it" : 	"هل تريد استرجاع",
    "Refund Payment" : "استرداد المبلغ",
    "Cash Refund" : "استرداد نقدي",
    "Bank Refund" : "استرداد بنكي",
    "Card Refund" : "استرداد بالبطاقه",
    "Refund Done By" : "الاسترداد تم ب" ,
    "Return To Stock" : "العوده الي المخزن",
    "Wastage" : "الفقد" ,
    "Customer Type (All)" : "نوع العميل",
    "Unpaid" : 	"غير مدفوع",
    "Search By Mobile Number" : "البحث من خلال رقم التليفون",
    "Total Order" : "الطلب كامل" ,
    "Unpaid Order" : "طلب غير مدفوع",
    "Last Order" : "اخر طلب" ,
    "Phone NO" : "رقم التليفون" ,
    "View Note" :	"عرض الملاحظه",
    "Add Notes" : 	"اضف ملاحظه",
    "Note" : "ملاحظه",
    "View Notes" : "عرض الملاحظه",
    "Total Orders" : "الطلبات كامله"	,
    "Pending orders" : "اوامر معلقة",
    "Pending Sales" : 	"المبيعات المعلقه",
    "Search By Order ID" : 	"بحث حسب الرقم التعريفي ااطلب",
    "Order Status (All)" : "حالة الطلب" ,
    "Delivered" : "تم التوصيل ",
    "Payment Type (All)" : "نوع الدفع"	,
    "Cash_Bank" : "نقدي - بنكي",
    "Branch (All)" : "فرع",
    "Order Date Time" : "الطلب التاريخ الوقت",
    "Status" : "الحاله",
    "Amount" : "مقدار" ,
    "Pay Date Time" : "الدفع التاريخ الوقت",
    "Inventory Details" : "تفاصيل المخزون"	,
    "Category (All)" : "الفئه",
    "Stock Count" : "عدد الاسهم" ,
    "Stocks" : "المخزون" ,
    "Order Locations" : "موقع الطلب",
    "customer Name" : 	"اسم العميل" ,
    "Quntity" : "كميه"	,
    "Order Status" : "حالة الطلب",
    "Orderd Date" : "تاريخ الطلب",
    "Orders By Mode" : "طلب حسب الوضع"	,
    "Delivery Charges" : "رسوم التوصيل",
    "Add On Charges" : "اضافة علي الرسوم",
    "Payment Mode" : 	"وضع الدفع"	,
    "Sales Report" : 	"تقرير المبيعات",
    "Total Cash" : "مجموع المبالغ النقديه"	,
    "Total Bank" : "مجموع المبلغ البنكي" ,
    "Total Sales Amount" : "اجمالي مبلغ المبيعات" ,
    "Refund" :  "استرجاع المبلغ",
    "Logo" : "شعار",
    "Branch Name" : "اسم الفرع"	,
    "Ratings" : 	"التقييمات",
    "Timezone" : 	"الوحده الزمنيه",
    "Building/House No" : "مبني رقم",
    "Building/House Name" : "اسم المبني",
    "Block" : "حي",
    "Street" : "شارع",
    "State" : "دوله",
    "City" : "مدينه"	,
    "Language" : 	"اللغه" ,
    "TimeZone" : 	"الوحده الزمنيه",
    "Currency" : "عمله",
    "Latitude" : "خط العرض",
    "Longitude" :  	"خط الطول"	,
    "Add Menu" : "اضافة قائمه",
    "Code" : "كود",
    "Phone 1" : "هاتف 1",
    "Phone 2" : "هاتف 2",
    "Start Time" : "وقت البدء",
    "End Time" : "وقت الانتهاء",
    "Branch Logo" : "شعار الفرع",
    "Inactive" : "غير نشيط",
    "Service Areas" : 	"مناطق الخدمه"	,
    "Service Area" : "منطقة الخدمه",
    "No. of Tables" : "عدد الطاولات",
    "Add More" : "اضافة المزيد",
    "Delivery/Pickup" : "توصيل/ الاستلام",
    "Roles" : "الادوار" ,
    "ROLE" : "الدور" ,
    "Add" : "اضافه"	,
    "Edit" : "تعديل"	,
    "Role" : "وظيفة",
    "Role Name" : "اسم الوظيفه",
    "Enter Role Name" : "ادخال اسم الوظيفه" ,
    "Access Rights & Permissions" : "حقوق الوصول و الاذن",
    "Default Page" : "الصفحات الافتراضيه",
    "Staff" :  "العامليين",
    "Phone Number" : "رقم التليفون",
    "Password" : "الرقم السري" ,
    "Language Preferance" : "تفضيلات اللغه"	,
    "Photo" : "صوره",
    "Category Name" : "اسم الفئه",
    "Display Order" : "ترتيب العرض",
    "Item Category" : "فئة العنصر" ,
    "Add Item Category" : "اضافة فئة العنصر" ,
    "Edit Item Category"  :	"تعديل فئة العنصر",	
    "Category Name (Arabic)" :	"اسم الفئه" ,	
    "Image" :	"صوره"	,
    "Items under" :	"العناصر تحت" ,	
    "Print All Barcodes" : "طباعة كل الرموز الشريطيه"	,
    "Price" : "السعر",	
    "Discount Price" :	"سعر الخصم"	,
    "Dis. Odr" :	"رقم العرض" ,	
    "Item Information" :"معلومات العنصر",	
    "Advanced Details" :	"تفاصيل اكثر"	,
    "Category" :	"فئه"	,
    "Item Name (Arabic)" :	"اسم العنصر", 	
    "Description" :	"وصف"	,
    "Description (Arabic)" :	"وصف"	,
    "Item Size" :	"حجم الصنف",	
    "Single" :	"فردي"	,
    "Multiple" :	"اختياري"	,
    "Do you want to add Discount" :	"هل تريد اضافة خصم"	,
    "Do you want to add Stocks" :	"هل تريد اضافة مخزون",	
    "Selling Price" :	"سعر البيع" , 	
    "Cost Price" :	"سعر التكلفه",	
    "Discounted Selling Price":	"سعر البيع المخفض" 	,
    "Stock":	"المخزون" ,	
    "Enter Price" :	"ادخل السعر",	
    "Enter Stocks" :	"ادخل المخزون"	,
    "Expiry Date" :	"تاريخ انتهاء الصلاحيه"	,
    "Discount apply for" :	"تطبيق الخصم ل"	,
    "Visible For Dining" :	"الظهور للصاله"	,
    "Visible For Pickup Delivery" :	"الظهور  للتوصيل",
    "Ingredients" :	"المكونات"	,
    "Ingredient Name" :	"اسم المكون",
    "Ingredients Type" : "نوع المكون",
    "Ingredient" : "المكونات",
    "AddOn" : "اضافه علي",
    "Both" : "سويا",
    "ADD-Ons" : "اضافات",
    "Addon Name" : "اسم المضاف",
    "AddOn Price" : "سعر المضاف",
    "Item Type" : "نوع الصنف",
    "Veg" : "نباتي",
    "Non-Veg" : "غير نباتي",
    "Egg Only" : "بيض فقد",
    "Spicy Level" : "حار",
    "Mild" : "لطيف",
    "Medium" : "متوسط",
    "Hot" : "سخن",
    "Combos" : "كومبو",
    "Combo Settings" : "اعدادات الكومبو",
    "Combo Alternative Name" : "الاسم البديل للكومبو",
    "combo display position" : "مكان عرض الكومبو",
    "First" : "اول",
    "Last" : "اخر",
    "Combo Name" : "اسم الكومبو",
    "Combo Type" : "نوع الكومبو",
    "Fixed combo" : "كومبو ثابت",
    "Customized Combo" : "كومبو حسب الطلب",
    "Category shown as branches selected" : "الفئه المعروضه كفروع مختاره",
    "Tax Group" : "مجموعه ضريبيه",
    "Applied For" : "يقدم علي",
    "Rate" : "معدل",
    "Pickup & Delivery" : "الاستلام و التسليم",
    "Locations" : "المواقع",
    "Location Name" : "اسم الموقع",
    "Drivers" : "السائقين",
    "Driver Name" : "اسم السائق",
    "Vehicle Number" : "رقم العربه",
    "Name" : "الاسم",
    "Commission" : "عموله",
    "Discount/Coupon" : "كوبون / خصم",
    "Coupon Code" : "رقم الكوبون",
    "Discount Type" : "نوع الخصم",
    "Discount Percentage" : "نسبة الخصم",
    "Debit card/Credit card/Online Payments" : "بطاقة ائتمان / الدفع اونلاين",
    "Payment" : "الدفع",
    "Card Name" : "اسم الكارت",
    "Bank Charges" : "الرسوم المصرفيه",
    "Ingredients/Add On" : "المكونات / اضافه علي",
    "Ingredients/Add-On" : "المكونات / اضافه علي",
    "Commisions" : "عموله",
    "In Price" : "في السعر",
    "In Percentage" : "في النسبه",
    "Delivery Source" : "مصدر التسليم",
    "Payment Gateway" : "بوابة الدفع",
    "App ID/Key" : "مفتاح التطبيق",
    "Secret Key" : "المفتاح السري",
    "Start Preparing" : "بدا التجهيز",
    "Mark as ready" : "وضع علامه علي انه جاهز",
    "Not Added" : "غير مضافه",
    "Added" : "مضافه",
    "Maximimum" : "الحد الاقصي",
    "Dining" : "الصاله",
    "View Menu" : "عرض القائمه",
    "Country" : "البلد",
    "Branch" : "الفرع",
    "Email" : "البريد الالكتروني",
    "English" : "اللغه الانجليزيه",
    "Pickup/Delivery" : "الاستلام و التسليم",
    "Add-on" : "اضافه",
    "Item" : "عنصر",
    "Note:Category shown as branches selected" : "ملحوظة عرض الخانه عند اختيار الفرع",
    "Combo" : "كومبو",
    "Title" : "عنوان",
    "Ingredients/AddOn" : "المكونات /المضاف",
    "Coupon" : "كوبون",
    "Card" : "بطاقة",
    "Add-On" : "اضافه",
    "Ingredient & Add-On" : "المكونات /المضاف",
    "Merchant MID" : "تاجر MID",
    "Merchant Key" : "مفتاح التاجر",
    "Merchant Website" : "موقع التاجر",
    "Mobile Number" : "رقم التيليفون",
    "Pickup Order Type" : "نوع استلام الطلب",
    "Car Details" : "تفاصيل السياره",
    "Area" : "المنطقه",    
    "Address" : "العنوان",
    "Pin Code" : "رمز pin",
    "Remarks" : "ملاحظات",
    "Confirm Order"  : "تاكيد الطلب",
    "Paid" : "مدفوعه",
    "Refunded" : "تم استردادها",
    "Not-Paid" : "غير مدفوعه",
    "Table No" : "رقم الطاوله",
    "Contact Detail" : "تفاصيل الاتصال",
    "Order Taken By" : "تم اخذ الطلب عن طريق",
    "Refund done By" : "تم الاسترداد بواسطة",
    "Payment taken By" : "الدفع عن طريق",
    "Cash Payment" : "الدفع نقدي",
    "Bank Payment" : "الدفع بواسطة البنك",
    "All" : "كل",
    "New Order" : "طلب جديد",
    "Vehicle No" : "رقم لوحة العربه",
    "Order Number" : "رقم الطلب",
    "Phone" : "رقم التليفون",
    "Bill No" : "فاتوره",
    "NOT PAID" : "غير مدفوع",
    "Time" : "الوقت",
    "QTY" : "QTY",
    "RATE" : "معدل",
    "AMT" : "AMT",
    "Total Items Sold" : "اجمالي العناصر المباعه",
    "Subtotal" : "المجموع الفرعي",
    "Cash Tendered" : "مناقصة النقد",
    "Change" : "تغييير",
    "Bill To" : "مشروع قانون ل",
    "Mob" : "موبيل",
    "Activate Payment Link" : "رابط الدفع النشط",
    "Copy Text" : "نسخ النص",
    "Record Payment" : "سجل الدفع",
    "Edit Stocks" : "تعديل المخزون",
    "your selected order paying amount" : "مبلغ دفع طلبك المحدد",
    "Pay now" : "ادفع الان",
    "Do you want to print Receipt" : "هل تريد طباعة ايصال",

    "Ordered Date" : "تاريخ الطلب",
    "New" : "جديد",
    "Choose Date" : "اختار تاريخ",
    "BANK" : "بنك",
    "CASH & BANK" : "كاش و البنك",
    "Sub-Total" : "المجموع الفرعي",
    "Order" : "طلب",
    "Amount Given" : "الكميه",
    "UNPAID" : "لم يدفع بعد",
    "Discount Orders" : "الطلبات المخفضة",
    "Closed" : "مغلق",
    "Open" : "يعمل",
    "All Rights Reserved" : "كل الشروط و الاحكام",
    "Choose Your Size" :"اختار الحجم",
    "Select your Ingredients" :"تحديد المكونات",
    "Select your Add-On" :"تحديد الاضافات",
    "Add Item" :"اضافة عنصر",
    "Proceed to Address" :"اكمل للعنوان",
    "Home" :"منزل",
    "Add Location" :"اضافة مكان",
    "Paid Amount" :"اجمالي الدفع",
    "Refundable Amount" :"المبلغ القابل للاسترداد",
    "We are cancelling following items from your order" :"سنلغى العناصر الاتية من طلبك طلب",
    "Enter Mobile Number" :"ادخل رقم الهاتف",
    "Use Same Details" :"استخدم البينات",
    "Excluded Ingredients" :"استبعاد المكونات",
    "Choose" :"اختار",
    "Office" :"مكتب",
    "Delivery" : "توصيل",
    "Charge" : "تكلفة",
    "Enter Other City" : "أدخل مدينة أخرى",
    "Units":"وحدات",
    "Quantity":"الكميه",
    "Details":"التفاصيل",
    "Ingrediants Reports":"تقرير المكونات",
    "Gross Profit":"اجمالي الربح",
    "Total Tax":"الضريبه",
    "Total Wastage":"الهدر",
    "Net Profit":"صافي الربح",
    "Item name":"سم العنصر",
    "Return to stock":"العوده الى المخزون",
    "Recipe Ingredients":"المكونات",
    "Add Note" : 	"اضف ملاحظه",
    "Add Item Note" : "أضافه ملحوظة",
    "Customizable" : "قابل للتعديل",



 

}